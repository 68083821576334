import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, Dialog, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import { ApiPaths } from "../../../service/api-constants";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import AautiUpload from "../../globalComponents/AautiUpload";
import { showToast } from "../../globalComponents/Toast";
import constantSizes from "../commonInstitutes/CommonConstants";
import DialogTopBar from "../commonInstitutes/DialogTopBar";
import { InstituteAuthService } from "../instituteServices/institute-api-services";
import ButtonsComponent from "../commonInstitutes/ButtonsComponent";

const CreateOrEditInstituteInformation = ({
  model,
  onClose,
  data,
  modalTitle,
}) => {
  const { instituteId } = useSelector((state) => state.app);
  const MainHeading =
    modalTitle === "Edit"
      ? "Update Institute Information"
      : "Add Institute Information";
  const dispatch = useDispatch();

  const [items, setItems] = useState(
    modalTitle === "Edit"
      ? [data]
      : [
          {
            header: "",
            description: "",
            uploadImage:
              "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Mamidisetti Lakshmi Bank Book.jpeg",
            instituteId: instituteId,
            deleted: false,
          },
        ]
  );

  useEffect(() => {
    setItems(
      modalTitle === "Edit"
        ? [data]
        : [
            {
              header: "",
              description: "",
              instituteId: instituteId,
              deleted: false,
              uploadImage:
                "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Mamidisetti Lakshmi Bank Book.jpeg",
            },
          ]
    );
  }, [modalTitle]);

  useEffect(() => {
    checkValidations();
  }, [items]);
  const updateBranch = () => {
    dispatch(setAautiLoader(true));
    // const authData = [InstituteInfo]
    console.log(items);

    InstituteAuthService.updateInstituteInformation(
      items[0],
      onSuccessUpdateInstituteBranch,
      onFailureUpdateInstituteBranch
    );
  };

  const onSuccessUpdateInstituteBranch = (response) => {
    dispatch(setAautiLoader(false));
    showToast("success", response?.message);

    closeDialog();
  };

  const onFailureUpdateInstituteBranch = (error) => {
    dispatch(setAautiLoader(false));
    showToast("error", error);
    closeDialog();
  };

  const handleSaveAndAdd = () => {
    modalTitle == "Edit" ? updateBranch() : createBranch();
  };

  /****Create Api done */
  const createBranch = () => {
    dispatch(setAautiLoader(true));
    const authData = [items];
    console.log(items, "<---AUTH");
    InstituteAuthService.createInstituteInformation(
      items,
      onSuccessCreateInstituteBranch,
      onFailureCreateInstituteBranch
    );
  };
  const onSuccessCreateInstituteBranch = (response) => {
    dispatch(setAautiLoader(false));

    showToast("success", response?.message);
    closeDialog();
  };

  const onFailureCreateInstituteBranch = (error) => {
    dispatch(setAautiLoader(false));
    alert("error");
    showToast("error", error);
    closeDialog();
  };

  /***Validations ***/
  const [ShowErr, setShowErr] = useState(false);

  const checkValidations = () => {
    items.map((each) => {
      const boolVal =
        each?.header?.length >= 3 && each?.description?.length >= 50;
      setShowErr(boolVal);
    });
  };
  /***Onchange Inputs working */
  const handleInputChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
    checkValidations();
  };

  /***Add Section working */
  const addItem = () => {
    const newItem = {
      id: items.length + 1,
      header: "",
      description: "",
    };
    setItems([...items, newItem]);
  };

  const closeDialog = () => {
    setItems([
      {
        header: "",
        description: "",
        uploadImage: [],
        instituteId: instituteId,
        deleted: false,
      },
    ]);
    onClose();
  };

  const onRemoveCard = (item, index) => {
    if (items?.length !== 1) {
      let updatedlist = [];

      console.log(items?.length, "<----items?.length");
      {
        items.map((each, eachindex) => {
          if (eachindex !== index) {
            updatedlist = [...updatedlist, each];
          }
        });
      }
      console.log(updatedlist, "<----------updated");
      setItems(updatedlist);
    }
  };

  const onChangeFun = async (val, feild) => {
    if (feild === "uploadImage") {
      const formData = new FormData();
      formData.append("file", val.target.files[0]);
      let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
        method: "post",
        body: formData,
      });
      let responseJson = await res.json();

      if (responseJson.result !== null) {
        setItems((prev) => [
          {
            ...prev,
            [feild]: responseJson?.result,
          },
        ]);
      }
    } else {
      setItems((prev) => [
        {
          ...prev,
          [feild]: val,
        },
      ]);
    }
  };

  return (
    <Dialog keepMounted open={model} onClose={closeDialog} sx={{}}>
      <Card
        sx={{
          flexGrow: 1,
          paddingTop: 20,
          width: 400,
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AautiText
          size="medium"
          weight={"bold"}
          title={MainHeading}
          textAlign={"center"}
          textStyle={{ marginBottom: 5 }}
        />

        <Button
          variant="outlined"
          size="small"
          sx={{ width: "100%", flex: 1, marginY: 1 }}
          onClick={() => addItem()}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Section
        </Button>

        <Box sx={{ overflow: "scroll", width: "100%" }}>
          {items.map((item, index) => (
            <Card
              sx={{ marginY: 1, width: "100%", borderColor: "red", p: 1.5 }}
            >
              <DialogTopBar
                textAlign={"center"}
                disabled={items?.length === 1}
                textStyle={{ marginBottom: 5 }}
                onClose={() => onRemoveCard(item, index)}
              />
              <>
                <AautiTextInput
                  text={"Title"}
                  important
                  style={{ width: "100%" }}
                  value={item.header}
                  onChange={(e) =>
                    handleInputChange(index, "header", e.target.value)
                  }
                />
                {true && (
                  <AautiText
                    size={constantSizes.aautiTextSmall}
                    weight={constantSizes.aautiTextWeightNormal}
                    title={`(Minimum 3 Characters)`}
                    // textAlign={"center"}
                    textStyle={{ marginBottom: 5 }}
                  />
                )}
                <AautiTextInput
                  text={"Description"}
                  important
                  multiline
                  rows={3}
                  style={{ width: "100%", marginBottom: 1 }}
                  value={item.description}
                  onChange={(e) =>
                    handleInputChange(index, "description", e.target.value)
                  }
                />
                {true && (
                  <AautiText
                    size={constantSizes.aautiTextSmall}
                    weight={constantSizes.aautiTextWeightNormal}
                    title={`${item?.description?.length}/1000 (Minimum 50 Characters)`}
                    textStyle={{ marginBottom: 5 }}
                  />
                )}

                <AautiUpload
                  accept="image/*"
                  iconName="Upload Image "
                  important
                  id="UploadImage"
                  onChange={(e) => {
                    onChangeFun(e, "uploadImage");
                  }}
                />
                {items?.uploadImage && (
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: 6,
                      padding: 5,
                      position: "relative",
                      height: 110,
                      width: 140,
                      marginTop: 10,
                      backgroundColor: "red",
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setItems((prev) => [
                          {
                            ...prev,

                            uploadImage: "",
                          },
                        ]);
                      }}
                      sx={{
                        position: "absolute",
                        right: 1,
                        background: "#fff",
                        "&:hover": {
                          background: "#fff",
                        },
                      }}
                    >
                      <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
                    </IconButton>
                    <img
                      src={items?.uploadImage}
                      style={{ height: "100%", width: "100%" }}
                      alt="cancelled check"
                    />
                  </div>
                )}
              </>
            </Card>
          ))}
        </Box>

        <ButtonsComponent
          onClickButton1={() => closeDialog()}
          onClickButton2={() => handleSaveAndAdd()}
          btnOneText={"Cancel"}
          btnTwoText={modalTitle === "Edit" ? "Update" : "Submit"}
          btnTwodisabled={!ShowErr}
        />
      </Card>
    </Dialog>
  );
};

export default CreateOrEditInstituteInformation;
