import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import InPersonIcon from "../../../assets/images/drop-inperson.png";
const InPerson = () => {
  return (
    <Tooltip title={"In Person"}>
      <IconButton
        sx={{
          mb: -0.5,
          p: 0.8,
        }}
      >
        <img
          src={InPersonIcon}
          alt="inPerson"
          style={{
            height: "18px",
            width: "18px",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default InPerson;
