export default function AautiCaps(string) {
  var grammar = [
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "with",
    "on",
    "at",
    "to",
    "from",
    "by",
    "is",
    "this"
  ]
  if (!string) return string
  var capString = string?.toLowerCase()
  capString = capString
    .split(". ")
    .map(function (word) {
      return !grammar.includes(word) ? word.charAt(0).toUpperCase() + word.slice(1) : word
    })
    .join(". ")
  return capString
}
