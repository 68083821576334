import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import { showToast } from "../../globalComponents/Toast";
import BasicModal from "../commonInstitutes/BasicModel";
import BasicPopover from "../commonInstitutes/BasicPopOver";
import PageHeading from "../commonInstitutes/PageHeading";
import { InstituteAuthService } from "../instituteServices/institute-api-services";
import "../Style.css";
import CreateOrEditInstituteInformation from "./CreateOrEditInstituteInformation";
import AautiText from "../../globalComponents/AautiText";
import constantSizes from "../commonInstitutes/CommonConstants";
import SkeletonVariants from "../commonInstitutes/SkeletonComponent";

const InstituteInfo = () => {
  const [responselist, setResponseList] = useState([]);
  const [Data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [DeleteModel, setDeleteModel] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  const { instituteId } = useSelector((state) => state.app);
  const [editInstituteData, setEditInstituteData] = useState();
  const [isloding, setisloding] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeSearchInput = (value) => {
    setSearchInput(value);
    setData(
      responselist.filter((each) =>
        each?.branchName?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    getInstituteInformation();
  }, []);

  /***Get all institues working */
  const getInstituteInformation = () => {
    dispatch(setAautiLoader(true));
    const params = { instituteId };
    InstituteAuthService.GETInstituteInformation_ByInstituteId(
      params,
      onSuccessGetInstituteInformation,
      onFailureGetInstituteInformations
    );
  };
  const onSuccessGetInstituteInformation = (response) => {
    if (
      response !== undefined &&
      response !== null &&
      response?.result !== undefined &&
      response?.result !== null
    ) {
      // dispatch(setAautiLoader(false));
      setResponseList(response?.result);
      setisloding(false);
      setData(response?.result);
    }
  };
  const onFailureGetInstituteInformations = (error) => {
    // dispatch(setAautiLoader(false));
  };

  const handleClick = (event, item) => {
    setEditInstituteData(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createBranch = () => {
    setModalTitle("Create");
    setOpenModel(true);
  };

  const onCloseModel = () => {
    setOpenModel(false);
  };

  const editBranch = () => {
    setAnchorEl(false);
    setModalTitle("Edit");
    setOpenModel(true);
  };
  const handleCloseModel = () => {
    setDeleteModel(!DeleteModel);
    setAnchorEl(null);
  };

  /***Delete api working */
  const deleteInstitute = () => {
    dispatch(setAautiLoader(true));
    InstituteAuthService.DeleteInstituteInformation(
      { id: editInstituteData._id },
      onSuccessDeleteInstitute,
      onFailureDeleteInstitute
    );
  };

  const onSuccessDeleteInstitute = (response) => {
    handleCloseModel();
    // dispatch(setAautiLoader(false));
    showToast("success", response?.message);

    getInstituteInformation();
  };
  const onFailureDeleteInstitute = (error) => {
    // dispatch(setAautiLoader(false));
    showToast("error", error);
  };

  return (
    <Grid className="GridMainConatiner">
      <PageHeading
        pageTitle={"Institutions"}
        searchInput={searchInput}
        onChangeSearchInput={onChangeSearchInput}
        btnText={"Create"}
        onClickAdd={createBranch}
        search
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {isloding
          ? Data?.map((index) => (
              <Card key={index} className="GridCardContainer">
                <SkeletonVariants width={"100%"} height={"20vh"} />
              </Card>
            ))
          : Data?.map((each) => (
              <Card className="GridCardContainer">
                <img
                  style={{ height: "50%", width: "100%" }}
                  src={each.uploadImage}
                  alt={"upload image"}
                  loading="lazy"
                />
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={(event) => handleClick(event, each)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  sx={{ p: 0 }}
                  title={each.header}
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent sx={{ m: 0, p: 0 }}>
                  {/* <Typography variant="body2" color="text.secondary">
                                {each?.description}
                            </Typography> */}

                  <AautiText
                    size={constantSizes.aautiTextSmall}
                    weight={constantSizes.aautiTextWeightNormal}
                    title={each?.description}
                  />
                </CardContent>
              </Card>
            ))}
        <BasicPopover
          Id={id}
          isopen={open}
          anchor={anchorEl}
          onClose={handleClose}
          Text1={"Edit"}
          Text2={"Delete"}
          Text1Function={editBranch}
          Text2Function={() => setDeleteModel(!DeleteModel)}
        />
        {DeleteModel && (
          <BasicModal
            title="Aauti"
            text="Are you sure you want to delete this Institute."
            isShow={DeleteModel}
            Bfunction1={handleCloseModel}
            Bfunction2={deleteInstitute}
            buttontext1="Cancel"
            buttontext2="Delete"
          />
        )}
      </div>
      <CreateOrEditInstituteInformation
        model={openModel}
        onClose={onCloseModel}
        data={editInstituteData}
        modalTitle={modalTitle}
      />
    </Grid>
  );
};

export default InstituteInfo;
