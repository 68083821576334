const header = 'Assign Instructors and Participants'
const unassigned = 'Unassigned Courses'
const assigned = 'Assigned Courses'
const instructor = 'Instructors'
const participant = 'Participants'
const bottomTabs = [instructor, participant]
const assign = 'Assign'
const individual = 'individual'
const group = 'group'
const update = 'Update'
const level = 'Levels'
const category = 'Categories'
const UnassignedUsers = 'Unassigned'
const AssignedUsers = 'Assigned'

export const Constants = {
  header,
  unassigned,
  assigned,
  instructor,
  participant,
  tabs: bottomTabs,
  assign,
  individual,
  group,
  update,
  level,
  category,
  UnassignedUsers,
  AssignedUsers,
  virtual: 'Virtual',
}
