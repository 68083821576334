import {
  AiOutlineBranches,
  AiOutlineHome,
  AiOutlineSchedule,
} from "react-icons/ai";
import {
  BsFileEarmarkPlay,
  BsPersonAdd,
  BsPersonCheck,
  BsPersonHeart,
} from "react-icons/bs";
import { FaListCheck, FaRepeat } from "react-icons/fa6";
import { LuPackagePlus, LuUserCheck } from "react-icons/lu";
import {
  MdCalendarMonth,
  MdOutlineAssignment,
  MdOutlineAttachMoney,
  MdOutlineEventAvailable,
  MdOutlineGroups,
  MdOutlinePlayLesson,
  MdOutlineReviews,
  MdOutlineSettings,
  MdOutlineSupportAgent,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { PiBriefcase } from "react-icons/pi";
import { RiBankLine, RiCalendarTodoLine, RiCoupon2Fill } from "react-icons/ri";
import { SlBookOpen } from "react-icons/sl";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { FiInfo } from "react-icons/fi";
import { BiSolidDashboard } from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbAddressBook } from "react-icons/tb";

export const spAccountItems = [
  {
    id: 4,
    label: "My Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/my-courses",
  },
  {
    id: 690,
    label: "My Schedule",
    icon: AiOutlineSchedule,
    screenName: "/my-schedule",
  },
  {
    id: 8,
    label: "My Gigs",
    icon: MdOutlineVideoLibrary,
    screenName: "/view-gigs",
  },
  {
    id: 6,
    label: "Lesson Plans",
    icon: MdOutlinePlayLesson,
    screenName: "/myLesson-plans",
  },
  {
    id: 56,
    label: "Adhoc Events",
    icon: RiCalendarTodoLine,
    screenName: "/adhoc-events",
  },
  {
    id: 56,
    label: "Course Adhoc Events",
    icon: RiCalendarTodoLine,
    screenName: "/course-adhoc-events",
  },
  {
    id: 1094,
    label: "Adhoc Class Pricing",
    icon: SlBookOpen,
    screenName: "/adhoc-price",
  },

  // {
  //   id: 9,
  //   label: "Schedule",
  //   icon: MdCalendarMonth,
  //   screenName: "/AdhocEvent",
  // },

  {
    id: 139,
    label: "Students",
    icon: MdOutlineGroups,
    screenName: "/students",
    children: [],
  },
  {
    id: 11,
    label: "My Revenue",
    icon: MdOutlineAttachMoney,
    screenName: "/my-revenue",
  },
  {
    id: 5,
    label: "My Packages",
    icon: LuPackagePlus,
    screenName: "/my-packages",
    children: [],
  },
  {
    id: 39,
    label: "Attendance",
    icon: BsPersonCheck,
    screenName: "/attendance-details",
  },
  {
    id: 1989,
    label: "Feedback",
    icon: FiInfo,
    screenName: "/feedback",
  },
  {
    id: 1953,
    label: "My Address",
    icon: TbAddressBook,
    screenName: "address",
  },
  {
    id: 86,
    label: "Refund Configuration",
    icon: MdOutlineAttachMoney,
    screenName: "/refund-configuration",
  },
];

export const endUserAccount = [
  {
    id: 4,
    label: "My Learnings",
    icon: BsFileEarmarkPlay,
    screenName: "/my-learnings",
  },
  {
    id: 33,
    label: "My Favorite SP",
    icon: BsPersonHeart,
    screenName: "/my-instructors",
  },
  {
    id: 9,
    label: "Schedule",
    icon: MdCalendarMonth,
    screenName: "/search-result",
  },
  {
    id: 10,
    label: "Wishlist",
    icon: FavoriteBorderIcon,
    screenName: "/wishlist",
  },
  {
    id: 7,
    label: "Add Member",
    icon: BsPersonAdd,
    screenName: "/members-list",
  },
  {
    id: 39,
    label: "Attendance",
    icon: BsPersonCheck,
    screenName: "/attendance-details",
  },
  {
    id: 8,
    label: "My Gigs",
    icon: MdOutlineVideoLibrary,
    screenName: "/view-gigs",
  },
  // {
  //   id: 85,
  //   label: "My Orders",
  //   icon: MdOutlineAttachMoney,
  //   screenName: "/orders",
  // },
  {
    id: 198977,
    label: "Feedback",
    icon: FiInfo,
    screenName: "/feedback",
  },
  {
    id: 19863,
    label: "My Address",
    icon: TbAddressBook,
    screenName: "address",
  },
];

export const spSettigsList = [
  {
    id: 11,
    label: "Reviews & Ratings",
    icon: MdOutlineReviews,
    screenName: "/reviews-ratings",
    children: [],
  },
  {
    id: 71,
    label: "Bank Details",
    icon: RiBankLine,
    screenName: "/bank-details",
  },
  {
    id: 111,
    label: "Transfer Requests",
    icon: MdOutlineVideoLibrary,
    screenName: "/transfer-requests",
  },
  {
    id: 101,
    label: "Payout Requests",
    icon: MdOutlineAttachMoney,
    screenName: "/payout-requests",
  },
  {
    id: 102,
    label: "Reschedule & Merging",
    icon: FaRepeat,
    screenName: "/reschedule-merge",
  },
  {
    id: 13,
    label: "Coupons & Discounts",
    icon: RiCoupon2Fill,
    screenName: "/coupons",
    children: [],
  },
  {
    id: 120,
    label: "Sponsered Courses",
    icon: FaListCheck,
    screenName: "/sponsered-courses",
    children: [],
  },
  {
    id: 14,
    label: "My Interests",
    icon: InterestsOutlinedIcon,
    // screenName: "/interests",
    screenName: "/profile/interests",
    children: [],
  },
  {
    id: 94,
    label: "My Professions",
    icon: PiBriefcase,
    screenName: "/select-profession",
  },
  {
    id: 8,
    label: "Help & Support",
    icon: MdOutlineSupportAgent,
    screenName: "/help-support",
  },
  // {
  //   id: 37,
  //   label: "About App",
  //   icon: FiInfo,
  //   screenName: "/whyAauti",
  // },
  // { id: 11, label: "Change Password", icon: LockResetIcon, screenName: "" },
  // { id: 12, label: "Delete Account", icon: FiInfo, screenName: "" },
  {
    id: 220,
    label: "Delete Account",
    icon: FiInfo,
    screenName: "/delete-deactivate",
  },
  {
    id: 86,
    label: "Refund Configuration",
    icon: MdOutlineAttachMoney,
    screenName: "/refund-configuration",
  },
];

export const endUserSettings = [
  {
    id: 14,
    label: "My Interests",
    icon: InterestsOutlinedIcon,
    // screenName: "/interests",
    screenName: "/profile/interests",
    children: [],
  },
  {
    id: 1,
    label: "Refunds",
    icon: MdOutlineAttachMoney,
    screenName: "/refunds",
  },
  // { id: 11, label: "Change Password", icon: LockResetIcon, screenName: "" },
  {
    id: 8,
    label: "Help & Support",
    icon: MdOutlineSupportAgent,
    screenName: "/help-support",
  },
  {
    id: 220,
    label: "Delete Account",
    icon: FiInfo,
    screenName: "/delete-deactivate",
  },
  {
    id: 86,
    label: "Refund Configuration",
    icon: MdOutlineAttachMoney,
    screenName: "/refund-configuration",
  },
  // {
  //   id: 37,
  //   label: "About App",
  //   icon: FiInfo,
  //   screenName: "/whyAauti",
  // },
];

export const InstituteSideItems = [
  {
    id: 1,
    label: "Dashboard",
    icon: BiSolidDashboard,
    screenName: "/institute/dashboard",
  },
  {
    id: 2,
    label: "Home Institues",
    icon: AiOutlineHome,
    screenName: "/institute/home",
  },
  {
    id: 3,
    label: "Branches",
    icon: AiOutlineBranches,
    screenName: "/institute/branches",
  },
  {
    id: 6,
    label: "Institute Availbility",
    icon: MdOutlineEventAvailable,
    screenName: "/institute/Availbility",
  },
  {
    id: 8,
    label: "Holidays",
    icon: MdCalendarMonth,
    screenName: "/institute/holidays",
  },
  {
    id: 9,
    label: "Instructors Details",
    icon: LiaChalkboardTeacherSolid,
    screenName: "/institute/instructors/instructor-details",
  },
  {
    id: 10,
    label: "Participants Details",
    icon: HiOutlineUsers,
    screenName: "/institute/participants/participants-details",
  },
  {
    id: 23,
    label: "My Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/my-courses",
  },
  {
    id: 13,
    label: "Ongoing Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/institute/ongoingCourses",
  },
  {
    id: 14,
    label: "Upcoming Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/institute/upcomingCourses",
  },
  {
    id: 15,
    label: "Completed Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/institute/completedCourses",
  },
  {
    id: 17,
    label: "Top Instructors",
    icon: LiaChalkboardTeacherSolid,
    screenName: "/institute/topInstructors",
  },
  {
    id: 18,
    label: "Gigs",
    icon: MdOutlineVideoLibrary,
    screenName: "/institute/gigs",
  },
  {
    id: 19,
    label: "Settings",
    icon: MdOutlineSettings,
    screenName: "/settings",
  },
  {
    id: 20,
    label: "Assigning ",
    icon: MdOutlineAssignment,
    screenName: "/institute/assigning/instructorsAndParticipants",
  },
  {
    id: 21,
    label: "Terms  And  Conditions",
    icon: MdCalendarMonth,
    screenName: "/institute/termsAndConditions",
  },
  {
    id: 22,
    label: "About Institute",
    icon: MdCalendarMonth,
    screenName: "/aboutInstitute",
  },
];

export const InstructorSideItems = [
  {
    id: 1,
    label: "Dashboard",
    icon: BiSolidDashboard,
    screenName: "/instructor/dashboard",
  },
  {
    id: 2,
    label: "Home Instructor",
    icon: AiOutlineHome,
    screenName: "/instructor",
  },
  {
    id: 3,
    label: "Participants Details",
    icon: HiOutlineUsers,
    screenName: "/instructor-participants-details",
  },
  {
    id: 4,
    label: "Reviews & Ratings",
    icon: MdOutlineReviews,
    screenName: "/intructors-ratings-reviews",
  },
  {
    id: 5,
    label: "SP-Attendance",
    icon: LuUserCheck,
    screenName: "/instructor/sp-attendance",
  },
  {
    id: 6,
    label: "My Courses",
    icon: MdCalendarMonth,
    screenName: "/instructor/my-courses",
  },
  {
    id: 7,
    label: "SP-Lessons",
    icon: MdOutlinePlayLesson,
    screenName: "/sp-lessons",
  },
  {
    id: 8,
    label: "Lesson-plan",
    icon: MdOutlinePlayLesson,
    screenName: "/lessons-plan",
  },
  {
    id: 19,
    label: "Settings",
    icon: MdOutlineSettings,
    screenName: "/settings",
  },
];
export const ParticipantsSideItems = [
  {
    id: 1,
    label: "Dashboard",
    icon: BiSolidDashboard,
    screenName: "/participant/dashboard",
  },
  {
    id: 2,
    label: "Home Instructor",
    icon: MdCalendarMonth,
    screenName: "/participant",
  },
  {
    id: 3,
    label: "My Courses",
    icon: MdCalendarMonth,
    screenName: "/participant/my-courses",
  },
  {
    id: 7,
    label: "EU - Attendance",
    icon: BiSolidDashboard,
    screenName: "/participant/eu-attendance",
  },
  {
    id: 8,
    label: "Privacy Policy",
    icon: BiSolidDashboard,
    screenName: "/participant/privacyPolicy",
  },
  {
    id: 8,
    label: "Report this Course",
    icon: BiSolidDashboard,
    screenName: "/participant/reportCourse",
  },
];
