import React, { useEffect, useState } from "react";
import GlobalListComponent from "../globalComponents/GlobalListComponent";
import { useLocation, useParams } from "react-router-dom";
import { AuthService } from "../../service";

const CoursesByCategory = () => {
  const [courseList, setCoursesList] = useState([]);
  const { category } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const screenName = "courseRelatedToCategory";
  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
    fetchData(e.target.value, {});
  };
  const handleClickApply = (filterKeys) => {
    fetchData("", filterKeys);
  };

  const fetchData = (value = "", obj = {}) => {
    setLoading(true);
    let payload = { ...obj, count: 100, offset: 0 };
    if (category) {
      payload.category = [category];
    }
    if (value) {
      payload.searchKey = value;
    }

    AuthService.getCourseByCategory(
      payload,
      (response) => {
        setLoading(false);
        if (response?.result) {
          setCoursesList(response?.result);
        }
      },
      (error) => {
        console.error(error, "error-getting-courses-category");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData("", {});
  }, [category]);

  return (
    <GlobalListComponent
      searchValue={searchInput}
      onChangeSearchValue={onChangeSearchInput}
      handleClickApply={handleClickApply}
      screenName={screenName}
      list={courseList}
      headerText={category}
      {...{ loading }}
    />
  );
};

export default CoursesByCategory;
