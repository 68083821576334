import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "./Constants";
import { showToast } from "../../globalComponents/Toast";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import { InstituteAuthService } from "../instituteServices/institute-api-services";

const additionalLevels = [
  "Pre-Beginner",
  "Beginner",
  "Pre-Intermediate",
  "Intermediate",
  "Pre-Advanced",
  "Advanced",
  "Proficiency",
  "Job Role Training",
  "Formal Training",
  "Personal Improvement",
  "Activities, Hobbies",
];

const apiStatus = {
  idle: "idle",
  loading: "loading",
  success: "success",
  failed: "failed",
  processing: "processing",
};

const courseUpdateClassTypes = ["Individual", "Group"];
const howToTeach = ["Virtual", "In-Person"];
const dbKey = "_id";

function convertToUtc(date) {
  return moment(date).utc().format();
}

export function checkNull(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    value === "null" ||
    value?.length === 0 ||
    value == 0
  );
}
export function checkNA(data) {
  if (true) {
    return checkNull(data) ? "N/A" : data;
  } else {
    return checkNull(data) ? "" : data;
  }
}

const Container = () => {
  const { instituteId, loginUserId, categories } = useSelector(
    (state) => state.app
  );
  // let allBranches
  let languages;

  const inputFields = [
    {
      name: Constants.level,
      list: additionalLevels,
    },
    {
      name: Constants.category,
      list: categories,
    },
    {
      name: "Class Type",
      list: howToTeach,
    },
    {
      name: "Course Type",
      list: courseUpdateClassTypes,
    },
    // {
    //   name: 'Languages',
    //   list: languages,
    // },
  ];

  const [data, setData] = useState({
    unassignedCourseData: [],
    assignedCourseData: [],
    selectedCard: {},
    openBatchBS: false,
    isUnassigned: false,
    openUploadBs: false,
    type: Constants.tabs[0],
    instructors: [],
    participants: [],
    selectedBatch: {},
    openUserBS: false,
    selectedInstructors: [],
    selectedParticipants: [],
    isInstSelectAll: false,
    isPartSelectAll: false,
    courseType: "",
    isBtnEnabled: false,
    searchInst: [],
    searchPart: [],
    isBatchExpired: false,
    batchInst: [],
    batchPart: [],
    searchBatchInst: [],
    searchBatchPart: [],
    expandCourseState: {
      selectedCourseId: "",
      selectedBatchId: "",
      selectedBranch: "",
      selectedCourseData: {},
      courseBranches: [],
      selectedBranchCode: "",
    },
    inputFields: inputFields,
    isFilterOpen: false,
    category: {},
    classType: [],
    duplicateType: [],
    levels: [],
    branches: [],
    language: [],
    filterCourseType: [],
    selectedCourseBranches: [],
    branchCode: "",
    openBranchList: false,
    unassignedUser: [],
    assignedUser: [],
    selectedUserType: Constants.UnassignedUsers,
    instructorDetails: [],
    totalSlotsAvail: 0,
    openMenuId: false,
    primarySP: "",
  });

  const dispatch = useDispatch();

  let isInstructor = data?.type === Constants.instructor ? true : false;

  let date = new Date();

  useEffect(() => {
    let array = [];
    if (!checkNull(data?.duplicateType) && data?.duplicateType?.length > 0) {
      data?.duplicateType?.map((each) => {
        if (each === Constants.virtual) {
          array.push("virtual");
        }
        if (each !== Constants.virtual) {
          array.push("inPerson");
        }
      });
    }
    setData((prev) => ({ ...prev, classType: array }));
  }, [data?.duplicateType]);

  const getApis = (searchKey = "") => {
    let params = {
      instituteId,
      timeZone: moment().utcOffset(),
      searchKey: "",
    };
    if (data?.levels?.length > 0) {
      params.levels = data?.levels;
    }
    if (data?.language?.length > 0) {
      params.language = data?.language;
    }
    if (data?.classType?.length > 0) {
      params.classType = data?.classType;
    }
    if (data?.filterCourseType?.length > 0) {
      params.courseType = data?.filterCourseType;
    }
    if (data?.category?.category?.length > 0) {
      params.category = data?.category?.category;
      params.subCategories = data?.category?.subCategories;
    }
    if (data?.branches?.length > 0) {
      params.branches = data?.branches;
    }
    // if (!data?.isFilterOpen) {
    //   if (data?.levels?.length > 0) {
    //     params.levels = data?.levels
    //   }
    //   if (data?.language?.length > 0) {
    //     params.language = data?.language
    //   }
    //   if (data?.classType?.length > 0) {
    //     params.classType = data?.classType
    //   }
    //   if (data?.filterCourseType?.length > 0) {
    //     params.courseType = data?.filterCourseType
    //   }
    //   if (data?.category?.category?.length > 0) {
    //     params.category = data?.category?.category
    //     params.subCategories = data?.category?.subCategories
    //   }
    //   if (data?.branches?.length > 0) {
    //     params.branches = data?.branches
    //   }
    // }
    if (searchKey?.length > 0) {
      params.searchKey = searchKey;
    }
    InstituteAuthService.getUnassignedCourses(params, onSuccess, onFailure);
    InstituteAuthService.getAssignedCourses(
      params,
      onSuccessAssigned,
      onFailure
    );
  };

  useEffect(() => {
    let unassigned = [];
    let assigned = [];
    if (data?.type === Constants.instructor) {
      data?.searchInst?.map((each) => {
        if (each?.selected) {
          assigned.push(each);
        } else {
          unassigned.push(each);
        }
      });
    } else {
      data?.searchPart?.map((each) => {
        if (each?.selected) {
          assigned.push(each);
        } else {
          unassigned.push(each);
        }
      });
    }
    setData((prev) => ({
      ...prev,
      unassignedUser: unassigned,
      assignedUser: assigned,
      selectedUserType:
        unassigned?.length > 0
          ? Constants.UnassignedUsers
          : Constants.AssignedUsers,
    }));
  }, [data?.searchInst, data?.searchPart, data?.type]);

  useEffect(() => {
    if (!data?.isFilterOpen) {
      dispatch(setAautiLoader(true));
      getApis();
    }
  }, [data?.isFilterOpen]);

  // useEffect(() => {
  //   if (allBranches?.length > 0) {
  //     let res = inputFields
  //     res = [
  //       ...inputFields,
  //       {
  //         name:'Branches',
  //         data: allBranches,
  //       },
  //     ]
  //     setData((prev) => ({
  //       ...prev,
  //       inputFields: res,
  //     }))
  //   }
  // }, [allBranches])

  useEffect(() => {
    let inst = [];
    let part = [];
    data?.instructors?.map((each) => {
      if (data?.selectedInstructors?.includes(each?.[dbKey])) {
        inst?.push(each);
      }
    });
    data?.participants?.map((each) => {
      if (data?.selectedParticipants?.includes(each?.[dbKey])) {
        part?.push(each);
      }
    });
    setData((prev) => ({
      ...prev,
      batchInst: inst,
      batchPart: part,
      searchBatchInst: inst,
      searchBatchPart: part,
    }));
  }, [
    data?.selectedInstructors,
    data?.selectedParticipants,
    data?.instructors,
    data?.participants,
  ]);

  useEffect(() => {
    // dispatch(setAautiLoader(true))
    getBranchesandLanguages();
  }, []);

  const getBranchesandLanguages = async () => {
    const response = await InstituteAuthService.Institute_GetallBranchesbyId({
      instituteId,
    });
    const allBranches = response?.data?.result;
    let inputdata = [];
    if (allBranches?.length > 0) {
      inputdata = [
        ...inputFields,
        {
          name: "Branches",
          list: allBranches,
        },
      ];
    }
    const resp = await InstituteAuthService.getAllLanguages();
    const languages = resp?.data?.result;
    if (languages?.length > 0) {
      inputdata = [
        ...inputdata,
        {
          name: "Languages",
          list: languages,
        },
      ];
    }
    setData((prev) => ({
      ...prev,
      inputFields: inputdata,
    }));
  };
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      isBtnEnabled:
        !checkNull(data?.selectedInstructors) &&
        data?.selectedInstructors?.length > 0 &&
        !checkNull(data?.selectedParticipants) &&
        data?.selectedParticipants?.length > 0
          ? true
          : false,
    }));
    if (data?.courseType === Constants.group) {
      if (data?.selectedBatch?.availableSlots === data?.selectedParticipants) {
        showToast({ text: "All Slots are Filled" });
      }
    }
    if (
      checkNull(data?.selectedInstructors) &&
      data?.selectedInstructors?.length <= 0
    ) {
      setData((prev) => ({ ...prev, isInstSelectAll: false }));
    }
    if (
      checkNull(data?.selectedParticipants) &&
      data?.selectedParticipants?.length <= 0
    ) {
      setData((prev) => ({ ...prev, isPartSelectAll: false }));
    }
  }, [data?.selectedInstructors, data?.selectedParticipants]);

  const callApi = (value, onSuccess, branchCode = "") => {
    dispatch(setAautiLoader(true));
    let code = !checkNull(data?.branchCode)
      ? data?.branchCode
      : !checkNull(branchCode)
      ? branchCode
      : "";
    let params = {
      instituteId,
      branchCode: code,
      category: data?.selectedCard?.category,
      subCategory: data?.selectedCard?.subCategory,
      batchId: value?.[dbKey],
    };
    setData((prev) => ({ ...prev, branchCode: code, primarySP: "" }));
    InstituteAuthService.getAssignedUserList(params, onSuccess, onFailure);
  };

  const getBranchesForCourse = (value, onSuccess) => {
    dispatch(setAautiLoader(true));
    let params = {
      instituteId,
      courseId: data?.selectedCard?.[dbKey],
      batchId: value?.[dbKey],
    };
    InstituteAuthService.fetchCourseBranches(params, onSuccess, onFailure);
  };

  const onSuccessGettingUL = (response) => {
    dispatch(setAautiLoader(false));
    const res = response?.data?.result;
    if (
      response?.data?.status?.toLowerCase() === apiStatus.success &&
      !checkNull(response?.data?.result)
    ) {
      let inst = [];
      let part = [];
      !checkNull(res) &&
        res?.instructors?.map((each) => {
          if (each?.selected) {
            inst?.push(each?.[dbKey]);
          }
        });
      !checkNull(res) &&
        res?.participants?.map((each) => {
          if (each?.selected) {
            part?.push(each?.[dbKey]);
          }
        });
      setData((prev) => ({
        ...prev,
        instructors: checkNull(res) ? [] : res?.instructors,
        participants: checkNull(res) ? [] : res?.participants,
        openUserBS: !data?.openUserBS,
        searchInst: checkNull(res) ? [] : res?.instructors,
        searchPart: checkNull(res) ? [] : res?.participants,
        selectedInstructors: inst,
        selectedParticipants: part,
        primarySP: checkNull(res) ? "" : res?.serviceProviderId,
      }));
    }
  };

  const onSuccessAfterUpload = (response) => {
    dispatch(setAautiLoader(false));
    const res = response?.data?.result;
    if (
      response?.data?.status?.toLowerCase() === apiStatus.success &&
      !checkNull(response?.data?.result)
    ) {
      setData((prev) => ({
        ...prev,
        instructors: checkNull(res) ? [] : res?.instructors,
        participants: checkNull(res) ? [] : res?.participants,
        searchInst: checkNull(res) ? [] : res?.instructors,
        searchPart: checkNull(res) ? [] : res?.participants,
      }));
    }
  };

  const onSuccess = (response) => {
    dispatch(setAautiLoader(false));
    if (response?.status?.toLowerCase() === apiStatus?.success?.toLowerCase()) {
      setData((prev) => ({
        ...prev,
        unassignedCourseData: response?.result,
      }));
    }
  };

  const onSuccessAssigned = (response) => {
    dispatch(setAautiLoader(false));
    if (response?.status?.toLowerCase() === apiStatus?.success?.toLowerCase()) {
      setData((prev) => ({
        ...prev,
        assignedCourseData: response?.result,
      }));
    }
  };

  const cleanStates = () => {
    setData((prev) => ({
      ...prev,
      isInstSelectAll: false,
      isPartSelectAll: false,
      isBtnEnabled: false,
      selectedBatch: {},
      selectedInstructors: [],
      selectedParticipants: [],
      openUserBS: false,
      openUploadBs: false,
      instructors: [],
      participants: [],
      courseType: "",
      type: Constants.tabs[0],
      branchCode: "",
      expandCourseState: {
        ...data?.expandCourseState,
        selectedBatchId: "",
        selectedCourseId: "",
        selectedBranchCode: "",
        selectedBranch: "",
        courseBranches: [],
        selectedCourseData: {},
      },
      instructorDetails: [],
    }));
  };

  const onFailure = (error) => {
    dispatch(setAautiLoader(false));
    console.log(error, "BATCH errrorrrrrrrrr");

    showToast({ text: error?.message });
  };

  const getCardData = () => {
    const res = {};
    data?.unassignedCourseData?.find((each) => {
      if (each?.[dbKey] === data?.selectedCard?.[dbKey]) {
        Object.assign(res, each);
      }
    });

    if (Object.keys(res)?.length <= 0) {
      data?.assignedCourseData?.find((each) => {
        if (each?.[dbKey] === data?.selectedCard?.[dbKey]) {
          Object.assign(res, each);
        }
      });
    }
    setData((prev) => ({
      ...prev,
      selectedCard: Object.keys(res)?.length > 0 ? res : data?.selectedCard,
    }));
  };

  useEffect(() => {
    if (
      !checkNull(data?.selectedCard) &&
      Object.keys(data?.selectedCard)?.length > 0
    ) {
      getCardData();
    }
  }, [data?.assignedCourseData, data?.unassignedCourseData]);

  const onSuccessSave = (response) => {
    dispatch(setAautiLoader(false));
    if (response?.data?.status?.toLowerCase() === apiStatus.success) {
      dispatch(setAautiLoader(true));
      cleanStates();
      getApis();
    }
  };

  const callInstApi = (params) => {
    dispatch(setAautiLoader(true));
    InstituteAuthService.getAssignedUserList(
      params,
      onSuccessGetInst,
      onFailure
    );
  };

  const onCallFunc = (key, value) => {
    console.log(key, value, "availabel slots");
    switch (key) {
      case Constants.unassigned:
        setData((prev) => ({
          ...prev,
          selectedCard: value,
          isUnassigned: true,
          openBatchBS: !data?.openBatchBS,
          selectedBatch: {},
          type: Constants?.tabs?.[0],
        }));
        break;
      case Constants.assigned:
        setData((prev) => ({
          ...prev,
          selectedCard: value,
          isUnassigned: false,
          openBatchBS: !data?.openBatchBS,
          selectedBatch: {},
          type: Constants?.tabs?.[0],
        }));
        break;
      case "openUploadBS":
        setData((prev) => ({
          ...prev,
          openUploadBs: !data?.openUploadBs,
        }));
        break;
      case "closeBS":
        cleanStates();
        setData((prev) => ({ ...prev, openBatchBS: false }));
        break;
      case "closeUploadBS":
        setData((prev) => ({
          ...prev,
          openUploadBs: false,
        }));
        break;
      case "onSubmit":
        setData((prev) => ({
          ...prev,
          openUploadBs: false,
        }));
        callApi(data?.selectedBatch, onSuccessAfterUpload);
        break;
      case "type":
        setData((prev) => ({
          ...prev,
          type: value,
          searchInst: data?.instructors,
          searchPart: data?.participants,
          searchBatchInst: data?.batchInst,
          searchBatchPart: data?.batchPart,
        }));
        break;
      case "individualBatch":
        setData((prev) => ({
          ...prev,
          selectedBatch: value,
          type: Constants?.tabs?.[0],
          courseType: Constants.individual,
          isBatchExpired: convertToUtc(value?.endDate) < convertToUtc(date),
          branchCode: "",
        }));

        const onSuccessGetBranch = (response) => {
          dispatch(setAautiLoader(false));
          const res = response?.data?.result;
          if (
            response?.data?.status?.toLowerCase() === apiStatus.success &&
            !checkNull(response?.data?.result)
          ) {
            setData((prev) => ({ ...prev, selectedCourseBranches: res }));
            callApi(value, onSuccessGettingUL, res?.[0]?.branchCode);
          }
        };
        getBranchesForCourse(value, onSuccessGetBranch);
        break;
      case "groupBatch":
        setData((prev) => ({
          ...prev,
          selectedBatch: value,
          type: Constants?.tabs?.[0],
          courseType: Constants.group,
          isBatchExpired: convertToUtc(value?.endDate) < convertToUtc(date),
          branchCode: "",
        }));
        const onSuccessGetBranches = (response) => {
          dispatch(setAautiLoader(false));
          const res = response?.data?.result;
          if (
            response?.data?.status?.toLowerCase() === apiStatus.success &&
            !checkNull(response?.data?.result)
          ) {
            setData((prev) => ({ ...prev, selectedCourseBranches: res }));
            callApi(value, onSuccessGettingUL, res?.[0]?.branchCode);
          }
        };
        getBranchesForCourse(value, onSuccessGetBranches);
        break;
      case "userBSClose":
        setData((prev) => ({
          ...prev,
          openUserBS: false,
          isBtnEnabled: false,
          isInstSelectAll: false,
          isPartSelectAll: false,
          selectedInstructors: [],
          selectedParticipants: [],
          branchCode: "",
        }));
        break;
      case "userChecked":
        if (data?.type === Constants.participant) {
          if (data?.selectedParticipants?.includes(value)) {
            setData((prev) => ({
              ...prev,
              selectedParticipants: data?.selectedParticipants?.filter(
                (each) => each !== value
              ),
            }));
          } else {
            if (data?.courseType === Constants.group) {
              if (
                data?.selectedBatch?.maxNoOfStudents ===
                data?.selectedParticipants?.length
              ) {
                showToast({ text: "All Slots are Filled" });
              } else {
                setData((prev) => ({
                  ...prev,
                  selectedParticipants: [...data?.selectedParticipants, value],
                }));
              }
            } else {
              if (data?.selectedParticipants?.length === 1) {
                showToast({ text: "All Slots are Filled" });
              } else {
                setData((prev) => ({
                  ...prev,
                  selectedParticipants: [...data?.selectedParticipants, value],
                }));
              }
            }
          }
        } else {
          if (data?.selectedInstructors?.includes(value)) {
            let result = data?.selectedInstructors?.filter(
              (each) => each !== value
            );
            setData((prev) => ({
              ...prev,
              primarySP:
                data?.primarySP === value
                  ? result?.[0]?.length > 0
                    ? result?.[0]
                    : ""
                  : data?.primarySP,
              selectedInstructors: result,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              selectedInstructors: [...data?.selectedInstructors, value],
              primarySP:
                data?.selectedInstructors?.length > 0 ? data?.primarySP : value,
            }));
          }
        }
        break;
      case "selectAll":
        if (isInstructor) {
          if (data?.isInstSelectAll) {
            setData((prev) => ({
              ...prev,
              selectedInstructors: isInstructor
                ? []
                : data?.selectedInstructors,
              isInstSelectAll: isInstructor
                ? !data?.isInstSelectAll
                : data?.isInstSelectAll,
            }));
          } else {
            let res = [];
            data?.instructors?.map((each) => {
              res.push(each?.[dbKey]);
            });
            setData((prev) => ({
              ...prev,
              selectedInstructors: res,
              isInstSelectAll: !data?.isInstSelectAll,
            }));
          }
        } else {
          if (data?.isPartSelectAll) {
            setData((prev) => ({
              ...prev,
              selectedParticipants: !isInstructor
                ? []
                : data?.selectedParticipants,
              isPartSelectAll: !isInstructor
                ? !data?.isPartSelectAll
                : data?.isPartSelectAll,
            }));
          } else {
            if (data?.courseType === Constants.group) {
              if (
                !checkNull(data?.selectedBatch?.maxNoOfStudents?.toString()) &&
                data?.selectedBatch?.maxNoOfStudents > 0
              ) {
                let ids = [];

                data?.participants?.map((each, index) => {
                  if (index + 1 <= data?.selectedBatch?.maxNoOfStudents) {
                    ids.push(each?.[dbKey]);
                  }
                });
                setData((prev) => ({
                  ...prev,
                  selectedParticipants: ids,
                  isPartSelectAll: !data?.isPartSelectAll,
                }));
              }
            } else {
              let res = data?.participants?.[0]?.[dbKey];
              setData((prev) => ({
                ...prev,
                selectedParticipants: [res],
                isPartSelectAll: !data?.isPartSelectAll,
              }));
            }
          }
        }
        break;
      case Constants.assign:
        dispatch(setAautiLoader(true));
        const res = {
          courseId: data?.selectedCard?.[dbKey],
          instituteId,
          batchId: data?.selectedBatch?.[dbKey],
          instructors: data?.selectedInstructors,
          serviceProviderId: data?.primarySP,
          participants: data?.selectedParticipants,
          instituteAdminId: loginUserId,
          branchCode: data?.branchCode,
        };
        InstituteAuthService.saveAssignCourse(res, onSuccessSave, onFailure);
        break;
      case "usersSearch":
        setData((prev) => ({
          ...prev,
          searchInst: isInstructor ? value : data?.instructors,
          searchPart: !isInstructor ? value : data?.participants,
          searchBatchInst: isInstructor ? value : data?.batchInst,
          searchBatchPart: !isInstructor ? value : data?.batchPart,
        }));
        break;
      case "mainSearch":
        getApis(value);
        break;
      case "selectedCourse":
        const id =
          value?.groupDaily?.length > 0
            ? value?.groupDaily?.[0]?.[dbKey]
            : value?.groupWeekly?.length > 0
            ? value?.groupWeekly?.[0]?.[dbKey]
            : value?.individualDaily?.length > 0
            ? value?.individualDaily?.[0]?.[dbKey]
            : value?.individualWeekly?.length > 0
            ? value?.individualWeekly?.[0]?.[dbKey]
            : "";

        const getBranches = (response) => {
          const res = response?.data?.result;
          dispatch(setAautiLoader(false));
          if (response?.data?.status?.toLowerCase() === apiStatus.success) {
            setData((prev) => ({
              ...prev,
              expandCourseState: {
                ...data.expandCourseState,
                selectedCourseId:
                  data?.expandCourseState?.selectedCourseId === value?.[dbKey]
                    ? ""
                    : value?.[dbKey],
                selectedBatchId: id,
                selectedBranch: res?.[0]?.branchName,
                selectedCourseData: value,
                courseBranches: res,
                selectedBranchCode: res?.[0]?.branchCode,
              },
              instructorDetails: [],
              totalSlotsAvail: 0,
            }));
            let stateValue = value;
            let param = {
              instituteId,
              branchCode: res?.[0]?.branchCode,
              category: stateValue?.category,
              subCategory: stateValue?.subCategory,
              batchId: id,
            };
            callInstApi(param);
          }
        };

        if (data?.expandCourseState?.selectedCourseId !== value?.[dbKey]) {
          dispatch(setAautiLoader(true));
          let req = {
            instituteId,
            courseId: value?.[dbKey],
            batchId: id,
          };
          InstituteAuthService.fetchCourseBranches(req, getBranches, onFailure);
        } else {
          setData((prev) => ({
            ...prev,
            expandCourseState: {
              ...data?.expandCourseState,
              selectedCourseId: "",
              selectedBatchId: "",
              selectedBranch: "",
              selectedCourseData: {},
              courseBranches: [],
            },
            instructorDetails: [],
            totalSlotsAvail: 0,
          }));
        }

        break;
      case "expandBatch":
        if (data?.expandCourseState?.selectedBatchId !== value) {
          setData((prev) => ({
            ...prev,
            expandCourseState: {
              ...data.expandCourseState,
              selectedBatchId: value,
            },
            instructorDetails: [],
            totalSlotsAvail: 0,
          }));
          let dataValue = data?.expandCourseState;
          let paramObj = {
            instituteId,
            branchCode: dataValue?.selectedBranchCode,
            category: dataValue?.selectedCourseData?.category,
            subCategory: dataValue?.selectedCourseData?.subCategory,
            batchId: value,
          };
          callInstApi(paramObj);
        } else {
          setData((prev) => ({
            ...prev,
            expandCourseState: {
              ...data.expandCourseState,
              selectedBatchId: "",
            },
            instructorDetails: [],
            totalSlotsAvail: 0,
          }));
        }
        break;
      case "openFilter":
        setData((prev) => ({ ...prev, isFilterOpen: !prev.isFilterOpen }));
        break;
      case "filterClose":
        setData((prev) => ({ ...prev, isFilterOpen: false }));

        break;
      case "filteredValues":
        setData((prev) => ({
          ...prev,
          duplicateType: value?.["Class Type"],
          levels: value?.Levels,
          filterCourseType: value?.["Course Type"],
          branches: value?.Branches,
          language: value?.Languages,
        }));
        getApis();
        break;
      case "filteredCatValue":
        setData((prev) => ({
          ...prev,
          category: value?.Category,
        }));
        getApis();
        break;
      case "cleanFilterStates":
        setData((prev) => ({
          ...prev,
          classType: [],
          levels: [],
          filterCourseType: [],
          branches: [],
          language: [],
          category: {},
          duplicateType: [],
        }));
        break;
      case "batchRelatedUsers":
        setData((prev) => ({ ...prev, branchCode: value }));
        let params = {
          instituteId,
          branchCode: value,
          category: data?.selectedCard?.category,
          subCategory: data?.selectedCard?.subCategory,
          batchId: data?.selectedBatch?.[dbKey],
        };
        dispatch(setAautiLoader(true));
        InstituteAuthService.getAssignedUserList(
          params,
          onSuccessAfterBU,
          onFailure
        );
        break;
      case "openBranchList":
        setData((prev) => ({ ...prev, openBranchList: !data?.openBranchList }));
        break;
      case "closeBranchList":
        setData((prev) => ({ ...prev, openBranchList: false }));
        break;
      case "BS_branches":
        dispatch(setAautiLoader(true));
        setData((prev) => ({
          ...prev,
          expandCourseState: {
            ...data.expandCourseState,
            selectedBranch: value?.branchName,
            selectedBranchCode: value?.branchCode,
          },
          openBranchList: false,
        }));

        let stateValue = data?.expandCourseState?.selectedCourseData;
        let param = {
          instituteId,
          branchCode: value?.branchCode,
          category: stateValue?.category,
          subCategory: stateValue?.subCategory,
          batchId: data?.expandCourseState?.selectedBatchId,
        };
        callInstApi(param);
        break;
      case Constants.AssignedUsers:
        setData((prev) => ({
          ...prev,
          selectedUserType:
            data?.selectedUserType === Constants.AssignedUsers
              ? ""
              : Constants.AssignedUsers,
        }));
        break;
      case Constants.UnassignedUsers:
        setData((prev) => ({
          ...prev,
          selectedUserType:
            data?.selectedUserType === Constants.UnassignedUsers
              ? ""
              : Constants.UnassignedUsers,
        }));
        break;
      case "openMenu":
        setData((prev) => ({ ...prev, openMenuId: value }));
        break;
      case "hideMenu":
        setData((prev) => ({ ...prev, openMenuId: "" }));
        break;
      case "primarySP":
        let selectedData = [];
        if (data?.selectedInstructors?.includes(value)) {
          selectedData = data?.selectedInstructors;
        } else {
          selectedData = [...data?.selectedInstructors, value];
        }
        setData((prev) => ({
          ...prev,
          openMenuId: "",
          primarySP: value,
          selectedInstructors: selectedData,
        }));
        break;
    }
  };

  const onSuccessGetInst = (response) => {
    dispatch(setAautiLoader(false));
    const res = response?.data?.result;
    console.log(response?.data?.result, "BATCH CALLLLINGGGGGGGGG");
    if (
      response?.data?.status?.toLowerCase() === apiStatus.success &&
      !checkNull(response?.data?.result)
    ) {
      let inst = [];
      !checkNull(res) &&
        res?.instructors?.map((each) => {
          if (each?.selected) {
            inst?.push(each);
          }
        });
      setData((prev) => ({
        ...prev,
        instructorDetails: inst,
        totalSlotsAvail: res?.availableSlots,
      }));
    }
  };

  const onSuccessAfterBU = (response) => {
    dispatch(setAautiLoader(false));
    const res = response?.data?.result;
    if (
      response?.data?.status?.toLowerCase() === apiStatus.success &&
      !checkNull(response?.data?.result)
    ) {
      let inst = [];
      let part = [];
      !checkNull(res) &&
        res?.instructors?.map((each) => {
          if (each?.selected) {
            inst?.push(each?.[dbKey]);
          }
        });
      !checkNull(res) &&
        res?.participants?.map((each) => {
          if (each?.selected) {
            part?.push(each?.[dbKey]);
          }
        });
      setData((prev) => ({
        ...prev,
        instructors: checkNull(res) ? [] : res?.instructors,
        participants: checkNull(res) ? [] : res?.participants,
        searchInst: checkNull(res) ? [] : res?.instructors,
        searchPart: checkNull(res) ? [] : res?.participants,
        selectedInstructors: inst,
        selectedParticipants: part,
        selectedUserType: Constants.UnassignedUsers,
      }));
    }
  };

  return { data, onCallFunc };
};
export default Container;
