import SendSharpIcon from "@mui/icons-material/SendSharp";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef, useState } from "react";
import { messageInputPlaceholder } from "./constants";
import { getFileTypes, onChangeMsgInput, onClickSendButton } from "./container";
import "./styles.scss";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { BsFileEarmarkText } from "react-icons/bs";
import { IoCloseCircleOutline, IoMic, IoMicOff } from "react-icons/io5";
import { useSpeechRecognition } from "react-speech-kit";
import EmojiPicker from "emoji-picker-react";
import { MdEmojiEmotions } from "react-icons/md";
import AautiText from "../../../../globalComponents/AautiText";
import { MdOutlineAudioFile } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { ReactMic } from "react-mic";
import { isEmpty } from "lodash";
import { ApiPaths } from "../../../../../service/api-constants";
import moment from "moment";

export default function RenderTextInput(props) {
  const {
    chatUserDetails,
    msgInput,
    setMsgInput,
    sendClicked,
    setSendClicked,
    text,
    replyClicked,
    setReplyClicked,
    replyObj,
    setReplyObj,
    fileUrl,
    setFileUrl,
    freezedObj,
    setFreezedObj,
    screen,
    classDetails,
  } = props;
  const [fileContainer, setFileContainer] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  // const [isListening, setIsListening] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  // const [chosenEmoji, setChosenEmoji] = useState(null);
  const [initialFocus, setInitialFocus] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [blobURL, setBlobUrl] = useState("");
  const [isBetween, setIsBetween] = useState(false);
  const [isTextInputDisabled, setIsTextInputDisabled] = useState(false);
  const inputRef = useRef(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [FeedsText, setFeedsText] = useState("");
  const emojiPickerRef = useRef(null);
  // const { listen, stop } = useSpeechRecognition({
  //   onResult: (result) => {
  //     setMsgInput(result);
  //   },
  // });
  const audioRef = useRef(null);
  const { userDetails, loggedinUserId } = useSelector((state) => state.app);
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    setFileContainer(true);

    const uploadedFiles = [];

    try {
      setImageLoader(true);
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);

        const res = await fetch(ApiPaths.BASE_URL + "file/upload", {
          method: "post",
          body: formData,
        });

        const responseJson = await res.json();
        uploadedFiles.push({
          file: responseJson.result,
          type: getFileTypes(responseJson.result),
        });
      }

      setFileUrl((prevFileUrl) => [...prevFileUrl, ...uploadedFiles]);
      setImageLoader(false);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setImageLoader(false);
    }
  };

  // const handleToggleListen = () => {
  //   if (isListening) {
  //     stop();
  //   } else {
  //     listen();
  //   }
  //   setIsListening((prev) => !prev);
  // };

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const onData = (recordedBlob) => {};

  const onStop = async (recordedBlob) => {
    const filename = recordedBlob.blobURL.split("/").pop();
    const extension = recordedBlob.blob.type.split("/")[1].split(";")[0];
    const NewFile = filename + "." + extension;

    try {
      // Get binary data from recordedBlob
      const binaryData = await recordedBlob.blob.arrayBuffer();

      // Create a new Blob object from binary data
      const blobFromBinary = new Blob([binaryData]);

      // Create a FormData object
      const formData = new FormData();

      // Append the Blob data to the FormData object
      formData.append("file", blobFromBinary, NewFile);

      // Send FormData to the server
      let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
        method: "post",
        body: formData,
      });

      let responseJson = await res.json();
      if (responseJson.status === "Success") {
        setBlobUrl(responseJson.result);
        setFileUrl([
          {
            file: responseJson.result,
            type: "AUDIO",
          },
        ]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleEmojiClick = (emojiObject, event) => {
    // setChosenEmoji(emojiObject);
    setMsgInput((prevInput) => `${prevInput || ""}${emojiObject.emoji}`);
  };

  const handleAttachClick = () =>
    document
      .getElementById(
        text === "JoinClassChatScreen"
          ? "JoinAttachmentInput"
          : text === "Feeds"
          ? "FeedsAttachmentInput"
          : "ChatAttachmentInput"
      )
      .click();

  useEffect(() => {
    const sendButton = document.getElementById("text_input_msg_send_btn");
    const isInputEmpty = !msgInput;
    if (
      !chatUserDetails?.blockedUsers?.includes(chatUserDetails?.user?._id) &&
      !chatUserDetails?.freezed &&
      !chatUserDetails?.adminFreezed &&
      !chatUserDetails?.message?.text ===
        `${userDetails?.displayName} left the group`
    ) {
      sendButton.className = isInputEmpty
        ? fileUrl
          ? "send_button_styles_enabled"
          : "send_button_styles_disabled"
        : "send_button_styles_enabled";
    }
  }, [
    chatUserDetails?.blockedUsers,
    chatUserDetails?.freezed,
    chatUserDetails?.adminFreezed,
    chatUserDetails?.user?._id,
    msgInput,
    fileUrl,
    chatUserDetails?.message?.text,
    userDetails?.displayName,
  ]);

  const jsdsjd = chatUserDetails?.admins?.some(
    (each) => each === loggedinUserId
  );

  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (replyClicked && inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyClicked]);

  useEffect(() => {
    if (!initialFocus && inputRef.current) {
      inputRef.current.focus();
      setInitialFocus(true);
    }
  }, [initialFocus]);

  useEffect(() => {
    const startDateTime = moment.utc(chatUserDetails?.batch?.startDateTime);
    const endDateTime = moment.utc(chatUserDetails?.batch?.endDateTime);
    const currentDateTime = moment.utc();

    const startOfDay = moment.utc().startOf("day");
    const endOfDay = moment.utc().endOf("day");
    const isTodayBetween =
      startOfDay.isBetween(startDateTime, endDateTime, null, "[]") ||
      endOfDay.isBetween(startDateTime, endDateTime, null, "[]");

    if (isTodayBetween) {
      const isTimeBetween = currentDateTime.isBetween(
        startDateTime,
        endDateTime
      );
      setIsBetween(isTimeBetween);
    } else {
      setIsBetween(false);
    }
  }, [
    chatUserDetails?.batch?.endDateTime,
    chatUserDetails?.batch?.startDateTime,
  ]);

  useEffect(() => {
    const validateTextInput = () => {
      const startTime = moment(classDetails?.startTime);
      const endTime = moment(classDetails?.endTime);
      const currentDateTime = moment();

      // Check if the current date is the same as the schedule date
      const scheduleDate = startTime.format("DD-MM-YYYY");
      const currentDate = currentDateTime.format("DD-MM-YYYY");

      if (scheduleDate === currentDate) {
        // Check if the current time is between startTime and endTime
        const isTodayBetween = currentDateTime.isBetween(
          startTime,
          endTime,
          null,
          "[]"
        );

        if (isTodayBetween) {
          setIsTextInputDisabled(false);
        } else if (currentDateTime.isBefore(startTime)) {
          setFeedsText("Feeds frozen because the course has not started yet");
          setIsTextInputDisabled(true);
        } else {
          setFeedsText("Feeds frozen because the course was completed");
          setIsTextInputDisabled(true);
        }
      } else {
        setFeedsText("");
        setIsTextInputDisabled(true); // Disable input if it's not today
      }
    };

    validateTextInput();
  }, [classDetails, screen]);

  const handleCloseReply = () => {
    setReplyClicked(false);
    setReplyObj({});
  };

  // const textInputEnableValidations = () => {
  //   console.log(classDetails.endTime, "sldkkkkkkkkkkk");

  //   const startTime = moment(classDetails.startTime);
  //   const endTime = moment(classDetails.endTime);
  //   const now = moment();

  //   const scheduleDate = startTime.format("DD-MM-YYYY");
  //   const currentDate = now.format("DD-MM-YYYY");

  //   const isLessThanToday = scheduleDate < currentDate;
  //   const isGreaterThanToday = scheduleDate > currentDate;
  //   const isToday = scheduleDate === currentDate;

  //   if (isLessThanToday) {
  //     setFeedsText("Feeds froze because the course was not yet started");
  //     return true;
  //   } else if (isToday) {
  //     if (now.isBetween(startTime, endTime, null, "[)")) {
  //       return false;
  //     } else {
  //       setFeedsText("Feeds froze because the course was completed");
  //       return true;
  //     }
  //   } else if (isGreaterThanToday) {
  //     return false;
  //   }
  // };

  if (chatUserDetails?.blockedUsers?.includes(chatUserDetails?.user?._id)) {
    return <div className={"render_text_input_container"}>user is blocked</div>;
  } else if (
    chatUserDetails?.user?.exitDate ||
    chatUserDetails?.message?.text ===
      `${userDetails?.displayName} left the group`
  ) {
    return (
      <div className={"render_text_input_container"}>
        You exited from the group
      </div>
    );
  } else if (screen === "Feeds" && isTextInputDisabled) {
    return (
      <div
        style={{
          border: "0.5px solid #e3e3e3",
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          textAlign: "center",
          backgroundColor: "#f7f4e9",
        }}
      >
        <AautiText
          weight={"medium"}
          title={FeedsText}
          size={"normal"}
          textStyle={{
            color: "#1E1E1E",
          }}
        />
      </div>
    );
  } else if (
    (chatUserDetails?.freezed || chatUserDetails?.adminFreezed) &&
    !isBetween
  ) {
    if (
      (chatUserDetails.type === "COURSE" || chatUserDetails.type === "ADHOC") &&
      freezedObj
    ) {
      return (
        <div className={"render_text_input_container"}>
          The group will be enabled once the class starts
        </div>
      );
    } else {
      return (
        <div className={"render_text_input_container"}>The group is frozen</div>
      );
    }
  } else if (chatUserDetails?.access === "ONLY_ME" && !jsdsjd) {
    return (
      <div className={"render_text_input_container"}>
        Only admins can send messages
      </div>
    );
  } else {
    return (
      <div
        className={"render_text_input_container"}
        ref={emojiPickerRef}
        // style={{ width: "50%" }}
      >
        {(fileUrl?.length || imageLoader) &&
          (imageLoader ? (
            <Box
              sx={{ position: "relative" }}
              className={"image_input_container"}
            >
              <AautiText
                size={"medium"}
                weight={"medium"}
                textStyle={{
                  color: "#000000",
                }}
                title={"Uploading..."}
              />
            </Box>
          ) : (
            <div className={"image_input_container"}>
              {fileUrl?.map((_, key) => (
                <div className={"image_close_icon_container"} key={key}>
                  <HighlightOffTwoToneIcon
                    className={"icon_close_styles"}
                    onClick={() => {
                      setBlobUrl("");
                      setFileUrl((prevFileUrl) =>
                        prevFileUrl.filter((_, i) => i !== key)
                      );
                    }}
                  />

                  {_?.type === "DOCUMENT" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "14vw",
                      }}
                    >
                      <BsFileEarmarkText className={"document_icon_styles"} />
                      <AautiText
                        textAlign={"left"}
                        size={"small"}
                        weight={"medium"}
                        textStyle={{
                          color: "#4B4D4D",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "150px",
                        }}
                        title={new URL(_?.file).pathname.split("/").pop()}
                      />
                    </div>
                  ) : _?.type === "VIDEO" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "14vw",
                      }}
                    >
                      <video
                        width={210}
                        src={_?.file}
                        style={{ borderRadius: "5px" }}
                      />
                      <AautiText
                        textAlign={"left"}
                        size={"small"}
                        weight={"medium"}
                        textStyle={{
                          color: "#4B4D4D",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "150px",
                        }}
                        title={new URL(_?.file).pathname.split("/").pop()}
                      />
                    </div>
                  ) : _?.type === "AUDIO" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdOutlineAudioFile className={"document_icon_styles"} />
                      <AautiText
                        textAlign={"left"}
                        size={"small"}
                        weight={"medium"}
                        textStyle={{
                          color: "#4B4D4D",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "150px",
                        }}
                        title={new URL(_?.file).pathname.split("/").pop()}
                      />
                    </div>
                  ) : _?.type === "IMAGE" ? (
                    _?.file.split(".").pop() === "svg" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "14vw",
                        }}
                      >
                        <BsFileEarmarkText className={"document_icon_styles"} />
                        <AautiText
                          textAlign={"left"}
                          size={"small"}
                          weight={"medium"}
                          textStyle={{
                            color: "#4B4D4D",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "150px",
                          }}
                          title={new URL(_?.file).pathname.split("/").pop()}
                        />
                      </div>
                    ) : (
                      <img
                        src={_?.file}
                        className={"chat_image_container"}
                        alt={"text"}
                        // srcSet="image.jpg, image.png, image.gif, image.svg, image.bmp, image.tiff, image.webp"
                      />
                    )
                  ) : (
                    <BsFileEarmarkText className={"document_icon_styles"} />
                  )}
                </div>
              ))}
            </div>
          ))}
        {replyClicked && (
          <div className="rly-sp-cont-main">
            <div className="repl-sp-sub">
              <AautiText
                weight={"bold"}
                textStyle={{
                  color: "#828282",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
                title={replyObj?.sender?.displayName}
              />
              <AautiText
                weight={"normal"}
                textStyle={{
                  color: "#828282",
                  display: "flex",
                  alignSelf: "flex-start",
                  marginTop: 5,
                  fontSize: "12px",
                }}
                title={
                  replyObj.type === "POLL" ? replyObj.question : replyObj?.text
                }
              />
            </div>
            <button className="empty-button" onClick={handleCloseReply}>
              <RiCloseCircleFill color="red" size={20} />
            </button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className={"message_input_styles"} style={{ width: "100%" }}>
            <div className={"render_text_input_sub_container"}>
              <button
                className="empty-button"
                // onClick={handleToggleListen}
                style={{
                  backgroundColor: "white",
                  borderRadius: "30px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {isRecording ? (
                  <IoMicOff
                    onClick={stopRecording}
                    size={25}
                    color="#066cb5"
                    style={{ marginTop: 2 }}
                  />
                ) : (
                  <IoMic
                    onClick={startRecording}
                    size={25}
                    color={"#066cb5"}
                    style={{ marginTop: 2 }}
                  />
                )}
              </button>
              {blobURL && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      marginLeft: "20px",
                    }}
                  >
                    <p>Recorded audio:</p>
                    <audio
                      controls
                      ref={audioRef}
                      src={blobURL}
                      style={{
                        width: "80%",
                        marginLeft: "15px",
                        height: "30px",
                      }}
                    />
                    <IoCloseCircleOutline
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBlobUrl("");
                        setFileUrl([]);
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: isRecording ? "block" : "none",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <ReactMic
                  record={isRecording}
                  className="sound-wave"
                  onStop={onStop}
                  onData={onData}
                  strokeColor="#000000"
                  backgroundColor="transparent"
                />
              </div>
              <input
                ref={inputRef}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "100%",
                  outline: "none",
                  marginLeft: "8px",
                  display: !isRecording && isEmpty(blobURL) ? "block" : "none",
                }}
                value={msgInput}
                autoFocus={!initialFocus}
                placeholder={messageInputPlaceholder}
                onChange={(event) => onChangeMsgInput(event, { setMsgInput })}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    (msgInput !== "" || !isEmpty(fileUrl))
                  ) {
                    onClickSendButton(event, {
                      chatUserDetails,
                      msgInput,
                      setMsgInput,
                      sendClicked,
                      setFileContainer,
                      setSendClicked,
                      fileUrl,
                      setFileUrl,
                      replyClicked,
                      replyObj,
                      setReplyClicked,
                    });
                    setClicked(false);
                    if (replyClicked) {
                      setReplyClicked(false);
                      setReplyObj({});
                    }
                    setBlobUrl("");
                  }
                }}
              />
              {!isRecording && isEmpty(blobURL) && (
                <button
                  onClick={() => setClicked(!clicked)}
                  className="empty-button"
                  style={{ marginTop: 4 }}
                >
                  <MdEmojiEmotions
                    size={25}
                    color={isEmpty(msgInput) ? "lightgrey" : "#066cb5"}
                  />
                </button>
              )}

              {clicked && (
                <div id="MainEmoji">
                  <EmojiPicker width={"100%"} onEmojiClick={handleEmojiClick} />
                </div>
              )}
            </div>

            <input
              type="file"
              id={
                text === "JoinClassChatScreen"
                  ? "JoinAttachmentInput"
                  : text === "Feeds"
                  ? "FeedsAttachmentInput"
                  : "ChatAttachmentInput"
              }
              style={{ display: "none" }}
              onChange={handleFileUpload}
              multiple
            />

            {!isRecording && (
              <button
                disabled={
                  isEmpty(msgInput) && isEmpty(blobURL) && isEmpty(fileUrl)
                }
                id={"text_input_msg_send_btn"}
                className="send_button_styles_enabled"
                onClick={(event) => {
                  if (
                    msgInput !== "" ||
                    !isEmpty(fileUrl) ||
                    !isEmpty(blobURL)
                  ) {
                    onClickSendButton(event, {
                      chatUserDetails,
                      msgInput,
                      setMsgInput,
                      sendClicked,
                      setSendClicked,
                      setFileContainer,
                      fileUrl,
                      setFileUrl,
                      replyClicked,
                      replyObj,
                      setReplyClicked,
                    });
                    setClicked(false);
                    if (replyClicked) {
                      setReplyClicked(false);
                      setReplyObj({});
                    }
                    setBlobUrl("");
                  }
                }}
              >
                <SendSharpIcon
                  className={"send_icon_styles"}
                  style={{
                    color:
                      !isEmpty(msgInput) ||
                      !isEmpty(blobURL) ||
                      !isEmpty(fileUrl)
                        ? "#066cb5"
                        : "lightgrey",
                  }}
                />
              </button>
            )}
          </div>
          <IconButton
            className={"send_attachment_custom_message"}
            onClick={handleAttachClick}
          >
            <FiPlus />
          </IconButton>
        </div>
      </div>
    );
  }
}
