export const chatConstants = {
  clickOnUserText: `It's nice to chat with someone`,
  clickOnUserSubText: `Pick a person from users list and start your conversation.`,
  getConversations: `GET_CONVERSATIONS`,
  ping: `PING`,
  pong: `PONG`,
  tapHereToKnowGroupInfo: "Tap here to know group info",
  documentPreview: "Document Preview",
  startDate: "Start Date",
  endDate: "End Date",
  results: "Results",
  students: "Students",
  pollDetails: "Poll Details",
  yourQuestionWillStandHereBelowFollowsTheAnswers: `Your Question will stand here & below follows the answers`,
  assignedTo: "Assigned To",
  viewResults: "View Results",
  previewImage: "Preview Image",
  groupInfo: "Group Info",
  courseInfo: "Course Info",
  groupName: "Group name",
  checkImagesVideosOtherMediaUploadsHere:
    "Check Images, Videos & other media uploads here",
  whoCanSendMessages: "Who can send messages",
  everyone: "Everyone",
  onlyMe: "Only Me",
  participants: "Participants",
  search: "Search",
  reportAProblem: "Report a problem",
  reportIfYouFindAnyTechnicalOrVulgarActivitiesHappenedInTheGroup:
    "Report if you find any technical or vulgar activities happened in the group",
  deleteGroup: "Delete Group",
  deleteChat: "Delete Chat",
  reportUser: "Report User",
  mediaAndLinks: "Media & Links",
  blockUser: "Block User",
  removeUser: "Remove User",
  removeAsAdmin: "Remove as Admin",
  makeAsAdmin: "Make as Admin",
  update: "Update",
  groupDescription: "Group description",
  contactsList: "Contacts List",
  done: "Done",
  noChatsSelected: "Welcome!",
  searchForPeopleToAdd: "Search for people to add",
  create: "Create",
  noContactsFound: "No Contacts Found.",
  noChat: 'No chat available',
  download: 'Download',
  addGroupImage: 'Please add group image',
  addGroupName: 'Please add group name',
  addGroupDescription: 'Please add group description',
  addGroupMembers: 'Please add group members',
  chatAccessHeader: 'Who can send messages',
  everyOne: 'Everyone',
  onlyMe: 'Only me',
  blockUser: 'Block User',
  unblockUser: 'Unblock User',
  blockGroup: 'Block Group',
  unblockGroup: 'Unblock Group',
  clearConversation: 'Clear Conversation',
  createGroup: 'Create',
  updateGroup: 'Update Group',
  editGroup: 'Edit Group',
  reportGroup: 'Report Group',
  deleteGroup: 'Delete Group',
  deleteFromChat: 'Delete From Chat',
  freezeGroup: 'Freeze Group',
  unfreezeGroup: 'Unfreeze Group',
  reportUser: 'Report User',
  exitGroup: 'Exit Group',
  groupInfo: 'Group Info',
  addUser: 'Add User',
  remove: 'Remove',
  admin: 'Admin',
  reply:'Reply',
  copy:'Copy Text',
  edit:'Edit',
  delete:'Delete'
};
export const chatUserListConstants = {
  getConversations: `GET_CONVERSATIONS`,
  getContacts: `GET_CONTACTS`,
  ping: `PING`,
  pong: `PONG`,
  lottiePlayerStyles: { width: "200px", height: "auto", cursor: "pointer" },
  emptyImageUploadStyles: { position: "absolute", inset: 0, zIndex: 0 },
};

export const DEFAULT_PLACEHOLDER = 'Write your message!'
export const MIN_INPUT_TOOLBAR_HEIGHT = 60
export const MIN_COMPOSER_HEIGHT = 50
export const MAX_COMPOSER_HEIGHT = 120

export const conversationRoomTypes = {
  DEFAULT: 'DEFAULT',
  BOTTOM_SHEET: 'BOTTOM_SHEET',
}

export const SocketEvents = {
  connect: 'CONNECT',
  login: 'LOGIN',
  logout: 'LOGOUT',
  ping: 'PING',
  pong: 'PONG',
  connected: 'CONNECTED',
  users: {
    status: 'USER_STATUS',
    getUnreadCount: 'GET_UNREAD_COUNT',
    getOnlineStatus: 'GET_ONLINE_STATUS',
    getSupport: 'GET_SUPPORT',
  },
  getContacts: 'GET_CONTACTS',
  getCourseSubscribers: 'GET_COURSE_SUBSCRIBERS',
  room: {
    getRoom: 'GET_ROOM',
    createRoom: 'CREATE_ROOM',
    getMessagesByRoom: 'GET_MESSAGES_BY_ROOM',
    created: 'ROOM_CREATED',
    updated: 'ROOM_UPDATED',
    delete: 'DELETE_ROOM',
    leave: 'LEAVE_ROOM',
    clearConversation: 'CLEAR_CONVERSATION',
    getConversations: 'GET_CONVERSATIONS',
    updateLastSeen: 'UPDATE_LAST_SEEN',
    freeze: 'FREEZE_ROOM',
    unFreeze: 'UNFREEZE_ROOM',
    getMembers: 'GET_MEMBERS_IN_ROOM',
    updateUsers: 'UPDATE_USERS',
    addUsers: 'ADD_USERS',
    removeUser: 'REMOVE_USER',
    blockUser: 'BLOCK_USER',
    unblockUser: 'UNBLOCK_USER',
    updateInfo: 'UPDATE_ROOM_INFO',
    makeAdmin: 'MAKE_AS_ADMIN',
    removeAdminAccess: 'REMOVE_ADMIN_ACCESS',
    deleteConversation: 'DELETE_CONVERSATION',
    makeModerator: 'MAKE_AS_MODERATOR',
    removeModerator: 'REMOVE_AS_MODERATOR',
    updateTimeFreeze: 'UPDATE_TIME_FREEZE',
  },
  message: {
    send: 'MESSAGE_SEND',
    delete: 'MESSAGE_DELETE',
    multiDelete: 'MESSAGE_MULTI_DELETE',
    received: 'MESSAGE_RECEIVED',
    messageDeleteForMe: 'MESSAGE_DELETE_FOR_ME',
    updateMessageDeliveryStatus: 'UPDATE_MESSAGE_DELIVERY_STATUS',
    edit: 'MESSAGE_EDIT',
  },
  aautiFeed: {
    recentFeed: 'RECENT_FEED',
    pollPublish: 'POLL_PUBLISH',
    agendaPublish: 'AGENDA_PUBLISH',
    askPublish: 'ASK_PUBLISH',
    assignmentPublish: 'ASSIGNMENT_PUBLISH',
    quizPublish: 'QUIZ_PUBLISH',
    updateFeedsAccess: 'UPDATE_FEEDS_ACCESS',
    getFeedsAccess: 'GET_FEEDS_ACCESS',
  },
  call: {
    request: 'CALL_REQUEST',
    response: 'CALL_RESPONSE',
    saveSessionCall: 'SAVE_SESSION_CALL',
    updateSessionCall: 'UPDATE_SESSION_CALL',
  },
}

export const ChatRoomAccess = {
  ONLY_ME: 'ONLY_ME',
  EVERYONE: 'EVERYONE',
  ONLY_ADMINS: 'ONLY_ADMINS',
}

export const messageTypes = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  AUDIO: 'AUDIO',
  SYSTEM: 'SYSTEM',
  REPLY: 'REPLY',
  POLL: 'POLL',
  ASK: 'ASK',
  AGENDA: 'AGENDA',
  ASSIGNMENT: 'ASSIGNMENT',
  TASK: 'TASK',
  QUIZ: 'QUIZ',
}

export const chatUserOptions = {
  MAKE_AS_MODERATOR: 'Make As Moderator',
  REMOVE_AS_MODERATOR: 'Remove As Moderator',
  MAKE_AS_ADMIN: 'Make As Admin',
  REMOVE_ADMIN: 'Remove As Admin',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
  REMOVE: 'Remove',
}

export const minCharacters = {
  name: 50,
  description: 50,
}

export const MaxChatResultsCount = 20

export const conversationTypes = {
  ONE_TO_ONE: 'ONE_TO_ONE',
  GROUP: 'GROUP',
  COURSE: 'COURSE',
  ADHOC: 'ADHOC',
  ASK: 'ASK',
  COURSE_ADHOC: 'COURSE_ADHOC',
}

export const acceptedImageTypes= ['jpeg',
  'jpg',
  'png',
  'HEIC',
  'PNG',
  'JPEG',
  'JPG',
  'webp']

export const fileExtensions = {
  image: acceptedImageTypes,
  video: ['mkv', '3gp', 'webm', 'mp4', 'mov'],
  audio:
    '.3g2 .3gp .aac .adt .adts .aif .aifc .aiff .asf .au .m3u .m4a .m4b .mid .midi .mp2 .mp3 .rmi .snd .wav .wax .wma .ogg',
  pdf: 'pdf',
  doc: ['doc', 'docx', 'xls'],
  ppt: ['pptx', 'ppt'],
}

export const lottiePlayerStyles = { width: "200px", height:'100%', cursor: "pointer" };
export const emptyImageUploadStyles = { position: "absolute", inset: 0, zIndex: 0 };
export const messageInputPlaceholder = `Enter your message here...`