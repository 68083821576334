import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { socket } from "..";
export function handleCloseSearch(event, args) {
  const { setSearchValue } = args;
  setSearchValue("");
}
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 25,
    minWidth: 440,
  },
  "& .MuiDialogActions-root": {
    padding: 25,
  },
}));
export function createGroupChat(props) {
  if (props.createGroup.text === "Chat") {
    // socket.send(
    //   JSON.stringify({
    //     event: "CREATE_ROOM",
    //     name: props.createGroup.name,
    //     avatar: props.createGroup.avatar,
    //     description: props.createGroup.description,
    //     members: props.createGroup.members,
    //     type: "GROUP",
    //   })
    // );
  } else {
    // socket.send(
    //   JSON.stringify({
    //     event: "CREATE_ROOM",
    //     admins: props.createGroup.admins,
    //     name: props.createGroup.name,
    //     courseId: props.createGroup.courseId,
    //     batch: props.createGroup.batch,
    //     avatar: props.createGroup.avatar,
    //     description: props.createGroup.description,
    //     members: props.createGroup.members,
    //     isCancelled: false,
    //     moderators: [],
    //     type: "COURSE",
    //   })
    // );
  }

  // socket.onmessage = (event) => {
  //   const parsedEvent = JSON.parse(event?.data);
  //   const { event: dataEvent, data: dataData } = parsedEvent || {};
  //   if (dataEvent === "PING") {
  //     socket.send(JSON.stringify({ event: "PONG" }));
  //   } else if (dataEvent === "ROOM_CREATED") {
  //     props?.setChatUserDetails({});
  //   } else if (dataEvent === "GET_CONVERSATIONS") {
  //     const newList = dataData.filter(
  //       (each) => each?.name || each.user.displayName
  //     );

  //     props?.setChatUserList(newList);
  //   }
  // // };
  // socket.onerror = (err) => {
  //   console.error(err, "sent_message");
  // };
}
export function GetRoomByRoomId(props) {
  const { roomType, courseId, batchId, setRoomData, roomData } = props;
  // if (socket.readyState === 1) {
  //   socket.send(
  //     JSON.stringify({
  //       event: "GET_ROOM",
  //       roomType,
  //       courseId,
  //       batchId,
  //     })
  //   );
  // }

  // socket.onmessage = (event) => {
  //   const parsedEvent = JSON.parse(event?.data);
  //   const { event: dataEvent, data: dataData } = parsedEvent || {};
  //   if (dataEvent === "PING") {
  //     socket.send(JSON.stringify({ event: "PONG" }));
  //   } else if (dataEvent === "GET_ROOM") {
  //     setRoomData(dataData);
  //   }
  // };
  // socket.onerror = (err) => {
  //   console.error(err, "sent_message");
  // };
  return roomData;
}
