import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import {
  approveOrRejectRequest,
  getBatchChangeRequestsBySpId,
} from "./Services";
import { useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MdOutlineCalendarMonth } from "react-icons/md";
import "./Style.scss";
import NoImage from "../../assets/images/no-image.svg";
import FilterIcon from "../../assets/images/FilterIcon.png";
import { Box, Grid } from "@mui/material";
import {
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Skeleton,
} from "@mui/material";
import NoData from "../globalComponents/NoDataFound";
import InPerson from "../globalComponents/icons/InPerson";
import moment from "moment";
import AautiDialog from "../globalComponents/AautiDialog";
import { LuClock4 } from "react-icons/lu";
import JoinButton from "../globalComponents/JoinButton";
import { IoMdClose } from "react-icons/io";
import EmptyData from "../globalComponents/EmptyData";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import { isEmpty } from "lodash";

const RequestItem = ({ each, index, setSelectedItem, selectedItem }) => {
  return (
    <div
      onClick={() => {
        // getCompleteOrders(each?._id);
        // setOrderID(each);
        // sePIndex(index);
        setSelectedItem(each);
      }}
      key={index}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
        borderRadius: each?.courseId == selectedItem?.courseId && 4,
        width: "98%",
        marginLeft: "1%",
        // marginBottom: 8,
        padding: "8px 8px",
        backgroundColor: each?.courseId == selectedItem?.courseId && "#C8DCF6",
        cursor: "pointer",
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <img
        style={{
          height: 50,
          width: 50,
          borderRadius: "50%",
        }}
        src={
          each?.courseImage ||
          "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
        }
        alt="order-img"
      />
      <div style={{ marginLeft: "5px" }}>
        <AautiText
          size={"semi"}
          textStyle={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            maxWidth: "100%",
            color: "#1E1E1E",
          }}
          title={each?.courseName || "NA"}
          weight={"bold"}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            justifyContent: "flex-end",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          {/* <AautiText
            textStyle={{ color: "#5C5B57" }}
            title={`Batches: 10 | Students: 20`}
            size={"small"}
          /> */}
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <IconButton
              sx={{ padding: "0px !important", marginRight: 1 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <InPerson
                size={18}
                style={{
                  color: "#1e1e1e",
                  padding: "0px !important",
                }}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const TransferRequests = () => {
  const { loggedinUserId, UserRole } = useSelector((state) => state.app);
  const [activeTab, setActiveTab] = useState("pending");
  const [requests, setRequests] = useState([]);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [selectedItem, setSelectedItem] = useState(requests[0]);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [refresh, setRefresh] = useState(false);

  const TransferType = [
    {
      id: 215,
      statetype: "Pending",
      type: "pending",
      attendancePerc: requests,
      attendanceCount: 3,
    },
    {
      id: 454,
      statetype: "Approved",
      type: "approved",
      attendancePerc: requests,
      attendanceCount: 3,
    },
    {
      id: 344,
      statetype: "Rejected",
      type: "rejected",
      attendancePerc: requests,
      attendanceCount: 3,
    },
  ];

  useEffect(() => {
    setLoading(true);
    getBatchChangeRequestsBySpId(
      loggedinUserId,
      activeTab,
      (response) => {
        if (response?.result) {
          setRequests(response?.result);
          setSelectedItem(response?.result[0]);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error, "error-transfer list");
        setLoading(false);
      }
    );
  }, [loggedinUserId, refresh, activeTab]);

  const handleRequestAction = (item, action) => {
    const requestId = item?._id;
    if (requestId) {
      const obj = {
        _id: requestId,
        transferStatus: action,
      };
      approveOrRejectRequest(
        obj,
        (resp) => {
          setRefresh(!refresh);
          setOpen(false);
        },
        (error) => {
          console.log(error, "error-transfer req");
          setOpen(false);
        }
      );
    }
  };

  const returnDate = (date, type) => {
    let formattedDate;
    if (type == "time") {
      formattedDate = moment(date).format("hh:mm A");
    } else {
      formattedDate = moment(date).format("DD MMM YYYY");
    }
    return formattedDate || "NA";
  };

  const returnWeek = (weeks) => {
    const nonEmptyElements = [];
    for (const key in weeks) {
      if (weeks[key].length > 0) {
        nonEmptyElements.push(
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <LuClock4 size={20} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
              }}
            >
              <AautiText
                title={`${returnDate(
                  weeks[key][0]?.startTime,
                  "time"
                )}-${returnDate(weeks[key][0]?.endTime, "time")}`}
              />
              <div
                style={{
                  background: "#066cb5",
                  padding: "2px 6px",
                  borderRadius: 4,
                }}
              >
                <AautiText
                  title={key.slice(0, 3)}
                  textStyle={{ color: "#ffffff" }}
                />
              </div>
            </div>
          </div>
        );
        // categories();
      }
    }
    return nonEmptyElements;
  };

  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <AccountTabs />
      <div style={{ width: "96%" }}>
        <AautiText
          title={"Transfer Requests"}
          textStyle={{ margin: "5px" }}
          weight={"bold"}
          size={"medium"}
        />
        <Grid style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
          {loading ? (
            <Box className="TRloadingdiv1">
              <Box className="TRloadingdiv2">
                <Skeleton variant="rounded" width={"100%"} height={40} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={mobileDevices ? 200 : 400}
                />
              </Box>
              <Box className="TRloadingdiv3">
                <Skeleton variant="rounded" width={"100%"} height={30} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Box>
            </Box>
          ) : (
            <Box className="TRboxShadowDiv">
              <Box className="TRleftmaindiv1">
                <div
                  className="TRSPleftmaindiv2"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    // width: "98%",
                    // marginLeft: "1%",
                  }}
                >
                  {TransferType.map((each, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveTab(each?.type);
                      }}
                      style={{
                        borderBottom:
                          each?.type === activeTab
                            ? "2px solid #078dda"
                            : "0px solid #dedede",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingBottom: "6px",
                      }}
                    >
                      <AautiText
                        size={"normal"}
                        weight={"semi"}
                        title={`${each.statetype} `}
                        textAlign={"start"}
                        textStyle={{
                          color:
                            each?.type === activeTab ? "#078dda" : "#1e1e1e",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormControl
                    sx={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      width: "80%",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={"text"}
                      onChange={(e) => setSearchInput(e.target.value)}
                      value={searchInput}
                      style={{ width: "100%" }}
                      label={"Search"}
                    />
                    <InputLabel htmlFor="outlined-adornment-password">
                      <AautiText title={"Search"} />
                    </InputLabel>
                  </FormControl>
                  <img src={FilterIcon} style={{ width: 26, height: "26px" }} />
                </div>
                <div className="TRscrollDiv">
                  {requests?.length === 0 ? (
                    <NoData NoDataFound={"No Data"} />
                  ) : (
                    requests?.map((each, index) => (
                      <RequestItem
                        {...{ each, index, setSelectedItem, selectedItem }}
                      />
                    ))
                  )}
                </div>
              </Box>
              {requests?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <EmptyData showExplore={true} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "81%",
                    marginTop: "10px",
                    padding: "10px",
                    height: "80vh",
                  }}
                >
                  <section style={{ height: "85px" }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={selectedItem?.courseImage || NoImage}
                          alt={selectedItem?.courseName}
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                        />
                        <div className="course-name-sub-cont">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <AautiText
                              textAlign={"left"}
                              size={"medium"}
                              weight={"bold"}
                              textStyle={{
                                color: "#4B4D4D",
                              }}
                              title={`${selectedItem?.courseName}`}
                            />
                          </div>
                          <AautiText
                            textAlign={"left"}
                            size={"normal"}
                            weight={"normal"}
                            textStyle={{
                              color: "#4B4D4D",
                            }}
                            title={`${"Course"}`}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            {/* <AautiText
                          textStyle={{ color: "#5C5B57" }}
                          title={`Batches: 10 | Students: 20`}
                          size={"small"}
                        /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #e8e8e8",
                      fontSize: "2px",
                    }}
                  />
                  <section>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        padding: "10px",
                        gap: "5px",
                      }}
                    >
                      {selectedItem?.requestedEndUsers?.map((item) => (
                        <button
                          style={{
                            width: "auto",
                            height: "50px",
                            minWidth: "100px",
                            borderRadius: "10px",
                            marginRight: "5px",
                            backgroundColor: "rgba(247, 247, 250, 1)",
                            border:
                              selectedId?._id === item?._id
                                ? "1px solid blue"
                                : "1px solid lightgrey",
                            display: "flex",
                            padding: "3px",
                            // justifyContent: "space-",
                            alignItems: "center",
                          }}
                          onClick={(e) => {
                            activeTab === "pending" && setSelectedId(item);
                            activeTab === "pending" && setOpen(true);
                          }}
                        >
                          <img
                            src={
                              item?.endUserImage ||
                              "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
                            }
                            alt="No"
                            style={{
                              width: "42px",
                              height: "42px",
                              maxHeight: "42px",
                              borderRadius: 10,
                            }}
                          />
                          <div
                            className="cartCard1"
                            style={{ alignItems: "center" }}
                          >
                            <AautiText
                              size={"small"}
                              weight={"bold"}
                              textStyle={{
                                color: "black",
                                lineHeight: "20px",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1, // Set the number of lines
                                overflow: "hidden",
                              }}
                              title={item?.displayName}
                              className={"fadeStyle"}
                            />
                          </div>
                        </button>
                      ))}
                    </div>
                  </section>
                </div>
              )}
            </Box>
          )}
        </Grid>
        <AautiDialog
          open={open}
          onClose={() => setOpen(false)}
          showCloseIcons={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <img
                src={selectedId?.endUserImage || NoImage}
                alt={selectedId?.displayName}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              <div className="course-name-sub-cont">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AautiText
                    textAlign={"left"}
                    size={"medium"}
                    weight={"bold"}
                    textStyle={{
                      color: "#4B4D4D",
                    }}
                    title={`${selectedId?.displayName}`}
                  />
                </div>
                <AautiText
                  textAlign={"left"}
                  size={"normal"}
                  weight={"normal"}
                  textStyle={{
                    color: "#4B4D4D",
                  }}
                  title={`${"Course"}`}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                ></div>
              </div>
            </div>
            <IoMdClose size={25} onClick={() => setOpen(false)} />
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: 4,
              background: "none",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 8,
                background: "#efefef",
              }}
            >
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: "#1e1e1e",
                  // width: "90%",
                }}
                title={`From :    ${selectedId?.fromBatch?.batchName}`}
              />
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: "#36AF58",
                  // width: "90%",
                }}
                title={`Available ${selectedId?.fromBatch?.availableSlots}`}
              />
            </div>
            <div style={{ padding: 8 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 8,
                }}
              >
                <CalendarMonthIcon
                  style={{ width: "20px", height: "20px" }}
                  size={20}
                />
                <AautiText
                  title={
                    selectedId?.fromBatch?.recurringDays ? "Weekly" : "Daily"
                  }
                  size={"semi"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 8,
                }}
              >
                <MdOutlineCalendarMonth size={20} />
                <AautiText
                  title={`${returnDate(
                    selectedId?.fromBatch?.startDate
                  )}-${returnDate(selectedId?.fromBatch?.endDate)}`}
                  size={"semi"}
                />
              </div>
              {selectedId?.fromBatch?.recurringDays ? (
                returnWeek(selectedId?.fromBatch?.recurringDays)
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 8,
                  }}
                >
                  <LuClock4 size={20} />
                  <AautiText
                    size={"semi"}
                    title={`${returnDate(
                      selectedId?.fromBatch?.startTime,
                      "time"
                    )}-${returnDate(selectedId?.fromBatch?.endTime, "time")}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: isEmpty(selectedId?.toBatch) && "none",
              border: "1px solid lightgrey",
              borderRadius: 4,
              background: "none",
              marginBottom: 10,
            }}
          >
            +
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 8,
                background: "#efefef",
              }}
            >
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: "#1e1e1e",
                  // width: "90%",
                }}
                title={`To :    ${selectedId?.toBatch?.batchName}`}
              />
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: "#36AF58",
                  // width: "90%",
                }}
                title={`Available ${selectedId?.toBatch?.availableSlots}`}
              />
            </div>
            <div style={{ padding: 8 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 8,
                }}
              >
                <CalendarMonthIcon
                  style={{ width: "20px", height: "20px" }}
                  size={20}
                />
                <AautiText
                  title={
                    selectedId?.toBatch?.recurringDays ? "Weekly" : "Daily"
                  }
                  size={"semi"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 8,
                }}
              >
                <MdOutlineCalendarMonth size={20} />
                <AautiText
                  title={`${returnDate(
                    selectedId?.toBatch?.startDate
                  )}-${returnDate(selectedId?.toBatch?.endDate)}`}
                  size={"semi"}
                />
              </div>
              {selectedId?.toBatch?.recurringDays ? (
                returnWeek(selectedId?.toBatch?.recurringDays)
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 8,
                  }}
                >
                  <LuClock4 size={20} />
                  <AautiText
                    size={"semi"}
                    title={`${returnDate(
                      selectedId?.toBatch?.startTime,
                      "time"
                    )}-${returnDate(selectedId?.toBatch?.endTime, "time")}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <JoinButton
              outlined
              title={"Reject"}
              className="rightCont-div7"
              onClick={() => {
                handleRequestAction(selectedId, "reject");
              }}
            />
            <JoinButton
              title={"Approve"}
              className="rightCont-div7"
              onClick={() => handleRequestAction(selectedId, "approved")}
            />
          </div>
        </AautiDialog>
      </div>
    </AppContainer>
  );
};

export default TransferRequests;
