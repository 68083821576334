import React from "react";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import NavButton from "./NavButton";
import { PriceConversions } from "../../CommonFunctions";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const MembersConditions = ({
  membersDiscount,
  handlePercentageChange,
  adhoc = false,
  setDialogOpen,
}) => {
  const { convertPriceWithCurrency } = PriceConversions();
  return (
    <div style={{ width: "100%" }}>
      {membersDiscount?.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <AautiText
              textStyle={{ color: "#000" }}
              title={`Per-Member Discount- For ${item?.noOfMembers || 0}`}
            />
            <AautiText
              title={`Final Price:${convertPriceWithCurrency(
                item?.finalPrice
              )}`}
              size={"small"}
              textStyle={{ display: adhoc && "none" }}
            />
          </div>
          <div style={{ width: "10%" }}>
            <AautiTextInput
              onChange={(e) => {
                handlePercentageChange(e.target.value, item?.noOfMembers);
              }}
              style={{ width: "100%" }}
              text={"%"}
              value={item?.percentage}
            />
          </div>
        </div>
      ))}
      {!adhoc && (
        <NavButton
          // disabled={!checkDisabled()}
          text={AautiStrings?.SaveString}
          handleClick={() => {
            setDialogOpen("");
          }}
        />
      )}
    </div>
  );
};

export default MembersConditions;
