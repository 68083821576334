import React from "react";
import AautiText from "./AautiText";
const NoData = ({ NoDataFound }) => {
  return (
    <div
      style={{
        // height: 200,
        height:'100%',
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AautiText
        title={NoDataFound}
        textStyle={{ color: "grey" }}
        weight={"bold"}
      />
    </div>
  );
};
export default NoData;
