import React, { createRef, useState } from "react";
import "./Style.scss";
import AautiUpload from "../../globalComponents/AautiUpload";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import Dialog from "@mui/material/Dialog";
import { MdAddCircleOutline } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AautiDialog from "../../globalComponents/AautiDialog";
import ImageCropper from "./ImageCropper";
import { isEmpty } from "lodash";
import { generateImagesUsingAi } from "./Services";
import NavButton from "../courseCreation/NavButton";
import { createCourseStrings } from "./Constants";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import HelperText from "../courseCreation/HelperText";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const CreateCourse3 = (props) => {
  const { CreateCourseObj, changeCreateObj, handleClose, handleOpen } = props;
  const [visibleImage, setVisibleImage] = useState("");
  const [visible, setVisible] = useState(false);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { userDetails } = useSelector((state) => state.app);
  const [multipleUploads, setMultipleUploads] = useState([]);
  const [aiImageField, setAiImageField] = useState("");

  const [activeImageIndex, setActiveImageIndex] = useState([]);

  const [showCropPop, setCropPop] = useState(false);
  const [aiImagesList, setAiImagesList] = useState([]);
  //cropper states
  const [image, setImage] = useState("");

  const [uploadedFile, setUploadedFile] = useState(null);
  const cropperRef = createRef(null);
  const [cropObject, setCropObj] = useState(null);

  const fileUpload = async (file) => {
    handleOpen(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await response.json();
    return responseJson;
  };

  const cancelCrop = () => {
    setImage("");
    setUploadedFile(null);
    setMultipleUploads([]);
    setCropObj(null);
    setCropPop(false);
    cropperRef.current = null;
  };

  const uploadHere = async (file, feild) => {
    let responseJson = await fileUpload(file);
    if (responseJson?.result) {
      if (feild === "relatedImages") {
        if (!CreateCourseObj?.relatedImages?.includes(responseJson?.result)) {
          changeCreateObj(
            [...CreateCourseObj.relatedImages, responseJson?.result],
            feild
          );
          cancelCrop();
        } else {
          cancelCrop();
        }
      } else if (feild === "relatedDocuments") {
        changeCreateObj(
          [...CreateCourseObj.relatedDocuments, responseJson.result],
          feild
        );
        cancelCrop();
      } else if (feild === "bookCoverPage") {
        changeCreateObj(
          [...CreateCourseObj.bookCoverPage, responseJson.result],
          feild
        );
        cancelCrop();
      } else {
        changeCreateObj(responseJson?.result, feild);
        cancelCrop();
      }
    }
    handleClose();
  };

  const checkImageRatios = async (e, field, crop) => {
    e.preventDefault();
    const file = e.target.files[0];
    const { files } = e.target;
    let imagesList = [];

    if (files?.length > 1) {
      handleOpen();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("platform", UploadBaseUrl);
        // const url = uploadUrl + ApiPaths.FILE_UPLOAD;
        // const response = await fetch(url, {
        //   method: "POST",

        //   body: formData,
        // });
        const json = await fileUpload(file);
        if (json.result) {
          imagesList?.push(json?.result);
        }
      }
      setMultipleUploads(imagesList);
      setCropPop(true);
      setImage(imagesList[0]);
      handleClose();
    } else {
      if (file) {
        if (!crop) {
          handleOpen();
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            if (field === "courseImage" || field === "bookCoverPage") {
              if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
                uploadHere(e.target.files[0], field);
              } else {
                alert(
                  createCourseStrings?.selectedImageMustHaveA4IsTo3AspectRatio
                );
              }
            } else if (field === "relatedImages") {
              if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
                uploadHere(e.target.files[0], field);
              } else {
                alert(
                  createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed16IsTo9
                );
              }
            }
          };
        } else {
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setUploadedFile(files[0]);
            setCropPop(true);
            const obj = {
              field,
              ratio:
                field === "courseImage" || field === "bookCoverPage"
                  ? "4 / 3"
                  : "16 / 9",
            };
            setCropObj(obj);
          };
          reader.readAsDataURL(files[0]);
        }
      }
    }
  };

  const removeRelatedImages = (positi) => {
    const a = CreateCourseObj?.relatedImages?.filter((each, index) => {
      return index !== positi;
    });
    changeCreateObj(a, "relatedImages");
  };
  const remveMediaFiles = (val) => {
    const a = CreateCourseObj?.relatedDocuments?.filter((each, index) => {
      return index !== val;
    });
    changeCreateObj(a, "relatedDocuments");
  };

  const removeCoverPage = (position) => {
    const a = CreateCourseObj?.bookCoverPage?.filter((each, index) => {
      return index !== position;
    });
    changeCreateObj(a, "bookCoverPage");
  };
  const handleUploadCroppedImage = async (file, field) => {
    handleOpen();
    if (isEmpty(multipleUploads)) {
      await uploadHere(file, field);
    } else {
      if (CreateCourseObj?.relatedImages?.length < 8) {
        let imagesList = [...CreateCourseObj.relatedImages] || [];
        let responseJson = await fileUpload(file);
        if (responseJson) {
          imagesList.push(responseJson?.result);
        }
        changeCreateObj(imagesList, "relatedImages");
        handleClose();
      } else {
        handleClose();
      }
    }
  };

  const handleRemoveMultipleImages = (item) => {
    const list = multipleUploads?.filter((each) => each !== item);
    if (isEmpty(list)) {
      setImage("");
      setCropPop(false);
      cancelCrop();
    } else {
      setMultipleUploads(list);
      setImage(list[0]);
    }
  };

  // console.log(CreateCourseObj, "courseObject?...");

  const handleGenerateAiImages = (field) => {
    const data = {
      prompt: `generate ${
        field == "relatedImages" ? "related images" : "images"
      } for category ${CreateCourseObj?.category || ""} with subcategory ${
        CreateCourseObj?.subCategory || ""
      }  for ${
        CreateCourseObj?.courseLevel?.join(" ,") || ""
      } with this aspect ratio 4/3 related to ${userDetails?.country}`,
      numberOfImages: field == "relatedImages" ? 4 : 2,
    };
    setAiImageField(field);
    handleOpen();
    generateImagesUsingAi(
      data,
      (response) => {
        handleClose();
        setAiImagesList(response?.result?.data);
      },
      (error) => {
        console.log(error, "error?....");
        handleClose();
      }
    );
  };

  return (
    <div className="CreateCourseTab">
      <div
        className="uploades"
        style={{
          flexDirection: mobileDevices ? "column" : "row",
          justifyContent: mobileDevices ? "center" : "space-between",
          alignItems: mobileDevices ? "center" : "flex-start",
          width: "100%",
          marginLeft: mobileDevices && "0px",
        }}
      >
        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              // marginTop: 15,
            }}
          >
            <AautiText
              title={AautiStrings?.CoverImageString}
              weight={"bold"}
              required
            />
            <button
              onClick={() => {
                handleGenerateAiImages("courseImage");
              }}
              id="cover-image-ai"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
                display: CreateCourseObj?.courseImage && "none",
              }}
            >
              <AautiText
                size={"small"}
                title={AautiStrings?.GenerateUsingAIString}
                weight={"bold"}
                textStyle={{
                  color: "#2076E5",
                  cursor: "pointer",
                }}
              />
            </button>
          </div>

          {CreateCourseObj?.courseImage ? (
            <BootstrapTooltip
              title={createCourseStrings?.previewImage}
              placement="bottom"
            >
              <div
                className="each-images-course"
                style={{
                  backgroundImage: `url(${CreateCourseObj?.courseImage})`,
                  padding: 3,
                  marginTop: 5,
                  marginBottom: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisibleImage(CreateCourseObj?.courseImage);
                  setVisible(true);
                }}
              >
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    changeCreateObj("", "courseImage");
                    document.getElementById("courseImage").value = "";
                  }}
                  fontSize="small"
                  sx={{
                    backgroundColor: "lightGrey",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </div>
            </BootstrapTooltip>
          ) : (
            <div>
              <AautiUpload
                disabled={false}
                id="courseImage"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  checkImageRatios(e, "courseImage", true);
                }}
                iconName={createCourseStrings?.coverImage}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadCoverImageImp}
                    />
                  </div>
                }
              />
              <div className="labels">
                <AautiText
                  size={"small"}
                  title={
                    createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
                  }
                  textStyle={{
                    fontFamily:
                      "AmazonEmber-Medium, Amazon Ember Medium, Amazon Ember, sans-serif",
                    color: "rgba(75, 75, 75, 0.9)",
                    margin: "2px",
                    marginBottom: "10px",
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              // marginTop: 15,
            }}
          >
            <AautiText
              title={createCourseStrings?.relatedImages}
              weight={"bold"}
              required
            />
            {CreateCourseObj?.relatedImages?.length < 8 && (
              <button
                onClick={() => {
                  handleGenerateAiImages("relatedImages");
                }}
                id="related-images-ai"
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                  width: "fit-content",
                }}
              >
                <AautiText
                  size={"small"}
                  title={"Generate using AI"}
                  weight={"bold"}
                  textStyle={{
                    color: "#2076E5",
                    cursor: "pointer",
                  }}
                />
              </button>
            )}
          </div>

          {CreateCourseObj?.relatedImages.length === 0 ? (
            <div>
              <AautiUpload
                multiple={true}
                id="upload-Related-Images"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  checkImageRatios(e, "relatedImages", true);
                }}
                iconName={createCourseStrings?.uploadMin2RelatedImages}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#000000" }}
                      fontSize="small"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadRelatedImgs}
                    />
                  </div>
                }
              />
              <div className="labels">
                <AautiText
                  size={"small"}
                  title={`${createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed16IsTo9} (min 2 images required)`}
                  textStyle={{
                    fontFamily:
                      "AmazonEmber-Medium, Amazon Ember Medium, Amazon Ember, sans-serif",
                    color: "rgba(75, 75, 75, 0.9)",
                    margin: "2px",
                    marginBottom: "10px",
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="relatedImage-mappi">
                {CreateCourseObj?.relatedImages?.map((each, index) => {
                  return (
                    <BootstrapTooltip title="Preview Image" placement="bottom">
                      <div
                        className="each-images-course"
                        key={index}
                        style={{
                          width: mobileDevices ? "150px" : "160px",
                          backgroundImage: `url(${each})`,
                          padding: mobileDevices ? "0px" : 3,
                          marginBottom: mobileDevices && 10,
                          marginTop: 5,
                        }}
                        resizeMode="stretch"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleImage(each);
                          setVisible(true);
                        }}
                      >
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            removeRelatedImages(index);
                            setVisibleImage("");
                            setVisible(false);
                          }}
                          fontSize="small"
                          sx={{
                            backgroundColor: "lightGrey",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </BootstrapTooltip>
                  );
                })}
                <input
                  type="file"
                  multiple={true}
                  accept="image/*"
                  onChange={(e) => {
                    checkImageRatios(e, "relatedImages", true);
                  }}
                  id="upload-Related-Images"
                  style={{ display: "none" }}
                />
                <div style={{ alignItems: "center", display: "flex" }}>
                  <label htmlFor="upload-Related-Images">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {CreateCourseObj?.relatedImages?.length < 8 && (
                        <MdAddCircleOutline
                          size={45}
                          fill={"#1e98d7"}
                          style={{
                            fontSize: 24,
                            cursor: "pointer",
                            color: "#1e98d7",
                            fontWeight: 500,
                            // marginTop: 18,
                          }}
                          error={
                            CreateCourseObj?.relatedImages.length < 2
                              ? true
                              : false
                          }
                        />
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className="uploades"
        style={{
          marginTop: 15,
          flexDirection: mobileDevices ? "column" : "row",
          alignItems: mobileDevices ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "98%" : "48%",
            marginBottom: mobileDevices && 15,
          }}
        >
          {/* <AautiText
            size={"medium"}
            weight={"normal"}
            textStyle={{
              fontFamily:
                "AmazonEmber-Medium,Amazon Ember Medium,Amazon Ember,sans-serif",
              color: "#333333",
            }}
            title={"Media & Files:"}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // marginTop: 15,
            }}
          >
            <AautiText
              title={AautiStrings?.mediaAndFilesString}
              weight={"bold"}
            />
          </div>

          {CreateCourseObj?.relatedDocuments?.length === 0 ? (
            <div>
              <AautiUpload
                id="upload related documents"
                iconName={"Upload Media & Files"}
                onChange={(e) => {
                  uploadHere(e.target.files[0], "relatedDocuments");
                }}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <DescriptionIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadMediaFiles}
                    />
                  </div>
                }
              />
            </div>
          ) : (
            <div
              className="relatedImage-mappi"
              style={{
                flexDirection: "row",
                display: "flex",
                marginBottom: 10,
              }}
            >
              {CreateCourseObj?.relatedDocuments?.map((each, index) => {
                return (
                  <div>
                    <div
                      style={{
                        width: 100,
                        height: 120,
                        marginRight: 10,
                        backgroundSize: "cover",
                        backgroundImage:
                          each.split(".").pop() === "jpeg" ||
                          each.split(".").pop() === "jpg" ||
                          each.split(".").pop() === "png"
                            ? `url(${each})`
                            : each.split(".").pop() === "mp4" ||
                              each.split(".").pop() === "MP4"
                            ? "url(https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Video.png)"
                            : "url(https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/640px-PDF_file_icon.svg.png)",
                      }}
                    >
                      <CloseIcon
                        color="#fff"
                        onClick={(e) => {
                          remveMediaFiles(index);
                        }}
                        style={{
                          WebkitTapHighlightColor: "transparent",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginTop: 3,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  uploadHere(e.target.files[0], "relatedDocuments");
                }}
                id="RelatedImages2"
                style={{ display: "none" }}
              />
              <label
                htmlFor="RelatedImages2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdAddCircleOutline
                    size={45}
                    fill={"#1e98d7"}
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color: "#1e98d7",
                      fontWeight: 500,
                      marginTop: 18,
                    }}
                    error={
                      CreateCourseObj?.relatedDocuments?.length < 2
                        ? true
                        : false
                    }
                  />
                </div>
              </label>
            </div>
          )}
        </div>

        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // marginTop: 15,
            }}
          >
            <AautiText title={createCourseStrings?.coverPage} weight={"bold"} />
          </div>

          {CreateCourseObj?.bookCoverPage?.length > 0 ? (
            <div className="relatedImage-mappi">
              {CreateCourseObj?.bookCoverPage?.map((each, index) => {
                return (
                  <BootstrapTooltip title="Preview Image" placement="bottom">
                    <div
                      className="each-images-course"
                      key={index}
                      style={{
                        width: mobileDevices ? "150px" : "160px",
                        backgroundImage: `url(${each})`,
                        padding: mobileDevices ? "0px" : 3,
                        marginBottom: mobileDevices && 10,
                        marginTop: 5,
                      }}
                      resizeMode="stretch"
                      onClick={() => {
                        setVisibleImage(each);
                        setVisible(true);
                      }}
                    >
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          removeCoverPage(index);
                          setVisibleImage("");
                          setVisible(false);
                        }}
                        fontSize="small"
                        sx={{
                          backgroundColor: "lightGrey",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </BootstrapTooltip>
                );
              })}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  checkImageRatios(e, "bookCoverPage");
                }}
                id="RelatedImages1"
                style={{ display: "none" }}
              />
              <label
                htmlFor="RelatedImages1"
                style={{ display: "flex", alignSelf: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdAddCircleOutline
                    size={45}
                    fill={"#1e98d7"}
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color: "#1e98d7",
                      fontWeight: 500,
                      marginTop: 18,
                    }}
                    // error={
                    //   CreateCourseObj?.bookCoverPage?.length < 2 ? true : false
                    // }
                  />
                </div>
              </label>
            </div>
          ) : (
            <div>
              <AautiUpload
                id={"CoverPage"}
                accept="image/jpeg, image/png"
                iconName={createCourseStrings?.uploadCoverPageOfTheBook}
                onChange={(e) => {
                  checkImageRatios(e, "bookCoverPage", true);
                }}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <PostAddSharpIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadCoverPageOfTheBook}
                    />
                  </div>
                }
              />
              <div className="labels">
                <AautiText
                  size={"small"}
                  title={
                    createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
                  }
                  textStyle={{
                    fontFamily:
                      "AmazonEmber-Medium, Amazon Ember Medium, Amazon Ember, sans-serif",
                    color: "rgba(75, 75, 75, 0.9)",
                    margin: "2px",
                    marginBottom: "10px",
                  }}
                />
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      <Dialog
        style={{
          width: "100%",
          height: "99%",
        }}
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth
        maxWidth={"md"}
      >
        <img
          src={visibleImage}
          alt=""
          style={{
            width: "100%",
            height: "525px",
            borderRadius: "4px",
          }}
        />
        <IconButton
          aria-label="close"
          onClick={() => setVisible(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Dialog>

      <AautiDialog
        dialogWidth={"80vw"}
        open={showCropPop || !isEmpty(aiImagesList)}
        onClose={() => {
          cancelCrop();
          setAiImagesList([]);
        }}
        titlePosition="left"
        dialogTitle={!isEmpty(aiImagesList) && "Choose image"}
      >
        {showCropPop && (
          <div style={{ width: "100%" }}>
            <ImageCropper
              aiImages={aiImageField}
              {...{
                image,
                cropperRef,
                cropObject,
                handleUploadCroppedImage,
                uploadedFile,
                cancelCrop,
                multipleUploads,
                setImage,
                handleRemoveMultipleImages,
                removeWhenUploaded: true,
              }}
            />
          </div>
        )}
        {!isEmpty(aiImagesList) && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                flexWrap: "wrap",
              }}
            >
              {aiImagesList?.map((img, index) => {
                const checkInclude = activeImageIndex?.some(
                  (each) => each.index == index
                );
                return (
                  <button
                    onClick={() => {
                      // setActiveImageIndex(img);
                      const object = {
                        image: "data:image/jpeg;base64," + img?.b64_json,
                        index,
                      };
                      let list = [...activeImageIndex];
                      if (aiImageField != "courseImage") {
                        if (checkInclude) {
                          const newList = list?.filter(
                            (each) => each?.index !== index
                          );
                          list = newList;
                        } else {
                          list.push(object);
                        }
                      } else {
                        list = [object];
                      }

                      setActiveImageIndex(list);
                    }}
                    key={index}
                    style={{
                      background: "none",
                      height: 200,
                      width: 310,
                      border: checkInclude ? "4px solid #2076E5" : "none",
                      padding: 0,
                      cursor: "pointer",
                      borderRadius: 4,
                    }}
                  >
                    <img
                      src={"data:image/jpeg;base64," + img?.b64_json}
                      alt="ai-images"
                      style={{ height: "100%", width: "100%", borderRadius: 3 }}
                    />
                  </button>
                );
              })}
            </div>
            {!isEmpty(activeImageIndex) && (
              <NavButton
                testId={"ai-img-next"}
                text={AautiStrings?.NextString}
                handleClick={() => {
                  setCropPop(true);
                  setImage(activeImageIndex?.[0]?.image);
                  if (aiImageField == "courseImage") {
                    setAiImagesList([]);
                    setActiveImageIndex([]);
                  } else if (aiImageField == "relatedImages") {
                    const multiList = activeImageIndex?.map(
                      (each) => each?.image
                    );
                    setMultipleUploads(multiList);
                    setAiImagesList([]);
                    setActiveImageIndex([]);
                  }
                }}
              />
            )}
          </div>
        )}
      </AautiDialog>
    </div>
  );
};

export default CreateCourse3;
