import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "../profile/Service";
import { setUserdetails } from "../../redux/reducer/appReducer";

const RefundConfigurationCont = (props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { userDetails } = useSelector((state) => state.app);

  const conditions = useMemo(
    () =>
      activeTab === 0
        ? userDetails?.courseRefundConditions?.[0]?.refundConditions ?? []
        : userDetails?.adhocRefundConditions?.[0]?.refundConditions ?? [],
    [userDetails, activeTab]
  );

  const tabs = [
    { title: "Courses", active: activeTab === 0 },
    { title: "Adhocs", active: activeTab === 1 },
  ];

  const onSaveRefundConditions = (conditions) => {
    // update userObject api
    const updatedUserObject = {
      ...userDetails,
    };
    let allowRefund = conditions?.some((each) => each?.isSelected);
    if (activeTab === 0) {
      updatedUserObject.courseRefundConditions = [
        { refundConditions: conditions, allowRefund },
      ];
    } else {
      updatedUserObject.adhocRefundConditions = [
        { refundConditions: conditions, allowRefund },
      ];
    }
    console.log(
      updatedUserObject.courseRefundConditions,
      "courseRefundConditions"
    );
    const onSuccess = (response) => {
      dispatch(setUserdetails(response?.result));
    };
    const onFail = (err) => {
      console.log(err);
    };

    AuthService.updateUserDetails(updatedUserObject, onSuccess, onFail);
  };

  return {
    conditions,
    activeTab,
    setActiveTab,
    tabs,
    onSaveRefundConditions,
  };
};

export default RefundConfigurationCont;
