import { useEffect, useRef, useState } from "react";
import RenderMessages from "./render_messages/index";
import RenderTextInput from "./render_text_input/index";
import "./styles.scss";
import { socket } from "../..";
import ChatUsersList from "../../chat_user_list";
export default function CustomMessages(props) {
  const [scrolledToBottom, setScrolledToBottom] = useState(true);
  const {
    chatUserDetails,
    messages,
    msgInput,
    setMsgInput,
    setMessages,
    sendClicked,
    setSendClicked,
    text,
    replyClicked,
    setReplyClicked,
    replyObj,
    setReplyObj,
    messageSearchInput,
    setMessageSearchInput,
    deleted,
    setDeleted,
    fileUrl,
    setFileUrl,
    pageNo,
    setPageNo,
    freezedObj,
    setFreezedObj,
    searchText,
    setSearchText,
    messageRefs,
    messageId,
    setChatUserList,
    chatUserList,
    setMessagesLoad,
    messagesLoad,
  } = props;

  const renderMessageProps = {
    chatUserDetails,
    messages,
    msgInput,
    setMsgInput,
    setMessages,
    sendClicked,
    setSendClicked,
    text,
    replyClicked,
    setReplyClicked,
    replyObj,
    setReplyObj,
    messageSearchInput,
    setMessageSearchInput,
    deleted,
    setDeleted,
    fileUrl,
    setFileUrl,
    pageNo,
    setPageNo,
    freezedObj,
    setFreezedObj,
    searchText,
    setSearchText,
    messageRefs,
    messageId,
    chatUserList,
  };
  const renderTxtInputProps = {
    chatUserDetails,
    msgInput,
    setMsgInput,
    setMessages,
    sendClicked,
    setSendClicked,
    text,
    replyClicked,
    setReplyClicked,
    replyObj,
    setReplyObj,
    messageSearchInput,
    setMessageSearchInput,
    deleted,
    setDeleted,
    fileUrl,
    setFileUrl,
    pageNo,
    setPageNo,
    freezedObj,
    setFreezedObj,
    setMessagesLoad,
    messagesLoad,
  };
  const containerRef = useRef(null);

  useEffect(() => {
    if (scrolledToBottom) {
      goToBottom();
    }
  }, [messages, setMessages, sendClicked, scrolledToBottom]);

  const goToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const isScrolledToBottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10;

    if (container.scrollTop === 0) {
      setPageNo((prevPageNo) => prevPageNo + 1);
    } else if (isScrolledToBottom) {
      setPageNo(0);
    }

    setScrolledToBottom(isScrolledToBottom);
  };

  useEffect(() => {
    // socket.onmessage = (event) => {
    //   const parsedEvent = JSON.parse(event?.data);
    //   const { event: dataEvent, data: dataData } = parsedEvent || {};
    //   if (dataEvent === "PING") {
    //     socket.send(JSON.stringify({ event: "PONG" }));
    //   } else if (dataEvent === "MESSAGE_RECEIVED") {
    //     setMessages((prev) => [...prev, dataData]);

    //     const newUsersList = chatUserList?.map((each) => {
    //       if (each._id === dataData.message.roomId) {
    //         return dataData;
    //       } else {
    //         return each;
    //       }
    //     });
    //     setChatUserList(newUsersList);
    //     // setChatUserList((prev) => {
    //     //   let isNew = true;
    //     //   const newUsersList = prev?.chatUserList?.map((each) => {
    //     //     if (each._id === dataData.message.roomId) {
    //     //       isNew = false;
    //     //       return dataData;
    //     //     } else {
    //     //       return each;
    //     //     }
    //     //   });
    //     //   if (isNew) {
    //     //     return [...prev, dataData];
    //     //   }
    //     //   console.log(newUsersList, "newUsersList");
    //     //   return newUsersList;
    //     // });
    //   }
    // };
  }, [messages, setChatUserList, setMessages]);
  return (
    <div
      className={
        text === "JoinClassChatScreen"
          ? "chat_height"
          : text === "CourseDetails"
          ? "course-details-container"
          : "custom_message_container"
      }
    >
      <div className={"custom_messages_sub_container"}>
        <div
          className={"custom_message_sub_sub_container"}
          onScroll={handleScroll}
          ref={containerRef}
        >
          <RenderMessages {...renderMessageProps} />
        </div>
      </div>
      <RenderTextInput {...renderTxtInputProps} />
    </div>
  );
}
