import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { GoDotFill } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import "./index.scss";
import { isEmpty } from "lodash";
import { courseConstant } from "./courseConstant";

const Fields = ({ text, content }) => {
  return (
    <div className="flex-container" style={{ alignItems: "center" }}>
      <AautiText size={"small"} weight={"bold"} title={text} />
      <AautiText size={"small"} title={content} />
    </div>
  );
};

const ActiveOverviewTab = ({ object, setActiveField }) => {
  const location = () => {
    const { virtual, inPerson } = object;
    if (virtual && inPerson) {
      return "Virtual, In Person";
    } else if (virtual) {
      return "Virtual";
    } else if (inPerson) {
      return "In Person";
    } else {
      return "";
    }
  };
  return (
    <div
      className="tab"
      style={
        {
          //   display: activeField === each.name ? "none" : "block",
        }
      }
      onClick={() => {
        setActiveField("Title");
      }}
    >
      <div className="text-icons">
        <AautiText
          size="big"
          weight={"bold"}
          title={object?.courseName}
          textStyle={{ marginBottom: 5, width: "80%", overflow: "hidden" }}
        />
        <div className="icons-flex">
          <GoDotFill color="green" />
          <FaArrowRight />
        </div>
      </div>
      {/* <AautiText title={each?.text} /> */}
      <div className="flex-container" style={{ flexWrap: "wrap" }}>
        <Fields
          text={courseConstant?.levels}
          content={`(${object.courseLevel?.join(", ")});`}
        />
        <Fields
          text={courseConstant?.category}
          content={`(${object?.category});`}
        />
        <Fields
          text={courseConstant?.courseType}
          content={`(${object?.courseType?.join(", ")});`}
        />
        <Fields text={courseConstant?.location} content={`(${location()});`} />
        <Fields
          text={courseConstant?.age}
          content={`(${object?.targetAgeGroups?.join(", ")});`}
        />
        {!isEmpty(object?.searchTags) && (
          <Fields
            text={courseConstant?.searchTags}
            content={`(${object?.searchTags?.join(", ")});`}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveOverviewTab;
