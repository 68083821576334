import React from "react";
import AautiText from "../globalComponents/AautiText";
import "./Style.scss";
import Footer from "../footer/Footer";
const ReturnPolicy = ({ showFooter = false }) => {
  return (
    <div>
      <div className="mainDivStyle">
        <AautiText
          className="titleTextStyle"
          size={"big"}
          weight={"bold"}
          textStyle={{
            marginTop: 15,
            marginBottom: 20,
          }}
          title={"Refund Policy"}
        />
        <div className="eachrowcontainer">
          <AautiText
            title={"Eligibility for Returns:"}
            textStyle={{ marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "You may be eligible for a return of your course purchase under the following circumstances:"
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "The course you purchased is not what you expected, and you have not completed more than 20% of the course content."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "You encounter technical issues that prevent you from accessing the course, and our technical support team is unable to resolve the issue within a reasonable time frame."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Timeframe for Returns:"}
            size={"medium"}
            weight={"bold"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
          />
          <AautiText
            title={
              "You must request a return within [number] days from the date of purchase. After this period, we will not accept return requests."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Non-Refundable Situations:"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "You will not be eligible for a return or refund in the following situations:"
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />

          <AautiText
            title={"You have completed more than 20% of the course content."}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"You have violated our terms of service or code of conduct."}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "You have shared your course materials with others, in violation of our copyright and intellectual property policies."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "You have used a promotional code or discount to purchase the course."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Return Process:"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "To request a return, please contact our customer support team at [contact email or phone number]. Please provide the following information:"
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />

          <AautiText
            title={"Your full name"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"Course name and purchase date"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"Reason for the return"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "Our team will review your request and respond within [number] business days."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Refund Process:"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "If your return request is approved, we will process your refund using the original payment method you used for the course purchase."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "Please allow [number] business days for the refund to reflect in your account."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Course Access:"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "Upon approval of your return, you will lose access to the course content immediately."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>

        <div className="eachrowcontainer">
          <AautiText
            title={"Changes to the Return Policy:"}
            textStyle={{ marginTop: "5px", marginBottom: "5px" }}
            size={"medium"}
            weight={"bold"}
          />
          <AautiText
            title={
              "[Your Company Name] reserves the right to modify this Course Purchase Return Policy at any time. Any changes will be posted on our website, and the effective date will be updated accordingly."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "By purchasing a course from [Your Company Name], you acknowledge that you have read, understood, and agreed to this Course Purchase Return Policy. If you have any questions or concerns, please do not hesitate to contact our customer support team."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={
              "Thank you for choosing [Your Company Name] for your learning needs."
            }
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"[Your Company Name]"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"[Website URL]"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
          <AautiText
            title={"[Contact Information]"}
            size={"normal"}
            weight={"normal"}
            textStyle={{ color: "#878393" }}
          />
        </div>
      </div>
      {showFooter && <Footer />}
    </div>
  );
};
export default ReturnPolicy;
