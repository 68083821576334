import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

const urls = {
  getRevenueBySpId: "payout/getRevenueBySpId?",
  getBankListBySpId: "bankDetails/getBankDetailsByServiceProiderId?",
};

function getPayoutRequests(
  serviceProviderId,
  count,
  offset,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: urls.getRevenueBySpId,
    method: ApiMethods.GET,
    params: {
      serviceProviderId,
      count,
      offset,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function GetBanksList(serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url: urls.getBankListBySpId,
    method: ApiMethods.GET,
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const AuthService = { getPayoutRequests, GetBanksList };
