import React, { useEffect, useState } from "react";
import AppContainer from "../../AppContainer";
import AccountTabs from "../../globalComponents/AccountTabs";
import AautiText from "../../globalComponents/AautiText";
import "../Style.scss";
import { AuthService } from "../service";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import EmptyData from "../../globalComponents/EmptyData";
import MembersLoader from "../../members/MembersLoader";
import moment from "moment";
import { noImage } from "../../calendar/constants";

const tabs = [
  { id: 1, name: "Credits", value: "credits" },
  { id: 2, name: "Banking", value: "netBanking" },
];
const Refunds = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("credits");
  const { loggedinUserId } = useSelector((state) => state.app);
  const [refundsList, setRefundsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    const params = { endUserId: loggedinUserId, refundMethod: activeTab };
    AuthService.getAllRefundRequests(
      params,
      (response) => {
        if (response?.result) {
          setRefundsList(response?.result);
        } else {
          setRefundsList([]);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error, "error");
      }
    );
  }, [activeTab]);

  return (
    <AppContainer>
      <AccountTabs />
      <AautiText
        size="big"
        weight={"bold"}
        title={"Refunds"}
        textStyle={{ marginBottom: 5 }}
      />
      <div className="tabs-flex">
        {tabs?.map((each, index) => (
          <button
            className={`tab-refund ${activeTab == each?.value && "active-class"
              }`}
            key={index}
            onClick={() => {
              setActiveTab(each?.value);
            }}
          >
            <AautiText title={each?.name} />
          </button>
        ))}
      </div>
      <div className="list-main">
        {loading ? (
          <div className="tabs-flex ">
            {[1, 2, 3]?.map((each, index) => (
              <MembersLoader key={index} />
            ))}
          </div>
        ) : isEmpty(refundsList) ? (
          <EmptyData showExplore={true} />
        ) : (
          <div className="refund-main">
            {refundsList?.map((each, index) => (
              <div className="refund-card" key={index}>
                <img
                  src={each?.courseImage || noImage}
                  className="refund-img2"
                  alt="refund-img"
                />
                <div>
                  <AautiText
                    title={each?.courseName}
                    textStyle={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 1,
                      textOverflow: "ellipsis",
                      maxWidth: "300px",
                    }}
                  />
                  <AautiText
                    size={"semi"}
                    title={`Ref ID: ${each?.receiptId}`}
                    textStyle={{ margin: "2px 0px" }}
                  />
                  <AautiText
                    size={"semi"}
                    title={moment(each?.createdAt).format(
                      "DD MMM YYYY hh:mm A"
                    )}
                  />
                  <div className="price-cont">
                    <AautiText
                      title={`${each?.currencySymbol +
                        (each?.refundAmount?.toFixed(2) || 0)
                        }`}
                      weight={"bold"}
                    />
                    <AautiText title={each?.status} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </AppContainer>
  );
};

export default Refunds;
