import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

function GetuserDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERSBYID,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function updateUserDetails(userDetailsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPADTE_USER_DETAILS,
    method: ApiMethods.PUT,
    data: userDetailsData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllContries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllGrades(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_GRADES,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllLanguages(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETALL_LANGUAGES,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getUserLocationByLatLang(lattitude, longitude, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_USER_LOCATION +
      "longitude=" +
      longitude +
      "&latitude=" +
      lattitude,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const AuthService = {
  GetuserDetailsById,
  getUserLocationByLatLang,
  updateUserDetails,
  getAllGrades,
  getAllLanguages,
  getAllContries,
};
