export const chevronLeft =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/chevronLeft.png";
export const course =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/course.png";
export const attendance =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/selectedUser.png";
export const rating =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/star.png";
export const users =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/users.png";
export const noImage =
  "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/noimage.jpeg";

const w = window.innerWidth;
const h = window.innerHeight;

export const isTimeInRange = (startTime, endTime) => {
  const currentDate = new Date();
  const fifteenMinutesBeforeStartTime = new Date(startTime);
  fifteenMinutesBeforeStartTime.setMinutes(
    fifteenMinutesBeforeStartTime.getMinutes() - 15
  );

  return (
    currentDate >= fifteenMinutesBeforeStartTime &&
    currentDate <= new Date(endTime)
  );
};
