import React from "react";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import { ChatRounded, Logout, Settings } from "@mui/icons-material";
import WifiProtectedSetupIcon from "@mui/icons-material/WifiProtectedSetup";
import PublicIcon from "@mui/icons-material/Public";
import { HeaderStrings } from "./constants";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { MdOutlineSupportAgent } from "react-icons/md";
import { HeaderSettingsIcon } from "../homeScreen/header/pageHeader/pageHeaderUtils";

const ProfileMenu = ({
  handleMenuClose,
  menuOpen,
  userDetails,
  FirstName,
  UserRole,
  handleUserRole,
  navigation,
  handleOpenConfirmation,
  mobileDevices,
  countryObject,
  setToggleCurrency,
  chatCount,
}) => {
  const isEndUser =
    userDetails?.role?.length == 1 && userDetails?.role?.includes("endUser");
  return (
    <Menu
      anchorEl={menuOpen}
      id="account-menu"
      open={menuOpen}
      onClose={handleMenuClose}
      // onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        className: "ProfileMenu-menu1",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ height: "auto !important" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Avatar src={userDetails?.profileImage} className="ProfileMenu-div1" />
        <AautiText
          title={userDetails?.displayName || FirstName || HeaderStrings?.user}
          textAlign={"center"}
        />
        {
          // !isChild &&
          userDetails?.role?.includes(HeaderStrings?.instituteAdmin) ? (
            <button className="ProfileMenu-button1">
              <AautiText
                weight={"bold"}
                title={HeaderStrings?.instituteAdminCap}
                className={"ProfileMenu-text1"}
              />
            </button>
          ) : isEndUser ? (
            <button
              onClick={() => {
                handleUserRole(isEndUser);
              }}
              style={{
                background: "none",
                marginTop: 10,
                cursor: "pointer",
                color: "#3083EF",
                border: "1px solid #3083EF",
                padding: 6,
                borderRadius: 3,
              }}
            >
              <AautiText
                textStyle={{}}
                title={"Become a Serviceprovider"}
                size={"semi"}
              />
            </button>
          ) : (
            <Tooltip title="Switch Profile">
              <button
                onClick={() => {
                  handleUserRole();
                }}
                className="ProfileMenu-button2"
              >
                <AautiText
                  size={"semi"}
                  weight={"bold"}
                  title={`${
                    userDetails?.role === HeaderStrings?.instituteAdmin
                      ? HeaderStrings?.instituteAdminCap
                      : UserRole === HeaderStrings?.serviceProvide2
                      ? HeaderStrings?.endUser
                      : HeaderStrings?.serviceProvider
                  }`}
                  className={"ProfileMenu-text1"}
                />
                <WifiProtectedSetupIcon className="ProfileMenu-text1" />
              </button>
            </Tooltip>
          )
        }
      </Box>
      <Divider />

      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          navigation("/calendar");
        }}
      >
        <ListItemIcon>
          <DashboardIcon fontSize="small" />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.myDashboard}
        />
      </MenuItem>

      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          navigation("/my-account");
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.myAccount}
        />
      </MenuItem>
      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          navigation("/chat");
        }}
      >
        <ListItemIcon>
          <ChatRounded fontSize="small" />
        </ListItemIcon>

        <AautiText
          size={"normal"}
          weight={"medium"}
          title={"Chat"}
          textStyle={{ marginRight: "5px" }}
        />
        {chatCount !== 0 && chatCount !== null && (
          <AautiText
            size={"small"}
            weight={"medium"}
            textStyle={{
              color: "white",
              padding: "1px 8px 0px 8px",
              borderRadius: "30px",
              backgroundColor: "#ed6c02",
              textAlign: "center",
            }}
            title={`${chatCount}`}
          />
        )}
      </MenuItem>
      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          navigation("/profile");
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.profileSettings}
        />
      </MenuItem>

      <MenuItem
        sx={{
          display:
            !userDetails?.role?.includes(HeaderStrings?.instituteAdmin) &&
            "none",
          minHeight: "auto !important",
        }}
        onClick={() => {
          handleMenuClose();
          navigation("/Institute-Information");
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.instituteInfo}
        />
      </MenuItem>

      <MenuItem
        style={{
          display: !mobileDevices && "none",
          minHeight: "auto !important",
        }}
        className="ProfileMenu-menu2"
        onClick={() => {
          setToggleCurrency(true);
          handleMenuClose();
        }}
      >
        <ListItemIcon>
          <Avatar
            variant="rounded"
            alt={countryObject?.name}
            src={countryObject?.flag}
            sx={{ width: "30px !important", height: "25px !important" }}
          />
        </ListItemIcon>
        <AautiText
          className={"ProfileMenu-text2"}
          size={"normal"}
          title={`${countryObject?.countryCode || countryObject?.name} - ${
            countryObject?.countryCurrencySymbol ||
            countryObject?.countryCurrency ||
            "NA"
          }`}
        />
      </MenuItem>
      <MenuItem
        style={{
          display: !mobileDevices && "none",
          minHeight: "auto !important",
        }}
        onClick={() => {
          handleMenuClose();
          navigation("/account-settings");
        }}
      >
        <ListItemIcon>
          <HeaderSettingsIcon />
        </ListItemIcon>
        <AautiText size={"normal"} weight={"medium"} title={"Settings"} />
      </MenuItem>
      <Divider />

      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          navigation("/help-support");
        }}
      >
        <ListItemIcon>
          <MdOutlineSupportAgent size={20} />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.HelpSupport}
        />
      </MenuItem>

      <MenuItem
        sx={{ minHeight: "auto !important" }}
        onClick={() => {
          handleMenuClose();
          handleOpenConfirmation(HeaderStrings?.logout);
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <AautiText
          size={"normal"}
          weight={"medium"}
          title={HeaderStrings?.logoutCap}
        />
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
