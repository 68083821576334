import { Avatar, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const CommentInput = ({
  handleInputCancel,
  handleInputSave,
  isReply = false,
  value = "",
}) => {
  const { userDetails } = useSelector((state) => state.app);
  const [inputValue, setInputValue] = useState(value);
  return (
    <div style={{ width: "100%" }}>
      <div className="comments-main">
        <Avatar
          src={userDetails?.profileImage}
          alt="user-profile"
          sx={{ display: value && "none" }}
        />
        <TextField
          value={inputValue}
          onChange={(e) => {
            let value = e.target.value.trimStart().replace(/\s+/g, " ");
            e.target.value = value;
            setInputValue(value);
            if (!isReply && !value) {
              handleInputCancel();
            }
          }}
          id="standard-basic"
          placeholder={`${isReply ? "Reply" : "Add"} Comment`}
          variant="standard"
          sx={{ width: "100%" }}
        />
      </div>
      <div
        className="comment-buttons-cont"
        style={{ display: !isReply && inputValue?.length < 3 && "none" }}
      >
        <button
          className="button"
          onClick={() => {
            setInputValue("");
            handleInputCancel();
          }}
        >
          <AautiText
            title={AautiStrings?.cancelString}
            textStyle={{ color: "#2076E5" }}
          />
        </button>
        <button
          className="button save"
          onClick={() => {
            handleInputSave(inputValue);
            setInputValue("");
          }}
        >
          <AautiText
            title={value != "" ? "Update" : "Comment"}
            textStyle={{ color: "#fff" }}
          />
        </button>
      </div>
    </div>
  );
};

export default CommentInput;
