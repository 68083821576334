import React, { useEffect, useState } from "react";
import { InstituteDashboard } from "storybook-signup";
import { getInstituteDashboardDetails, getInstituteDetails } from "./Services";
import { useNavigate } from "react-router-dom";
import { showToast } from "../globalComponents/Toast";
import { useParams } from "react-router-dom";
// import { InstituteDashboard } from "instituteSignup";

function InstituteProfile(props) {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [instituteDetails, setInstituteDetails] = useState(null);
  const params = useParams();
  useEffect(() => {
    getInstituteDashboardDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success" && res.result !== null) {
          setDashboardData(res?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    getInstituteDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success") {
          setInstituteDetails(res.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div
      style={{
        width: "100%",
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <InstituteDashboard
        dashboardData={dashboardData}
        instituteDetails={instituteDetails}
      />
    </div>
  );
}

export default InstituteProfile;
