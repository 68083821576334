import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { AiFillStar } from "react-icons/ai";
import "./Style.scss";
import Rating from "@mui/material/Rating";
import "./Style.scss";
import { useSelector } from "react-redux";
import { AuthService } from "../serviceProviderDetails/service";
import { Box, Skeleton } from "@mui/material";
import { showToast } from "../globalComponents/Toast";
import { FaChevronRight } from "react-icons/fa6";
import StarIcon from "@mui/icons-material/Star";
import NoData from "../globalComponents/NoDataFound";
import { getCourseRatings } from "./service";
import UserCard from "../course/courseDetails/UserCard";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
const ReviewsAndRatings = () => {
  const [ratingMap, setRatingMap] = useState("Courses");
  const { loggedinUserId } = useSelector((state) => state.app);
  const [ratingData, setRatingData] = useState(null);
  const [ratingSK, setRatingSK] = useState(true);
  const [spRatings, setSpRatings] = useState(null);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [tabs] = useState(["Courses", "Gigs", "Adhocs"]);
  const [spData, setSpData] = useState(null);

  const isCourse = ratingMap == "Courses";
  const isGig = ratingMap == "Gigs";
  const isAdhoc = ratingMap == "Adhocs";
  const DataString = isCourse ? "courses" : isGig ? "gigs" : "adhocs";
  const propString = isCourse ? "course" : isGig ? "gig" : "adhoc";

  const location = useLocation();
  const isDashboard = location?.state?.dashboard;

  useEffect(() => {
    getRating();
    if (isDashboard) {
      AuthService?.getServiceProviderDetails(
        loggedinUserId,
        loggedinUserId,
        (res) => {
          if (res?.status == "Success") {
            setSpData(res?.result[0]);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, []);

  useEffect(() => {
    getCoursesRatings();
    setRatingSK(true);
  }, [ratingMap]);

  const getCoursesRatings = () => {
    setRatingSK(false);
    let url = isCourse
      ? "ratingsReviews/getServiceProviderIdRatingsReviewsforCourse"
      : isGig
        ? "ratingsReviews/getServiceProviderIdRatingsReviewsforGig"
        : "ratingsReviews/getServiceProviderIdRatingsReviewsforAdhoc";
    getCourseRatings(
      url,
      loggedinUserId,
      (res) => {
        if (res?.status == "Success") {
          setRatingSK(false);
          setSpRatings(res?.result);
        }
      },
      (err) => { }
    );
  };

  const getRating = () => {
    AuthService.getServiceProviderRatingsReviewsBy(
      loggedinUserId,
      onSuccessRating,
      onFailure
    );
  };
  const onSuccessRating = (response) => {
    if (response.status === "Success") {
      setRatingSK(false);
      setRatingData(response?.result);
    } else {
      showToast("error", response.message);
      setRatingSK(false);
    }
  };
  const onFailure = (error) => {
    console.log(error, "error");
    setRatingSK(false);
  };
  const navigate = useNavigate();
  return (
    <AppContainer className="SPRmaindiv">
      <AccountTabs />
      <div className="SPRmaindiv2">
        {/* <AautiText textStyle={{ color: "grey" }} title={"My Aauti"} /> */}
        {/* <FaChevronRight
          size={12}
          style={{ margin: "0px 3px", color: "grey" }}
        /> */}
        <AautiText weight={"bold"} title={"Reviews & Ratings"} />
      </div>
      {ratingSK ? (
        <div className="SPRskeltondiv1">
          <div className="SPRskeltondiv2">
            {[{}, {}, {}]?.map((_, index) => (
              <Skeleton key={index} variant="rounded" width={200} height={60} />
            ))}
          </div>
          <div className="SPRskeltondiv3">
            {[{}, {}, {}, {}, {}, {}, {}, {}]?.map((_, index) => (
              <Skeleton key={index} variant="rounded" width={350} height={80} />
            ))}
          </div>
        </div>
      ) : (
        <>
          {
            <div className="SPRtabdiv1">
              {isDashboard && (
                <UserCard
                  {...{
                    completeDetails: {
                      serviceProviderId: spData?._id,
                      displayName: spData?.displayName,
                      averageRating: spData?.averageRating,
                      spCourseCount: spData?.coursesCount,
                      spStudentsCount: spData?.subscribersCount,
                      profileImage: spData?.profileImage,
                    },
                  }}
                  isDashboard={isDashboard}
                />
              )}
              <div className="SPRtabdiv2">
                {tabs.map((each, index) => (
                  <div
                    onClick={() => setRatingMap(each)}
                    key={index}
                    className="SPRwidthDiv"
                    style={{
                      border:
                        each === ratingMap
                          ? "1px solid #078dda"
                          : "1px solid #dedede",
                    }}
                  >
                    <div className="SPRrow">
                      <AiFillStar
                        size={mobileDevices ? 16 : 20}
                        style={{
                          color: each === ratingMap ? "#078dda" : "#1e1e1e",
                        }}
                      />
                      <AautiText
                        weight={"medium"}
                        size={"medium"}
                        textStyle={{
                          margin: "0px 5px",
                          color: each === ratingMap ? "#078dda" : "#1e1e1e",
                        }}
                        title={
                          each == "Courses"
                            ? ratingData?.coursesValue
                              ? ratingData?.coursesValue?.toFixed(1)
                              : ""
                            : each == "Gigs"
                              ? ratingData?.gigsValue
                                ? ratingData?.gigsValue?.toFixed(1)
                                : ""
                              : ratingData?.adhocValue
                                ? ratingData?.adhocValue?.toFixed(1)
                                : ""
                        }
                      />
                    </div>
                    <AautiText
                      weight={"medium"}
                      size={"semi"}
                      title={each}
                      textStyle={{
                        color: each === ratingMap ? "#078dda" : "#1e1e1e",
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className="SPRdataGap">
                {spRatings?.[DataString]?.length == 0 ||
                  !spRatings?.[DataString] ? (
                  <NoData NoDataFound={"No Ratings Yet"} />
                ) : (
                  <>
                    {spRatings?.[DataString]?.map((each, index) => (
                      <div key={index} className="SPRdatawidth">
                        <img
                          alt="ALt"
                          src={each?.[`${propString}Image`]}
                          className="SPRimage"
                        />
                        <div className="SPRcardwidth">
                          <AautiText
                            weight={"medium"}
                            size={"semi"}
                            title={each?.[`${propString}Name`]}
                            textStyle={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          />
                          <Box className="SPRStarwidth">
                            <Rating
                              sx={{ color: "#078dda" }}
                              color="#078dda"
                              name="text-feedback"
                              value={each?.["averageRating"]}
                              readOnly
                              precision={0.5}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                            <Box sx={{ ml: 2 }}>
                              {each?.["averageRating"] || 0.0}
                            </Box>
                          </Box>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          }
        </>
      )}
    </AppContainer>
  );
};
export default ReviewsAndRatings;
