import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { Box, Grid, Tab } from "@mui/material";
import "./style.scss";
import { useSelector } from "react-redux";
import { AuthService } from "./Service";
import { showToast } from "../globalComponents/Toast";
import { IoIosSearch } from "react-icons/io";
import { PriceConversions } from "../CommonFunctions";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AautiDialog from "../globalComponents/AautiDialog";
import CreateRequest from "./CreateRequest";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";

const PayoutRequest = () => {
  const { convertPriceWithCurrency } = PriceConversions();
  const [tabValue, setTabValue] = React.useState("1");
  const [count, setCount] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [banksList, setBanksList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { loggedinUserId, userDetails, UserRole } = useSelector(
    (state) => state.app
  );
  const [selectedBank, setSelectedBank] = useState({});
  const [payoutList, setPayoutList] = useState([]);
  // const payoutData = {
  //   serviceProviderId: loggedInUserId,
  //   countryName: payoutState.selectedBank[0]?.countryName,
  //   bankName: payoutState.selectedBank[0]?.bankName,
  //   accountHolderName: payoutState.selectedBank[0]?.accountHolderName,
  //   bankDocument: payoutState.selectedBank[0]?.accountHolderName,
  //   accountNumber: payoutState.selectedBank[0]?.accountNumber,
  //   ssnNumber: payoutState.selectedBank[0]?.ssnNumber,
  //   ifscCode: payoutState.selectedBank[0]?.ifscCode,
  //   dateOfBirth: payoutState.selectedBank[0]?.dateOfBirth,
  //   payoutStatus: 'inProgress',
  //   withdrawAmount: payoutState.withdrawAmount,
  //   TDS: tds,
  //   FederalTax: federalTax,
  //   instantPayCharges: instantPayCharges,
  //   netPay: netPay,
  // }

  useEffect(() => {
    AuthService.getPayoutRequests(
      loggedinUserId,
      count,
      offSet,
      onSuccessGetPayouts,
      onFailure
    );
    AuthService.GetBanksList(loggedinUserId, onSuccessBankList, onFailure);
  }, [count, loggedinUserId, offSet]);

  const onSuccessBankList = (response) => {
    if (response?.status === "Success") {
      setBanksList(response?.result);
    } else {
      showToast("response", response.message);
    }
  };

  const onSuccessGetPayouts = (response) => {
    if (response.status === "Success") {
      setPayoutList(response?.result[0]);
    } else {
      showToast("response", response.message);
    }
  };

  const onFailure = (error) => {
    showToast("response", error.message);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <AccountTabs />

      <div style={{ width: "96%" }}>
        <AautiText
          title={"Revenue"}
          textStyle={{ margin: "5px" }}
          weight={"bold"}
          size={"medium"}
        />
        <Grid style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
          <Box className="TRboxShadowDiv">
            <Box className="PRTRleftmaindiv1">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <AautiText
                  title={"Payout Requests"}
                  textStyle={{ margin: "5px" }}
                  weight={"bold"}
                  size={"normal"}
                />
                <AautiText
                  onClick={() => setOpenDialog(true)}
                  title={"Request Payout"}
                  textStyle={{
                    margin: "5px",
                    color: "#3083EF",
                    cursor: "pointer",
                  }}
                  weight={"bold"}
                  size={"normal"}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#F2F2F6",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "12px",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              >
                <IoIosSearch color="#5C5B57" size={18} />

                <input
                  placeholder="Search"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "20vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AautiText
                  title={"No Data"}
                  textStyle={{ margin: "5px" }}
                  weight={"medium"}
                  size={"medium"}
                />
              </div>
            </Box>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                // padding: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ padding: "10px", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button
                      style={{
                        width: "100%",
                        margin: "10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <AautiText
                        title={convertPriceWithCurrency(
                          payoutList?.availableFunds
                        )}
                        textStyle={{ color: "#000000" }}
                        weight={"bold"}
                        size={"medium"}
                      />
                      <AautiText
                        title={"Available Funds"}
                        textStyle={{ color: "#5C5B57" }}
                        weight={"medium"}
                        size={"semi"}
                      />
                    </button>
                    <button
                      style={{
                        width: "100%",
                        margin: "10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <AautiText
                        title={convertPriceWithCurrency(
                          payoutList?.pipelineFunds
                        )}
                        textStyle={{ color: "#000000" }}
                        weight={"bold"}
                        size={"medium"}
                      />
                      <AautiText
                        title={"Pipeline Funds"}
                        textStyle={{ color: "#5C5B57" }}
                        weight={"medium"}
                        size={"semi"}
                      />
                    </button>
                  </div>
                </div>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      sx={{ textTransform: "none", width: "100%" }}
                    >
                      <Tab
                        label="Courses"
                        value="1"
                        sx={{ textTransform: "none", width: "33%" }}
                      />
                      <Tab
                        label="Gigs"
                        value="2"
                        sx={{ textTransform: "none", width: "33%" }}
                      />
                      <Tab
                        label="Adhoc Events"
                        value="3"
                        sx={{ textTransform: "none", width: "33%" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">Courses</TabPanel>
                  <TabPanel value="2">Gigs</TabPanel>
                  <TabPanel value="3">Adhoc Events</TabPanel>
                </TabContext>
              </div>
            </div>
          </Box>
        </Grid>
        <AautiDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          dialogTitle={"Request Payout"}
        >
          <CreateRequest
            {...{
              payoutList,
              convertPriceWithCurrency,
              banksList,
              selectedBank,
              setSelectedBank,
            }}
          />
        </AautiDialog>
      </div>
    </AppContainer>
  );
};

export default PayoutRequest;
