import { Box } from "@mui/material";
import { Header } from "../header";
import Footer from "../footer";
import constants from "../constants.json";

import { Button } from "@mui/material";
import {
  LearnProfessionsRightImageTwo,
  SeeHowItWorksIcon,
  TextUnderLineIcon,
  VectorUnderLineIcon,
} from "../container";
import "./styles.scss";
import { EasyToStart } from "../easyToStart";
import ReadyToStart from "../readyToStart";
import imageFive from "./images/Image5.png";
import Frame1000007374 from "./images/Frame1000007374.png";
import FaqAccordion from "../faq";
import { useNavigate } from "react-router-dom";
import teach_with_aauti_mobile from './images/teach_with_aauti_mobile.png';
import what_aauti_helps_you_in_image_mobile from './images/what_aauti_helps_you_in_image_mobile.png'

export default function TeachWithAauti() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section id='LEARN_NEW_PROFESSION_TEACH'>
        <Box className="learn_professions_right_image_top">
          <img
            src={teach_with_aauti_mobile}
            alt="mob-image"
            style={{
              width: "95%",
            }}
          />
        </Box>
        <Box className='learn_new_profession_frame'>
          <Box className='learn_new_profession_text'>
            <p className='learn_new_profession_label'>
              Live & In person Teaching{" "}
              <span>
                <em>Learning</em>
              </span>{" "}
              Platform
            </p>
            <p className='learn_new_profession_label2'>
              Users today are digital natives with vast knowledge, while service
              providers are here to provide guidance and assistance, using AI in
              their respective fields.
            </p>
            <Box className='text_underline_container'>
              <TextUnderLineIcon />
            </Box>
          </Box>

          <Box className='learn_new_profession_button_container'>
            <Button
              variant='contained'
              className='learn_new_profession_button'
              onClick={() => {
                // dispatch(toggleSignInPop(true));
                // dispatch(setSignUpType("sign-up"));
                // dispatch(setSignupScreenIndex(0));
                // dispatch(setRegisteredType("Service Provider"));
                navigate("/register/user-type");
              }}
            >
              {constants.getStartedButton}
            </Button>
            <Box className='see_how_it_works_container'>
              <SeeHowItWorksIcon />
              <p className='see_how_it_works_label'>
                {constants.seeHowItWorksLabel}
              </p>
              <Box className='text_underline'>
                <TextUnderLineIcon />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='learn_professions_right_image'>
          <LearnProfessionsRightImageTwo />
        </Box>
      </section>
      <section id='WHAT_AAUTI_HELPS_YOU_IN'>
        <img
          src={imageFive}
          alt='imageFive'
          className='what_aauti_helps_you_in_image_five'
        />
        <Box className='what_aauti_helps_you_in_container'>
          <Box className='what_aauti_helps_you_in_text'>
            <p className='what_aauti_helps_you_in_label'>
              What <span className='what_aauti_helps_you_in_span'>Aauti</span>{" "}
              helps you in?
            </p>
            <p className='what_aauti_helps_you_in_label2'>
              Streamline your Institute’s operations and expand your reach.
            </p>
            <VectorUnderLineIcon />
          </Box>
          <img
            src={Frame1000007374}
            alt='Frame1000007374'
            className='what_aauti_helps_you_in_image'
          />
          <Box className="what_aauti_helps_you_in_image_mobile">
            <img src={what_aauti_helps_you_in_image_mobile} alt="Frame1000007374" />
          </Box>
        </Box>
      </section>
      <EasyToStart borderedText='Teaching' />
      <ReadyToStart />
      <FaqAccordion type='regular' />
      <Footer />
    </>
  );
}
