import {
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { HighlightOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

const AautiDialog = ({
  onClose,
  showCloseIcons,
  open,
  dialogTitle,
  children,
  minWidth = 500,
  maxWidth = 700,
  sxStyles,
  dialogWidth = "100%",
  titlePosition = "center",
  // maxWidth = 700,
  fullScreen,
  showHeader = true,
}) => {
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: { backdropFilter: "blur(4px)" },
      }}
      onClose={onClose}
      open={open}
      aria-labelledby="draggable-dialog-title"
      sx={{
        ...sxStyles,
        minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
        // maxWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
        zIndex: 502,
        "& .MuiDialog-paper": {
          width: `${dialogWidth} !important`,
          minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
          maxWidth: maxWidth,
        },
        // "& .MuiDialogContent-root": {
        //   overflowY: "hidden",
        //   paddingLeft: { xs: "15px", sm: 0 },
        //   paddingRight: { xs: "15px", sm: 0 },
        // },
      }}
    >
      <DialogTitle sx={{ display: !showHeader && "none" }}>
        <Typography
          align={titlePosition}
          component="p"
          variant="p"
          sx={{
            fontWeight: 500,
            fontSize: mobileDevices ? "15px" : ipodDevices ? "15px" : "18px",
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
        {!showCloseIcons && (
          <Box sx={{ position: "absolute", top: "5px", right: "5px" }}>
            <IconButton size="medium" onClick={onClose}>
              <HighlightOff />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          color: "text.secondary",
          fontSize: 14,
          p: mobileDevices ? "8px !important" : "30px !important",
          minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
          ...sxStyles,
        }}
        id="alert-dialog-description"
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default AautiDialog;
