import { Avatar } from "@mui/material";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { useDispatch } from "react-redux";
import {
  setJitsiFullScreenMode,
  setRoomName,
} from "../../redux/reducer/appReducer";
import { socket } from "../chat";

const InComingCallCompo = (props) => {
  const { callData, calDialogOpen, setCallDialogOpen, userData } = props;
  const [jitsiOpen, setJitsiOpen] = useState(false);
  const dispatch = useDispatch();
  const handleCloseCallDialog = () => {
    setCallDialogOpen(false);
  };

  const handleName = (value) => {
    if (value) {
      const words = value?.split(" ");
      if (words.length === 1) {
        return value.charAt(0).toUpperCase();
      } else {
        return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
      }
    }
  };

  const handleRejectCall = () => {
    // if (socket.readyState === 1) {
    //   socket.send(
    //     JSON.stringify({
    //       event: "CALL_RESPONSE",
    //       status: "Reject",
    //       requesterId: callData?._id,
    //     })
    //   );
    // }

    // socket.onmessage = (event) => {
    //   const parsedEvent = JSON.parse(event?.data);
    //   const { event: dataEvent, data: dataData } = parsedEvent || {};
    //   if (dataEvent === "PING") {
    //     socket.send(JSON.stringify({ event: "PONG" }));
    //   } else if (dataEvent === "CALL_RESPONSE") {
    //   }
    // };
    setCallDialogOpen(false);
  };

  const handleAcceptCall = () => {
    setJitsiOpen(true);
  };

  return (
    <>
      <Dialog
        header="Incoming Call"
        headerStyle={{
          textAlign: "center",

          backgroundColor: "#cae0eb",
        }}
        closable={false}
        visible={calDialogOpen}
        className="custom-dialog"
        onHide={() => handleCloseCallDialog}
      >
        <div
          className="custom-dialog-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="outline-cont">
            {userData && userData?.profileImage ? (
              <Avatar
                alt="Remy Sharp"
                src={userData?.profileImage}
                sx={{ width: 56, height: 56 }}
              />
            ) : (
              <Avatar
                sx={{
                  backgroundColor: "#066cb5",
                  width: 80,
                  height: 80,
                }}
              >
                {handleName(callData?.displayName)}
              </Avatar>
            )}
          </div>

          <AautiText
            textAlign={"center"}
            size={"medium"}
            weight={"bold"}
            textStyle={{
              color: "#4B4D4D",
              marginTop: "10px",
            }}
            title={callData?.displayName || "N/A"}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={() => { 
                const path = window.location.origin;
                const strWindowFeatures =
                  "location=yes,scrollbars=yes,status=yes";
                localStorage.setItem("room", callData?._id);
                const joinClassPath = `${path}/join_class/jitsi`;
                // window.open(joinClassPath, "_blank", strWindowFeatures);
                dispatch(setJitsiFullScreenMode(true));
                dispatch(setRoomName(`${callData?._id}`));
                setCallDialogOpen(false);
              }}
              style={{
                backgroundColor: "green",
                border: "none",
                outline: "none",
                borderRadius: "5px",
                padding: "10px",
                paddingRight: "25px",
                paddingLeft: "25px",
                color: "white",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              Accept
            </button>
            <button
              onClick={() => handleRejectCall()}
              style={{
                backgroundColor: "red",
                border: "none",
                outline: "none",
                borderRadius: "px",
                padding: "10px",
                paddingRight: "25px",
                paddingLeft: "25px",
                color: "white",
                cursor: "pointer",
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InComingCallCompo;
