import React, { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import { GrSchedules } from "react-icons/gr";
import moment from "moment";
import { MdOutlineCalendarMonth, MdOutlinePriceChange } from "react-icons/md";
import { LuClock4 } from "react-icons/lu";
import { BiSolidDiscount } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import { FiMonitor } from "react-icons/fi";
import { useSelector } from "react-redux";
import { PriceConversions } from "../../CommonFunctions";
import InPerson from "../../globalComponents/icons/InPerson";
import { showToast } from "../../globalComponents/Toast";

const BatchCard = ({
  previousBatch,
  type,
  setSelectedBatch,
  selectedBatch,
  slot,
  transfer = false,
}) => {
  const { countryObject, pricingRates } = useSelector((state) => state.app);

  const { convertPriceWithCurrency } = PriceConversions();

  const returnDate = (date, type) => {
    let formattedDate;
    if (type == "time") {
      formattedDate = moment(date).format("hh:mm A");
    } else {
      formattedDate = moment(date).format("DD MMM YYYY");
    }
    return formattedDate || "NA";
  };

  const isWaitingCourse = !previousBatch?.isAvailable
    ? previousBatch?.allowWaitingList
    : false;

  const returnWeek = (weeks) => {
    const nonEmptyElements = [];
    for (const key in weeks) {
      if (weeks[key].length > 0) {
        nonEmptyElements.push(
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <LuClock4 size={20} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
              }}
            >
              <AautiText
                title={`${returnDate(
                  weeks[key][0]?.startTime,
                  "time"
                )}-${returnDate(weeks[key][0]?.endTime, "time")}`}
              />
              <div
                style={{
                  background: "#066cb5",
                  padding: "2px 6px",
                  borderRadius: 4,
                }}
              >
                <AautiText
                  title={key.slice(0, 3)}
                  textStyle={{ color: "#ffffff" }}
                />
              </div>
            </div>
          </div>
        );
        // categories();
      }
    }
    return nonEmptyElements;
  };

  const currentDate = moment();
  const isFutureDate = moment(previousBatch?.discountStartDate).isAfter(
    currentDate
  );

  const checkDiscount =
    moment(previousBatch?.discountStartDate).isBefore(moment(new Date())) &&
    moment(previousBatch?.discountEndDate).isAfter(moment(new Date())) &&
    (previousBatch?.individualDiscountValue ||
      previousBatch?.groupDiscountValue) &&
    (previousBatch?.groupDiscountPrice ||
      previousBatch?.individualDiscountPrice);

  const returnDiscountType = (item) => {
    const value = item?.groupDiscountValue;

    if (item?.discountType !== "Amount") {
      return value ? `(${value}%)` : null;
    }
    return value ? `(-${convertPriceWithCurrency(value || 0)})` : 0;
  };

  return (
    <button
      disabled={type == "To" ? isWaitingCourse : !previousBatch?.isAvailable}
      onClick={() => {
        if (previousBatch?.isAvailable) {
          if (type == "To") {
            setSelectedBatch(previousBatch);
          }
          if (transfer) {
            setSelectedBatch(previousBatch);
          }
        } else {
          showToast("info", "Batch  not available");
        }
      }}
      style={{
        width: "100%",
        border:
          previousBatch?._id == selectedBatch?._id
            ? "2px solid #066cb5"
            : "1px solid lightgrey",
        borderRadius: 4,
        background: !previousBatch?.isAvailable ? "#d2d2d2" : "none",
        marginBottom: 10,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 8,
          background: "#efefef",
        }}
      >
        <AautiText
          size={"semi"}
          weight={"bold"}
          title={`${type || "From"} - ${previousBatch?.batchName || "NA"}`}
        />
        <AautiText
          size={"semi"}
          textStyle={{
            color: previousBatch?.isAvailable
              ? "Green"
              : isWaitingCourse
              ? "gold"
              : "Red",
          }}
          weight={"bold"}
          title={
            previousBatch?.isAvailable
              ? `Available ${previousBatch?.availableSlots || 0}`
              : isWaitingCourse
              ? `Waiting List ${previousBatch?.waitingListCount || 0}`
              : "CLOSED"
          }
        />
      </div>
      <div style={{ padding: 8 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            marginBottom: 8,
          }}
        >
          <GrSchedules size={20} />
          <AautiText title={slot || ""} size={"semi"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            marginBottom: 8,
          }}
        >
          <MdOutlineCalendarMonth size={20} />
          <AautiText
            title={`${returnDate(previousBatch?.startDate)}-${returnDate(
              previousBatch?.endDate
            )}`}
            size={"semi"}
          />
        </div>
        {previousBatch?.recurringDays ? (
          returnWeek(previousBatch?.recurringDays)
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginBottom: 8,
            }}
          >
            <LuClock4 size={20} />
            <AautiText
              size={"semi"}
              title={`${returnDate(
                previousBatch?.startTime,
                "time"
              )}-${returnDate(previousBatch?.endTime, "time")}`}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginTop: 8,
          }}
        >
          {previousBatch?.virtual ? (
            <FiMonitor fontSize="medium" />
          ) : (
            <InPerson fontSize="medium" />
          )}
          <AautiText
            title={`${convertPriceWithCurrency(previousBatch?.groupPrice)}`}
            size={"semi"}
            weight={"bold"}
            textStyle={{
              color: "#3166ba",

              textDecoration:
                checkDiscount && returnDiscountType(previousBatch)
                  ? "line-through"
                  : "",
            }}
          />
          <AautiText
            title={returnDiscountType(previousBatch)}
            size={"semi"}
            textStyle={{
              color: "#3166ba",
              display:
                (!returnDiscountType(previousBatch) || !checkDiscount) &&
                "none",
            }}
          />
          {returnDiscountType(previousBatch) && (
            <AautiText
              title={`${convertPriceWithCurrency(
                previousBatch?.groupDiscountPrice || 0
              )}`}
              weight={"bold"}
              textStyle={{
                color: "#3166ba",
                display: !checkDiscount && "none",
              }}
            />
          )}
        </div>
        <div
          style={{
            display:
              isFutureDate && previousBatch?.groupDiscountValue
                ? "flex"
                : "none",
            marginBottom: 6,
            marginTop: 6,
            alignItems: "center",
          }}
        >
          <BiSolidDiscount size={20} />
          <AautiText
            size={"small"}
            title={`(${
              returnDate(previousBatch?.discountStartDate) +
              " - " +
              returnDate(previousBatch?.discountEndDate)
            })`}
            textStyle={{ marginLeft: 10 }}
          />
        </div>
      </div>
    </button>
  );
};

export default BatchCard;
