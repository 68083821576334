import { FormControl, InputBase, NativeSelect, styled } from "@mui/material";
import React from "react";
import AautiText from "./AautiText";
import AautiTextInput from "./AautiTextInput";
import { useSelector } from "react-redux";
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      border: "0px",
      backgroundColor: "#fff",
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const AautiHead = ({
  title,
  searchText,
  setSearchText,
  search = false,
  hideOptions,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobileDevices ? "column" : "row",
        justifyContent: "space-between",
        // alignItems: "center",
        width: "100%",
      }}
    >
      <AautiText
        size={"large"}
        weight={"bold"}
        textAlign={"left"}
        title={title}
        textStyle={{
          fontSize: "20px",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
          marginBottom: 10,
        }}
      />
      <div
        style={{
          width: mobileDevices ? "100%" : search ? "40%" : "40%",
          display: "flex",
          marginBottom: "10px",
          justifyContent: mobileDevices ? "flex-start" : "flex-end",
          alignItems: "center",
        }}
      >
        {search && (
          <div style={{ width: "60%", marginRight: "15px" }}>
            <AautiTextInput
              text="Search"
              style={{ width: "100%" }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        )}
        {/* {!hideOptions && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <AautiText
              size={"semi"}
              textStyle={{ color: "grey", width: "auto", minWidth: "53px" }}
              title={"sort by:"}
            />
            <FormControl
              style={{
                width: search ? "80%" : "auto",
                minWidth: mobileDevices ? "100%" : "75%",
              }}
              variant="standard"
            >
              <NativeSelect
                id="demo-customized-select-native"
                // value={age}
                // onChange={handleChange}
                input={<BootstrapInput />}
              >
                <option value={10}>Recommended</option>
                <option value={20}>Best Selling </option>
                <option value={20}>New Arrivals </option>
              </NativeSelect>
            </FormControl>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AautiHead;
