import { socket } from "../../..";
import fileTypes from "./fileTyepes.json";
export function onChangeMsgInput(e, args) {
  const { setMsgInput } = args;
  setMsgInput(e.target.value);
}
export const idGenerator = () => {
  return "xxxxxxxxxxxx4xxxyxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export async function onClickSendButton(event, args) {
  const {
    chatUserDetails,
    msgInput,
    setMsgInput,
    setSendClicked,
    fileUrl,
    setFileUrl,
    replyClicked,
    replyObj,
    sendClicked,
  } = args;

  const loggedinUserId = localStorage.getItem("USER_ID");
  setMsgInput("");

  setSendClicked(!sendClicked);
  const sender = loggedinUserId;
  const receiver =
    chatUserDetails?.type !== "ONE_TO_ONE"
      ? chatUserDetails?._id
      : chatUserDetails?.user?._id || chatUserDetails?.user?.id;
  const roomId = chatUserDetails?._id;
  const sessionId = chatUserDetails?.sessionId;
  const createdAt = new Date();
  const batchId =
    chatUserDetails?.type === "COURSE"
      ? chatUserDetails?.batch?._id
      : chatUserDetails?.batch?._id;
  const text = msgInput || "";
  const parentId = replyObj?._id;
  const roomType = chatUserDetails?.type || "ONE_TO_ONE";
  const type = fileUrl?.length
    ? fileUrl?.length === 1
      ? fileUrl?.[0]?.type
      : "DOCUMENT"
    : replyClicked
    ? "REPLY"
    : "TEXT";
  const _id = idGenerator();
  const files = fileUrl?.map((_) => _?.file);
  // if (socket.readyState === 1) {
  //   if (replyClicked) {
  //     if (chatUserDetails?.type === "FEEDS") {
  //       socket.send(
  //         JSON.stringify({
  //           event: "MESSAGE_SEND",
  //           roomId: batchId,
  //           sender,
  //           text,
  //           batchId,
  //           roomType: "REPLY",
  //           sessionId,
  //           parentId,
  //           receiver: roomId,
  //           isAdhoc: false,
  //           type,
  //           _id,
  //         })
  //       );
  //     } else {
  //       socket.send(
  //         JSON.stringify({
  //           event: "MESSAGE_SEND",
  //           sender,
  //           receiver,
  //           roomId,
  //           roomType,
  //           createdAt,
  //           parentId,
  //           text,
  //           type,
  //           files,
  //           _id,
  //         })
  //       );
  //     }
  //   } else {
  //     if (chatUserDetails?.type === "COURSE") {
  //       socket.send(
  //         JSON.stringify({
  //           event: "MESSAGE_SEND",
  //           roomId: roomId,
  //           sender,
  //           text,
  //           batchId,
  //           roomType,
  //           receiver: roomId,
  //           isAdhoc: false,
  //           type,
  //           _id,
  //         })
  //       );
  //     } else if (chatUserDetails?.type === "FEEDS") {
  //       socket.send(
  //         JSON.stringify({
  //           event: "MESSAGE_SEND",
  //           roomId: batchId,
  //           sender,
  //           files,
  //           text,
  //           batchId,
  //           roomType: "FEEDS",
  //           sessionId,
  //           receiver: roomId,
  //           isAdhoc: false,
  //           type,
  //           _id,
  //         })
  //       );
  //     } else {
  //       socket.send(
  //         JSON.stringify({
  //           event: "MESSAGE_SEND",
  //           sender,
  //           receiver,
  //           roomId,
  //           roomType,
  //           createdAt,
  //           text,
  //           type,
  //           files,
  //           _id,
  //         })
  //       );
  //     }
  //   }
  // }

  // socket.onmessage = (event) => {
  //   const parsedEvent = JSON.parse(event?.data);

  //   const { event: dataEvent, data: dataData } = parsedEvent || {};
  //   if (dataEvent === "PING") {
  //     socket.send(JSON.stringify({ event: "PONG" }));
  //   } else if (dataEvent === "ROOM_CREATED") {
  //     socket.send(
  //       JSON.stringify({ event: "GET_MESSAGES_BY_ROOM", roomId: dataData?._id })
  //     );
  //   } else if (dataEvent === "GET_MESSAGES_BY_ROOM") {
  //   }
  // };
  // socket.onerror = (err) => {
  //   console.error(err, "sent_message");
  // };

  setFileUrl([]);
}
export const getFileTypes = (url) =>
  fileTypes[url.split(".").pop()] || "DOCUMENT";
