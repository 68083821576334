import React, { useEffect, useState } from "react";
import {
  AccordionSummary,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AautiText from "../../globalComponents/AautiText";
import "./Style.scss";
import { AuthService } from "./Services";
import FilterIcon from "../../../assets/images/FilterIcon.png";
import { showToast } from "../../globalComponents/Toast";
import { isEmpty } from "lodash";
import { PackageStrings } from "../Constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import cancel from './../../../assets/images/cancel.svg';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&& .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: "0px !important",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CourseFilters = ({
  onChangeSearchValue,
  searchValue,
  handleClickApply,
  screenName,
  from,
}) => {
  const [stateFilters, setFilterStates] = useState({});
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [state, setState] = useState([]);
  const [filterData,setFilterData]= useState([])
  const filteredData = () => {
    clearAllFilters({});
  };

  const clearAllFilters = () => {
    setFilterData([])
    setFilterStates({ ...state });
  };
  // screenNames:-
  // "blockbusterDeals"
  // "startingInNext8Hours"
  // "gigs"
  // "upcomingCourses"
  // "packages"
  // "searchResults"
  // "serviceProviderCourses"
  // "sameCoursesByOtherServiceProviders"
  // "courseRelatedToCategory"
  // "gigsOffered"
  // "gigsPurchased"
  // "orders"
  // "myCourses"
  // "myLearnings"
  // "moreResultsInCourses"
  // "topRatedInCourses"
  // "moreResultsInGigs"
  // "topRatedInGigs"
  // "moreResultsInAdhoc"
  // "topRatedInAdhoc"
  // "users"
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    filterAPI();
  }, []);
  const filterAPI = () => {
    AuthService.getFiltersData(onSuccess, onFailure);
  };
  const screenNameFilter = screenName ? screenName : "searchResults";
  const pushOptionsToFilteres = (key, catValue) => {
    let newKeysObjects = {};
    Object.keys(stateFilters).forEach((each) => {
      if (key === each) {
        if (stateFilters[each].includes(catValue)) {
          newKeysObjects[each] = stateFilters[each].filter(
            (item) => item !== catValue
          );
        } else {
          newKeysObjects[each] = [...stateFilters[each], catValue];
        }
      } else {
        newKeysObjects[each] = stateFilters[each];
      }
    });
    setFilterStates(newKeysObjects);
    handleClickApply(newKeysObjects);
    const keyNotExists = !stateFilters[key]?.includes(catValue);
    setFilterData(prevFilterData => {
    const filteredData = prevFilterData.filter(item => !(item.key === key && item.catValue === catValue));
    if (keyNotExists) {
        return [...filteredData, { key, catValue }];
    }
    return filteredData;
   });
  };
  const onSuccess = (response) => {
    setState(response?.result[0][screenNameFilter]);
    let myobj = {};
    Object?.keys(response?.result[0][screenNameFilter])?.map(
      (each, index) => (myobj[each] = [])
    );
    setFilterStates(!isEmpty(myobj) ? myobj : {});
  };
  const onFailure = () => {
    showToast(PackageStrings?.error, PackageStrings?.apiAlert);
  };
  const hasSelectedFilters = (filters) => {
    return Object.values(filters).some((filterArray) => filterArray.length > 0);
  };

  function formatKeys(text) {
    if (text === "recurrance") {
      return <AautiText title={"Recurrence"} size={"semi"} weight={"bold"} />;
    }
    const spacedText = text.replace(/([A-Z])/g, " $1").trim();
    let returnText = spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
    return <AautiText title={returnText} size={"semi"} weight={"bold"} />;
  }

  const onDeleteSelectedOne = (category) => {
    const newKeysObjects = filterData.filter(item => item.catValue !== category);
    let updatedFilters = { ...stateFilters };
    Object.keys(updatedFilters).forEach(key => {
      updatedFilters[key] = updatedFilters[key].filter(item => item !== category);
    });
    setFilterData(newKeysObjects);
    setFilterStates(updatedFilters);
  };

  const DisplayFilterData = () => {
    return (
        <div className='applied-filters-div'>
         {filterData?.length > 0 ? filterData?.map((category, index) => (
              <div key={index} className='applied-filters-button'>
                <AautiText title={category.catValue} />
                <img
                  src={cancel}
                  alt={'cancel'}
                  className='closeIcon'
                  onClick={() => {
                    onDeleteSelectedOne(category.catValue);
                  }}
                />
              </div>
            ))
          : null}
        </div>
    );
  };

  return (
    <div
      className="courseFilter-div1"
      style={{
        // padding: mobileDevices ? "6px" : "15px",
        height: mobileDevices ? "auto" : "83vh",
        overflow: "scroll",
        border: "1px solid #E5E9ED",
        borderBottom: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 4,
          alignItems: "center",
          padding: mobileDevices ? "6px" : "10px",
        }}
      >
        <TextField
          size="small"
          value={searchValue}
          onChange={onChangeSearchValue}
          placeholder={from ?? PackageStrings?.searchCourses}
          id="outlined-start-adornment"
          className="courseFilter-div3"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Tooltip
          title="Filters"
          style={{ display: mobileDevices ? "flex" : "none" }}
        >
          <IconButton
            onClick={handleMenuClick}
            size="small"
            aria-controls={menuOpen ? "account-menuu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
          >
            <img src={FilterIcon} alt="filter" />
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      <div
        className="courseFilter-div2"
        style={{
          justifyContent: hasSelectedFilters(stateFilters)
            ? "space-between"
            : "flex-start",
          padding: mobileDevices ? "4px 6px" : "6px 10px",
        }}
      >
        <AautiText
          textStyle={{ display: mobileDevices && "none" }}
          weight={"bold"}
          title={PackageStrings?.filters}
        />
      </div>
      <Divider />
      <div
        className="courseFilter-div2"
        style={{
          justifyContent: hasSelectedFilters(stateFilters)
            ? "space-between"
            : "flex-start",
          padding: mobileDevices ? "4px 6px" : "6px 10px",
        }}
      >
        <AautiText
          textStyle={{ display: mobileDevices && "none" }}
          weight={"bold"}
          title={PackageStrings?.appliedFilters}
        />
        {hasSelectedFilters(stateFilters) && (
          <AautiText
            size={"small"}
            className={"courseFilter-text1"}
            onClick={() => {
              filteredData();
            }}
            title={PackageStrings?.clearAll}
          />
        )}
      </div>
      {filterData?.length > 0 && <DisplayFilterData />}
      <Divider />
      <Menu
        anchorEl={menuOpen}
        id="account-menuu"
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          className: "ProfileMenu-menu2",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          height: "auto !important",
          p: mobileDevices ? "6px !important" : "10px !important",
        }}
      >
        {Object?.keys(state)?.map((key) => {
          return (
            <MenuItem
              sx={{
                minHeight: "auto !important",
                width: "100% !important",
              }}
            >
              <Accordion sx={{ width: "100% !important" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel1-${key}`}
                  id={`panel1-${key}`}
                >
                  {formatKeys(key)}
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100% !important" }}>
                  <div className="courseFilter-div4">
                    {state[key]?.map((cat, index) => {
                      const isActive = stateFilters[key]?.includes(cat?.value);

                      return (
                        <button
                          className="courseFilter-button1"
                          style={{
                            backgroundColor: isActive ? "#2076E5" : "#fff",
                            color: isActive ? "#fff" : "#000",
                          }}
                          key={index}
                          onClick={() => {
                            pushOptionsToFilteres(key, cat.value);
                          }}
                        >
                          <AautiText title={cat.name} size={"semi"} />
                        </button>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </MenuItem>
          );
        })}
      </Menu>
      {!mobileDevices && (
        <div style={{ padding: mobileDevices ? "6px" : "10px" }}>
          {Object?.keys(state)?.map((key) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel1-${key}`}
                  id={`panel1-${key}`}
                >
                  {formatKeys(key)}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="courseFilter-div4">
                    {state[key]?.map((cat, index) => {
                      const isActive = stateFilters[key]?.includes(cat?.value);

                      return (
                        <button
                          className="courseFilter-button1"
                          style={{
                            backgroundColor: isActive ? "#2076E5" : "#fff",
                            color: isActive ? "#fff" : "#000",
                          }}
                          key={index}
                          onClick={() => {
                            pushOptionsToFilteres(key, cat.value);
                          }}
                        >
                          <AautiText title={cat.name} size={"semi"} />
                        </button>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CourseFilters;
