import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { PriceConversions } from "../../CommonFunctions";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import JoinButton from "../../globalComponents/JoinButton";
import "../Style.scss";
import { noImage } from "../../calendar/constants";
import VirtualIcon from "../../globalComponents/icons/VirtualIcon";
import InPerson from "../../globalComponents/icons/InPerson";
import moment from "moment";

import GroupIcon from "../../../assets/images/group.svg";
import IndividualIcon from "../../../assets/images/individual.svg";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import { GoInfo } from "react-icons/go";
import { useSelector } from "react-redux";

const RequestRefunds = ({
  handleOptionChange,
  selectedOption,
  refundData,
  completeOrderState,
  setRefundReason,
  refundRequestApi,
  refundReason,
  handleChange,
  details,
}) => {
  const { convertPriceWithCurrency } = PriceConversions();
  const refundTotalData = refundData?.refundList?.[0] || refundData?.[0]
  const { userDetails } = useSelector((state) => state.app)
  const returnIcon = () => {
    if (refundTotalData?.classType == "virtual") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <VirtualIcon />
        </div>
      );
    } else if (refundTotalData?.classType == "inPerson") {
      return <InPerson />;
    }
  };

  const returnCourseType = (type) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 6,
          marginTop: 10,
        }}
      >
        {type == "individual" ? (
          <img
            src={IndividualIcon}
            alt="individual"
            style={{ height: "18px", width: "18px", zIndex: 100 }}
          />
        ) : (
          <img
            src={GroupIcon}
            alt="group"
            style={{ height: "18px", width: "18px", zIndex: 100 }}
          />
        )}

        <AautiText
          weight={"bold"}
          textStyle={{ color: "#000" }}
          title={`${refundTotalData?.currencySymbol +
            (refundTotalData?.localPurchasedPrice?.toFixed(2) || "N/A")}`}
        />
      </div>
    );
  };
  return (
    <div>
      <div className="refund-flex">
        <img
          src={refundTotalData?.courseImage || refundTotalData?.image || noImage}
          alt="refund-img"
          className="refund-img"
        />
        <div style={{ width: "auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <div style={{ width: "90%" }}>
              <AautiText
                title={refundTotalData?.courseName?.trim() || refundTotalData?.name?.trim() || "Name unavailable"}
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  width: "100%",
                  height: 39,
                  lineHeight: "19px",
                  zIndex: 200,
                  color: "#000",
                }}
              />
            </div>
            {returnIcon(refundData)}
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              textStyle={{ color: "#000" }}
              title={`Order Date: `}
            />
            <AautiText
              size={"semi"}
              title={`${moment(refundTotalData?.orderDate).format("DD MMM YYYY") || "N/A"}`}
              weight={"bold"}
              textStyle={{ color: "#000" }}
            />
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              title={`Order Id:`}
              textStyle={{ color: "#000" }}
            />
            <AautiText
              title={`${refundTotalData?.receiptId || "N/A"}`}
              size={"semi"}
              weight={"bold"}
              textStyle={{ color: "#000" }}
            />
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              title={`Payment Method:`}
              textStyle={{ color: "#000" }}
            />
            <AautiText
              size={"semi"}
              weight={"bold"}
              textStyle={{ color: "#000" }}
              title={`${refundTotalData?.paymentMethod || "N/A"}`}
            />
          </div>

          {returnCourseType(refundTotalData?.courseType)}
        </div>
      </div>
      <div className="refund-main-cont">
        <div className="refund-main-sub">
          <label
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              border: "1px solid #F2F2F6",
              borderRadius: 8,
              alignItems: "flex-start",
              padding: 10,
              marginBottom: 10,
            }}
          >
            <input
              type="radio"
              value="credits"
              style={{
                height: 20,
                width: 20,
              }}
              checked={selectedOption === "credits"}
              onChange={handleOptionChange}
            />
            <div
              className="radio-item-cont"
              style={{ marginLeft: 8, width: "95%" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <AautiText
                  title={`${userDetails.transactionCountrySymbol +
                    (refundData?.aautiRefundAmount?.toFixed(2) || refundTotalData?.aautiRefundAmount?.toFixed(2) || "N/A")}
                   in Aauti Credits`}
                  size={"medium"}
                  weight={"medium"}
                  textStyle={{ color: "#000000" }}
                />
                <div className="fast-con">
                  <AautiText
                    title={"Fastest"}
                    size={"semi"}
                    weight={"medium"}
                    textStyle={{ color: "#fff" }}
                  />
                </div>
              </div>
              <AautiText
                title={"Aauti Credits will show up in your account immediately"}
                size={"semi"}
                weight={"normal"}
                textStyle={{ color: "#5C5B57" }}
              />
            </div>
          </label>
          <label
            style={{
              display: refundTotalData?.isBatchStarted ? "none" : "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "flex-start",
              border: "1px solid #F2F2F6",
              borderRadius: 8,
              padding: 10,
            }}
          >
            <input
              type="radio"
              value="bankPayment"
              style={{
                height: 20,
                width: 20,
              }}
              checked={selectedOption === "bankPayment"}
              onChange={handleOptionChange}
            />
            <div
              className="radio-item-cont"
              style={{ marginLeft: 8, width: "95%" }}
            >
              <AautiText
                title={`${(refundTotalData?.currencySymbol +
                  (refundData?.refundAmount?.toFixed(2) || refundTotalData?.refundAmount?.toFixed(2)) || "N/A")}
                   in ${refundTotalData?.paymentMethod || "N/A"}`}
                size={"medium"}
                weight={"medium"}
                textStyle={{ color: "#000000" }}
              />
              <AautiText
                title={
                  "Most refunds will be posted to your account within 5 to 10 business days though it may take longer, depending on your payment method or location"
                }
                size={"semi"}
                weight={"normal"}
                textStyle={{ color: "#5C5B57" }}
              />
            </div>
          </label>
          <div className="flex-info">
            <GoInfo size={30} />
            <AautiText
              title={
                "Aauti will only be able to refund the original payment source that was used to process the payment. Aauti will process the refund in the same currency used in the payment."
              }
              size={"semi"}
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <AautiText
              title={"Refund Reason"}
              size={"normal"}
              weight={"bold"}
              textStyle={{ color: "#000000" }}
            />
            <AautiText
              title={"Help us improve: Why are you getting a refund?"}
              size={"semi"}
              weight={"normal"}
              textStyle={{
                color: "#5C5B57",
                marginTop: "5px",
                marginBottom: 8,
              }}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Reason <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={refundReason}
                label="Reason *"
                onChange={handleChange}
              >
                <MenuItem value={"The Course was too basic"}>
                  The Course was too basic
                </MenuItem>
                <MenuItem value={"The Course was too advance"}>
                  The Course was too advance
                </MenuItem>
                <MenuItem value={"The Course is outdated"}>
                  The Course is outdated
                </MenuItem>
              </Select>
            </FormControl>
            <AautiTextInput
              onChange={(e) => {
                handleChange(e.target.value, "details");
              }}
              value={details}
              multiline={true}
              important={true}
              maxLength={500}
              rows={3}
              text={"Details"}
              style={{ width: "100%" }}
              fullWidth
              helperText={`${details?.length}/500 (minimum 50 characters)`}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <JoinButton
              title={"Submit"}
              disabled={
                refundReason === "" ||
                selectedOption === "" ||
                details == "" ||
                details?.length < 50
              }
              onClick={() => {
                refundRequestApi();
                setRefundReason("credits");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestRefunds;
