import React from "react";
import AautiText from "./AautiText";
import AautiHead from "./AautiHead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AautiFileHeader = ({ activeTitle, show = 0 }) => {
  const navigate = useNavigate();
  const { UserRole } = useSelector((state) => state.app);
  const buttonStyle = {
    border: "none",
    background: "none",
    cursor: "pointer",
    padding: "none",
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <button
          style={buttonStyle}
          onClick={() => {
            navigate("/");
          }}
        >
          <AautiText title={"My Aauti > "} />
        </button>
        <button
          style={{ ...buttonStyle, display: show == 1 && "none" }}
          onClick={() => {
            if (UserRole == "endUser") {
              navigate("/my-learnings");
            } else {
              navigate("/my-courses");
            }
          }}
        >
          <AautiText title={"My Courses >"} />
        </button>
        {/* <button style={{ ...buttonStyle, margin: 0 }}> */}
        <AautiText
          title={activeTitle}
          weight={"bold"}
          textStyle={{ marginLeft: 5 }}
        />
        {/* </button> */}
      </div>
      {show != 1 && <AautiHead />}
    </div>
  );
};

export default AautiFileHeader;
