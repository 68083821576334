const baseUrl = "https://aautimpapi.azurewebsites.net/";


export const InstituteApiPaths = {
  BASE_URL: baseUrl,
  /** Auth Services */
  INSTITUTE_SIGN_UP: 'institution/saveInstitution',
  INSTITUTES_GET_All_BRANCHES_BY_ID: "branch/getAllBranchesByInstituteId",
  INSTITUTE_GET_PARTICIPANTS_Details_LIST: 'user/getEndUsersListByInstituteId',
  INSTITUTE_GET_INSTRUCTOR_Details_LIST: 'user/getServiceProvidersListByInstituteId',
  CREATE_BRANCH: 'branch/saveBranches',
  DELETE_BRANCH: 'branch/deleteBranch',
  UPDATE_BRANCH: 'branch/updateBranch',

  /**Jayasree */
  GET_ALL_INSTITUTE_TYPES: 'instituteTypes/getAllInstituteTypes',
  GET_ALL_ACADEMIC_SYSTEMS: 'academicSystem/getAllAcademicSystems',
  GET_All_GRADES: 'grades/getAllGrades',
  GET_INSTITUTE_COMPLETED_COURSES: 'institution/getCompletedInstituteCourseDetails',
  GET_INSTITUTE_UPCOMING_COURSES: 'institution/getUpcomingInstituteCourseDetails',
  GET_INSTITUTE_ONGOING_COURSES: 'institution/getOngoingInstituteCourseDetails',
  GET_INSTITUTE_RECENT_GIGS: 'gig/getAllRecentGigs',
  BLOCK_INSTRUCTOR: 'institution/blockInstituteUser?',
  UNBLOCK_INSTRUCTOR: 'institution/unBlockInstituteUser?',
  REMOVE_INSTRUCTOR: 'institution/deactivateInstituteUser?',
GET_TEMPLATE:'file/getInstituteUsersTemplate',
GET_INSTRUCTORS_AND_PARTICIPANTS: 'institution/getAllInstructorsByInstituteIdAndBranchId?',
GET_COURSE_BRANCHES: 'institution/getAllBranchDetailsByCourseId?',
SAVE_ASSIGNED_COURSE: 'myCourse/saveInstructorAndParticipantForbatch',


  /**Keerthi */
  INSTITUTE_DASHBOARD_DETAILS: "/institution/getInstituteDashboardDetails",
  UNASSIGNED_COURSES: 'institution/getInstituteUnAssignedCourses?',
  ASSIGNED_COURSES: 'institution/getInstituteAssignedCourses?',

  //Add Institute Information
  GET_Institute_Information_By_InstituteId: 'instituteInformation/getAllInstituteInformationByInstituteId?',

  CREATE_INSTITUTE_INFORMATION: 'instituteInformation/saveInstituteInformation',

  GET_INSTITUTE_INFORMATION_BYID: 'instituteInformation/getInstituteInformationById?',

  UPDATE_INSTITUTE_INFORMATION: 'instituteInformation/updateInstituteInformation',

  DELETE_INSTITUTE_INFORMATION: 'instituteInformation/deleteInstituteInformation?',
}

