import React, { useState, useEffect } from "react";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import {
  dailyMTObj,
  getNextTimeSlot,
  weekMTObj,
} from "../createCourse/Constants";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import { RxCross2 } from "react-icons/rx";
import { showToast } from "../../globalComponents/Toast";
import WeeklySlotSelection from "../courseCreation/WeeklySlotSelection";
import CustomSlotSelection from "../courseCreation/CustomSlotSelection";
import NavButton from "../courseCreation/NavButton";
import HelperText from "../courseCreation/HelperText";
import RescheduleWeek from "./RescheduleWeek";
import { saveRescheduleBatches } from "./Services";
import { useParams } from "react-router-dom";
import { formatDatesAndTimes } from "../../CommonFunctions";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { RescheduleConstants } from "./RescheduleConstants";
import { courseConstant } from "../courseCreation/courseConstant";

const UpdateBatch = ({
  dailyObj,
  setWeeklyObj,
  setDailyObj,
  activeBatchType,
  batchObj,
  handleSaveRescheduleApi,
}) => {
  const [batchObject, setBatchObject] = useState(batchObj);
  const { courseId } = useParams();
  let today = new Date();
  const moment = require("moment-timezone");
  const [editItem, setEditItem] = useState(null);
  const [activeBatch, setActiveBatch] = useState(
    batchObj?.recurringDays ? 20 : batchObj?.customDates ? 30 : 10
  );
  const [editBatch, setEditBatch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState("");
  const activeObj = batchObject;

  const onChangetimeSlotObj = (val, slot) => {
    let dateFormat = moment(val?.$d)?.format("YYYY-MM-DDT00:00:00");
    let timeFromat = moment(val?.$d)?.format("YYYY-MM-DDTHH:mm:00");
    const newOnHour = moment(timeFromat)
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:00");
    const isBefore = moment(timeFromat)?.isBefore(dailyObj?.endTime);
    if (slot === "startDate") {
      setBatchObject((prev) => ({
        ...prev,
        [slot]: dateFormat,
      }));
    } else if (slot === "endDate") {
      setBatchObject((prev) => ({
        ...prev,
        [slot]: dateFormat,
      }));
    } else if (slot === "startTime") {
      // !isBefore
      //   ? setDailyObj((prev) => ({
      //       ...prev,
      //       startTime: val,
      //       endTime: newOnHour,
      //     }))
      //   :
      setBatchObject((prev) => ({
        ...prev,
        [slot]: timeFromat,
        endTime: newOnHour,
        reScheduleStartTime: activeObj.startTime,
        reScheduleEndTime: activeObj?.endTime,
      }));
    } else if (slot === "endTime") {
      setBatchObject((prev) => ({
        ...prev,
        [slot]: timeFromat,
        reScheduleEndTime: activeObj?.endTime,
      }));
    } else {
      setWeeklyObj((prev) => ({
        ...prev,
        [slot]: val,
      }));
    }
  };

  const changeEditItem = (val, feild) => {
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    const timeFromat = moment(val?.$d).format("YYYY-MM-DDTHH:mm:00");
    const val2 = moment(val?.$d)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss");

    let newItem = null;
    if (feild == "startDate") {
      newItem = {
        ...editItem.each,
        startDate: dateFormat,
        endDate: dateFormat,
      };
    } else if (feild == "startTime") {
      newItem = {
        ...editItem.each,
        startTime: timeFromat,
        endTime: val2,
      };
    } else if (feild == "endTime") {
      newItem = {
        ...editItem.each,
        endTime: timeFromat,
      };
    } else if (feild == "endDate") {
      newItem = {
        ...editItem.each,
        endDate: timeFromat,
      };
    } else {
      newItem = { ...editItem.each, [feild]: val };
    }
    setEditItem((prev) => ({
      ...prev,
      each: newItem,
    }));
  };

  const nextTimeSlot = getNextTimeSlot();

  const returnMinTime = (startTime) => {
    const minTime = dayjs()
      .set("hour", moment(startTime).hour())
      .startOf("minute");
    // .add(30, "minute");
    return minTime;
  };

  const onChangeDate = (val, slot) => {
    const val2 = slot === "startDate" ? "discountStartDate" : "discountEndDate";
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    setDailyObj((prev) => ({
      ...prev,
      [slot]: dateFormat,
      [val2]: dateFormat,
    }));
  };

  const returnBatchType = () => {
    const endTime = moment(dailyObj?.startTime)
      ?.add(1, "hour")
      ?.format("YYYY-MM-DDTHH:mm:ss");
    switch (activeBatch) {
      case 10:
        return (
          <div className="start-end-time">
            <div className="eachDateStart">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["Time Picker"]}>
                  <TimePicker
                    label={AautiStrings?.StartTimeString}
                    timeSteps={{ minutes: 30 }}
                    onError={false}
                    sx={{ borderColor: "#000000", width: "100%" }}
                    minTime={
                      moment(activeObj?.startDate).format("YYYY-MM-DD") ===
                      moment(new Date()).format("YYYY-MM-DD")
                        ? dayjs(nextTimeSlot)
                        : null
                    }
                    // disablePast={
                    //   moment(dailyObj?.startDate).format("YYYY-MM-DD") ===
                    //   moment(new Date()).format("YYYY-MM-DD")
                    // }
                    ampm={false}
                    value={
                      // editItem
                      //   ? editItem?.each?.startTime
                      //     ? dayjs(editItem?.each?.startTime)
                      //     : null
                      //   : dayjs(activeObj?.startTime)
                      //   ? dayjs(activeObj?.startTime)
                      //   : dayjs(nextTimeSlot)
                      dayjs(activeObj?.startTime)
                    }
                    onChange={(newVal) => {
                      // const val2 = moment(newVal)
                      //   ?.add(1, "hour")
                      //   ?.format("YYYY-MM-DDTHH:mm:ss");
                      // if (editItem) {
                      //   changeEditItem(newVal, "startTime");
                      // } else {
                      //   onChangetimeSlotObj(newVal, "startTime");
                      //   // onChangetimeSlotObj(val2, "endTime");
                      // }
                      onChangetimeSlotObj(newVal, "startTime");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="eachDateStart">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={{ width: "100%" }}
                >
                  <TimePicker
                    sx={{ width: "100%" }}
                    label={AautiStrings?.EndTimeString}
                    timeSteps={{ minutes: 30 }}
                    // minTime={
                    //   editItem
                    //     ? returnMinTime(editItem?.each?.startTime)
                    //     : dayjs(endTime)
                    // }
                    ampm={false}
                    value={
                      // editItem
                      //   ? editItem?.each?.endTime
                      //     ? dayjs(editItem?.each?.endTime)
                      //     : null
                      //   : activeObj?.endTime
                      //   ? dayjs(activeObj?.endTime)
                      //   : null
                      dayjs(activeObj?.endTime)
                    }
                    onChange={(newVal) => {
                      editItem
                        ? changeEditItem(newVal, "endTime")
                        : onChangetimeSlotObj(newVal, "endTime");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        );
      case 20:
        const recurringDays = activeObj?.recurringDays;
        const WeekArray = Object.keys(recurringDays).filter(
          (day) => recurringDays[day].length > 0
        );

        return (
          <div style={{ width: "80%" }}>
            {isEmpty(WeekArray) ? (
              <button
                className="day-button"
                onClick={() => {
                  setDialogOpen("weekly");
                }}
              >
                Select Days
              </button>
            ) : (
              <>
                <AautiText
                  title={RescheduleConstants?.selectedDays}
                  size={"small"}
                />
                <div
                  className="tags-container"
                  style={{
                    gap: 6,
                    overflow: "scroll",
                    flexWrap: "nowrap",
                    marginTop: 1,
                  }}
                  onClick={() => {
                    setDialogOpen("weekly");
                  }}
                >
                  {WeekArray.map((each, index) => (
                    <button key={index} className="day-button">
                      <AautiText size={"small"} key={index} title={each} />
                      <RxCross2 />
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        );
      case 30:
        const selectedRange = `${moment(activeObj?.startDate).format(
          "DD MMM YYYY"
        )}-${moment(activeObj?.endDate).format("DD MMM YYYY")}`;
        return (
          <div style={{ width: "100%", marginTop: -8 }}>
            {activeObj?.customDates ? (
              <>
                <AautiText
                  title={RescheduleConstants?.selectedDates}
                  size={"small"}
                />
                <div
                  onClick={() => {
                    setDialogOpen("custom");
                  }}
                  className="tags-container"
                  style={{ width: "100%", padding: "8px 10px", marginTop: 1 }}
                >
                  <AautiText title={selectedRange} />
                </div>
              </>
            ) : (
              <button>
                <AautiText
                  title={RescheduleConstants?.selectDates}
                  onClick={() => {
                    setDialogOpen("custom");
                  }}
                />
              </button>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const handleRescheduleBatch = () => {
    let reObj = {
      ...batchObject,
      isRescheduled: true,
      endDate: moment(batchObject?.endDate).format("YYYY-MM-DDT00:00:00"),
      startDate: moment(batchObject?.startDate).format("YYYY-MM-DDT00:00:00"),
      reScheduleEndDate: batchObj?.endDate,
      reScheduleStartDate: batchObj?.startDate,
      reScheduleStartTime: batchObj?.startTime,
      reScheduleEndTime: batchObj?.endTime,
      startTime: batchObject?.startTime,
      endTime: batchObject?.endTime,
      reScheduleBatchName: batchObj?.batchName,
    };
    if (batchObj?.recurringDays) {
      reObj.reScheduleRecurringDays = batchObj.recurringDays;
    }
    const slot = batchObj?.type;
    let saveObj = { courseId, [slot]: [reObj] };
    saveObj[slot] = formatDatesAndTimes(saveObj[slot]);

    handleSaveRescheduleApi(saveObj);
  };

  const onChangeFields = (val, field) => {
    setBatchObject((prevData) => ({
      ...prevData,
      [field]: val,
    }));
  };

  const addSlotValidations = () => {
    const presentTime = new Date();
    const ipDate = new Date(activeObj?.startDate);
    const newErrors = {};
    if (activeObj?.batchName?.length < 3)
      newErrors.username = "title is required";
    if (!(activeObj?.individualPrice || activeObj?.groupPrice))
      newErrors.price = "Price is required";
    // if (presentTime > ipDate) {
    //   newErrors.startTime = "start time cannot be present time";
    // }
    if (Object.keys(newErrors)?.length == 0) {
      // else if (activeObj?.level?.length == 0)
      //   newErrors.level = "please select level";
      // else if (activeObj?.courseType?.length == 0)
      //   newErrors.courseType = "Please select course type";
      // else if (!activeObj?.inPerson && !activeObj?.virtual)
      //   newErrors.password = "Please select any Inperson Or Virtual";
      // else if (activeObj?.categories?.length == 0)
      //   newErrors.courseType = "Please select categories";
      // else if (activeObj?.countries?.length == 0)
      //   newErrors.password = "Please select countries";
      // else if (
      //   activeObj?.noOfStudents < 2 &&
      //   activeObj?.courseType?.includes("Group")
      // )
      //   newErrors.password = "No of students should be more than 2.";
      // else if (!activeObj?.adhocImage) newErrors.image = "Please upload image";

      return false;
    } else {
      return true;
    }
  };

  const updateSlots = (object) => {
    const reObj = {
      ...batchObject,
      recurringDays: object,
    };
    setBatchObject(reObj);
    setDialogOpen("");
  };

  return (
    <div className="tab">
      <div
        className="flex-container"
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <div style={{ width: "100%" }}>
          <div>
            <AautiText
              title={AautiStrings?.step1BatchString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <button
              className={`level-item course-types center-content active-class`}
            >
              <AautiText title={activeBatchType} size="normal" textStyle={{}} />
            </button>
          </div>
          <div className="level-main">
            <AautiText
              title={AautiStrings?.step2DetailsString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  gap: 10,
                  marginBottom: 15,
                }}
              >
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings?.StartDateString}>
                        <DatePicker
                          // disabled={
                          //   activeObj?._id &&
                          //   !isEmpty(CreateCourseObj?.endUserList)
                          // }
                          value={
                            // editItem
                            //   ? editItem?.each?.startDate
                            //     ? dayjs(editItem?.each?.startDate)
                            //     : null
                            //   : activeObj?.startDate
                            //   ? dayjs(activeObj?.startDate)
                            //   : null
                            dayjs(activeObj?.startDate)
                          }
                          onChange={(e) => {
                            onChangetimeSlotObj(e, "startDate");
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs(activeObj?.startDate)}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings?.EndDateString}>
                        <DatePicker
                          // disabled={
                          //   activeObj?._id &&
                          //   !isEmpty(CreateCourseObj?.endUserList)
                          // }
                          sx={{ minWidth: "100% !important" }}
                          value={
                            editItem
                              ? editItem?.each?.endDate
                                ? dayjs(editItem?.each?.endDate)
                                : null
                              : activeObj?.endDate
                              ? dayjs(activeObj?.endDate)
                              : null
                          }
                          onChange={(newDate) => {
                            editItem
                              ? changeEditItem(newDate, "endDate")
                              : onChangetimeSlotObj(newDate, "endDate");
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs(activeObj?.startDate)}
                          maxDate={dayjs(activeObj?.startDate).add(6, "month")}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <AautiTextInput
                  text={AautiStrings?.BatchTitleString}
                  onChange={(e) => {
                    onChangeFields(e.target.value, "batchName");
                  }}
                  important
                  maxLength={50}
                  value={activeObj?.batchName}
                  style={{ width: "100%" }}
                />
                <HelperText
                  title={`${
                    activeObj?.batchName?.length || 0
                  }/50 (Minimum of 5 Characters)`}
                />
              </div>
            </div>
          </div>

          <div className="level-main">
            <AautiText
              title={courseConstant?.selectBatchType}
              //   required={true}
              //   weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div className="flex-container">
              <FormControl sx={{ minWidth: "20%" }} size="medium">
                <InputLabel id="demo-simple-select-label">
                  Batch Type
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  autoWidth
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  value={activeBatch}
                  label={AautiStrings?.BatchTypeString}
                  onChange={(event) => {
                    setActiveBatch(event.target.value);
                  }}
                >
                  <MenuItem
                    value={10}
                    disabled={
                      activeBatch?.title !== "groupDaily" &&
                      activeBatch?.title !== "individualDaily"
                    }
                  >
                    Daily
                  </MenuItem>
                  <MenuItem
                    value={20}
                    disabled={
                      activeBatch?.title !== "groupWeekly" ||
                      activeBatch?.title !== "individualWeekly" ||
                      !activeObj?.recurringDays
                    }
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem value={30} disabled={!activeObj?.customDates}>
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
              {returnBatchType()}
            </div>
          </div>

          <div className="flex-container buttons-cont">
            <NavButton
              disabled={addSlotValidations()}
              text={AautiStrings?.updateBatchString}
              handleClick={() => {
                // const inTime = moment(activeObj?.startTime);
                // const now = moment();
                // const hasPassed =
                //   inTime.isBefore(now) &&
                //   inTime.isSame(activeObj?.startDate, "day");
                // if (hasPassed && activeBatch !== 30) {
                //   showToast("info", "Please check start date & time");
                // } else {
                //   handleRescheduleBatch();
                // }
                // const currentDate = moment();
                // const start = moment(activeObj?.startDate);
                // const end = moment(activeObj?.endDate);
                // if (currentDate.isBefore(start) || currentDate.isAfter(end)) {
                //   showToast("info", "Please check Start & End Dates");
                // } else {
                handleRescheduleBatch();
                // }
              }}
            />
          </div>
        </div>
      </div>

      <AautiDialog
        titlePosition="left"
        dialogTitle={AautiStrings?.selectDayAndTimeString}
        open={!isEmpty(dialogOpen)}
        onClose={() => {
          setDialogOpen("");
        }}
      >
        {dialogOpen == "weekly" && (
          <RescheduleWeek {...{ batchObject, updateSlots }} />
        )}
        {dialogOpen == "custom" && (
          <CustomSlotSelection
            closePop={() => {
              setDialogOpen("");
            }}
            {...{
              dailyObj,
              setDailyObj,
              nextTimeSlot,
              onChangetimeSlotObj,
            }}
          />
        )}
      </AautiDialog>
    </div>
  );
};

export default UpdateBatch;
