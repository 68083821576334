import React from "react"
import { Box, Grid, Skeleton } from "@mui/material"

const MembersLoader = () => {
  const numberOfSkeletons = 4;
  const skeletonArray = Array.from({ length: numberOfSkeletons });
  return (
    <Box sx={{ width: "92%", margin: "25px 0px" }}>
      <Skeleton animation="wave" variant="text" height={50} width={300} />
      <Skeleton animation="wave" variant="text" height={20} width={200} />
      <Skeleton animation="wave" variant="text" height={30} width={400} />
      <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", marginTop: 20, gap:5}} >
        {skeletonArray.map((_, index) => (
          <div style={{ display: 'flex', flexDirection: "row", width: 333, justifyContent: "space-between", }} >
            <Skeleton animation="wave" variant="circular" height={80} width={80} />
            <div style={{ display: 'flex', flexDirection: "column", gpa: 2, width: "70%" }} >
              <Skeleton animation="wave" variant="text" height={30} width={"90%"} />
              <Skeleton animation="wave" variant="text" height={30} width={"80%"} />
              <Skeleton animation="wave" variant="text" height={30} width={"60%"} />
            </div>
          </div>
        ))}
      </div>
    </Box>
  )
}
export default MembersLoader
