import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import constants from "../constants.json";
import { useDispatch, useSelector } from "react-redux";
import { getTopServiceProviders } from "../reducer";
import AppContainer from "../../AppContainer";
import GlobalListComponent from "../../globalComponents/GlobalListComponent";
import LoadingCards from "../../globalComponents/LoadingCards";
import { isEmpty } from "lodash";
import NoData from "../../globalComponents/NoDataFound";
import SpHomeCard from "./SpHomeCard";
import AautiText from "../../globalComponents/AautiText";

const MoreServiceProviders = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const handleClickApply = (filterKeys) => {
    //  fetchData("", filterKeys);
    alert("hello user");
  };
  const { topServiceProviders, status } = useSelector(
    (state) => state.homePageReducer
  );

  useEffect(() => {
    handleGetServiceProvidersByCat();
  }, [dispatch, state?.activeSpCategory]);

  const handleGetServiceProvidersByCat = () => {
    dispatch(
      getTopServiceProviders(
        constants?.categoriesData?.[state?.activeSpCategory]
      )
    );
  };

  return (
    <AppContainer>
      <AautiText
        size={"large"}
        textStyle={{ color: "#1E1E1E", marginBottom: 15 }}
        title={"More Service Providers"}
      />
      {status == "loading" ? (
        <LoadingCards />
      ) : isEmpty(topServiceProviders) ? (
        <NoData />
      ) : (
        <SpHomeCard {...{ topServiceProviders }} />
      )}
    </AppContainer>
  );
};

export default MoreServiceProviders;
