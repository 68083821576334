import React, { useEffect } from "react";
import moment from "moment";
import AautiText from "../../globalComponents/AautiText";
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { AuthService } from "./Service";
import { useDispatch, useSelector } from "react-redux";
import LogoIcon from "../../../assets/images/aautiIcon.png";
import { showToast } from "../../globalComponents/Toast";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import {
  setBuyNowdata,
  setScreenName,
} from "../../../redux/reducer/appReducer";
import { IoTrashOutline } from "react-icons/io5";
import { setCartCount } from "../../../redux/reducer/appReducer";
import { PackageStrings } from "../Constants";
import { setBuyNowDataAfterRefresh } from "../../CommonFunctions";
import { escape, isEmpty } from "lodash";
import { checkNull } from "../../../utils/Helpers";
import { userRoles } from "../../../storage/constants";

const Notifications = ({ handleCloseNot, setAnchorElNot, unreadCount }) => {
  const [value, setValue] = React.useState(0);
  const [unReadMessages, setUnReadMessages] = useState([]);
  const [AllNot, setAlNot] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingNotifi, setLoadingNotifi] = useState(false);
  const [showLoadBtn, setShowLoadBtn] = useState(true);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const { loggedinUserId, isUserLoggedIn, UserRole } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const [accepteState, setAccepteState] = useState(false);
  const [clearNotification, setClearNotification] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [inCart, setInCart] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setClearNotification("");
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className="notification-div1"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getNotificationData = () => {
    setLoadingNotifi(true);
    AuthService.getAllNotificationsByUserId(
      loggedinUserId,
      currentPage,
      itemsPerPage,
      onSuccessNot,
      onFailure
    );
  };

  useEffect(() => {
    getNotificationData();
  }, [loggedinUserId, currentPage]);

  const onSuccessNot = (response) => {
    if (response?.status === "Success") {
      setUnReadMessages(response?.result?.unreadCount || 0);
      setLoadingNotifi(false);
      if (response?.result?.notifications?.length > 0) {
        setAlNot([...AllNot, ...response?.result?.notifications]);
        dispatch(setCartCount(99));
      } else {
        setShowLoadBtn(false);
      }
    } else {
      setLoadingNotifi(false);
    }
  };

  const onClickCrosscon = (id) => {
    AuthService.deleteNotificationById(id, onSuccessDeleted, onFailure);
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(PackageStrings?.error, error.message);
  };
  const onSuccessDeleted = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);
      setOpen(false);
      dispatch(setCartCount(99));
      let filtered = AllNot?.filter((each, index) => {
        return selected?._id !== each?._id;
      });
      setAlNot(filtered);
    }
  };

  const onSuccessDeletAllNot = (response) => {
    if (response.status === PackageStrings?.success) {
      setOpen(false);
      showToast(PackageStrings?.successSmall, response.message);
      dispatch(setCartCount(99));
      AuthService.getAllNotificationsByUserId(
        loggedinUserId,
        0,
        10,
        (res) => {
          if (res?.status == "Success") {
            setAlNot(res?.result?.notifications);
          }
        },
        onFailure
      );
    }
    setLoadingNotifi(false);
  };

  const filteredFunction = (item) => {
    const AdhocPurchaseNavigation = () => {
      const onSuccessAdhocDetails = (response) => {
        if (response.status === "Success" && !isEmpty(response.result)) {
          const obj = {
            ...response.result[0].adhocSession,
            screenName: PackageStrings?.notifications,
            usersList: response?.result?.[0]?.adhocSession?.subscribers,
          };
          navigate("/join_class", {
            state: obj,
          });
        }
      };
      AuthService.getAdhocClassDetails(
        item?.sessionId,
        onSuccessAdhocDetails,
        onFailureFunc
      );
    };
    const CoursePurchaseNavigation = () => {
      const params = {
        courseId: item?.courseId,
        batchId: item?.batchId,
      };
      if (UserRole === userRoles.endUser) {
        params.userId = loggedinUserId;
      }
      console.log(params);
      AuthService.getRecentSessionData(
        params,
        (res) => {
          if (checkNull(res?.result?._id || res?.result?.id)) {
            console.log("No Sessions Available");
          } else {
            handleOnPressCourseCard(res, item);
          }
        },
        onFailure
      );
      const handleOnPressCourseCard = (res, data) => {
        console.log(res?.result, "res");
        navigate("/join_class", {
          state: res?.result,
        });
      };
    };
    switch (item?.notificationType) {
      case "Adhoc purchase":
        return AdhocPurchaseNavigation();
      case "course purchase":
        return CoursePurchaseNavigation();
      default:
        break;
    }
  };
  const handleClickNot = (each, inviteStatus) => {
    if (each?.notificationType === PackageStrings?.course2) {
      navigate(`course-details/${each?.courseId}`);
    } else if (
      each?.notificationType === PackageStrings?.adhocStartIn5Minutes
    ) {
      navigate(`calendar`);
    } else if (
      each?.notificationType === PackageStrings?.courseStartin15Minutes ||
      each?.notificationType === PackageStrings?.batchTransfer ||
      each?.notificationType == "Adhoc purchase" ||
      each?.notificationType == PackageStrings.coursepurchase
    ) {
      filteredFunction(each);
    } else if (each?.notificationType === PackageStrings?.inviteMember) {
      inviteCall(each, inviteStatus);
    } else if (each?.notificationType === PackageStrings?.gig) {
      navigate(`gig-preview/${each?.gigId}`);
    }
    setAnchorElNot(undefined);
  };
  const memberAccept = (data, actionDetails) => {
    const reqData = {
      _id: data?.id,
      userStatus: actionDetails,
    };
    delete data.inviteCourse;
    delete data.id;
    switch (data?.type) {
      case "member Transfer":
        return AuthService.childTransferUpdateStatus(reqData, (response) => {
          if (response?.status?.toLowerCase() === "success") {
            const newList = AllNot?.map((each) => {
              if (each?._id === reqData.id) {
                return { ...each, status: "1", userStatus: actionDetails };
              }
              return each;
            });
            setAlNot(newList);
          }
        });
      case "invite Member":
        AuthService.updateUserInviteStatus(
          reqData,
          (response) => {
            if (response?.status?.toLowerCase() === "success") {
              const newList = AllNot?.map((each) => {
                if (each?._id === reqData.id) {
                  return { ...each, status: "1", userStatus: actionDetails };
                }
                return each;
              });
              setAlNot(newList);
            }
          },
          onFailure
        );
      case "invite moderator":
        console.log("moderator");
        AuthService.moderatorUpdateStatus(
          reqData,
          (response) => {
            if (response?.status?.toLowerCase() === "success") {
              const newList = AllNot?.map((each) => {
                if (each?._id === reqData.id) {
                  return { ...each, status: "1", userStatus: actionDetails };
                }
                return each;
              });
              setAlNot(newList);
            }
          },
          onFailure
        );
      default:
        break;
    }
  };
  const handleMarkAsAllRead = () => {
    setLoadingNotifi(true);
    AuthService.clearAllNotificationsByUser(
      loggedinUserId,
      onSuccessClearAll,
      onFailure
    );
  };
  const onSuccessClearAll = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);

      getNotificationData();
    } else {
      showToast(PackageStrings?.error, response.message);
    }
    setLoadingNotifi(false);
  };
  const inviteCall = (each, inviteStatus) => {
    const data = {
      id: each?._id,
      userStatus: inviteStatus,
    };
    AuthService.getInviteStatus(data, onSuccessInvaite, onFailure);
  };
  // const inviteCourseCall = (each, inviteStatus) => {
  //   const data = {
  //     id: each?._id,
  //     userStatus: inviteStatus,
  //   };
  //   const onSuccessUpdate = () => {
  //     AuthService.getInviteStatus(data, onSuccessInvaite, onFailure);
  //   };
  //   const userData = {
  //     courseId: each?.courseId,
  //     userId: each?.userId,
  //     batchId: each?.batchId,
  //     type: each?.notificationType,
  //     serviceProviderId: each?.serviceProviderId,
  //   };
  //   AuthService.updateRejectData(userData, onSuccessUpdate, onFailure);
  // };
  const onSuccessInvaite = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);
      getNotificationData();
    } else {
      showToast(PackageStrings?.error, response.message);
    }
    setLoadingNotifi(false);
  };
  const batchrescheduleCall = (each) => {
    const data = {
      courseId: each?.courseId,
      batchId: each?.batchId,
      endUserId: loggedinUserId,
      notificationId: each?._id,
      status: "accepted",
    };
    AuthService.batchReschedule(data, onSuccessReschedule, onFailure);
  };
  const onSuccessReschedule = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);
      getNotificationData();
    } else {
      showToast(PackageStrings?.error, response.message);
    }
    setLoadingNotifi(false);
  };
  const courseBuyApi = (data) => {
    const onSuccessApi = (response) => {
      let buyNowData = [];
      if (response?.status?.toLowerCase() === "success") {
        const courseData = response?.result[0];
        const { course = {} } = courseData;
        const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
          course?.batchDeatils;
        const selectedBatch = [
          ...individualDaily,
          ...individualWeekly,
          ...groupDaily,
          ...groupWeekly,
        ].find((each) => each._id === data?.batchId);
        const courseObject = {
          type: "course",
          endUserId: loggedinUserId,
          serviceProviderId: courseData?.serviceProviderId,
          courseId: course?._id || course?.id,
          serviceProviderName: courseData?.serviceProviderName,
          selectedBatch: selectedBatch,
          selectedBatchId: selectedBatch?._id,
          transactionCurrencyCode: courseData?.transactionCurrencyCode,
          course: {
            _id: course?._id || course?.id,
            courseName: course?.courseName,
            category: course?.category,
            subCategory: course?.subCategory,
            courseDescription: course?.courseDescription,
            courseImage: course?.courseImage,
            courseLevel: course?.courseLevel,
            averageRating: course?.averageRating,
            ratingCount: course?.ratingCount,
            batchDeatils: {
              individualDaily,
              individualWeekly,
              groupDaily,
              groupWeekly,
            },
            blockbusterDeal: course?.blockbusterDeal,
            adminDiscount: course?.adminDiscount,
            blockbusterEndDate: course?.blockbusterEndDate,
            endUserList: course?.endUserList,
          },
          subscribersCount: courseData?.endUserList?.length ?? 0,
          averageRating: courseData?.averageRating,
          ratingCount: courseData?.ratingCount,
          yearsOfExperience: courseData?.yearsOfExperience,
          childDetails: [
            {
              endUserId: loggedinUserId,
              batchId: selectedBatch?._id,
            },
          ],
        };
        buyNowData.push(courseObject);
        dispatch(setBuyNowdata(buyNowData));
        navigate(`/buyNow`, {
          state: "buyNow",
        });
      } else {
        console.log("buyNow failed");
      }
    };
    if (!inCart) {
      addToCart(data);
    }
    AuthService.getBuyNowData(data, onSuccessApi, onFailure);
  };
  const addToCart = (completeDetails, id) => {
    let ApiObj = {
      type: "course",
      endUserId: loggedinUserId,
      serviceProviderId: completeDetails?.serviceProviderId,
      courseId: completeDetails?._id,
      batchId: id ?? "",
      parentId: loggedinUserId,
      childDetails: { endUserId: id },
    };
    AuthService.saveCart(
      ApiObj,
      (response) => {
        if (response.status === "Success") {
          showToast("info", response?.message);
          dispatch(setCartCount(99));
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error save cart");
      }
    );
  };
  const rejectInvite = (data) => {
    delete data.inviteCourse;
    delete data.id;
    AuthService.updateUserInviteStatus(data, onSuccessReject, onFailure);
  };
  const onSuccessReject = () => {
    getNotificationData();
  };
  const [batchRSdata, setBatchData] = useState("");

  const returnButtons = (item) => {
    const memberData = {
      id: item?._id,
      type: item?.notificationType,
    };
    const sendId = {
      courseId: item?.courseId,
      userId: item?.userId,
      batchId: item?.batchId,
      inviteCourse: true,
      id: item?._id,
      type: item?.notificationType,
      serviceProviderId: item?.serviceProviderId,
    };
    switch (item?.notificationType) {
      case PackageStrings?.course2:
        return (
          <button
            className="buttonStyleNotif"
            onClick={() => {
              handleClickNot(item);
            }}
          >
            <AautiText
              title={PackageStrings?.viewCourse}
              size={"semi"}
              className={"notification-text1"}
              weight={"medium"}
            />
          </button>
        );
      case PackageStrings?.coursepurchase:
        return (
          <button
            className="buttonStyleNotif"
            onClick={() => {
              handleClickNot(item);
            }}
          >
            <AautiText
              title={PackageStrings?.viewCourse}
              size={"semi"}
              className={"notification-text1"}
              weight={"medium"}
            />
          </button>
        );
      case PackageStrings?.gig:
        return (
          <button
            className="buttonStyleNotif"
            onClick={() => {
              handleClickNot(item);
            }}
          >
            <AautiText
              title={PackageStrings?.viewGig}
              size={"semi"}
              className={"notification-text1"}
              weight={"medium"}
            />
          </button>
        );
      case PackageStrings?.courseStartin15Minutes:
        return (
          <button
            className="buttonStyleNotif"
            onClick={() => {
              handleClickNot(item);
            }}
          >
            <AautiText
              title={PackageStrings?.joinClass}
              size={"semi"}
              className={"notification-text1"}
              weight={"medium"}
            />
          </button>
        );
      case PackageStrings?.adhocStartIn5Minutes:
        return (
          <button
            className="buttonStyleNotif"
            onClick={() => {
              handleClickNot(item);
            }}
          >
            <AautiText
              title={PackageStrings?.viewAdhoc}
              size={"semi"}
              className={"notification-text1"}
              weight={"medium"}
            />
          </button>
        );
      case PackageStrings?.inviteCourse:
        return (
          <>
            {item?.userStatus ? (
              <AautiText
                title={item?.userStatus}
                size={"small"}
                textStyle={{ color: "#078dda", marginTop: 3 }}
                weight={"medium"}
              />
            ) : (
              <div style={{ flexDirection: "row", display: "flex" }}>
                <button
                  className="buttonStyleNew"
                  onClick={() => {
                    rejectInvite(sendId);
                  }}
                >
                  <AautiText
                    title={"Reject"}
                    size={"semi"}
                    className={"notification-text3"}
                    weight={"medium"}
                  />
                </button>
                <button
                  className="buttonStyleNotif"
                  onClick={(event) => {
                    event.stopPropagation();
                    courseBuyApi(sendId);
                  }}
                >
                  <AautiText
                    title={"Buy Now"}
                    size={"semi"}
                    className={"notification-text1"}
                    weight={"medium"}
                  />
                </button>
              </div>
            )}
          </>
        );
      case PackageStrings?.inviteMember:
      case PackageStrings?.inviteModerator:
        return (
          <>
            {item?.userStatus ? (
              <AautiText
                textStyle={{
                  color: item?.userStatus === "Rejected" ? "red" : "#078dda",
                  marginTop: 3,
                }}
                title={`${
                  item?.userStatus === "Rejected"
                    ? "Invitation Rejected"
                    : "Invitation Accepted"
                }`}
                size={"small"}
                weight={"medium"}
              />
            ) : (
              <div style={{ flexDirection: "row", display: "flex" }}>
                <button
                  className="buttonStyleNew"
                  onClick={() => {
                    memberAccept(memberData, "Rejected");
                  }}
                >
                  <AautiText
                    title={"Reject"}
                    size={"semi"}
                    className={"notification-text3"}
                    weight={"medium"}
                  />
                </button>
                <button
                  className="buttonStyleNotif"
                  onClick={() => {
                    memberAccept(memberData, "approved");
                  }}
                >
                  <AautiText
                    title={"Accept"}
                    size={"semi"}
                    className={"notification-text1"}
                    weight={"medium"}
                  />
                </button>
              </div>
            )}
          </>
        );
      case PackageStrings?.BatchReschedule:
        const batchDetailsApiCall = (item) => {
          const data = { courseId: item?.courseId, batchId: item?.batchId };
          AuthService.getBatchDetailsByBatchId(
            data,
            onSuccessReschedule,
            onFailure
          );
        };
        const onSuccessReschedule = (response) => {
          if (response.status === PackageStrings?.success) {
            setBatchData(response?.result?.[0]);
          } else {
            showToast(PackageStrings?.error, response.message);
          }
        };
        return (
          <>
            {item?.userStatus ? (
              <AautiText
                title={item?.userStatus}
                size={"small"}
                textStyle={{
                  color: item?.userStatus === "rejected" ? "red" : "#078dda",
                  marginTop: 3,
                }}
                weight={"medium"}
              />
            ) : (
              <div style={{ flexDirection: "row", display: "flex" }}>
                <button
                  className="buttonStyleNew"
                  onClick={() => {
                    navigate(`/reschedule-refund`, {
                      state: {
                        courseId: item?.courseId,
                        batchId: item?.batchId,
                        notificationId: item?._id,
                      },
                    });
                    handleCloseNot();
                  }}
                >
                  <AautiText
                    title={"Reject"}
                    size={"semi"}
                    className={"notification-text3"}
                    weight={"medium"}
                  />
                </button>
                <button
                  className="buttonStyleNotif"
                  onClick={() => {
                    batchrescheduleCall(item);
                  }}
                >
                  <AautiText
                    title={"Accept"}
                    size={"semi"}
                    className={"notification-text1"}
                    weight={"medium"}
                  />
                </button>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };
  const formatDate = (isoDate) => {
    const dateObject = new Date(isoDate);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  function formatTo12HourTime(isoString) {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }

  const batchData = [
    {
      leftCont: "Batch Name",
      rightCont: batchRSdata.batchName,
    },
    {
      leftCont: "Date",
      rightCont:
        formatDate(batchRSdata?.startDate) +
        "-" +
        formatDate(batchRSdata?.endDate),
    },
    {
      leftCont: "Existing Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.startTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.endTime) +
        ")",
    },
    {
      leftCont: "Proposed Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        ")",
    },
  ];
  const handleClearAll = () => {
    AuthService.deleteAllNotificationsByUserId(
      loggedinUserId,
      onSuccessDeletAllNot,
      onFailure
    );
  };
  function formatKeys(text) {
    const spacedText = text?.replace(/([A-Z])/g, " $1").trim();
    return spacedText?.charAt(0)?.toUpperCase() + spacedText?.slice(1) || "";
  }
  const onFailureFunc = () => {
    console.log("error Message");
  };
  return (
    <div className="notification-div2">
      {accepteState ? (
        <div className="NFbatchRSdiv">
          <AautiText
            title={"Batch Details"}
            className={"NFcolor"}
            weight={"bold"}
          />
          <div className="NFdiv2">
            <img
              alt="images"
              src={batchRSdata?.courseImage}
              className="NFimagestyle"
            />
            <div className="NFstudents">
              <AautiText
                title={batchRSdata?.reScheduleBatchName}
                className={"NFcolor"}
                weight={"bold"}
              />
              <AautiText
                title={
                  batchRSdata?.acceptedUsers?.length + " Students" ||
                  0 + " Students"
                }
                className={"NFcolor"}
                weight={"medium"}
              />
            </div>
          </div>
          {batchData?.map((each, index) => (
            <div key={index} className="NFmapData">
              <AautiText
                title={each?.leftCont}
                weight={"medium"}
                textStyle={{ marginBottom: 3, color: "#1e1e1e" }}
              />
              <AautiText
                title={each?.rightCont}
                weight={"bold"}
                className={"NFcolor"}
              />
            </div>
          ))}
          <div className="NFbuttonStyle">
            <button
              className="buttonStyleNotif"
              style={{
                border: "1px solid #078dda",
                marginRight: "10px",
                backgroundColor: "#ffffff",
              }}
              onClick={() => {
                setAccepteState(false);
              }}
            >
              <AautiText
                title={"Reject"}
                textStyle={{ color: "#078dda" }}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
            <button
              className="buttonStyleNotif"
              onClick={() => {
                navigate("reschedule");
                handleCloseNot();
              }}
            >
              <AautiText
                title={"Accept"}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="notification-div3">
            <AautiText
              weight={"bold"}
              title={PackageStrings?.notifications}
              className={"notification-text2"}
            />
            <button className="notification-button1" onClick={handleCloseNot}>
              <MdCancel color="grey" size={20} />
            </button>
          </div>
          <div>
            <div>
              <Box sx={{ width: "100%" }}>
                {AllNot?.length > 0 && isUserLoggedIn && (
                  <div className="notification-div4">
                    <AautiText
                      textStyle={{ display: unReadMessages == 0 && "none" }}
                      onClick={() => handleMarkAsAllRead()}
                      title={PackageStrings?.markAsRead}
                      size={"semi"}
                      weight={"bold"}
                      className={"notification-text4"}
                    />
                    <IoTrashOutline
                      onClick={() => {
                        setOpen(true);
                        setClearNotification("clearAll");
                      }}
                      size={18}
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}

                <CustomTabPanel value={value} index={0}>
                  {AllNot?.length > 0 && isUserLoggedIn ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {AllNot?.map((each) => {
                        return (
                          <div
                            className="notification-div6"
                            style={{ position: "relative", width: "100%" }}
                          >
                            <div
                              style={{
                                cursor:
                                  each?.notificationType === "invite Course" ||
                                  each?.status === "0"
                                    ? "pointer"
                                    : null,
                              }}
                              id={"course-notification"}
                              className={
                                each?.status === "1"
                                  ? "notCard"
                                  : unReadMessages == 0
                                  ? "notCard"
                                  : "notCard2"
                              }
                              onClick={() => {
                                if (each?.status === "0") {
                                  handleClickNot(each);
                                }
                                if (
                                  each?.notificationType === "invite Course" ||
                                  each?.notificationType === "gig purchase"
                                ) {
                                  if (each?.courseId) {
                                    navigate(
                                      `course-details/${each?.courseId}`
                                    );
                                    handleCloseNot();
                                  } else if (each?.gigId) {
                                    handleCloseNot();
                                    navigate(`gig-preview/${each?.gigId}`);
                                  }
                                } else if (
                                  each?.notificationType ===
                                  PackageStrings?.batchTransfer
                                ) {
                                  filteredFunction(each);
                                } else if (
                                  each?.notificationType == "Adhoc purchase"
                                ) {
                                  handleClickNot(each);
                                }
                              }}
                              key={each?._id}
                            >
                              <img
                                alt={PackageStrings?.altText}
                                src={
                                  each?.courseImage ||
                                  each?.gigImage ||
                                  LogoIcon
                                }
                                className="notification-img1"
                              />
                              <div className="notification-div7">
                                <div className="notification-div8">
                                  <AautiText
                                    size={
                                      each?.notificationType === "invite Member"
                                        ? "small"
                                        : "semi"
                                    }
                                    title={
                                      each?.notificationType === "invite Member"
                                        ? each?.message
                                        : formatKeys(each?.notificationType)
                                    }
                                    weight={"bold"}
                                    className={"notification-text5"}
                                    textStyle={{
                                      width: moment(each?.createdAt)
                                        ?.fromNow()
                                        .includes("few")
                                        ? "59%"
                                        : "65%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 2,
                                    }}
                                  />
                                  <AautiText
                                    size={"small"}
                                    title={moment(each?.createdAt).fromNow()}
                                    className={"notification-text6"}
                                  />
                                </div>
                                <div
                                  className="notification-div9"
                                  style={{
                                    justifyContent:
                                      each?.notificationType !== "invite Member"
                                        ? "space-between"
                                        : "flex-end",
                                  }}
                                >
                                  {each?.notificationType !==
                                    "invite Member" && (
                                    <AautiText
                                      size={"small"}
                                      textAlign={"left"}
                                      title={each?.message}
                                      className={"notification-text7"}
                                    />
                                  )}
                                  <button
                                    className="cancelIcon2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelected(each);
                                      setOpen(true);
                                      setClearNotification("delete");
                                    }}
                                  >
                                    <IoTrashOutline size={18} />
                                  </button>
                                </div>
                                <div className="return-button">
                                  {returnButtons(each)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {showLoadBtn && AllNot?.length > 9 && (
                        <AautiText
                          onClick={() => setCurrentPage(currentPage + 1)}
                          title={"Load More"}
                          size={"semi"}
                          weight={"bold"}
                          className={"notification-text4"}
                        />
                      )}
                    </div>
                  ) : (
                    !loadingNotifi && (
                      <div className="notification-div10">
                        <AautiText title={PackageStrings?.noNotifications} />
                      </div>
                    )
                  )}
                  {loadingNotifi && (
                    <div className="notification-div5">
                      <CircularProgress />
                    </div>
                  )}
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> {"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (clearNotification === "delete") {
                onClickCrosscon(selected?._id);
              } else {
                handleClearAll();
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notifications;
