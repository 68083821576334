import React, { memo } from "react";
// import { constants } from "./constants";
import { Box, Button } from "@mui/material";
import { VectorUnderLineIcon } from "../../homeScreen/container";
import "./styles.scss";
import { ExploreMoreContainer } from "./container";
import ExploreMoreSkeleton from "./skeleton";

const ExploreMore = ({ catgoriesToShow = 4 }) => {
  const { exploreData, navigateToSubCategories, navigateToAllCategories } =
    ExploreMoreContainer();
  return (
    <section id="DOSE_OF_INSPIRATION" className="more_categories_explore">
      <Box className="dose_of_inspiration_frame">
        <p className="dose_of_inspiration_label">
          Explore More <span className="custom-span">Categories</span>
        </p>
        <Box className="text_underline text_underline2">
          <VectorUnderLineIcon />
        </Box>
      </Box>
      {exploreData.isLoading ? (
        <ExploreMoreSkeleton />
      ) : (
        <>
          <Box className="more_categories_list_container">
            {exploreData?.data
              .slice(0, catgoriesToShow)
              .map((category, index) => (
                <Box className="more_categories_list" key={index}>
                  <ul className="more_categories_list_ul">
                    <li className="more_categories_list_label_head">
                      {category?.categoryName}
                    </li>
                    {category?.subCategories
                      ?.slice(0, 6)
                      .map((subCategory, subIndex) => (
                        <li
                          className="more_categories_list_label_content"
                          key={subIndex}
                          onClick={() =>
                            navigateToSubCategories(category, subCategory)
                          }
                        >
                          {subCategory?.subCategoryName}
                        </li>
                      ))}
                    <li
                      className="more_categories_list_label_head text_blue"
                      onClick={navigateToAllCategories}
                    >
                      More
                    </li>
                  </ul>
                </Box>
              ))}
          </Box>
          <Box className="view_more_container">
            <Button
              variant="contained"
              className="view_more_button"
              onClick={navigateToAllCategories}
            >
              View All Categories
            </Button>
          </Box>
        </>
      )}
    </section>
  );
};

export default memo(ExploreMore);
