import { BsPersonVideo3 } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { LuMonitor } from "react-icons/lu";
import { MdGroups } from "react-icons/md";

export const courseTypes = [
  {
    id: 1,
    icon: <IoMdPerson size={25} />,
    title: "Individual",
    text: "Selecting Individual lets you train one person as the timings you’ll figure out to teach.",
  },
  {
    id: 1,
    icon: <MdGroups size={25} />,
    title: "Group",
    text: "Selecting Group lets you teach multiple persons as the timings you’ll figure out to teach.",
  },
  {
    id: 1,
    icon: <BsPersonVideo3 size={25} />,
    title: "Gig",
    text: "Gig’s are short streaming videos.",
  },
];

export const ClassTypes = [
  {
    id: 1,
    name: "Virtual",
    icon: <LuMonitor />,
  },
  { id: 2, name: "In Person", icon: <IoMdPerson /> },
];
