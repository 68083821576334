import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function SkeletonVariants(props) {
    const {width,height} =  props
  return (
    <Stack  spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton   animation="wave" variant="rectangular" width={width} height={height} />
      {/* <Skeleton variant="rounded" width={210} height={60} /> */}
    </Stack>
  );
}