import React from "react";
import default_user_avatar from "../images/default_user_avatar.png";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosStar } from "react-icons/io";
import { MdOutlineIosShare } from "react-icons/md";
import "../styles.scss";
import { setSpFollowId } from "../../../redux/reducer/appReducer";
import { useDispatch } from "react-redux";
import ShareIcon from "../../globalComponents/icons/ShareIcon";

const SpHomeCard = ({ slide, topServiceProviders }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      id="FINDING_SLIDER"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box className="slider_container_box flex_container">
        {topServiceProviders?.map((slide) => (
          <Box
            className="slide_content_box"
            key={slide?.serviceProviderId}
            onClick={() => {
              navigate(`/sp-profile/${slide?.serviceProviderId}`);
            }}
          >
            <img
              src={slide?.profileImage || default_user_avatar}
              alt={slide?.title}
              className="slider_image"
            />
            <Box className="slider_content_box_wrapper">
              <Box className="slider_content_box_learners_box">
                <Box className="slider_content_box_learners_text">
                  {slide?.subscribercount == 1 || slide?.subscribersCount == 1
                    ? `${
                        slide?.subscribercount || slide?.subscribersCount || 0
                      } Student`
                    : `${
                        slide?.subscribercount || slide?.subscribersCount || 0
                      } Students`}
                </Box>
                <Box className="slider_content_box_learners_text">
                  {slide?.coursesCount === 1
                    ? `${slide?.coursesCount || 0} Course`
                    : `${slide?.coursesCount || 0} Courses`}
                </Box>
              </Box>
              <Box className="slider_content_box_title_text">
                {slide?.displayName || "N/A"}
              </Box>
              <Box className="slider_content_box_certified_box">
                <Box className="slider_content_box_certified_text">
                  <p>
                    {" "}
                    <IoIosStar className="slider_content_box_certified_icon" />{" "}
                    &nbsp;
                    {slide?.averageRating
                      ? `${slide?.averageRating?.toFixed(1) || 0} Ratings`
                      : "New"}
                  </p>
                </Box>
                <Box className="slider_content_box_certified_follow">
                  <ShareIcon shareUrl={`${window.location.origin}`} />
                  <Button
                    variant="contained"
                    className="slider_content_box_certified_follow_button"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        setSpFollowId({
                          serviceProviderId: slide?._id,
                          name: slide?.displayName,
                        })
                      );
                      navigate("/login");
                    }}
                  >
                    Follow
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SpHomeCard;
