import Divider from "@mui/material/Divider";
import * as React from "react";
import { useEffect } from "react";
import Lottie from "react-lottie-player";
import { useSelector } from "react-redux";
import { noMessagesYet, startNewConv } from "./constants";
import lottie_animation_no_message from "./lottie_animation_no_message.json";
import Messages from "./messages/index";
import "./styles.scss";
import AautiText from "../../../../globalComponents/AautiText";
import { socket } from "../../..";
export default function RenderMessages(props) {
  const {
    messages = [],
    text,
    msgInput,
    setMsgInput,
    setMessages,
    sendClicked,
    setSendClicked,
    replyClicked,
    setReplyClicked,
    replyObj,
    setReplyObj,
    deleted,
    setDeleted,
    screen,
    searchText,
    setSearchText,
    messageRefs,
    messageId,
  } = props;
  let newUserMessage = null;
  let prevUser = null;
  const { loggedinUserId } = useSelector((state) => state.app);
  const [messageList, setMessageList] = React.useState([]);
  useEffect(() => {
    if (messages.length > 0) {
      setMessageList(
        messages?.sort(
          (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
        )
      );
    }
  }, [messages, deleted]);

  function formatDate(dateString) {
    const moment = require("moment");
    const date = moment(dateString);
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (date.isSame(today, "day")) {
      return "Today";
    } else if (date.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return date.format("MMMM D, YYYY");
    }
  }

  if (messageList?.length > 0) {
    let currentDate = null;
    let showMessageDate = true;
    return messageList.map((message, index) => {
      if (!(message.type === "TEXT" && message.text === "")) {
        newUserMessage =
          newUserMessage === null || prevUser !== message?.sender?._id;
        prevUser = newUserMessage ? message?.sender?._id : prevUser;
        const messageDate = new Date(message.createdAt).toLocaleDateString();
        if (messageDate !== currentDate) {
          currentDate = messageDate;
          showMessageDate = true;
        } else {
          showMessageDate = false;
        }
        return (
          <div key={index}>
            {showMessageDate && (
              <div className={"system_messages"}>
                <Divider>
                  <div className={"system_msg_txt"}>
                    <p>{formatDate(messageDate)}</p>
                  </div>
                </Divider>
              </div>
            )}

            <div
              ref={(el) => messageRefs && (messageRefs.current[index] = el)}
              id={messageId}
            >
              <Messages
                item={message}
                index={index}
                key={"message " + index}
                myId={loggedinUserId}
                newUserMessage={newUserMessage}
                text={text}
                setMsgInput={setMsgInput}
                msgInput={msgInput}
                setMessages={setMessages}
                sendClicked={sendClicked}
                setSendClicked={setSendClicked}
                replyClicked={replyClicked}
                setReplyClicked={setReplyClicked}
                replyObj={replyObj}
                setReplyObj={setReplyObj}
                deleted={deleted}
                setDeleted={setDeleted}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </div>
          </div>
        );
      }
    });
  } else {
    return (
      <div className={"no_message_lottie_player_container"}>
        <Lottie
          loop
          play
          animationData={lottie_animation_no_message}
          className={
            text === "JoinClassChatScreen"
              ? "lottie_player_styles_JoinChat"
              : "lottie_player_styles"
          }
        />

        <AautiText
          weight={"medium"}
          title={screen === "Feeds" ? "No Feeds Yet" : noMessagesYet}
          size={"normal"}
          textStyle={{
            color: "#1E1E1E",
          }}
        />
        {screen !== "Feeds" && (
          <AautiText
            weight={"medium"}
            title={startNewConv}
            size={"normal"}
            textStyle={{
              color: "#1E1E1E",
            }}
          />
        )}
      </div>
    );
  }
}
