import { Avatar, IconButton } from "@mui/material";
import React, { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import moment from "moment";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import CommentInput from "./CommentInput";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoTrashOutline } from "react-icons/io5";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const CommentCard = ({
  comment,
  replyId,
  setReplyId,
  handleInputSave,
  handleThumbAction,
  showReplyId,
  setShowReplyId,
  handleDeleteComment,
  handleEditComment,
  editCommentObj,
  handleUpdateComment,
  handleEditReply,
  editReplyObj,
  handleUpdateReply,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const { loggedinUserId } = useSelector((state) => state.app);

  const handleClick = (event, card) => {
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCard(null);
  };

  const returntime = (time) => {
    const formattedTime = moment.utc(time).local().startOf("seconds").fromNow();
    return formattedTime || "NA";
  };

  const isIncludedInLike = comment?.likedUsers?.some(
    (each) => each == loggedinUserId
  );
  const isIncludedInDisLike = comment?.disLikeUsers?.some(
    (each) => each == loggedinUserId
  );
  return (
    <div className="comments-det">
      <Avatar
        src={comment?.profileImage}
        alt="comment-profile"
        sx={{ height: 40, width: 40 }}
        variant="circular"
      />
      {editCommentObj?._id == comment?._id ? (
        <CommentInput
          value={editCommentObj?.comment}
          handleInputCancel={() => handleEditComment({})}
          handleInputSave={handleUpdateComment}
        />
      ) : (
        <div style={{ width: "92%" }}>
          <div className="comment-header-name">
            <AautiText
              title={comment?.displayName}
              size={"semi"}
              weight={"bold"}
              textStyle={{ color: "#000" }}
            />
            <AautiText title={returntime(comment?.createdAt)} size={"small"} />
          </div>
          <div style={{ position: "relative", marginTop: 2, width: "100%" }}>
            <AautiText
              title={comment?.comment}
              size={"semi"}
              textStyle={{ width: "90%" }}
            />
            <div className="comments-actions">
              <button
                className="reply-btn"
                style={{ display: replyId == comment?._id && "none" }}
                onClick={() => {
                  setReplyId(comment?._id);
                }}
              >
                <AautiText
                  title={AautiStrings?.replyString}
                  textStyle={{ color: "#2076E5" }}
                  size={"semi"}
                />
              </button>
              <button
                className="reply-btn"
                style={{ color: isIncludedInLike && "#2076E5" }}
                onClick={() => {
                  handleThumbAction("like", comment);
                }}
              >
                <FiThumbsUp />
                <AautiText
                  size={"semi"}
                  title={comment?.likedUsers?.length || 0}
                />
              </button>
              <button
                className="reply-btn"
                style={{ color: isIncludedInDisLike && "#2076E5" }}
                onClick={() => {
                  handleThumbAction("disLike", comment);
                }}
              >
                <FiThumbsDown />
                <AautiText
                  size={"semi"}
                  title={comment?.disLikeUsers?.length || 0}
                />
              </button>
            </div>
            <div
              className="comment-menu"
              style={{
                display: loggedinUserId == comment?.endUserId ? "flex" : "none",
              }}
            >
              <IconButton
                onClick={() => {
                  handleEditComment(comment);
                  handleClose();
                }}
                sx={{ p: 0.5 }}
              >
                <MdOutlineModeEditOutline size={16} />
              </IconButton>
              <IconButton
                onClick={() => {
                  // console.log({ comment });
                  handleDeleteComment(comment?._id);
                  handleClose();
                }}
                sx={{ p: 0.5 }}
              >
                <IoTrashOutline size={16} />
              </IconButton>
              {/* <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                // onClose={handleClose}
              >
                <MenuItem
                  
                >
                  Edit
                </MenuItem>
                <MenuItem
                  
                >
                  Delete
                </MenuItem>
              </Menu> */}
            </div>
          </div>
          <div
            style={{
              display: replyId == comment?._id ? "flex" : "none",
              marginTop: 15,
            }}
          >
            <CommentInput
              isReply={true}
              handleInputCancel={() => {
                setReplyId("");
              }}
              handleInputSave={(val) => {
                handleInputSave(val, true, comment);
              }}
            />
          </div>
          <div
            style={{
              display: isEmpty(comment?.replies) && "none",
              marginTop: 4,
            }}
          >
            <button
              className="reply-btn"
              style={{ marginLeft: 15, color: "#2076E5" }}
              onClick={() => {
                if (showReplyId && showReplyId == comment?._id) {
                  setShowReplyId("");
                } else {
                  setShowReplyId(comment?._id);
                }
              }}
            >
              {showReplyId && showReplyId == comment?._id ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
              <AautiText
                title={`${comment?.replies?.length} replies`}
                size={"semi"}
              />
            </button>
            <div
              style={{
                display:
                  showReplyId == comment?._id && showReplyId !== ""
                    ? "flex"
                    : "none",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "95%" }}>
                {comment?.replies?.map((reply, index) => {
                  const isIncludedInLikeReply = reply?.likedUsers?.some(
                    (each) => each == loggedinUserId
                  );
                  const isIncludedInDisLikeReply = reply?.disLikeUsers?.some(
                    (each) => each == loggedinUserId
                  );
                  return (
                    <>
                      <div
                        className="comments-det"
                        // style={{ width: "98%" }}
                        key={index}
                      >
                        <Avatar
                          src={reply?.profileImage}
                          alt="comment-profile"
                          sx={{ height: 40, width: 40 }}
                          variant="circular"
                        />
                        {editReplyObj?._id == reply?._id ? (
                          <div
                            style={{
                              display: "flex",
                              width: "94%",
                            }}
                          >
                            <CommentInput
                              value={reply?.comment}
                              handleInputCancel={() => {
                                handleEditReply({});
                              }}
                              handleInputSave={(val) => {
                                handleUpdateReply(val);
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ width: "94%" }}>
                            <div className="comment-header-name">
                              <AautiText
                                title={reply?.displayName}
                                size={"semi"}
                                weight={"bold"}
                                textStyle={{ color: "#000" }}
                              />
                              <AautiText
                                title={returntime(reply?.createdAt)}
                                size={"small"}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                marginTop: 2,
                                width: "100%",
                              }}
                            >
                              <AautiText
                                title={reply?.comment}
                                size={"semi"}
                                textStyle={{ width: "90%" }}
                              />
                              <div className="comments-actions">
                                <button
                                  className="reply-btn"
                                  style={{
                                    color: isIncludedInLikeReply && "#2076E5",
                                  }}
                                  onClick={() => {
                                    handleThumbAction("like", reply);
                                  }}
                                >
                                  <FiThumbsUp />
                                  <AautiText
                                    size={"semi"}
                                    title={reply?.likedUsers?.length || 0}
                                  />
                                </button>
                                <button
                                  className="reply-btn"
                                  style={{
                                    color:
                                      isIncludedInDisLikeReply && "#2076E5",
                                  }}
                                  onClick={() => {
                                    handleThumbAction("disLike", reply);
                                  }}
                                >
                                  <FiThumbsDown />
                                  <AautiText
                                    size={"semi"}
                                    title={reply?.disLikeUsers?.length || 0}
                                  />
                                </button>
                              </div>

                              <div
                                className="comment-menu"
                                style={{
                                  display:
                                    loggedinUserId == reply?.endUserId
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleEditReply(reply);
                                    handleClose();
                                  }}
                                  sx={{ p: 0.5 }}
                                >
                                  <MdOutlineModeEditOutline size={16} />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    // console.log({ comment });
                                    handleDeleteComment(reply?._id);
                                    handleClose();
                                  }}
                                  sx={{ p: 0.5 }}
                                >
                                  <IoTrashOutline size={16} />
                                </IconButton>
                                {/* <IconButton
                                sx={{ padding: 0 }}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, 1)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && selectedCard === 1}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                <MenuItem onClick={handleClose}>
                                  Delete
                                </MenuItem>
                              </Menu> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentCard;
