import moment from "moment";

export function getDateTimeDifference(dateTime) {
  if (!moment(dateTime).isValid()) {
    return "";
  }
  const momentDate = moment.utc(dateTime).local();
  const currentDate = moment().local();
  if (momentDate.isValid()) {
    if (momentDate.isSame(currentDate, "date")) {
      if (Math.abs(momentDate.diff(currentDate)) < 6000) {
        return "just now";
      } else {
        const hoursDiff = Math.abs(momentDate.diff(currentDate, "h"));
        if (hoursDiff < 1) {
          const minutesDiff = Math.abs(momentDate.diff(currentDate, "minutes"));
          if (minutesDiff < 1) {
            return "just now";
          }
          return `${minutesDiff} min${minutesDiff > 1 ? "s" : ""} ago`;
        }
        return `${hoursDiff} hr${hoursDiff > 1 ? "s" : ""} ago`;
      }
    }
    if (momentDate.isBefore(currentDate, "date")) {
      const diff = Math.abs(momentDate.diff(moment().local(), "days"));
      if (diff == 1) {
        return "Yesterday";
      }
      return momentDate.format("DD/MM/YYYY");
    }
  }
  return "N/A";
}

export function checkNull(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    value === "null" ||
    value?.length === 0 ||
    value == 0
  );
}
export const removePlusAtStart = (value) => {
  const string = value || ''
  return string.startsWith('+') ? string.slice(1) : string
}
export const pluralOrSingular = (num, str, es = false) => {
  if (num != 1) {
    return es ? str + "es" : str + "s";
  } else {
    return str;
  }
};

export const convertMultipleSpacesToSingleSpace = (str) => {
  //Preventing the string starting with a space
  if (str?.length == 1 && str == " ") {
    return "";
  }
  // Use a regular expression to match multiple spaces
  const regex = /\s+/g;

  // Replace all occurrences of multiple spaces with a single space
  return str?.replace(regex, " ");
};

export function capitalizeWordFirstLetter(string) {
  var grammar = ["a", "an", "the", "and", "or", "of", "are", "for"];
  if (checkNull(string)) return "";
  var capString = string?.[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
  capString = capString
    .split(" ")
    .map(function (word) {
      return !grammar.includes(word)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word;
    })
    .join(" ");
  return capString;
}
