import { IconButton, Tooltip } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { RxCrossCircled } from "react-icons/rx";
import dayjs from "dayjs";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import NavButton from "./NavButton";
import { isEmpty } from "lodash";
import { courseConstant } from "./courseConstant";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const CustomSlotSelection = ({
  courseBatch,
  setCourseBatch,
  nextTimeSlot,
  onChangetimeSlotObj,
  closePop,
}) => {
  let today = new Date();
  const [selectedDate, setSelectedDate] = useState("");
  const [dateRanges, setDateRanges] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const returnRangeDates = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const dates = {};
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const date = moment(currentDate).format("YYYY-MM-DD");
      dates[date] = {};
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const handleCustomDates = (dateObj) => {
    const startDate = moment(dateObj?.startDate).format("YYYY-MM-DDT00:00:00");
    const endDate = moment(dateObj?.endDate).format("YYYY-MM-DDT00:00:00");
    const dates = returnRangeDates(startDate, endDate);
    setDateRanges(dates);

    setCourseBatch((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleRemoveDate = (date) => {
    let excludedDates = courseBatch?.excludedDates
      ? [...courseBatch?.excludedDates]
      : [];
    const updatedDatesObject = { ...dateRanges };
    delete updatedDatesObject[date];
    const exclude = moment(date).format("YYYY-MM-DDT00:00:00");
    excludedDates.push({ date: exclude });
    const delteCustomDate = { ...courseBatch?.customDates };
    delete delteCustomDate[date];

    setDateRanges(updatedDatesObject);
    setCourseBatch((prev) => ({
      ...prev,
      excludedDates: excludedDates,
      customDates: delteCustomDate,
    }));
  };

  const handleAddCustomTimes = (date, field) => {
    const timeFormat = moment(date?.$d).format("YYYY-MM-DDTHH:mm:00");

    const val2 = moment(date?.$d)
      ?.add(1, "hour")
      ?.format("YYYY-MM-DDTHH:mm:ss");
    const updatedCustomDates = { ...courseBatch?.customDates };
    if (!updatedCustomDates[selectedDate]) {
      updatedCustomDates[selectedDate] = {};
    }
    updatedCustomDates[selectedDate][field] = timeFormat;
    updatedCustomDates[selectedDate]["endTime"] = val2;
    setCourseBatch((prev) => ({
      ...prev,
      customDates: updatedCustomDates,
    }));
  };

  const endTimeValue = !isEmpty(courseBatch?.customDates)
    ? courseBatch?.customDates[selectedDate]?.endTime
    : courseBatch?.endTime;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DateRange
        editableDateInputs={true}
        months={2}
        onChange={(item) => {
          setState([item.selection]);
          handleCustomDates(item.selection);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
        style={{ alignSelf: "center" }}
        minDate={today}
        showSelectionPreview={true}
        direction="horizontal"
      />

      {dateRanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            overflow: "hidden",
            overflowX: "scroll",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {Object.keys(dateRanges)?.map((each, index) => (
            <button
              key={index}
              onClick={() => {
                if (
                  courseBatch?.startTime === "" ||
                  courseBatch?.endTime == ""
                ) {
                  alert("please set timings");
                } else {
                  setSelectedDate(each);
                }
              }}
              style={{
                padding: "5px 10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 4,
                border:
                  selectedDate === each
                    ? "2px solid #066cb5"
                    : "1px solid lightgrey",
                gap: 6,
              }}
            >
              {moment(each).format("DD MMM")}
              <Tooltip title={courseConstant?.addToExceptionsList}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveDate(each);
                  }}
                >
                  <RxCrossCircled size={20} />
                </IconButton>
              </Tooltip>
            </button>
          ))}
        </div>
      )}

      <div
        className="start-end-time"
        style={{
          width: "100%",
          border: "none",
          alignItems: "flex-end",
          // justifyContent: "",
        }}
      >
        <div
          style={{ borderRight: "1px solid #dddddd " }}
          className="eachDateStart"
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]} fullWidth>
              <DemoItem label={AautiStrings.StartTimeString}>
                <TimePicker
                  ampm={false}
                  value={dayjs(
                    (courseBatch?.customDates &&
                      courseBatch?.customDates[selectedDate]?.startTime) ||
                      nextTimeSlot
                  )}
                  timeSteps={{ minutes: 30 }}
                  onChange={(newVal) => {
                    if (selectedDate) {
                      handleAddCustomTimes(newVal, "startTime");
                    } else {
                      onChangetimeSlotObj(newVal, "startTime");
                    }
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="eachDateStart">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <DemoItem label={AautiStrings.EndTimeString}>
                <TimePicker
                  ampm={false}
                  minTime={dayjs(endTimeValue)}
                  value={dayjs(endTimeValue)}
                  timeSteps={{ minutes: 30 }}
                  onChange={(newVal) => {
                    if (selectedDate) {
                      handleAddCustomTimes(newVal, "endDate");
                    } else {
                      onChangetimeSlotObj(newVal, "endTime");
                    }
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <AddCircleOutlineOutlinedIcon fontSize="large" />
      </div>
      <NavButton
        text={AautiStrings.DoneString}
        handleClick={() => {
          closePop();
        }}
      />
    </div>
  );
};

export default CustomSlotSelection;
