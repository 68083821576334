import React, { createRef, useState } from "react";
import AautiText from "../../../globalComponents/AautiText";
import AautiUpload from "../../../globalComponents/AautiUpload";

import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import AautiDialog from "../../../globalComponents/AautiDialog";
import { Cropper } from "react-cropper";
import GigUploads from "./GigUploads";
import { useSelector } from "react-redux";
import { checkYoutubeVideo } from "../../../CommonFunctions";
import ReactPlayer from "react-player";
import { AautiStrings } from "../../../globalComponents/AautiStrings";
import { createCourseStrings } from "../Constants";

const GigImageFields = ({
  handleGigImage,
  gigObject,
  handleImageFields,
  CreateCourseObj,
  handleAddTopic,
  handleRemoveTopic,
  handleOnChangeTopic,
  uploadGigImage,
  handleUploadVideoUrl,
}) => {
  const { relatedImages } = CreateCourseObj;
  const { shortVideoUrl, gigImage, gigVideos } = gigObject;
  const {
    mobileDevices,
    desktop,
    miniLapys,
    ipodDevices,
    aboveLargeMobileDevices,
    lapy,
  } = useSelector((state) => state.responsive);
  const [preview, setPreview] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const cropperRef = createRef(null);
  const [image, setImage] = useState("");
  const [gig, setGig] = useState();
  const [cropImage, setCropImage] = useState(false);

  const checkImageRatios = async (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        // if (field === "gigImage") {
        if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
          handleGigImage(e, field);
        } else {
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setUploadedFile(files[0]);
            setCropImage(true);
          };
          reader.readAsDataURL(files[0]);
        }
        // } else if (field === "relatedImages") {
        //   if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
        //     handleGigImage(e, field);
        //   } else {
        //     alert("Only Images with the folloiwng ratio are allowed: 16:9");
        //   }
        // }
      };
    }
  };

  const returnVideoName = (video) => {
    var filename = video.substring(video.lastIndexOf("/") + 1);

    var videoName = filename.split(".").slice(0, -1).join(".");

    return videoName;
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const fileName = uploadedFile?.name;
        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File(
            [blob],
            fileName ?? returnVideoName(image),
            {
              type: "image/png",
              lastModified: Date.now(),
            }
          );
          //   setCropData(croppedFile);
          uploadGigImage(croppedFile, "gigImage");
          closeCrop();
        }, "image/png");
      }
    }
  };

  const renderImages = (url, field, index) => {
    return (
      <Tooltip
        title={AautiStrings?.previewString}
        onClick={() => {
          setPreview(url);
        }}
      >
        <div
          style={{
            border: "1px solid lightgrey",
            borderRadius: 6,
            padding: 5,
            position: "relative",
            height: 120,
            width: 130,
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleImageFields(field, index);
              document.getElementById(field).value = "";
            }}
            sx={{
              position: "absolute",
              right: 1,
              background: "#fff",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
          </IconButton>
          <img
            src={url}
            style={{ height: "100%", width: "100%" }}
            alt="gigImage"
          />
        </div>
      </Tooltip>
    );
  };

  const renderVideo = (url, field, index) => {
    const isYoutubeVideo = checkYoutubeVideo(url);

    return (
      <div
        style={{
          border: "1px solid lightgrey",
          borderRadius: 6,
          padding: 5,
          position: "relative",
          height: 120,
          width: 130,
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleImageFields(field, index);
            if (field == "shortVideoUrl") {
              document.getElementById(field).value = "";
            }
          }}
          sx={{
            position: "absolute",
            right: 1,
            background: "#fff",
            zIndex: 200,
            cursor: "pointer",
            "&:hover": {
              background: "#fff",
            },
          }}
        >
          <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
        </IconButton>
        {isYoutubeVideo ? (
          <ReactPlayer url={url} controls width="100%" height="100%" />
        ) : (
          <video
            src={url}
            muted
            autoPlay={true}
            style={{ height: "100%", width: "100%" }}
          />
        )}
      </div>
    );
  };

  const closeCrop = () => {
    setCropImage(false);
    setImage("");
    setUploadedFile(null);
  };
  const headText = (text, bool = true) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          // marginTop: 15,
        }}
      >
        <AautiText size={"normal"} title={text} required={bool} />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 15,
        }}
      >
        <div style={{ width: "49%" }}>
          {headText(createCourseStrings?.coverImage)}
          <AautiUpload
            id="gigImage"
            onChange={(e) => {
              checkImageRatios(e, "gigImage");
            }}
            accept="image/jpeg, image/png"
            iconName={createCourseStrings?.coverImage}
            important
            customLabel={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <AddPhotoAlternateOutlinedIcon
                  sx={{ color: "#8687a7" }}
                  fontSize="large"
                />
                <AautiText
                  size={"semi"}
                  weight={"normal"}
                  textStyle={{ color: "#8687a7" }}
                  title={createCourseStrings?.uploadGigCoverImage}
                />
              </div>
            }
          />
          <AautiText
            size={"small"}
            textAlign={"left"}
            title={
              createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
            }
          />
          {!isEmpty(gigImage) && renderImages(gigImage, "gigImage")}
        </div>
        <div style={{ width: "49%" }}>
          {headText("Media Files", false)}

          <AautiUpload
            id="mediaFiles"
            onChange={(e) => {
              handleGigImage(e, "mediaFiles");
            }}
            iconName={createCourseStrings?.coverImage}
            customLabel={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <AddPhotoAlternateOutlinedIcon
                  sx={{ color: "#8687a7" }}
                  fontSize="large"
                />
                <AautiText
                  size={"semi"}
                  weight={"normal"}
                  textStyle={{ color: "#8687a7" }}
                  title={createCourseStrings?.uploadMediaAndFiles}
                />
              </div>
            }
          />

          {!isEmpty(relatedImages) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                marginTop: 5,
              }}
            >
              {relatedImages?.map((item, index) => (
                <div key={index}>
                  {item.endsWith(".mp4")
                    ? renderVideo(item, "relatedImages", index)
                    : renderImages(item, "mediaFiles", index)}
                </div>
              ))}
            </div>
          )}

          {/* <AautiText
          size={"small"}
          textAlign={"left"}
          title={"Only images with the following ratio are allowed: 4:3"}
        /> */}
        </div>
        <div style={{ width: "49%" }}>
          {headText(createCourseStrings?.previewVideo)}

          <AautiUpload
            accept="video/mp4,video/x-m4v,video/*"
            id="shortVideoUrl"
            onChange={(e) => {
              handleGigImage(e, "shortVideoUrl");
            }}
            iconName={createCourseStrings?.previewVideo}
            important
            customLabel={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <AddPhotoAlternateOutlinedIcon
                  sx={{ color: "#8687a7" }}
                  fontSize="large"
                />
                <AautiText
                  size={"semi"}
                  weight={"normal"}
                  textStyle={{ color: "#8687a7" }}
                  title={createCourseStrings?.uploadPreviewVideo}
                />
              </div>
            }
          />
          {!isEmpty(shortVideoUrl) && (
            <div
              style={{
                marginTop: 10,
              }}
            >
              {renderVideo(shortVideoUrl, "shortVideoUrl")}
            </div>
          )}
          {/* <AautiText
          size={"small"}
          textAlign={"left"}
          title={"Only images with the following ratio are allowed: 4:3"}
        /> */}
        </div>
        <div style={{ width: "49%" }}>
          {headText(createCourseStrings?.uploadGig)}
          <div
            style={{ width: "100%" }}
            onClick={() => {
              setGig(true);
            }}
          >
            <AautiUpload
              disabled
              id="upload media"
              iconName={createCourseStrings?.coverImage}
              important
              customLabel={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternateOutlinedIcon
                    sx={{ color: "#8687a7" }}
                    fontSize="large"
                  />
                  <AautiText
                    size={"semi"}
                    weight={"normal"}
                    textStyle={{ color: "#8687a7" }}
                    title={createCourseStrings?.uploadGigImp}
                  />
                </div>
              }
            />
          </div>
          {!isEmpty(gigVideos) && (
            <div
              style={{
                alignSelf: "flex-start",
                marginTop: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  marginTop: 5,
                }}
              >
                {gigVideos?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      // border: activeIndex == index && "1px solid lightgrey",
                      cursor: "pointer",
                      // borderRadius: 5,
                    }}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                  >
                    {renderVideo(each?.videoUrl, "gigVideo", index)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <AautiDialog
        dialogWidth={"80vw"}
        open={cropImage}
        onClose={() => {
          closeCrop();
        }}
      >
        <div style={{ width: "100%" }}>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={9 / 16}
            aspectRatio={9 / 16}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />

          <div style={{ marginTop: 10 }}>
            <AautiText weight={"bold"} title={AautiStrings?.previewString} />
            <div
              className="img-preview"
              style={{
                width: "120px",
                float: "left",
                height: "200px",
                borderRadius: 5,
                marginTop: 10,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: 20,
              gap: 10,
            }}
          >
            <button
              style={{
                border: "1px solid lightgrey",
                color: "#000",
                fontSize: "13px",
                fontWeight: "500",
                borderRadius: 4,
                padding: "5px 15px 5px 15px",
                cursor: "pointer",
              }}
              onClick={() => {
                closeCrop();
              }}
            >
              Cancel
            </button>
            <button
              style={{
                background: "#3399ff",
                color: "#fff",
                fontSize: "13px",
                fontWeight: "500",
                border: "none",
                borderRadius: 4,
                padding: "5px 15px 5px 15px",
                cursor: "pointer",
              }}
              onClick={() => {
                getCropData();
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </AautiDialog>
      <AautiDialog
        key={true}
        open={!isEmpty(preview) || gig || false}
        onClose={() => {
          setPreview(null);
          setGig(false);
        }}
      >
        <GigUploads
          {...{
            preview,
            setPreview,
            setGig,
            setActiveIndex,
            renderVideo,
            activeIndex,
            gigVideos,
            handleRemoveTopic,
            handleAddTopic,
            handleOnChangeTopic,
            gig,
            handleGigImage,
            handleUploadVideoUrl,
          }}
        />
      </AautiDialog>
    </>
  );
};

export default GigImageFields;
