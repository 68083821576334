import React, { useState, useEffect } from "react";
import AautiText from "../../globalComponents/AautiText";
import {
  Box,
  Avatar,
  Skeleton,
  FormControl,
} from "@mui/material";
import "./styles.scss";
import { showToast } from "../../globalComponents/Toast";
import { useSelector } from "react-redux";
import { AuthService } from "../../serviceProviderDetails/service";
import { FaChevronRight } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NoData from "../../globalComponents/NoDataFound";
import { IoIosArrowDown } from "react-icons/io";
import { isEmpty } from "lodash";
import EmptyTopic from "../../globalComponents/EmptyTopic";
import EmptyImage from "../../../assets/images/EmptyAttendence.png";
import AautiDialog from "../../globalComponents/AautiDialog";
import { SpStringConstants } from "./SpStringConstants";
import AppContainer from "../../AppContainer";
import AccountTabs from "../../globalComponents/AccountTabs";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { pluralOrSingular } from "../../../utils/Helpers";

const AttendanceCard = ({ item, onClick, selectedItem, modal, }) => {
  return (
    <div
      onClick={() => { !modal && onClick(item); }}
      className="SPAleftCarddiv1"
      style={{
        background: item?._id === selectedItem?._id ? "#E9F2FD" : "#fff",
      }}
    >
      <Avatar
        alt={item?.courseName}
        src={item?.courseImage || item?.adhocImage}
        sx={{ width: 50, height: 50, mr: 1.5 }}
      />
      <div className="SPAleftCarddiv2">
        <AautiText
          weight={"bold"}
          size={"semi"}
          title={CapitalizeFirstLetter(item?.courseName || item?.adhocName)}
        />
        <AautiText
          weight={"medium"}
          size={"small"}
          className={"SAPsessionsText"}
          title={`${item?.totalSessionsCount}`+pluralOrSingular(`${item.totalSessionsCount}`, ' Session')+' | '+
          `${item?.totalCoursePercentage?.toFixed(0) || item?.avgPercentage?.toFixed(0) || item?.totalAdhocPercentage?.toFixed(0) }% 
           ${SpStringConstants.AttendenceText}`}
        />
      </div>
    </div>
  );
};

const SpAttendence = (props) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [bactchIndex, setBatchIndex] = useState(-1);
  const [spattendanceData, setSpattendanceData] = useState(null);
  const [attendanceDetails, setAttendanceDetails] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const { loggedinUserId, UserRole } = useSelector((state) => state.app);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const attendanceType = [
    {
      id: 215,
      statetype: attendanceDetails?.coursesCount === 1 ? "Course" : "Courses",
      type: "courses",
      attendancePerc: attendanceDetails?.coursesValue,
    },
    {
      id: 454,
      statetype: "Adhocs",
      type: "adhocs",
      attendancePerc: attendanceDetails?.adhocValue,
    },
  ];
  const [attendanceState, setAttendanceState] = useState(attendanceType[0]?.type);
  const EUrole = () => {
    if (UserRole === SpStringConstants.endUserText) {
      return EUattendanceDataAPI();
    } else {
      return SPattendanceDataAPI();
    }
  };

  useEffect(() => {
    setLoading(true);
    EUrole();
  }, [UserRole, attendanceState]);
  const attendanceFilter = spattendanceData?.[attendanceState]?.filter(
    (each) => {
      return (each?.courseName || each?.adhocName)
        ?.toLowerCase()
        ?.includes(searchInput?.toLowerCase());
    }
  );
  const SPattendanceDataAPI = () => {
    const noOfDays = "500";
    const serviceProviderId = loggedinUserId;
    AuthService.getAttendance(
      serviceProviderId,
      noOfDays,
      onSuccess,
      onFailure
    );
  };
  const onSuccess = (response) => {
    if (response?.data?.status === "Success") {
      setAttendanceDetails(response?.data?.result);
      const data = {
        serviceProviderId: loggedinUserId,
        count: 100,
        offSet: 0,
      }
      if (attendanceState === "adhocs") {
        AuthService.getAdhocAttendanceSp(
          data,
          onSuccessTypeAttendanceEu,
          onFailure
        );
      } else {
        AuthService.getCourseAttendanceSp(
          data,
          onSuccessTypeAttendanceEu,
          onFailure
        );
      }
    } else {
      setLoading(false);
      showToast("error", response?.data?.result?.message);
    }
  };
  const EUattendanceDataAPI = () => {
    const noOfDays = "500";
    const endUserId = loggedinUserId;
    AuthService.getEndUserAttendance(
      endUserId,
      noOfDays,
      onSuccessEUA,
      onFailure
    );
  };
  const onSuccessEUA = (response) => {
    if (response?.data?.status === "Success") {
      setAttendanceDetails(response?.data?.result);
      const data = {
        endUserId: loggedinUserId,
        noOfDays: 500,
        count: 100,
        offSet: 0,
      }
      if (attendanceState === "adhocs") {
        AuthService.getAdhocAttendanceEU(
          data,
          onSuccessTypeAttendanceEu,
          onFailure
        );
      } else {
        AuthService.getCourseAttendanceEU(
          data,
          onSuccessTypeAttendanceEu,
          onFailure
        );
      }
    } else {
      setLoading(false);
      showToast("error", response?.data?.result?.message);
    }
  };
  const onSuccessTypeAttendanceEu = (response) => {
    if (response?.data?.status === "Success") {
      setSpattendanceData(response?.data?.result);
      setLoading(false);
      if (attendanceState === "adhocs") {
        setSelectedItem(response?.data?.result.adhocs?.[0])
      } else {
        setSelectedItem(response?.data?.result.courses?.[0])
      }
    } else {
      setLoading(false);
      showToast("error", response?.data?.result?.message);
    }
  }
  const onFailure = (error) => {
    setLoading(false);
    console.log(error, "error");
  };

  const onClickCard = (each) => {
    const newItem = spattendanceData?.[attendanceState]?.filter(
      (item) => item?._id === each._id
    );
    setSelectedItem(newItem[0]);
    if (mobileDevices) {
      setDialogVisible(true);
    }
    setBatchIndex("")
  };
  const DialogData = () => {
    return (
      <>{(attendanceState === "adhocs" ? !isEmpty(spattendanceData?.adhocs) : !isEmpty(spattendanceData?.courses)) ?
        <div className="SPArightdiv">
          <div className="SPAsprightdiv2">
            <img
              src={
                selectedItem?.courseImage === "" ||
                  selectedItem?.courseImage?.split(".").pop() === "HEIC"
                  ? SpStringConstants.noImage
                  : selectedItem?.courseImage ||
                  selectedItem?.adhocImage ||
                  SpStringConstants.noImage
              }
              alt="CImage"
              className="SPArightImage"
            />
            <div className="SPArightColumn">
              <div className="SPArightStart">
                <AautiText
                  title={CapitalizeFirstLetter(selectedItem?.courseName || selectedItem?.adhocName)}
                  className={"SPAblackText"}
                  weight={"bold"}
                  size={"normal"}
                />
              </div>
              <div className="SPArightChildDiv">
                {attendanceState !== "adhocs" && (
                  <AautiText
                    title={` 
                    ${selectedItem?.batches
                      ? selectedItem?.batches?.length 
                      : " 0"
                      } 
                    ${pluralOrSingular(`${selectedItem?.batches?.length}`, ' Batch',true," " )}
                    |
                    ${selectedItem?.totalSessionsCount
                      ? selectedItem?.totalSessionsCount
                      : 0
                      }
                    ${pluralOrSingular(`${selectedItem?.batches?.length}`, ' Session')}
                      | 
                    ${selectedItem?.totalCoursePercentage ||
                        selectedItem?.totalAdhocPercentage ||
                        0
                        }% ${SpStringConstants.AttendenceText}`} 
                    weight={"medium"}
                    className={"SPAcolorText"}
                    size={"normal"}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="SPAselectedDiv">
            {attendanceState === "adhocs" ? (
              <>
                <div className="SPAsessionsdivNew">
                  <AautiText
                    title={SpStringConstants.sessionsText}
                    weight={"bold"}
                    className={"SPAtextStyle"}
                    size={"normal"}
                  />
                  <AautiText
                    title={SpStringConstants.AttendenceText}
                    weight={"bold"}
                    className={"SPAtextStyle"}
                    size={"normal"}
                  />
                  {UserRole !== SpStringConstants.endUserText && (
                    <AautiText
                      title={SpStringConstants.PercentageText}
                      weight={"bold"}
                      className={"SPAblackText"}
                      size={"normal"}
                    />
                  )}
                </div>
                {selectedItem?.sessions?.map((each, index) => {
                  return (
                    <div
                      className="SPASPAsessionsdiv2"
                      style={{
                        borderBottom:
                          index + 1 === each?.length
                            ? "0px"
                            : "0.5px solid #E9E9E9",
                      }}
                    >
                      <AautiText
                        title={`${index} ${SpStringConstants.SessionText}`}
                        weight={"normal"}
                        className={"SPAtextStyle"}
                        size={"normal"}
                      />
                      <AautiText
                        title={
                          UserRole === SpStringConstants.endUserText
                            ? `${each?.attended === false
                              ? SpStringConstants.NotAttendedText
                              : SpStringConstants.AttendedText
                            }`
                            : `${each?.attendedCount} / ${each?.totalcount}`
                        }
                        weight={"normal"}
                        textStyle={{
                          width: "30%",
                          color:
                            UserRole === SpStringConstants.endUserText &&
                              each?.attended === false
                              ? "red"
                              : "black",
                        }}
                        size={"normal"}
                      />
                      {UserRole !== SpStringConstants.endUserText && (
                        <AautiText
                          title={`${each?.sessionPercentage} %`}
                          weight={"normal"}
                          className={"SPAblackText"}
                          size={"normal"}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {selectedItem?.batches?.map((each, index) => {
                  return (
                    <div key={index} className="SPAselectedDiv2">
                      <div
                        className="SPAindexDiv"
                        onClick={() =>
                          bactchIndex === index
                            ? setBatchIndex(-1)
                            : setBatchIndex(index)
                        }
                      >
                        <AautiText
                          title={each?.batchName ? each?.batchName : "N/A"}
                          size={"semi"}
                          className={"SAPbatchText"}
                        />
                        {bactchIndex === index ? (
                          <IoIosArrowDown
                            size={mobileDevices ? 16 : 20}
                            style={{ marginRight: 10 }}
                          />
                        ) : (
                          <FaChevronRight
                            size={mobileDevices ? 14 : 16}
                            style={{ marginRight: 10 }}
                          />
                        )}
                      </div>
                      {bactchIndex === index && (
                        <div className="SPAborder05">
                          <div className="SPAsessionsdiv">
                            <AautiText
                              title={SpStringConstants.sessionsText}
                              weight={"bold"}
                              className={"SPAtextStyle"}
                              size={"normal"}
                            />
                            <AautiText
                              title={SpStringConstants.AttendenceText}
                              weight={"bold"}
                              className={"SPAtextStyle"}
                              size={"normal"}
                            />
                            {UserRole !== SpStringConstants.endUserText && (
                              <AautiText
                                title={SpStringConstants.PercentageText}
                                weight={"bold"}
                                className={"SPAblackText"}
                                size={"normal"}
                              />
                            )}
                          </div>
                          <div
                            className="SPA80scroll"
                            style={{
                              height: each?.sessions?.length > 4 && "25vh",
                            }}
                          >
                            {each?.sessions?.map((item, index1) => {
                              return (
                                <div
                                  className="SPASPAsessionsdiv2"
                                  style={{
                                    borderBottom:
                                      index1 + 1 === each?.sessions?.length
                                        ? "0px"
                                        : "0.5px solid #E9E9E9",
                                  }}
                                >
                                  <AautiText
                                    title={`${index1 + 1} ${SpStringConstants.SessionText} `}
                                    weight={"normal"}
                                    className={"SPAtextStyle"}
                                    size={"normal"}
                                  />
                                  <AautiText
                                    title={
                                      UserRole === SpStringConstants.endUserText
                                        ? `${item?.attended === false
                                          ? SpStringConstants.NotAttendedText
                                          : SpStringConstants.AttendedText
                                        }`
                                        : `${item?.attendedCount} / ${item?.totalcount}`
                                    }
                                    weight={"normal"}
                                    textStyle={{
                                      width: "30%",
                                      color:
                                        UserRole ===
                                          SpStringConstants.endUserText &&
                                          item?.attended === false
                                          ? "red"
                                          : "black",
                                    }}
                                    size={"normal"}
                                  />
                                  {UserRole !== SpStringConstants.endUserText && (
                                    <AautiText
                                      title={`${item?.sessionPercentage} %`}
                                      weight={"normal"}
                                      className={"SPAblackText"}
                                      size={"normal"}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        :
        <EmptyTopic
          // message={SpStringConstants.messageText}
          extraText={SpStringConstants.extraTextText}
          Image={EmptyImage}
          topicName={SpStringConstants.topicNameText}
        />
      }
      </>
    )
  }
  return (
    <AppContainer style={{ height: "88vh" }}>
      <AccountTabs />
      {!mobileDevices && (
        <div className="SPAmainDiv2">
          <div className="SPAcenterDiv">
            <AautiText
              title={SpStringConstants.AttendenceText}
              textStyle={{ color: "#000000" }}
            />
          </div>
          {/* <div className="SPAcenterDiv">
            <AautiText
              title={SpStringConstants.SortByText}
              className={"SAPmyAautiHead"}
            />
            <FormControl variant="standard">
              <NativeSelect
                id="demo-customized-select-native"
                input={<BootstrapInput />}
              >
                <option value={10}>Recommended</option>
                <option value={20}>Best Selling</option>
                <option value={20}>Newest arrivals </option>
              </NativeSelect>
            </FormControl>
          </div> */}
        </div>
      )}
      {loading ? (
        <Box className="SPAloadingdiv1">
          <Box className="SPAloadingdiv2">
            <Skeleton variant="rounded" width={"100%"} height={40} />
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={40}
              sx={{ m: "8px 0px" }}
            />
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={mobileDevices ? 200 : 400}
            />
          </Box>
          <Box className="SPAloadingdiv3">
            <Skeleton variant="rounded" width={"100%"} height={30} />
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={40}
              sx={{ m: "8px 0px" }}
            />
            <Skeleton variant="rounded" width={"100%"} height={60} />
          </Box>
        </Box>
      ) :
        // !(
        //   isEmpty(spattendanceData?.courses) ||
        //   isEmpty(spattendanceData?.adhocs)
        // ) ? (
        <Box className="SPAboxShadowDiv">
          <Box className="SPAleftmaindiv1">
            <div className="SPASPleftmaindiv2">
              {attendanceType.map((each, index) => (
                <div
                  className="SPAleftcard45"
                  key={index}
                  onClick={() => {
                    setAttendanceState(each?.type);
                    if (each.type === "adhoc") {
                      setSelectedItem(spattendanceData?.adhocs?.[0]);
                    } else setSelectedItem(spattendanceData?.courses?.[0]);
                  }}
                  style={{
                    border:
                      each?.type === attendanceState
                        ? "1px solid #078dda"
                        : "1px solid #dedede",
                  }}
                >
                  <AautiText
                    size="medium"
                    weight={"semi"}
                    title={`${each.attendancePerc ? each?.attendancePerc?.toFixed(0) : "0"}% `}
                    textAlign={"start"}
                    textStyle={{
                      color:
                        each?.type === attendanceState ? "#078dda" : "#1e1e1e",
                    }}
                  />
                  <AautiText
                    size="medium"
                    weight={"semi"}
                    title={`${each.statetype}`}
                    textAlign={"start"}
                    textStyle={{
                      color:
                        each?.type === attendanceState ? "#078dda" : "#1e1e1e",
                    }}
                  />

                </div>
              ))}
            </div>
            <FormControl sx={{ m: 1 }} size="small" variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={"text"}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                label={SpStringConstants.SearchOrderText}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <InputLabel htmlFor="outlined-adornment-password">
                {SpStringConstants.SearchOrderText}
              </InputLabel>
            </FormControl>
            <div className="SPAscrollDiv">
              {attendanceFilter?.length === 0 ? (
                <NoData NoDataFound={SpStringConstants.topicNameText} />
              ) : (
                attendanceFilter?.map((each) => (
                  <>
                    <AttendanceCard
                      item={each}
                      onClick={onClickCard}
                      {...{ selectedItem }}
                    />
                  </>
                ))
              )}
            </div>
          </Box>
          {!mobileDevices ? (
            <>{<DialogData />}</>
          ) : (
            <AautiDialog
              open={dialogVisible}
              onClose={() => setDialogVisible(false)}
            >
              <DialogData />
            </AautiDialog>
          )}
        </Box>
        // ) : (
        //   <EmptyTopic
        //     // message={SpStringConstants.messageText}
        //     extraText={SpStringConstants.extraTextText}
        //     Image={EmptyImage}
        //     topicName={SpStringConstants.topicNameText}
        //   />
        // )
      }
    </AppContainer>
  )
};

export default SpAttendence;
