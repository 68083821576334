import { memo } from "react";
import {
  ChatInitialComponent as initialComponent,
  RenderChatScreen,
} from "./components";
import "./styles.scss";
import { chatUrl } from "../../service/api-constants";
const Chat = () => {
  const { allRenderProps } = initialComponent();

  return <RenderChatScreen {...allRenderProps} />;
};
export default memo(Chat);

const renderSocketConnection = () => {
  const loggedinUserId = localStorage.getItem("USER_ID");
  const isUserLoggedIn = localStorage.getItem("Logged In") === "true";

  const connect = () => {
    if (!isUserLoggedIn) return null; // If the user is not logged in, return null

    const socket = new WebSocket(`${chatUrl + loggedinUserId}`);

    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.event === "PING") {
        const response = { event: "PONG" };
        socket.send(JSON.stringify(response));
      }
    };

    socket.onclose = () => {
      console.log("WebSocket closed. Reconnecting...");
      setTimeout(connect, 1000);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      socket.close();
    };

    return socket;
  };

  return connect();
};
export const socket = ()=>{};
