import { useMemo, useState } from "react";
import "./index.scss";
import moment from "moment";
import AautiText from "../globalComponents/AautiText";
import ClockIcon from "../../assets/images/Clock-Icon.png";
import { Icon } from "../appCards/AdhocEventsCard";

const WeekView = ({ data }) => {
  const weekReccurences = {
    Sun: data?.sunTimeSlots,
    Mon: data?.monTimeSlots,
    Tue: data?.tueTimeSlots,
    Wed: data?.wedTimeSlots,
    Thu: data?.thuTimeSlots,
    Fri: data?.friTimeSlots,
    Sat: data?.satTimeSlots,
  };

  const [selected, setSelected] = useState("");
  const days = useMemo(() => {
    const slots = Object.keys(weekReccurences).filter(
      (each) => weekReccurences[each].length > 0
    );
    setSelected(slots[0]);
    return slots;
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="week-cont">
        {days.map((each) => {
          return (
            <button
              className={`week-day-btn ${
                each == selected ? "selected-day" : ""
              }`}
              onClick={(e) => {
                e?.stopPropagation();
                setSelected(each);
              }}
            >
              {<AautiText title={each} />}
            </button>
          );
        })}
      </div>
      {selected && (
        <div
          style={{
            gap: 12,
            marginBottom: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon icon={ClockIcon} />
          <AautiText
            textStyle={{
              color: "#5C5B57",
              maxWidth: "350px",
              textWrap: "nowrap",
              overflow: "scroll",
              maxWidth: "400px",
            }}
            title={`${weekReccurences[selected].map((each) => {
              return (
                "  " +
                moment(each.startTime).format("LT") +
                " - " +
                moment(each.endTime).format("LT")
              );
            })}`}
          />
        </div>
      )}
    </div>
  );
};

export default WeekView;
