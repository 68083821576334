const gigStrings = {
  alreadyPurchased: "Already Purchased",
  alredayAddedCart: "Already added to cart",
  errorSaveCart: "error save cart",
  unableToPlayGig: "Unable to play this gig",
  notAvailable: "N/A",
  views: "Views",
  addToCart: "Add To Cart",
  removeFromCart: "Remove From Cart",
  buyNow: "Buy Now",
  gigs: "Gigs",
  gig: "gig",
  gigCap: "Gig",
  success: "Success",
  error: "error",
  errorGettingReviews: "Error getting gig reviews",
  right: "right",
  Courses: "Courses",
  symbol1: "#",
  symbol2: ",",
  upto35Off: "Upto 35% Off",
  gigContent: "Gig Content",
  whatWillLearn: "What you will learn",
  cancelPolicy: "Cancellation Policy",
  Reviews: "Reviews",
  cancelString:
    "Cancel up to within 24 hours before the start time for a full refund.",
  reportGig: "Report Gig",
  refundPolicy: "Refund Policy",
  otherGigs: "Other Gigs By",
  viewAll: "(View All)",
  noGigsAvailable: "No Gigs Available",
  similarSubCat: "Similar Subcategories you may like",
  similarGigsByOther: "Similar Gigs by Other Service Providers",
  addLoading: "Advertisement Loading",
  gigsProvidedBy: "Gigs Provided By",
  myAauti: "My Aauti >",
  gigsPurchased: "Gigs Purchased",
  gigsOffered: "Gigs Offered",
  noDraftGigsFound: "No Draft Gigs Found",
  nogigsFound: "No Gigs Found",
  explore: "Explore",
  addGig: "Add a Gig",
  extraText:
    " consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  keepExploringText: "Keep exploring to find a Gig!",
  search: "Search",
  noGigsFoundWithSearch: "No Gigs Found with your Search ",
  addReview: "Add a Review",
  gigReviews: "Gig Reviews",
};

export default gigStrings;
