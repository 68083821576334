import ProfileMenu from "./ProfileMenu";
import { BsCartCheck } from "react-icons/bs";
import { NotificationsNone, SearchRounded } from "@mui/icons-material";
// import Popover from "@mui/material/Popover";

import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Popover,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import AautiText from "../globalComponents/AautiText";
import { HeaderStrings } from "./constants";
import "./Style.scss";
import Notifications from "../home/notifications/index";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setReloadCart } from "../../redux/reducer/appReducer";
import { socket } from "../chat";
import {
  HeaderCartIcon,
  HeaderChatIcon,
  HeaderChatInActive,
  HeaderNotificationsIcon,
  HeaderNotificationsInActive,
  HeaderSettingsIcon,
} from "../homeScreen/header/pageHeader/pageHeaderUtils";
import { IoSettingsOutline } from "react-icons/io5";
import { CapitalizeFirstLetter } from "../CommonFunctions";
import { getUnreadCount, setActiveRoom } from "../../redux/reducer/chatSlice";
import { useLocation } from "react-router-dom";

const HeaderTabsLogin = ({
  handleMenuClose,
  menuOpen,
  userDetails,
  FirstName,
  userRole,
  handleUserRole,
  UserRole,
  navigation,
  logoutFunction,
  mobileDevices,
  handleCloseNot,
  anchorElNot,
  id,
  openNot,
  handleClickNot,
  unreadCount,
  loadingCountNot,
  handleMenuClick,
  countryObject,
  setToggleCurrency,
  setAnchorElNot,
  cartCount,
  handleOpenConfirmation,
  profilePercentage,
  dispatch,
  relaodCart,
  pathname,
  eventsResponsiveHandle,
}) => {
  const chatCount = useSelector(getUnreadCount)
  const { hasEvents } = useSelector((state) => state.app);

  const location = useLocation()

  const onClickChat = ()=>{
    if(!(location.pathname === '/chat')){
      navigation("/chat");
      dispatch(setActiveRoom({data:undefined}))
    }
  }

  return (
    <div className="HeaderTabLogin-div1">
      {hasEvents && eventsResponsiveHandle()}
      <div
        className="HeaderTabLogin-div1"
        style={{
          gap: mobileDevices ? 4 : 6,
        }}
      >
        <IconButton
          onClick={() => {
            // navigation("/cart");
            if (pathname !== "/cart") {
              navigation("/cart");
            } else {
              dispatch(setReloadCart(!relaodCart));
            }
          }}
          style={{ marginLeft: mobileDevices ? 0 : -4 }}
        >
          {cartCount > 0 ? (
            <Badge
              color="primary"
              overlap="circular"
              showZero
              badgeContent={
                loadingCountNot ? (
                  <CircularProgress size={10} color="inherit" />
                ) : (
                  cartCount || 0
                )
              }
              aria-describedby={id}
              variant="contained"
              sx={{ cursor: "pointer" }}
              max={99}
            >
              <HeaderCartIcon />
            </Badge>
          ) : (
            <HeaderCartIcon />
          )}
        </IconButton>
        <IconButton
          sx={{ display: mobileDevices && "none" }}
          onClick={onClickChat}
          style={{ marginLeft: 2 }}
        >
          {chatCount ? <HeaderChatIcon /> : <HeaderChatInActive />}
        </IconButton>

        {/* <Badge
          color="warning"
          overlap="circular"
          showZero={unreadCount > 0}
          badgeContent={
            loadingCountNot ? (
              <CircularProgress size={10} color="inherit" />
            ) : (
              unreadCount
            )
          }
          aria-describedby={id}
          variant="contained"
          onClick={handleClickNot}
          sx={{ cursor: "pointer" }}
          max={99}
        >
          <NotificationsNone />
        </Badge> */}
        <IconButton onClick={handleClickNot} style={{ marginLeft: -2 }}>
          {unreadCount ? (
            <HeaderNotificationsIcon />
          ) : (
            <HeaderNotificationsInActive />
          )}
        </IconButton>
        <IconButton
          sx={{ display: mobileDevices && "none" }}
          onClick={() => {
            navigation("/account-settings");
          }}
        >
          <HeaderSettingsIcon />
        </IconButton>
        {!mobileDevices && (
          <Popover
            sx={{
              p: 1,
              "& .MuiPopover-paper": {
                p: 2,
                width: "420px",
                maxWidth: "600px",
              },
            }}
            id={id}
            open={openNot}
            anchorEl={anchorElNot}
            onClose={handleCloseNot}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Notifications
              handleCloseNot={handleCloseNot}
              setAnchorElNot={setAnchorElNot}
              anchorElNot={anchorElNot}
              unreadCount={unreadCount}
            />
          </Popover>
        )}
        {mobileDevices && (
          <Drawer
            anchor={"right"}
            open={openNot}
            onClose={handleCloseNot}
            sx={{ width: "100%" }}
            id="NotDrawer"
          >
            <Notifications
              handleCloseNot={handleCloseNot}
              setAnchorElNot={setAnchorElNot}
              anchorElNot={anchorElNot}
              unreadCount={unreadCount}
            />
          </Drawer>
        )}
      </div>
      <div className="HeaderTabLogin-div2">
        {!mobileDevices && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ position: "relative" }}
              onClick={() => navigation("/profile")}
            >
              <Avatar
                sx={{ width: 30, height: 30, marginRight: 0.5 }}
                src={userDetails?.profileImage}
              />
              <CircularProgress
                variant="determinate"
                value={profilePercentage}
                size={42}
                sx={{
                  color: "#066cb5",
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            </Box>
          </Box>
        )}
        <Tooltip title="Account settings" id="Profile-name">
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={handleMenuClick}
            size="small"
            aria-controls={menuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
          >
            {mobileDevices && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    sx={{ width: 30, height: 30, marginRight: 0.5 }}
                    src={userDetails?.profileImage}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={profilePercentage}
                    size={42}
                    sx={{
                      color: "#066cb5",
                      position: "absolute",
                      top: -6,
                      left: -6,
                      zIndex: 1,
                    }}
                  />
                </Box>
              </Box>
            )}
            {!mobileDevices && (
              <div>
                <AautiText
                  title={
                    CapitalizeFirstLetter(
                      userDetails?.displayName || FirstName
                    ) || HeaderStrings?.user
                  }
                  className={"HeaderTabLogin-text2"}
                />
                <AautiText
                  className={"HeaderTabLogin-text3"}
                  size={"small"}
                  title={`(${
                    userDetails?.role?.includes(HeaderStrings?.instituteAdmin)
                      ? HeaderStrings?.instituteAdminCap
                      : UserRole?.includes(HeaderStrings?.serviceProvide2)
                      ? HeaderStrings?.serviceProvider
                      : HeaderStrings?.endUser
                  })`}
                  textAlign={"center"}
                />
              </div>
            )}
          </IconButton>
        </Tooltip>
      </div>

      {/* <Popover
        id={}
        open={false}
        // anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
      </Popover> */}

      <ProfileMenu
        {...{
          handleMenuClose,
          menuOpen,
          userDetails,
          FirstName,
          userRole,
          handleUserRole,
          UserRole,
          navigation,
          logoutFunction,
          handleOpenConfirmation,
          dispatch,
          mobileDevices,
          countryObject,
          setToggleCurrency,
          chatCount,
        }}
      />
    </div>
  );
};

export default HeaderTabsLogin;
