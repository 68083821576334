import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { AuthService } from "./Service";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";
import { FaUserAlt } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import AautiText from "../globalComponents/AautiText";
import { chatbotConstants } from "./chatbotConstants";

function Chatbot({ setOpenBot, openBot }) {
  const { loggedinUserId, UserRole } = useSelector((state) => state.app);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const chatEndRef = useRef(null);
  const navigate = useNavigate();

  const handleFetchCourses = () => {
    setLoading(true);
    setError(null);
    AuthService.getMyCoursesByUserid(
      loggedinUserId,
      (data) => {
        const courses =
          data?.result?.map((course) => ({
            name: course.courseName,
            id: course._id,
            type: "course",
          })) || [];
        if (courses.length === 0) {
          addMessage(
            chatbotConstants?.noCoursesAvailable,
            false,
            [],
            null,
            "pastelRed"
          );
        } else {
          courses.forEach((course) =>
            addMessage(
              course.name,
              false,
              [],
              course.id,
              "pastelGreen",
              "course"
            )
          );
        }
        setLoading(false);
        addMessage(
          chatbotConstants?.wouldYouLikeToContinueOrEndTheConversation,
          false,
          ["Continue", "End"]
        );
      },
      () => {
        setError(chatbotConstants?.failedToFetchCourses);
        setLoading(false);
      }
    );
  };

  const handleFetchGigs = () => {
    setLoading(true);
    setError(null);

    if (UserRole === "serviceprovider") {
      AuthService.getMyGigsForServiceProvider(
        loggedinUserId,
        null,
        (data) => {
          const gigs =
            data?.result?.map((gig) => ({
              name: gig.gigName,
              id: gig._id,
              type: "gig",
            })) || [];
          if (gigs.length === 0) {
            addMessage(
              chatbotConstants?.noGigsAvailable,
              false,
              [],
              null,
              "pastelRed"
            );
          } else {
            gigs.forEach((gig) =>
              addMessage(gig.name, false, [], gig.id, "pastelGreen", "gig")
            );
          }
          setLoading(false);
          addMessage(
            chatbotConstants?.wouldYouLikeToContinueOrEndTheConversation,
            false,
            [chatbotConstants?.continue, chatbotConstants?.end]
          );
        },
        () => {
          setError(chatbotConstants?.failedToFetchGigs);
          setLoading(false);
        }
      );
    } else if (UserRole === "endUser") {
      AuthService.getMyGigsForEndUser(
        loggedinUserId,
        (data) => {
          const gigs =
            data?.result?.map((gig) => ({
              name: gig.gigName,
              id: gig._id,
              type: "gig",
            })) || [];
          if (gigs.length === 0) {
            addMessage(
              chatbotConstants?.noGigsAvailable,
              false,
              [],
              null,
              "pastelRed"
            );
          } else {
            gigs.forEach((gig) =>
              addMessage(gig.name, false, [], gig.id, "pastelGreen", "gig")
            );
          }
          setLoading(false);
          addMessage(
            chatbotConstants?.wouldYouLikeToContinueOrEndTheConversation,
            false,
            [chatbotConstants?.continue, chatbotConstants?.end]
          );
        },
        () => {
          setError(chatbotConstants?.failedToFetchGigs);
          setLoading(false);
        }
      );
    }
  };

  const handleFetchLearnings = () => {
    setLoading(true);
    setError(null);
    AuthService.getAllSubscriptionsByUserID(
      loggedinUserId,
      (data) => {
        const learnings =
          data?.result?.map((learning) => ({
            name: learning.courseName,
            id: learning.courseId,
            type: "learning",
          })) || [];
        if (learnings.length === 0) {
          addMessage(
            chatbotConstants?.noLearningsAvailable,
            false,
            [],
            null,
            chatbotConstants?.pastelRed
          );
        } else {
          learnings.forEach((learning) =>
            addMessage(
              learning.name,
              false,
              [],
              learning.id,
              "pastelGreen",
              "learning"
            )
          );
        }
        setLoading(false);
        addMessage(
          chatbotConstants?.wouldYouLikeToContinueOrEndTheConversation,
          false,
          [chatbotConstants?.continue, chatbotConstants?.end]
        );
      },
      () => {
        setError(chatbotConstants?.failedToFetchLearnings);
        setLoading(false);
      }
    );
  };

  const addMessage = (
    text,
    isUser = false,
    opts = [],
    itemId = null,
    color = null,
    itemType = null
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, isUser, options: opts, itemId, color, itemType },
    ]);
  };

  const handleOptionClick = (option) => {
    if (UserRole === "serviceprovider") {
      if (option === "My Courses" || option === "My Gigs") {
        addMessage(option, true);
        if (option === "My Courses") {
          handleFetchCourses();
        } else if (option === "My Gigs") {
          handleFetchGigs();
        }
      }
    } else if (UserRole === "endUser") {
      if (
        option === "My Learnings" ||
        option === "My Gigs" ||
        option === "Refund Status"
      ) {
        addMessage(option, true);
        if (option === "My Learnings") {
          handleFetchLearnings();
        } else if (option === "My Gigs") {
          handleFetchGigs();
        } else {
          addMessage(`${option} Refund status empty`, false);
          addMessage(
            chatbotConstants?.wouldYouLikeToContinueOrEndTheConversation,
            false,
            [chatbotConstants?.continue, chatbotConstants?.end]
          );
        }
      }
    }

    if (option === "Continue") {
      showInitialOptions();
    } else if (option === "End") {
      addMessage(chatbotConstants?.thankYouTheChatWillNowRestart, false);
      setTimeout(() => {
        setMessages([]);
        showInitialOptions();
      }, 2000);
    } else {
      const selectedItem = messages.find((msg) => msg.itemId === option);
      if (selectedItem && selectedItem.itemType === "course") {
        navigate(`/course-details/${option}`);
      } else if (selectedItem && selectedItem.itemType === "gig") {
        navigate(`/gig-preview/${option}`);
      } else if (selectedItem && selectedItem.itemType === "learning") {
        navigate(`/course-details/${option}`);
      }
    }
  };

  const showInitialOptions = () => {
    if (UserRole === "serviceprovider") {
      addMessage(
        chatbotConstants?.howCanIHelpYouPleaseSelectTheBelowOptions,
        false,
        ["My Courses", "My Gigs"]
      );
    } else if (UserRole === "endUser") {
      addMessage(
        chatbotConstants?.howCanIHelpYouPleaseSelectTheBelowOptions,
        false,
        [
          chatbotConstants?.myLearnings,
          chatbotConstants?.myGigs,
          chatbotConstants?.refundStatus,
        ]
      );
    }
  };

  useEffect(() => {
    showInitialOptions();
  }, []);

  useEffect(() => {
    setMessages([]);
    showInitialOptions();
  }, [UserRole]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ borderRadius: 5 }}
    >
      <Grid item xs={12} sm={8} md={6}>
        <Paper
          elevation={3}
          style={{
            height: "70vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            padding="10px"
            style={{
              borderBottom: "1px solid #ddd",
              position: "sticky",
              top: "0",
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "inset 0 0 10px 1px #E6EBFF",
                marginRight: "10px",
              }}
            >
              <FaUserAlt style={{ color: "#3363ff99", fontSize: "20px" }} />
            </Avatar>
            <AautiText
              title={chatbotConstants?.support}
              textStyle={{
                fontSize: "20px",
                color: "#404040",
                alignItems: "center",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            />
            <IconButton
              style={{ marginLeft: "auto" }}
              onClick={() => setOpenBot(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <List style={{ flexGrow: 1 }}>
            {messages.map((msg, index) => (
              <React.Fragment key={index}>
                <ListItem
                  style={{
                    justifyContent: msg.isUser ? "flex-end" : "flex-start",
                    cursor: msg.itemId ? "pointer" : "default",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: msg.isUser ? "flex-end" : "flex-start",
                  }}
                  onClick={() => msg.itemId && handleOptionClick(msg.itemId)}
                >
                  <Paper
                    style={{
                      padding: "10px",
                      backgroundColor:
                        msg.color === "pastelRed"
                          ? "#ffd1d1"
                          : msg.color === "pastelGreen"
                          ? "#d1ffd1"
                          : msg.isUser
                          ? "#e0e0e0"
                          : "#f5f5f5",
                      maxWidth: "80%",
                      boxShadow: "none",
                      cursor: msg.itemId ? "pointer" : "default",
                    }}
                  >
                    <ListItemText primary={msg.text} />
                  </Paper>
                  {msg.options?.length > 0 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={msg.isUser ? "flex-end" : "flex-start"}
                      style={{ width: "100%" }}
                    >
                      {msg.options.map((option, idx) => (
                        <Button
                          key={idx}
                          variant="contained"
                          color="primary"
                          onClick={() => handleOptionClick(option)}
                          style={{ margin: "5px 0", width: "fit-content" }}
                        >
                          {option}
                        </Button>
                      ))}
                    </Box>
                  )}
                </ListItem>
              </React.Fragment>
            ))}
            <div ref={chatEndRef} />
          </List>
          {loading && <CircularProgress />}
          {error && <Alert severity="error">{error}</Alert>}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Chatbot;
