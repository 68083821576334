import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import JoinButton from "../../globalComponents/JoinButton";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "./Service";
import { PackageStrings } from "../Constants";
import { showToast } from "../../globalComponents/Toast";
import { useSelector } from "react-redux";
import AautiDialog from "../../globalComponents/AautiDialog";
import RequestRefunds from "../../ordersScreen/refunds/RequestRefunds";
import { PriceConversions } from "../../CommonFunctions";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import IndividualIcon from "../../../assets/images/individual.svg";
import GroupIcon from "../../../assets/images/group.svg";
import "./Styles.scss";
import { isEmpty } from "lodash";
import { sassTrue } from "sass";
import { Skeleton } from "@mui/material";

const ReschedulePage = () => {
  const { state } = useLocation();
  const { courseId, batchId, notificationId } = state;
  const [selectedData, setSelectedData] = useState("");
  const [selectedOptionRefund, setselectedOptionRefund] =
    useState("otherBatches");
  const [cartData, setCartData] = useState(null);
  const [scheduleSkeleton, setScheduleSkeleton] = useState(false)
  const [typeState, setTypeState] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("credits");
  const [refundData, setRefundData] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [details, setDetails] = useState("");
  const { convertPriceWithCurrency } = PriceConversions();
  const navigate = useNavigate();
  const { loggedinUserId } = useSelector((state) => state.app);
  const selectedBatch =
    selectedData?.type === "individualDaily" ||
      selectedData?.type === "individualWeekly"
      ? "individual"
      : "group";
  const selectedRejectedBatch = selectedData?.batchId || selectedData?._id;
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (event, type) => {
    if (type === "details") {
      setDetails(event);
    } else {
      setRefundReason(event.target.value);
    }
  };
  const handleOptionChangeBatch = (event) => {
    setselectedOptionRefund(event.target.value);
  };
  useEffect(() => {
    batchDetailsApiCall();
  }, []);
  const batchDetailsApiCall = () => {
    setScheduleSkeleton(true)
    const data = { courseId: courseId, batchId: batchId };
    AuthService.getBatchDetailsByBatchId(data, onSuccessReschedule, onFailure);
  };
  console.log(selectedData, "selectedData")
  const onSuccessCourseData = (response) => {
    if (response.status === PackageStrings?.success) {
      setScheduleSkeleton(false)
      setCartData(response?.result);
      setSelectedData(response?.result?.[typeState]?.[0]);
    }
  };
  const onSuccessReschedule = (response) => {
    if (response.status === PackageStrings?.success) {
      setTypeState(response?.result[0]?.type);
      const data = {
        courseId: courseId,
        batchId: batchId,
        type: response?.result[0]?.type,
        price:
          response?.result[0]?.type === "groupDaily" ||
            response?.result[0]?.type === "groupWeekly"
            ? response?.result[0]?.localGroupPrice
            : response?.result[0]?.localIndividualPrice,
      };
      AuthService.getBatchesByCourseId(data, onSuccessCourseData, onFailure);
    } else {
      showToast(PackageStrings?.error, response.message);
    }
  };
  const onFailure = (error) => {
    showToast(PackageStrings?.error, error.message);
    setScheduleSkeleton(false)
  };
  const formatDate = (isoDate) => {
    const dateObject = new Date(isoDate);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const onPressReject = () => {
    if (selectedOptionRefund === "otherBatches") {
      const status = "batchChange";
      const data = {
        status,
        selectedRejectedBatch,
      };
      onPressRejectSubmit(data);
    } else {
      getRescheduleRefundAmountAPI();
      setOpen(true);
    }
  };
  const onPressRejectSubmit = () => {
    const apiData = {
      courseId: courseId,
      toId: selectedRejectedBatch,
      fromId: batchId,
      endUserId: loggedinUserId,
      batchType: selectedBatch,
    };
    const rejectData = {
      courseId,
      batchId,
      endUserId: loggedinUserId,
      notificationId,
      status: "accepeted",
    };
    AuthService.batchReschedule(
      rejectData,
      () => onSuccessBatch(apiData),
      onFailure
    );
  };
  const onSuccessBatch = (res) => {
    AuthService.changeBatchInReject(res, onSuccessStatus, onFailure);
  };
  const onSuccessStatus = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(response.message);
      navigate(`/`);
    } else {
      showToast(response.message);
    }
  };
  const getRescheduleRefundAmountAPI = () => {
    const data = {
      courseId: courseId,
      batchId: batchId,
      endUserId: loggedinUserId,
    };
    AuthService.getRescheduleRefundAmount(data, onSuccessData, onFailure);
  };
  const onSuccessData = (response) => {
    setRefundData(response?.result);
  };
  const onSubmitRefund = (data) => {
    const apiData = {
      courseId: courseId,
      batchId: batchId,
      endUserId: loggedinUserId,
      notificationId,
      status: "rejected",
    };
    AuthService.batchReschedule(apiData, onSuccessStatus, onFailure);
  };
  const refundRequestApi = () => {
    const data = {
      orderId: refundData?._id,
      reason: refundReason,
      orderDate: refundData?.orderDate,
      endUserId: loggedinUserId,
      description: details,
      currencyCode: refundData?.currencyCode,
      currencySymbol: refundData?.currencySymbol,
      serviceProviderId: refundData?.serviceProviderId,
      purchasedPrice: refundData?.purchasedPrice,
      localPurchasedPrice: refundData?.localPurchasedPrice,
      refundMethod:
        selectedOption !== "credits" ? refundData?.paymentMethod : "credits",
      parentId: refundData?.parentId || "",
      refundAmount:
        selectedOption !== "credit"
          ? refundData?.refundAmount
          : convertPriceWithCurrency(refundData?.refundAmount),
      courseId: refundData?.courseId,
      batchId: refundData?.batchId,
    };
    AuthService.refundRequest(data, onSuccessRefund, onFailure);
  };
  const onSuccessRefund = (response) => {
    if (response?.data.status === "Success") {
      showToast("success", response?.data?.message);
      onSubmitRefund();
    } else {
      showToast("error", response?.data?.message);
    }
  };
  const formattedDiscount = (each) => {
    return each?.discountType === "Percentage"
      ? `${each?.localGroupDiscountValue ||
      each?.localIndividualDiscountValue ||
      0
      }%`
      : `${convertPriceWithCurrency(each?.localGroupDiscountValue) ||
      convertPriceWithCurrency(each?.localIndividualDiscountValue) ||
      0
      }`;
  };
  function convertToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    // Convert to local time in 12-hour format with AM/PM
    const localTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return localTime;
  }

  return (
    <div className="rsmainDiv">
      <div className="rsmaindiv2">
        <AautiText title={PackageStrings?.RescheduleRefund} weight={"bold"} />
        <div className="rsradio1">
          {scheduleSkeleton ? <div style={{ display: "flex", flexDirection: "column", margin: "10px 0px" }}>
            <Skeleton variant="text"
              sx={{ width: 300, height: 40 }} />
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "10px 0px", gap: 10 }}>
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} variant="rounded"
                  sx={{ width: 200, height: 80, }} />
              ))}
            </div>
            <Skeleton variant="text" sx={{ width: 300, height: 40 }}
            />
          </div> :
            <>  {cartData?.[typeState]?.length > 0 && (
              <>
                <div className="rsmarginB">
                  <input
                    type="radio"
                    id="otherBatches"
                    name="option"
                    value="otherBatches"
                    checked={selectedOptionRefund === "otherBatches"}
                    className="rsradiocursor"
                    onChange={handleOptionChangeBatch}
                  />
                  <label
                    className="rscursor"
                    onChange={handleOptionChangeBatch}
                    htmlFor="otherBatches"
                  >
                    Other Batches Available
                  </label>
                </div>
                {selectedOptionRefund === "otherBatches" && (
                  <div className="rsmapdiv">
                    {cartData?.[typeState]?.map((each, index) => (
                      <div
                        onClick={() => setSelectedData(each)}
                        key={index}
                        className="rschilddiv"
                        style={{
                          border:
                            selectedData?._id === each?._id
                              ? "2px solid #078dda"
                              : "1px solid #5C5B5733",
                        }}
                      >
                        <div className="rschilddiv2">
                          <AautiText
                            title={each?.batchName}
                            size={"semi"}
                            weight={"bold"}
                            className={"rstext90"}
                          />
                        </div>
                        <div className="rsicondiv">
                          <div className="rsicondiv2">
                            <CalendarMonthIcon sx={{ fontSize: 15, mr: 1.2 }} />
                            <AautiText
                              title={
                                each?.type === "individualDaily" ||
                                  each?.type === "groupDaily" ||
                                  typeState === "groupDaily" ||
                                  typeState === "individualDaily"
                                  ? "Daily"
                                  : "Weekly"
                              }
                              size={"semi"}
                              className={"rscolor"}
                            />
                          </div>
                          <div className="rsicondiv2">
                            <EditCalendarOutlinedIcon
                              sx={{ fontSize: 15, mr: 1.2 }}
                            />
                            <AautiText
                              title={
                                formatDate(each?.reScheduleStartDate) +
                                "-" +
                                formatDate(each?.reScheduleEndDate)
                              }
                              size={"semi"}
                            />
                          </div>
                          <div className="rsicondiv2">
                            <ScheduleOutlinedIcon
                              sx={{ fontSize: 15, mr: 1.2 }}
                            />
                            <AautiText
                              title={
                                convertToLocalTime(each?.reScheduleStartTime) +
                                "-" +
                                convertToLocalTime(each?.reScheduleEndTime)
                              }
                              size={"semi"}
                            />
                          </div>
                          {(each?.individualDiscountPrice ||
                            each?.individualPrice) && (
                              <div className="rsgroupDiv">
                                <img
                                  src={IndividualIcon}
                                  alt="individual"
                                  className="rsimagestyle"
                                />
                                <AautiText
                                  size={"semi"}
                                  weight={"medium"}
                                  className={"rstextline"}
                                  textStyle={{
                                    textDecoration:
                                      !isEmpty(each?.individualDiscountPrice) &&
                                      "line-through",
                                  }}
                                  title={`${convertPriceWithCurrency(
                                    each?.individualPrice
                                  )}`}
                                />
                                <AautiText
                                  title={"(" + formattedDiscount(each) + ")"}
                                  size={"semi"}
                                  textStyle={{
                                    display:
                                      isEmpty(each?.individualDiscountPrice) &&
                                      "none",
                                  }}
                                  className={"rstextmargin"}
                                />
                                <AautiText
                                  size={"semi"}
                                  weight={"bold"}
                                  className={"rscolor"}
                                  textStyle={{
                                    display:
                                      isEmpty(each?.individualDiscountPrice) &&
                                      "none",
                                  }}
                                  title={`${convertPriceWithCurrency(
                                    each?.individualDiscountPrice
                                  )}`}
                                />
                              </div>
                            )}
                          {(each?.groupDiscountPrice || each?.groupPrice) && (
                            <div className="rsgroupDiv">
                              <img
                                src={GroupIcon}
                                alt="individual"
                                className="rsimagestyle"
                              />
                              <AautiText
                                size={"semi"}
                                weight={"medium"}
                                className={"rstextline"}
                                textStyle={{
                                  textDecoration: isEmpty(
                                    each?.groupDiscountPrice
                                  )
                                    ? ""
                                    : "line-through",
                                }}
                                title={`${convertPriceWithCurrency(
                                  each?.groupPrice
                                )}`}
                              />
                              <AautiText
                                title={"(" + formattedDiscount(each) + ")"}
                                size={"semi"}
                                textStyle={{
                                  display:
                                    isEmpty(each?.groupDiscountPrice) && "none",
                                }}
                                className={"rstextmargin"}
                              />
                              <AautiText
                                size={"semi"}
                                weight={"bold"}
                                className={"rscolor"}
                                title={`${convertPriceWithCurrency(
                                  each?.groupDiscountPrice
                                )}`}
                                textStyle={{
                                  display:
                                    isEmpty(each?.groupDiscountPrice) && "none",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
              <div className={cartData?.[typeState]?.length > 0 && "rsmarginB2"}>
                <input
                  type="radio"
                  id="requestRefund"
                  name="option"
                  value="requestRefund"
                  checked={selectedOptionRefund === "requestRefund"}
                  className="rsradiocursor"
                  onChange={handleOptionChangeBatch}
                />
                <label
                  className="rscursor"
                  onChange={handleOptionChangeBatch}
                  htmlFor="requestRefund"
                >
                  Request for a refund
                </label>
              </div>   </>
          }
        </div>
        {selectedOptionRefund && (
          <div className="rsJoinDiv">
            <JoinButton
              title={"Cancel"}
              onClick={() => {
                setSelectedData("");
                navigate("/");
              }}
              outlined
              buttonStyle={{
                width: "100px",
                marginRight: 15,
                border: "1px solid #078dda",
                color: "#078dda",
                Padding: "2px 0px",
                height: "auto",
              }}
            />
            <JoinButton
              disabled={selectedOptionRefund === "otherBatches" && !selectedData}
              onClick={() => {
                onPressReject();
              }}
              title={"Submit"}
              buttonStyle={{
                width: "100px",
                Padding: "2px 0px",
                height: "auto",
              }}
            />
          </div>
        )}
      </div>
      <AautiDialog open={open} onClose={() => setOpen(false)}>
        <RequestRefunds
          {...{
            handleOptionChange,
            selectedOption,
            refundData,
            setRefundReason,
            refundRequestApi,
            refundReason,
            handleChange,
            details,
          }}
        />
      </AautiDialog>
    </div>
  );
};
export default ReschedulePage;
