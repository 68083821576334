import React from "react";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";

const NavButton = ({ disabled = false, text, handleClick, testId }) => {
  return (
    <div className="flex-container buttons-cont">
      <button
        id={testId || text}
        disabled={disabled}
        className="save-button"
        style={{ background: disabled && "#d2d2d2" }}
        onClick={handleClick}
      >
        <AautiText title={text} weight={"medium"} />
      </button>
    </div>
  );
};

export default NavButton;
