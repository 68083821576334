import { Route, Routes } from "react-router-dom";
import ReviewsAndRatings from "../components/ratingReviews";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute.js";
import TransferRequests from "../components/transferRequests/TransferRequests.js";
import SpAttendence from "../components/instructor/spAttendence/index.js";
import React, { Suspense, lazy } from "react";
import Spinner from "../components/globalComponents/Spinner.js";
import MyLearnings from "../components/course/myCourses/MyLearnings.js";
// import InstituteWebDesign from "../components/signIn/InstituteWebDesign.js";
import CourseCategories from "../components/courseCategories/index.js";
import InstituteProfile from "../components/signIn/InstituteProfile.js";
import PartTypeComponent from "../components/partTypeComponent/index.js";
import PartTypeViewComponent from "../components/partTypeViewComponent/index.js";
import InstituteInfo from "../components/institution/intituteInformation/instituteInfo.js";
import AcccountManagement from "../components/accountManagement/AcccountManagement.js";
import Feedback from "../components/feedback/index.js";
import CourseCreation from "../components/course/courseCreation/index.js";
import EnrolledCourses from "../components/members/EnrolledCourses.js";
import Refunds from "../components/ordersScreen/refunds/index.js";
import Reschedule from "../components/course/reschedule/index.js";
import RescheduleList from "../components/course/reschedule/RescheduleList.js";
import ReschedulePage from "../components/home/notifications/reschedulePage.js";
import CourseHistory from "../components/course/myCourses/CourseHistory.js";
import Chatbot from "../components/chatbot/index.js";
import PayoutRequest from "../components/payoutRequests/index.js";
import HomePageNew from "../components/homeScreen/index.js";
import TeachWithAauti from "../components/homeScreen/teachWithAauti/";
import Institutions from "../components/homeScreen/institutions/index.js";
import DeleteOrDeactivate from "../components/deleteOrDeactivate/index.js";
import MoreResults from "../components/adhoc/matchMySchedule/MoreResults.js";
import CourseAdhocEvent from "../components/course/courseAdhoc/index.js";
import CoursesByCategory from "../components/course/CoursesByCategory.js";
import MoreServiceProviders from "../components/homeScreen/serviceprovidersSection/MoreServiceProviders.js";
import PublicRoute from "./PublicRoute.js";
import RefundConfiguration from "../components/refundConfiguration/Component";
import MoreListing from "../components/homeScreen/whatOthersTalkAboutUs/MoreListing.js";

const SignInScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignIn };
});

const SignInOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignInOtpScreen };
});

const RegisterScreenServiceProvider = lazy(async () => {
  const module = await import("../components/homeScreen/authentication/signUp");
  return { default: module.SignUpServiceProvider };
});

const ForgotPasswordScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ForgotPassword };
});

const SelectUserTypeScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SelectUserType };
});

const SignUpOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignUpOtpScreen };
});

const SignUpEndUser = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignUpEndUser };
});

const ForgotPasswordOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ForgotPasswordOtpScreen };
});

const ResetPassword = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ResetPassword };
});

const ChangePassword = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ChangePassword };
});

const SocialSignInScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SocialSignInScreen };
});

const SelectProfession = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectProfession };
});

const SelectSubCategories = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectSubCategories };
});

const SelectIntrests = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectIntrests };
});
const InstituteSignup = lazy(() =>
  import("../components/signIn/InstituteSignup.js")
);

// const RoleSelection = lazy(() =>
//   import("../components/signIn/RoleSelection.js")
// );
const InstitutionDashboard = lazy(() =>
  import("../components/institution/dashboard/Dashboard.js")
);
const ParticipantsDashboard = lazy(() =>
  import("../components/participants/Dashboard.js")
);
const Home = lazy(() => import("../components/home/Home.js"));
const HomePage = lazy(() => import("../components/homePage/index.js"));
const SimilarPackages = lazy(() =>
  import("../components/home/package/SimilarPackages.js")
);
const InstitutionsHome = lazy(() =>
  import("../components/bpInstitute/index.js")
);
const JitsiVideoCall = lazy(() =>
  import("../components/calendar/join_class/Jitsi/index.js")
);
const AboutAauti = lazy(() =>
  import("../components/globalComponents/CommingSoon.js")
);
const SKPVersion = lazy(() => import("../components/skpVersion/SKPVersion.js"));
const AllCategories = lazy(() =>
  import("../components/home/categories/index.js")
);
const CalendarComponent = lazy(() => import("../components/calendar/index"));
const JoinClassComponent = lazy(() =>
  import("../components/calendar/join_class/index")
);
const JitsiComponent = lazy(() =>
  import("../components/calendar/join_class/Jitsi/index.js")
);
const JitsiPipMode = lazy(() => import("../jitsiContainer/index.js"));
const SignIn = lazy(() => import("../components/signIn/SignIn.js"));
const MoreTopProvidersCards = lazy(() =>
  import("../components/home/topProviders/MoreTopProviders.js")
);
const Cart = lazy(() => import("../components/cart/Cart.js"));
const NotFound = lazy(() => import("../components/notFound/NotFound.js"));
const SearchResult = lazy(() =>
  import("../components/adhoc/matchMySchedule/SearchResult.js")
);
const CourseDetails = lazy(() =>
  import("../components/course/courseDetails/index.js")
);
const Interests = lazy(() => import("../components/interests/index.js"));
const SPdetailsScreen = lazy(() =>
  import("../components/serviceProviderDetails/index.js")
);
const CreateLessonPlan = lazy(() =>
  import("../components/createLessonPlan/index.js")
);
const FavorateServiceProviders = lazy(() =>
  import("../components/home/topProviders/FavoriteServiceProviders.js")
);
const OfftimeSchedule = lazy(() =>
  import("../components/adhoc/matchMySchedule/offtimeSchedule/index.js")
);
const Profile = lazy(() => import("../components/profile/Profile.js"));
const AllGigs = lazy(() => import("../components/gig/AllGigs.js"));
const MoreBlockDeals = lazy(() =>
  import("../components/home/blockbuster/MoreBlockDeals.js")
);
const MorePackages = lazy(() =>
  import("../components/home/package/MorePackages.js")
);
const NewSessionCardView = lazy(() =>
  import("../components/home/upcomming/NewSessionCardView.js")
);
const WishList = lazy(() => import("../components/home/wishlist/index.js"));
const Chat = lazy(() => import("../components/chat/Chat.js"));
const MyCourses = lazy(() => import("../components/course/myCourses/index.js"));
const SPrevenue = lazy(() => import("../components/revenueSP/index.js"));
const MyLessonPlans = lazy(() => import("../components/lessonPlan/index.js"));

// import RescheduleAndMerge from "../components/rescheduleAndMerge/index.js";
const MyOrders = lazy(() => import("../components/ordersScreen/index.js"));
const AddPackage = lazy(() =>
  import("../components/home/package/AddPackage.js")
);

// const SubCategoriesListTab = lazy(() =>
//   import("../components/home/categories/SubCategories.js")
// );
const RefundRequest = lazy(() =>
  import("../components/refund/RefundRequest.js")
);
const CommingSoon = lazy(() =>
  import("../components/globalComponents/CommingSoon.js")
);

// const SelectProfession = lazy(() =>
//   import("../components/home/categories/Select_Profession/selectProfession.js")
// );
const SubGigs = lazy(() => import("../components/gig/SubGigs.js"));
// const SelectSubCategories = lazy(() =>
//   import(
//     "../components/home/categories/Select_Profession/selectSubCategories.js"
//   )
// );

const RescheduleAndMerge = lazy(() =>
  import("../components/rescheduleAndMerge/index.js")
);
const AdhocProce = lazy(() =>
  import("../components/adhoc/adhocPrice/index.js")
);
const SPCoupons = lazy(() => import("../components/cart/coupons/SPCoupons.js"));
const AddCoupons = lazy(() =>
  import("../components/cart/coupons/AddCoupons.js")
);
const AdhocDetails = lazy(() =>
  import("../components/adhoc/adhocDetails/index.js")
);

const TermsAndConditions = lazy(() =>
  import("../components/conditions/TermsAndConditions.js")
);
const ReturnPolicy = lazy(() =>
  import("../components/conditions/ReturnPolicy.js")
);
const ViewAllGigs = lazy(() => import("../components/gig/ViewAllGigs.js"));
const MembersList = lazy(() => import("../components/members/index.js"));
const BankDetails = lazy(() => import("../components/bankDetails/index.js"));

const GigPreview = lazy(() => import("../components/gig/GigPreview.js"));
const MyAvailability = lazy(() =>
  import("../components/availability/index.js")
);
const InstituteHeader = lazy(() =>
  import("../components/institution/homeInstitutes/HomeInstituteScreen.js")
);
// const DetailsScreen = lazy(() =>
//   import("../components/institution/details/DetailsScreen.js")
// );
const ParticipantsScreen = lazy(() =>
  import("../components/institution/participants/participantsScreen.js")
);
const BranchesScreen = lazy(() =>
  import("../components/institution/branches/BranchesScreen.js")
);
// const InstructorInviteScreen = lazy(() =>
//   import("../components/institution/instructors/instructorInviteScreen.js")
// );
// const ParticipantsInviteScreen = lazy(() =>
//   import("../components/institution/participants/participantsInviteScreen.js")
// );
const InstitutionAvailability = lazy(() =>
  import("../components/institution/InstitutionAvailability.js")
);
const CreateHolidays = lazy(() =>
  import("../components/institution/holidays/CreateHolidays.js")
);
const InstitutionHome = lazy(() =>
  import("../components/institution/InstitutionHome.js")
);
const UpdateInstitutionDetails = lazy(() =>
  import(
    "../components/institution/updateInstituteDetails/UpdateInstituteDetails.js"
  )
);
const AssigningInstructorsAndParticipants = lazy(() =>
  import(
    "../components/institution/assigning/AssingningInstrorsAndParticipants.js"
  )
);
const SettingScreen = lazy(() =>
  import("../components/institution/instituteSettings/SettingScreen.js")
);
const UploadedInformation = lazy(() =>
  import("../components/institution/UploadedInformation.js")
);
const Holidays = lazy(() =>
  import("../components/institution/holidays/Holidays.js")
);
const RRScreen = lazy(() =>
  import("../components/instructor/reviewRatings/RR_Screen.js")
);
const SpLesson = lazy(() =>
  import("../components/instructor/spLessons/Sp_lesson.js")
);
const RatingScreen = lazy(() =>
  import("../components/serviceProviderDetails/RatingScreen.js")
);
const InstructorParticipantsDetails = lazy(() =>
  import(
    "../components/instructor/participantsDetails/InstructorParticipantsDetails.js"
  )
);

const SimilarCourses = lazy(() =>
  import("../components/course/SimilarCourses.js")
);

const SimilarGigs = lazy(() => import("../components/gig/SimilarGigs.js"));

const InstructorUploadedInformation = lazy(() =>
  import("../components/instructor/InstructorUploadInformation.js")
);
const HomeInstructor = lazy(() =>
  import("../components/instructor/HomeInstructor.js")
);
const LessonPlans = lazy(() =>
  import("../components/instructor/spLessons/LessonPlans.js")
);
const InstrutorDashboard = lazy(() =>
  import("../components/instructor/Dashboard.js")
);
const InstructorScreen = lazy(() =>
  import("../components/institution/instructors/InstructorScreen.js")
);
const SpMyCourses = lazy(() =>
  import("../components/instructor/myCourses/SP_MyCourses.js")
);
const HomeParticipants = lazy(() =>
  import("../components/participants/HomeParticipants.js")
);
const ParticpantsCourses = lazy(() =>
  import("../components/participants/myCourses/Courses.js")
);
const EuAttendence = lazy(() =>
  import("../components/participants/euAttendence/index.js")
);
const AboutInstitute = lazy(() =>
  import("../components/institution/aboutInstitute/AboutInstitute.js")
);
const ReportCourse = lazy(() =>
  import("../components/participants/ReportCourse.js")
);
const TopInstructors = lazy(() =>
  import("../components/institution/topInstructors/index.js")
);
const PrivacyPolicy = lazy(() =>
  import("../components/conditions/PrivacyPolicy.js")
);
const CreateOrEditBranch = lazy(() =>
  import("../components/institution/branches/CreateOrEditBranch.js")
);
const Payment = lazy(() => import("../components/paymentsGateway/Payments.js"));
const AllTrendingCourses = lazy(() =>
  import("../components/home/upcomming/AllTrendingCourses.js")
);
const MoreTestimonies = lazy(() =>
  import("../components/home/testimonials/MoreTestimonies.js")
);
const MyPackages = lazy(() =>
  import("../components/home/package/MyPackages.js")
);
const OrderStatus = lazy(() =>
  import("../components/paymentsGateway/OrderStatus.js")
);
const PackageDetails = lazy(() =>
  import("../components/home/package/PackageDetails.js")
);
const HelpandSupport = lazy(() =>
  import("../components/help&support/index.js")
);
const SubCategoriesListTab = lazy(() =>
  import("../components/home/categories/SubCategories.js")
);
const SubCategoryCourses = lazy(() =>
  import("../components/home/categories/SubCategoryCourses.js")
);
const Students = lazy(() => import("../components/students/index.js"));
const AdhocEvents = lazy(() =>
  import("../components/adhoc/adhocEvents/index.js")
);
const MySchedule = lazy(() => import("../components/mySchedule/index.js"));
const SubCourses = lazy(() =>
  import("../components/course/courseDetails/SubCourses.js")
);

const ExploreMoreCategories = lazy(() =>
  import("../components/homeScreen/exploreMoreCategories/index.js")
);

const RefundConfig = lazy(() =>
  import("../components/refundConfiguration/Component.js")
);

const ExpandGigs = lazy(() => import("../components/gig/ExapandGig.js"));

const getHome = (role) => {
  let data;
  if (role?.includes("instituteAdmin")) {
    data = { home: <InstitutionDashboard />, path: "/institute" };
  } else if (role?.includes("instructor")) {
    data = { home: <InstitutionDashboard />, path: "/instructor" };
  } else if (role?.includes("participant")) {
    data = { home: <ParticipantsDashboard />, path: "/participant" };
  } else {
    data = { home: <Home />, path: "/" };
  }
  return data;
};

const Router = () => {
  const { isUserLoggedIn, userDetails } = useSelector((state) => state.app);
  // const home = isUserLoggedIn ? getHome(userDetails?.role).home : <HomePage />;
  const home = isUserLoggedIn ? (
    getHome(userDetails?.role).home
  ) : (
    <HomePageNew />
  );
  const routes = [
    { path: "/", element: home },
    { path: "/home", element: <Home /> },
    { path: "/institutions", element: <InstitutionsHome /> },
    {
      path: "/part-types/:category/:subCategory/:id/:noOfSessions/:isCourseId/:isDraftId",
      element: <PartTypeComponent />,
    },
    {
      path: "/part-type-view/:id/:isCourseId/:userId",
      element: <PartTypeViewComponent />,
    },
    { path: "/create-offTime", element: <OfftimeSchedule /> },
    { path: "/whyAauti", element: <AboutAauti /> },
    { path: "/SVK", element: <SKPVersion /> },
    { path: "/categories", element: <AllCategories /> },
    // { path: "/interests", element: <Interests /> },
    { path: "/course-details/:id", element: <CourseDetails /> },
    { path: "/sp-profile/:id", element: <SPdetailsScreen /> },
    { path: "/top-serviceproviders", element: <MoreTopProvidersCards /> },
    { path: "/gigs", element: <AllGigs /> },
    { path: "/payments", element: <CommingSoon /> },
    { path: "/Reviews", element: <CommingSoon /> },
    { path: "/search-result", element: <SearchResult /> },
    { path: "/search-results/:type", element: <MoreResults /> },

    { path: "/similar-gigs", element: <SimilarGigs /> },
    { path: "/similar-courses", element: <SimilarCourses /> },
    { path: "/similar-packages", element: <SimilarPackages /> },

    // { path: "/sub-categories/:cateID", element: <SubCategoriesListTab /> },
    { path: "institute/profile/:id", element: <InstituteProfile /> },
    { path: "/add-package", element: <AddPackage /> },

    { path: "/:subCtegory/gigs", element: <SubGigs /> },
    { path: "/course-creation", element: <CourseCreation /> },
    { path: "/refund-req", element: <RefundRequest /> },
    { path: "/NewSessionCardView", element: <NewSessionCardView /> },
    { path: "/block-deals", element: <MoreBlockDeals /> },
    { path: "/more-packages", element: <MorePackages /> },
    { path: "/select-profession", element: <SelectProfession /> },
    { path: "/select-subCtegories", element: <SelectSubCategories /> },
    { path: "/sp-coupons", element: <SPCoupons /> },
    { path: "/add-coupon", element: <AddCoupons /> },
    { path: "/termsandconditions", element: <TermsAndConditions /> },
    { path: "/privacypolicy", element: <PrivacyPolicy /> },
    { path: "/refundpolicy", element: <ReturnPolicy /> },
    { path: "/gig-preview/:id", element: <GigPreview /> },
    { path: "/newletter", element: <CommingSoon /> },
    { path: "/blog", element: <CommingSoon /> },
    { path: "/careers", element: <CommingSoon /> },
    { path: "/features", element: <CommingSoon /> },
    { path: "/solutions", element: <CommingSoon /> },
    { path: "/overview", element: <CommingSoon /> },

    { path: "/what-others-talk-about", element: <MoreListing /> },
    {
      path: "/sub-categories/:cateID/courses",
      element: <SubCategoryCourses />,
    },
    { path: "/more-serviceproviders", element: <MoreServiceProviders /> },
    { path: "/sub-categories/:cateID", element: <SubCategoriesListTab /> },
    // ******** Institute Screens paths **********
    // { path: "/institute/profile/:id", element: <Profile /> },
    { path: "/institute/home", element: <InstitutionHome /> },
    { path: "/InstituteHeader", element: <InstituteHeader /> },
    { path: "/institute/dashboard", element: <InstitutionDashboard /> },
    { path: "/institute/branches", element: <BranchesScreen /> },
    { path: "/institute/branches/create", element: <CreateOrEditBranch /> },
    { path: "/institute/instructors", element: <InstructorScreen /> },
    { path: "/Institute-Information", element: <InstituteInfo /> },
    {
      path: "/institute/instructors/instructor-details",
      element: <UploadedInformation />,
    },
    { path: "/institute/participants", element: <ParticipantsScreen /> },
    {
      path: "/institute/participants/participants-details",
      element: <UploadedInformation />,
    },
    { path: "/institute", element: <InstitutionHome /> },
    { path: "/institute/ongoingCourses", element: <NewSessionCardView /> },
    { path: "/institute/gigs", element: <AllGigs /> },
    { path: "/institute/upcomingCourses", element: <NewSessionCardView /> },
    { path: "/institute/completedCourses", element: <NewSessionCardView /> },
    { path: "/institute/holidays", element: <Holidays /> },
    { path: "/settings", element: <SettingScreen /> },
    { path: "/institute/topInstructors", element: <TopInstructors /> },
    { path: "/institute/holidays/createHolidays", element: <CreateHolidays /> },
    { path: "/institute/Availbility", element: <InstitutionAvailability /> },
    { path: "/reschedule-merge", element: <RescheduleAndMerge /> },
    {
      path: "/institute/assigning/instructorsAndParticipants",
      element: <AssigningInstructorsAndParticipants />,
    },
    { path: "/institute/update", element: <UpdateInstitutionDetails /> },
    { path: "/institute/termsAndConditions", element: <TermsAndConditions /> },
    { path: "/aboutInstitute", element: <AboutInstitute /> },
    // ******** instructorScreen paths **********
    { path: "/instructor/dashboard", element: <InstrutorDashboard /> },
    { path: "/instructor", element: <HomeInstructor /> },
    {
      path: "/instructors/instructor-participants-details",
      element: <InstructorUploadedInformation />,
    },
    { path: "/intructors-ratings-reviews", element: <RRScreen /> },
    { path: "/instructor/sp-attendance", element: <SpAttendence /> },
    { path: "/instructor/my-courses", element: <SpMyCourses /> },
    {
      path: "/instructors-details/instructor-participants-details",
      element: <UploadedInformation />,
    },
    {
      path: "/instructor-participants-details",
      element: <InstructorParticipantsDetails />,
    },
    { path: "/sp-ratings&review", element: <RatingScreen /> },
    { path: "/participant/dashboard", element: <ParticipantsDashboard /> },
    { path: "/participant", element: <HomeParticipants /> },
    { path: "/participant/my-courses", element: <ParticpantsCourses /> },
    { path: "/participant/eu-attendance", element: <EuAttendence /> },
    { path: "/participant/privacyPolicy", element: <PrivacyPolicy /> },
    { path: "/participant/reportCourse", element: <ReportCourse /> },
    { path: "/testimonies", element: <MoreTestimonies /> },
    { path: "/trending-courses", element: <AllTrendingCourses /> },
    { path: "/my-packages", element: <MyPackages /> },
    { path: "/help-support", element: <HelpandSupport /> },
    { path: "/sponsered-courses", element: <CommingSoon /> },
    { path: "/:subCtegory/courses", element: <SubCourses /> },
    { path: "/child/:suscriptions", element: <EnrolledCourses /> },
    { path: "/:subCtegory/gigs", element: <SubGigs /> },
    { path: "*", element: <NotFound /> },
    { path: "/cart", element: <Cart /> },
    { path: "/adhoc-details/:id", element: <AdhocDetails /> },
    { path: "/institute/sign-up", element: <InstituteSignup /> },
    { path: "institute/profile/:id", element: <InstituteProfile /> },
    { path: "/newletter", element: <CommingSoon /> },
    { path: "/blog", element: <CommingSoon /> },
    { path: "/careers", element: <CommingSoon /> },
    { path: "/features", element: <CommingSoon /> },
    { path: "/solutions", element: <CommingSoon /> },
    { path: "/overview", element: <CommingSoon /> },
    { path: "/expand-gig/:id", element: <ExpandGigs /> },
    { path: "/package-details/:id", element: <PackageDetails /> },
    { path: "/user/profile/change-password", element: <ChangePassword /> },
    { path: "/social-sign-in", element: <SocialSignInScreen /> },
    { path: "courses/:category", element: <CoursesByCategory /> },
    { path: "/profile/interests", element: <SelectIntrests /> },
    { path: "/explore-more-categories", element: <ExploreMoreCategories /> },
  ];
  const protectedRoutes = [
    { path: "/sp-create-lesson", element: <CreateLessonPlan /> },
    { path: "/chat", element: <Chat /> },
    { path: "/wishlist", element: <WishList /> },

    { path: "/buyNow", element: <Cart /> },
    { path: "/profile", element: <Profile /> },
    { path: "/students", element: <Students /> },
    { path: "/myLesson-plans", element: <MyLessonPlans /> },
    { path: "/payments-gateway/status", element: <OrderStatus /> },
    { path: "/calendar", element: <CalendarComponent /> },
    { path: "/help", element: <Chatbot /> },
    { path: "/payout-requests", element: <PayoutRequest /> },

    { path: "/JitsiComponent", element: <JitsiComponent /> },
    { path: "/join_class", element: <JoinClassComponent /> },
    { path: "/create-lesson-plan", element: <CreateLessonPlan /> },
    { path: "/reviews-ratings", element: <ReviewsAndRatings /> },
    { path: "/adhoc-price", element: <AdhocProce /> },
    { path: "/my-courses", element: <MyCourses /> },
    { path: "/my-learnings", element: <MyLearnings /> },
    { path: "/coupons", element: <SPCoupons /> },
    { path: "/my-revenue", element: <SPrevenue /> },
    { path: "/members-list", element: <MembersList /> },
    { path: "/bank-details", element: <BankDetails /> },
    { path: "/add-package", element: <AddPackage /> },
    { path: "/orders", element: <MyOrders /> },
    { path: "/edit-gig", element: <CourseCreation /> },
    { path: "/gig-creation", element: <CourseCreation /> },
    { path: "/edit-course", element: <CourseCreation /> },
    { path: "/attendance-details", element: <SpAttendence /> },
    { path: "/view-gigs", element: <ViewAllGigs /> },
    {
      path: "/my-instructors",
      element: <FavorateServiceProviders />,
    },
    // { path: "/institutes", element: <Institutes /> },
    {
      path: "/my-instructors",
      element: <FavorateServiceProviders />,
    },
    { path: "/join_class/jitsi", element: <JitsiVideoCall /> },
    { path: "/refunds", element: <Refunds /> },
    { path: "/account-settings", element: <AcccountManagement /> },
    { path: "/adhoc-events", element: <AdhocEvents /> },
    { path: "/AdhocEvent", element: <MyAvailability /> },
    { path: "my-account", element: <AcccountManagement /> },
    { path: "/feedback", element: <Feedback /> },
    { path: "/course-creation", element: <CourseCreation /> },
    { path: "/reschedule/:courseId", element: <Reschedule /> },
    { path: "/course-history/:courseId", element: <CourseHistory /> },
    { path: "/reschedule-refund", element: <ReschedulePage /> },
    {
      path: "/payments-gateway/payment-checkout/:orderId/:countryCode/:currency/:locale",
      element: <Payment />,
    },
    { path: "/reschedule-list", element: <RescheduleList /> },
    { path: "/sp-lessons", element: <SpLesson /> },
    { path: "/lessons-plan", element: <LessonPlans /> },
    { path: "/my-schedule", element: <MySchedule /> },
    // { path: "/help-support", element: <CommingSoon /> },
    { path: "/overview", element: <CommingSoon /> },
    { path: "/transfer-requests", element: <TransferRequests /> },
    { path: "/CourseCategories", element: <CourseCategories /> },
    { path: "/delete-deactivate", element: <DeleteOrDeactivate /> },
    { path: "/course-adhoc-events", element: <CourseAdhocEvent /> },
    { path: "/refund-configuration", element: <RefundConfig /> },
  ];

  const publicRoutes = [
    { path: "/teach-with-Aauti", element: <TeachWithAauti /> },
    { path: "/institutes", element: <Institutions /> },
    { path: "/login", element: <SignInScreen /> },
    { path: "/register/user-type", element: <SelectUserTypeScreen /> },
    {
      path: "/register/service-provider",
      element: <RegisterScreenServiceProvider />,
    },
    { path: "/register/end-user", element: <SignUpEndUser /> },
    {
      path: "/institute/sign-up",
      element: <InstituteSignup />,
    },
    { path: "/login/sign-in-otp", element: <SignInOtpScreen /> },
    { path: "/forgot-password", element: <ForgotPasswordScreen /> },
    {
      path: "/forgot-password/otp-screen",
      element: <ForgotPasswordOtpScreen />,
    },
    { path: "/register/otp-screen", element: <SignUpOtpScreen /> },
    { path: "/forgot-password/reset-password", element: <ResetPassword /> },
    {
      path: "/register/select-subcategories",
      element: <SelectSubCategories />,
    },
    { path: "/register/select-intrests", element: <SelectIntrests /> },
    { path: "/register/select-profession", element: <SelectProfession /> },
    {
      path: "/institute/sign-up",
      element: <InstituteSignup />,
    },
  ];
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 10000000000,
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Routes>
        {routes.map((each) => (
          <Route key={each.path} path={each.path} element={each.element} />
        ))}

        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute isUserLoggedIn={isUserLoggedIn}>
                {route.element}
              </PublicRoute>
            }
          />
        ))}

        <Route
          element={
            <PublicRoute
              path={window.location.pathname}
              isUserLoggedIn={isUserLoggedIn}
            />
          }
        >
          {publicRoutes.map((each) => (
            <Route key={each.path} path={each.path} element={each.element} />
          ))}
        </Route>
        <Route
          element={
            <ProtectedRoute
              path={window.location.pathname}
              isUserLoggedIn={isUserLoggedIn}
            />
          }
        >
          {protectedRoutes.map((each) => (
            <Route key={each.path} path={each.path} element={each.element} />
          ))}
        </Route>
      </Routes>
      <SignIn />
      <JitsiPipMode />
    </Suspense>
  );
};
export default Router;
