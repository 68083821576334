import React, { useState } from "react";
import "./Style.scss";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AautiText from "../../globalComponents/AautiText";
import { DialogTitle, Drawer } from "@mui/material";
import CourseLessonPlan from "./CourseLessonPlan";
import { useSelector } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import Sessions from "./Sessions";
import ChooseLessonPlans from "./ChooseLessonPlans";
import { FaTrashAlt } from "react-icons/fa";
import { isEmpty } from "lodash";
import "../courseCreation/index.scss";
import ConfirmationDialogRaw from "../../globalComponents/ConfirmationDialogRaw";
import { PartType } from "aauti_lessonplan";
import { saveMyLessonPlan } from "../../createLessonPlan/Services";
import { showToast } from "../../globalComponents/Toast";
import { createCourseStrings } from "./Constants";

const CreateCourse2 = (props) => {
  const {
    CreateCourseObj,
    changeCreateObj,
    planObj,
    changePlanObject,
    sessionObject,
    topicObject,
    dialoTitle,
    setDialogTitle,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [handleLesson, setHandleLesson] = useState(true);
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(false);
  };

  const saveLesson = () => {
    let newObj = {
      name: CreateCourseObj?.courseName,
      description: CreateCourseObj?.courseDescription,
      category: CreateCourseObj?.category,
      subCategory: CreateCourseObj?.subCategory,
      level: CreateCourseObj?.courseLevel,
      duration: 0,
      noOfSessions: 1,
      noOfTopics: 1,
      noOfSubTopics: 0,
      serviceProviderId: planObj?.serviceProviderId,
      lessons: planObj?.lessons,
    };
    changeCreateObj(newObj, "lessonPlan");
    toggleDrawer();
  };

  const saveLessonPlan = (data) => {
    const { category, subCategory, serviceProviderId, courseLevel } =
      CreateCourseObj;
    const updatedData = {
      ...data,
      category,
      subCategory,
      serviceProviderId,
      courseLevel,
    };
    saveMyLessonPlan(
      updatedData,
      (response) => {
        if (response.status === "Success") {
          showToast("success", response.status);
          setDialogTitle(createCourseStrings?.chooseLessonPlan);
          // navigate("/myLesson-plans");
        } else {
          alert("error");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const SetDropDataToShow = () => {
    switch (dialoTitle) {
      case "Sessions":
        return (
          <Sessions
            {...{
              planObj,
              changePlanObject,
              sessionObject,
              topicObject,
              toggleDrawer,
              saveLesson,
            }}
          />
        );
      case "Create Lesson Plan":
        return (
          // <CourseLessonPlan
          //   {...{
          //     CreateCourseObj,
          //     changeCreateObj,
          //     planObj,
          //     setPlanObj,
          //     changePlanObject,
          //     sessionObject,
          //     activeSession,
          //     activeSubTopic,
          //     activeTopic,
          //     setactiveSession,
          //     setactiveTopic,
          //     setactiveSubTopic,
          //     topicObject,
          //     subTopicObject,
          //     toggleDrawer,
          //   }}
          // />
          <div style={{ width: "90%" }}>
            <PartType
              fromWeb={false}
              onSubmit={(data) => {
                saveLessonPlan(data);
              }}
            />
          </div>
        );
      case "Choose Lesson Plan":
        return (
          <ChooseLessonPlans
            {...{ CreateCourseObj, changeCreateObj, toggleDrawer }}
          />
        );
      case "Generate Using AI":
        return null;
      default:
        return null;
    }
  };
  return (
    <div
      className="CreateCourseTab"
      style={{
        width: "100%",
      }}
    >
      {CreateCourseObj?.lessonPlan?.name && (
        <div style={{ marginBottom: 10 }}>
          <AautiText
            title={createCourseStrings?.selectedLessonPlan}
            textStyle={{ fontSize: 15, marginTop: 10 }}
          />
          <div
            style={{
              padding: 10,
              border: "1px #3166ba solid",
              borderRadius: 5,
              width: "100%",
              backgroundColor: "#e3f2fd",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              color: "#3166ba",
              flexDirection: "row",
            }}
          >
            <AautiText
              textStyle={{
                color: "#3166ba",
                fontSize: 15,
              }}
              title={CreateCourseObj?.lessonPlan?.name}
            />
            <div>
              <RiPencilFill
                style={{
                  color: "black",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenDrawer(true);
                  setDialogTitle("Sessions");
                }}
                size={20}
              />
              <FaTrashAlt
                size={20}
                onClick={() => setOpen(!open)}
                style={{ color: "black", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="lessonPlans-dev">
        <div
          className="choose-lsn-plan"
          style={{
            marginBottom: mobileDevices && 10,
          }}
          onClick={() => {
            setOpenDrawer(true);
            setDialogTitle(createCourseStrings?.chooseLessonPlan);
          }}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{ color: "#8687a7" }}
            fontSize="large"
          />
          <AautiText
            title={createCourseStrings?.chooseLessonPlan}
            textStyle={{ color: "#8687a7", marginTop: 10 }}
            weight={"bold"}
          />
        </div>
        <div
          className="choose-lsn-plan"
          style={{
            marginBottom: mobileDevices && "0px",
          }}
          onClick={() => {
            setOpenDrawer(true);
            setDialogTitle(createCourseStrings?.createLessonPlan);
          }}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{ color: "#8687a7" }}
            fontSize="large"
          />
          <AautiText
            title={createCourseStrings?.createLessonPlan}
            textStyle={{ color: "#8687a7", marginTop: 10 }}
            weight={"bold"}
          />
        </div>
      </div>
      <div className="lessonPlans-dev">
        {/* <div
          className="choose-lsn-plan"
          style={{
            marginBottom: mobileDevices && 10,
          }}
          onClick={() => {
            showToast("info", "Coming Soon");
          }}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{ color: "#8687a7" }}
            fontSize="large"
          />
          <AautiText
            title={"Generate Using AI"}
            textStyle={{ color: "#8687a7", marginTop: 10 }}
            weight={"bold"}
          />
        </div> */}
        <div
          className="choose-lsn-plan"
          style={{
            marginBottom: mobileDevices && 10,
          }}
          onClick={() => {
            setOpenDrawer(true);
            setDialogTitle("Sessions");
          }}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{ color: "#8687a7" }}
            fontSize="large"
          />
          <AautiText
            title={createCourseStrings?.enterSessionManually}
            textStyle={{ color: "#8687a7", marginTop: 10 }}
            weight={"bold"}
          />
        </div>
      </div>

      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClick={(e) => {
          e.stopPropagation();
          // handleDelete(each?._id);
          changeCreateObj({}, "lessonPlan");
          setHandleLesson(!handleLesson);
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer}
        anchor="bottom"
        sx={{
          // zIndex: 99,
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0px 0px",
            minHeight: "calc(100vh - 200px)",
            // from == "updatestatus" || from == "inviteAdmin"
            //   ? "calc(100vh - 150px)"
            //   : from == "manageFunraiserAdmin"
            //   ? "calc(100vh - 200px)"
            //   : "calc(50vh)",
            mt: "0px !important",
            maxHeight: { xs: "calc(100vh - 100px)", sm: "calc(100vh - 150px)" },
          },
          "&.MuiDrawer-root": {
            // zIndex: 1100,
          },
        }}
      >
        <DialogTitle sx={{ alignSelf: "center", fontWeight: "bold" }}>
          {dialoTitle}
        </DialogTitle>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {SetDropDataToShow()}
        </div>
      </Drawer>
    </div>
  );
};

export default CreateCourse2;
