import AddCircleIcon from "@mui/icons-material/AddCircle";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { DataViewLayoutOptions } from "primereact/dataview";
import * as React from "react";
import { useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import "../Style.css";

const FilterData = [
  {
    id: 1,
    title: "Levels",
    subtitles: ["Beginner", "intermediate", "Expert"],
  },
  {
    id: 2,
    title: "Category",
    subtitles: ["Arts", "Music", "Dance", "Desigining", "Cooking"],
  },
  {
    id: 3,
    title: "Price",
    subtitles: ["0-$100", "", "$101-$200", "> $200"],
  },
  {
    id: 4,
    title: "Status",
    subtitles: ["Ongoing", "Upcoming", "Expired"],
  },
  {
    id: 5,
    title: "Class Type",
    subtitles: ["Virtual", "Inperson"],
  },
];

export default function PageHeading(props) {
  const {
    pageTitle,
    searchInput,
    onChangeSearchInput,
    btnText,
    onChangeLayout,
    layout,
    search,
    filter,
    onFilter,
    AddIcon,
    onClickAdd,
    onClickBtn2,
    btnText2,
  } = props;

  // const [ListData, setListData] = useState(FilterData)
  const [filteredItem, setFilteredItem] = useState(FilterData[0]);
  const [filteredSubItem, setFilteredSubItem] = useState(
    FilterData[0].subtitles[0]
  );
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSelectItem = (ind) => {
    setFilteredItem(FilterData.filter((each, index) => index == ind)[0]);
  };
  const onSelectsubFilteredItem = (ind) => {
    setFilteredSubItem(
      filteredItem.subtitles.filter((each, index) => index == ind)[0]
    );
    setOpen(false);
  };

  return (
    <Box className="PageHeadingMainContainer">
      {pageTitle && (
        <AautiText
          title={pageTitle}
          size={"large"}
          weight={"bold"}
          textStyle={{
            color: "#066cb5",
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {search && (
          <Box
            borderRadius={20}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              // label="Search"
              value={searchInput}
              placeholder="Search"
              InputProps={{
                style: {
                  height: "40px",
                  paddingRight: 0,
                  textAlign: "center",
                },
                endAdornment: filter ? (
                  <Button variant="standard" size="small" onClick={onFilter}>
                    <TuneIcon size={"small"} />
                  </Button>
                ) : null,
                type: "search",
              }}
              onChange={(e) => onChangeSearchInput(e.target.value)}
            />
            {layout && (
              <DataViewLayoutOptions
                layout={props?.layout}
                onChange={(e) => onChangeLayout(e)}
                style={{ marginLeft: 10 }}
              />
            )}
            {open && (
              <Dialog onClose={() => handleClose()} open={open}>
                <Paper elevation={0}>
                  <DialogTitle>Filters</DialogTitle>
                  <List sx={{ pt: 0 }}>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        elevation: 5,
                      }}
                    >
                      <Box>
                        {FilterData?.map((each, index1) => (
                          <>
                            {" "}
                            <ListItem disableGutters key={index1}>
                              <ListItemButton
                                onClick={() => onSelectItem(index1)}
                                sx={{ mt: 0, mb: 0, width: "20vw" }}
                                style={{
                                  backgroundColor:
                                    filteredItem.title === each.title
                                      ? "#fafafa"
                                      : "white",
                                }}
                              >
                                <ListItemText primary={each.name} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </Box>
                      <Box>
                        {filteredItem?.subtitles?.map((item, index2) => (
                          <>
                            <ListItem
                              disableGutters
                              sx={{ width: "20vw", backgroundColor: "#fafafa" }}
                              key={index2}
                            >
                              <ListItemButton
                                onClick={() => onSelectsubFilteredItem(index2)}
                              >
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </Box>
                    </Box>
                  </List>
                </Paper>
              </Dialog>
            )}
          </Box>
        )}

        {btnText && (
          <div style={{ textAlign: "right", margin: 10 }}>
            <Button
              variant="contained"
              startIcon={AddIcon && <AddCircleIcon />}
              onClick={() => onClickAdd()}
            >
              {btnText}
            </Button>
          </div>
        )}
        {btnText2 && (
          <div style={{ textAlign: "right", margin: 10 }}>
            <Button
              variant="contained"
              startIcon={AddIcon && <AddCircleIcon />}
              onClick={() => onClickBtn2()}
            >
              {btnText2}
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
}
