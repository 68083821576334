import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

export default function BasicModal(props) {
    const {title,text,buttontext1,buttontext2,isShow,Bfunction2,Bfunction1} = props
  const [open, setOpen] = React.useState(isShow);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{ 
    setOpen(false)
    Bfunction1()
};

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h8" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-title" fontSize={18}>
            {text}
          </Typography>
          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:2}}>
          <Button sx={{marginX:2}} size='small' variant='outlined' onClick={handleClose}>{buttontext1}</Button>
          <Button size='small' variant='contained' onClick={()=>Bfunction2()}>{buttontext2}</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}