import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { HiPlus } from "react-icons/hi";
import HelperText from "./HelperText";
import { useSelector } from "react-redux";
import "./index.scss";
import { MdEdit } from "react-icons/md";
import { courseConstant } from "./courseConstant";

const AddressSelection = ({
  handleOpenAddress,
  courseAdhoc = false,
  CreateCourseObj,
}) => {
  const { userDetails } = useSelector((state) => state.app);
  const sortList = userDetails?.addressList
    ?.map((item) => ({ ...item }))
    .sort((a, b) => b.isDefault - a.isDefault);
  return (
    <div className="level-main">
      <div className="add-list">
        {sortList?.slice(0, 1)?.map((each, index) => {
          return (
            <button
              key={index}
              className={`address-card ${each?.isDefault && "active-class"} ${
                courseAdhoc && "adress-card-width"
              }`}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <AautiText
                  title={each.addressType}
                  textStyle={{ color: "#2076E5" }}
                />
                <MdEdit
                  color="#2076E5"
                  onClick={() => {
                    handleOpenAddress();
                  }}
                />
              </div>
              <AautiText title={each?.address} size={"semi"} />
              <AautiText
                title={`Floor: ${each?.floor}`}
                size={"semi"}
                textAlign={"left"}
                textStyle={{
                  display: !each?.floor && "none",
                  marginTop: 6,
                }}
              />
              <AautiText
                title={`Landmark: ${each?.landMark}`}
                size={"semi"}
                textAlign={"left"}
                textStyle={{
                  display: !each?.landMark && "none",
                  marginTop: 6,
                }}
              />

              {CreateCourseObj?.isClientLocation && (
                <AautiText
                  textStyle={{ marginTop: 2 }}
                  size={"small"}
                  title={`Client Location Radius: ${
                    CreateCourseObj?.radius || 0
                  } ${CreateCourseObj?.radiusType}s`}
                />
              )}
            </button>
          );
        })}
        {userDetails?.addressList?.length <= 0 && (
          <button
            className="add-plus"
            onClick={() => {
              handleOpenAddress();
            }}
          >
            <HiPlus color="#2076E5" size={25} />
          </button>
        )}
      </div>
      <div
        className="flex-container"
        style={{ gap: 10, display: courseAdhoc ? "none" : "flex" }}
      >
        <HelperText title={courseConstant?.selectALocation} />
        <HelperText title={courseConstant?.addALocation} />
        <HelperText title={courseConstant?.pickARadiusFromTheDesiredLocation} />
        <HelperText title={courseConstant?.editLocationAndFixRadius} />
      </div>
    </div>
  );
};

export default AddressSelection;
