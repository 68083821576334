import { createSelector, createSlice } from "@reduxjs/toolkit";
import { userRoles } from "../../storage/constants";

const initialState = {
  isSocketOpen: false,
  unreadCount: 0,
  activeRoom: undefined,
  eventHandler: null,
  contacts: [],
  activeUser: {
    _id: null,
    onlineStatus: false,
  },
  conversations: [],
};

const getUserProfileDetails = (state) => ({
  _id: state.app?.loggedinUserId,
  displayName: state.app?.userDetails?.displayName,
  profileImage: state.app?.userDetails?.profileImage,
});


export const getActiveChatRoom = (state) => state.chat.activeRoom;

const chatsListUserProps = (state) => ({
  approvalStatus: state.app.userDetails?.approvalStatus ?? false,
  role: state.app.UserRole,
});

export const getUnreadCount = (state) => state.chat.unreadCount;
export const getContacts = (state) => state.chat.contacts;
export const getIsSocketOpen = (state) => state.chat.isSocketOpen;
export const getUserRole = state=>state.app.UserRole

export const getChatsListProps = createSelector(
  [chatsListUserProps, getUnreadCount, getContacts, getIsSocketOpen,getActiveChatRoom],
  (props1, unreadCount,contacts, isSocketOpen,activeRoom) => ({
    ...props1,
    contacts,
    unreadCount,
    isSocketOpen,
    activeRoom
  })
);

export const chatRoomSelector = createSelector(
  [getActiveChatRoom, getUserProfileDetails,getIsSocketOpen],
  (activeRoom, sender,isSocketOpen) => ({ activeRoom, sender,isSocketOpen })
);

export const createGroupDialogSelector = createSelector(
  [getUserRole, getContacts],
  (role, contactsList) => ({ role, contactsList })
);



const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    connectionEstablished(state) {
      state.isSocketOpen = true;
    },
    disconnectSocket(state) {
      state.isSocketOpen = false;
      state.unreadCount = 0;
      state.activeRoom = undefined;
      state.eventHandler = null;
      state.contacts = [];
      state.activeUser = {
        _id: null,
        onlineStatus: false,
      };
    },
    setActiveRoom(state, { payload: { data, resetContacts = false } }) {
      state.activeRoom = data;
      if (resetContacts) {
        state.contacts = [];
      }
    },
    setSocketHandler(state, { payload }) {
      state.eventHandler = payload;
    },
    updateUnreadCount(state, { payload }) {
      state.unreadCount = payload;
    },
    updateContacts(state, { payload }) {
      state.contacts = payload;
    },
    addAdmin(state, { payload }) {
      state.activeRoom.admins.push(payload);
    },
    removeAdmin(state, { payload }) {
      state.activeRoom.admins = state.activeRoom.admins?.filter(
        (adminId) => adminId !== payload
      );
    },
    addModerator(state, { payload }) {
      state.activeRoom.moderators.push(payload);
    },
    removeModerator(state, { payload }) {
      state.activeRoom.moderators = state.activeRoom.moderators?.filter(
        (adminId) => adminId !== payload
      );
    },
    blockUser(state, { payload: { blockedBy, userId } }) {
      state.activeRoom.user.blockedBy = blockedBy;
      if (!state.activeRoom?.blockedUsers) {
        state.activeRoom.blockedUsers = [];
      }
      state.activeRoom.blockedUsers.push(userId);
    },
    unblockUser(state, { payload }) {
      state.activeRoom.user.blockedBy = null;
      state.activeRoom.blockedUsers = state.activeRoom.blockedUsers?.filter(
        (userId) => userId !== payload
      );
    },
    setActiveUser(state, { payload = { _id: null, onlineStatus: false } }) {
      state.activeUser = payload;
    },
  },
});

export const {
  addAdmin,
  removeAdmin,
  addModerator,
  removeModerator,
  connectionEstablished,
  disconnectSocket,
  setSocketHandler,
  startSocketConnection,
  setActiveRoom,
  updateContacts,
  updateUnreadCount,
  blockUser,
  unblockUser,
  setActiveUser,
} = chatSlice.actions;

export default chatSlice.reducer;
