import React, { useEffect, useState } from "react";
import "./Style.scss";
import { chooseLessonPlans } from "./Constants";
import AautiText from "../../globalComponents/AautiText";
import { getAllLessonsBySpId } from "./Services";
import { useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import { showToast } from "../../globalComponents/Toast";

const ChooseLessonPlans = (props) => {
  const { CreateCourseObj, changeCreateObj, toggleDrawer } = props;
  const { loggedinUserId } = useSelector((state) => state.app);
  const [existingLessonPlans, setExistingLessonPlans] = useState(null);
  const [EditItem, setEditItem] = React.useState(null);

  const handleClick = (each) => {
    setEditItem(each);
  };
  const handleClose = () => {
    setEditItem(null);
  };

  useEffect(() => {
    getAllLessonsBySpId(
      loggedinUserId,
      (res) => {
        if (res?.status == "Success") {
          setExistingLessonPlans(res?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const filteredLessonPlans = existingLessonPlans?.filter((each, index) => {
    return (
      each?.category == CreateCourseObj?.category &&
      each?.subCategory == CreateCourseObj?.subCategory
    );
  });

  const addLessonPlan = (plan) => {
    const obj = {
      name: plan?.contentName,
      description: plan?.contentDescription,
      category: plan?.category,
      subCategory: plan?.subCategory,
      level: plan?.level,
      duration: 0,
      noOfSessions: plan?.sessions?.length,
      noOfTopics: 1,
      noOfSubTopics: 0,
      serviceProviderId: loggedinUserId,
      lessons: plan?.sessions,
    };

    changeCreateObj(obj, "lessonPlan");
    toggleDrawer();
  };

  const Card = (each, index) => {
    let topicsCount = 0;
    each?.sessions?.map((obj, index) => {
      topicsCount += obj?.topics?.length;
    });

    let SubtopicsCount = 0;
    each?.sessions?.map((obj, index) => {
      obj?.topics?.map((ren, index) => {
        SubtopicsCount += ren?.subTopics?.length;
      });
    });

    return (
      <div
        key={each._id}
        className="existing-lesson-co"
        onClick={() => {
          addLessonPlan(each);
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="existing-lesson-head-co">
            <AautiText
              title={each?.name || each?.contentName || "NA"}
              weight={"bold"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                color: "#3166ba",
              }}
            />
            {/* <MoreVertIcon
              sx={{ width: "10%", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (EditItem) {
                  handleClose();
                } else {
                  handleClick(each);
                }
              }}
            /> */}
          </div>
          <div style={{ padding: 10, height: "75px" }}>
            <AautiText
              title={each?.description || each?.contentDescription || "NA"}
              size={"semi"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,

                overflow: "hidden",
                // marginTop: ,
                // padding: 7,s
              }}
            />
          </div>
          <div className="existing-lesson-foot-co">
            <AautiText
              size={"semi"}
              title={`${
                each?.sessions?.length || each?.lessons?.length || 0
              } Session`}
              textStyle={{
                color: "#000000",
                borderRight: "2px solid #000000",
                paddingRight: 10,
              }}
            />
            <AautiText
              size={"semi"}
              title={`${topicsCount || 0} Topic`}
              textStyle={{
                color: "#000000",
                borderRight: "2px solid #000000",
                paddingRight: 10,
              }}
            />
            <AautiText
              size={"semi"}
              title={`${SubtopicsCount || 0} Subtopics`}
              textStyle={{
                color: "#000000",
              }}
            />
          </div>
        </div>
        {/* {EditItem?._id === each._id && (
          <div className="EditOptions-co">
            {["Edit", "Delete"].map((option) => (
              <MenuItem
                key={option}
                onClick={(e) => {
                  setEditItem(null);
                  // e.stopPropagation();
                  // if (option === "Delete") {
                  //   deleteItem();
                  // } else if (option === "Edit") {
                  //   dispatch(editLessonPlanObject(each));
                  //   navigate("/create-lesson-plan");
                  // }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </div>
        )} */}
      </div>
    );
  };

  return (
    <div className="choose-lessons">
      <div className="aauti-third-party">
        {chooseLessonPlans.map((each, index) => {
          return (
            <div
              key={each.id}
              className="third-item"
              style={{ cursor: "pointer" }}
              onClick={() => {
                showToast("info", "Coming Soon");
              }}
            >
              <AautiText
                title={each.head}
                textStyle={{ marginBottom: 10, color: "#3166ba" }}
                weight={"bold"}
                size={"normal"}
              />
              <AautiText
                title={each.desc}
                size={"semi"}
                textStyle={{ color: "#333333" }}
              />
            </div>
          );
        })}
      </div>

      <div className="LessonPlans-map-co">
        {filteredLessonPlans?.map((each, index) => {
          return Card(each, index);
        })}
      </div>
    </div>
  );
};

export default ChooseLessonPlans;
