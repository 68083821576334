import { IconButton, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import AautiText from "../../../globalComponents/AautiText";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import AautiUpload from "../../../globalComponents/AautiUpload";
import JoinButton from "../../../globalComponents/JoinButton";
import { showToast } from "../../../globalComponents/Toast";
import { createCourseStrings } from "../Constants";
import { AautiStrings } from "../../../globalComponents/AautiStrings";

const GigUploads = ({
  preview,
  setActiveIndex,
  renderVideo,
  activeIndex,
  gigVideos,
  handleRemoveTopic,
  handleAddTopic,
  handleOnChangeTopic,
  gig,
  setGig,
  setPreview,
  handleGigImage,
  handleUploadVideoUrl,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [uploadUrl, setUploadUrl] = useState("");

  return (
    <div>
      {!isEmpty(preview) && (
        <div>
          <img
            src={preview}
            alt="img-preview"
            style={{
              width: "100%",
              height: "525px",
              borderRadius: "4px",
            }}
          />
        </div>
      )}
      {gig && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <AautiUpload
              multiple={true}
              id="upload video"
              accept="video/mp4,video/x-m4v,video/*"
              onChange={(e) => {
                handleGigImage(e, "gigVideo");
              }}
              iconName={"video"}
              important
              customLabel={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <AddPhotoAlternateOutlinedIcon
                    sx={{ color: "#8687a7" }}
                    fontSize="large"
                  />
                  <AautiText
                    size={"semi"}
                    weight={"normal"}
                    textStyle={{ color: "#8687a7" }}
                    title={createCourseStrings?.uploadVideoImp}
                  />
                </div>
              }
            />
          </div>
          <p style={{ margin: "10px 0px 10px 0px" }}>or</p>
          <div
            style={{
              width: "100%",
              // border: "1px solid lightgrey",
              // borderRadius: 4,
              // padding: 6,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              value={uploadUrl}
              onChange={(e) => {
                const value = e.target.value.trimStart().replace(/\s+/g, " ");
                setUploadUrl(value);
              }}
              size="small"
              fullWidth
              placeholder={createCourseStrings?.pasteURLHere}
              id="fullWidth"
            />
            <IconButton
              onClick={() => {
                if (uploadUrl !== "") {
                  handleUploadVideoUrl(uploadUrl, "uploadUrl");
                  setUploadUrl("");
                } else {
                  showToast("info", "Url field cannot be empty");
                }
              }}
            >
              <AddCircleIcon sx={{ color: "#078dda" }} />
            </IconButton>
          </div>
          {!isEmpty(gigVideos) && (
            <div
              style={{
                alignSelf: "flex-start",
                marginTop: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  marginTop: 5,
                }}
              >
                {gigVideos?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      border: activeIndex == index && "1px solid blue",
                      cursor: "pointer",
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                  >
                    {renderVideo(each?.videoUrl, "gigVideo", index)}
                  </div>
                ))}
              </div>
              <div style={{ width: "100%", marginTop: 10 }}>
                {/* <AautiTextInput
                   
                    text={"Video Title"}
                    style={{ width: "100%" }}
                  /> */}
                <DemoItem
                  label={createCourseStrings?.videoTitle}
                  style={{
                    "& .MuiTypography-root": {
                      color: "red !important",
                    },
                  }}
                >
                  <TextField
                    value={gigVideos[activeIndex]?.videoTitle}
                    size="small"
                    fullWidth
                    placeholder="Video Title"
                    onChange={(e) => {
                      handleOnChangeTopic(
                        e.target.value,
                        activeIndex,
                        "videoTitle"
                      );
                    }}
                    id="fullWidth"
                    style={{ width: "100%" }}
                  />
                </DemoItem>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  border: "1px solid lightgrey",
                  padding: 9,
                  marginTop: 10,
                  borderRadius: 5,
                }}
              >
                <AautiText
                  title={AautiStrings?.topicsString}
                  weight={"medium"}
                  textStyle={{ color: "#000" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15,
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px 5px 10px",
                      fontWeight: 600,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    disabled={gigVideos[activeIndex]?.topics?.length == 1}
                    onClick={() => {
                      // setCount(count - 1);
                      handleRemoveTopic(activeIndex);
                    }}
                  >
                    -
                  </button>
                  <AautiText
                    title={gigVideos[activeIndex]?.topics?.length}
                    weight={"medium"}
                    textStyle={{ color: "#000" }}
                  />
                  <button
                    disabled={gigVideos[activeIndex]?.topics?.length == 10}
                    style={{
                      padding: "5px 10px 5px 10px",
                      fontWeight: 600,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setCount(count + 1);
                      handleAddTopic(activeIndex);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                {gigVideos[activeIndex]?.topics?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      marginTop: 8,
                    }}
                  >
                    <DemoItem
                      fullWidth
                      label={`Topic ${index + 1}`}
                      style={{
                        "& .MuiTypography-root": {
                          color: "red !important",
                        },
                        width: "100%",
                      }}
                    >
                      <TextField
                        sx={{ width: "98%" }}
                        style={{ width: "330px" }}
                        value={`${each?.topicName}`}
                        size="small"
                        fullWidth
                        placeholder={createCourseStrings?.videoTitle}
                        onChange={(e) => {
                          handleOnChangeTopic(
                            e.target.value,
                            activeIndex,
                            "topicName",
                            index
                          );
                        }}
                        id="Topic-name"
                      />
                    </DemoItem>
                    <div style={{ width: "35%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          views={["hours", "minutes", "seconds"]}
                          ampm={false}
                          onChange={(e) => {
                            console.log(e, "tomepicker event");
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  width: "99%",
                  alignItems: "center",
                }}
              >
                <JoinButton
                  buttonStyle={{
                    width: mobileDevices ? "20%" : "14%",
                    height: "40px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    marginTop: "8px",
                    alignSelf: "flex-end",
                  }}
                  title={AautiStrings?.DoneString}
                  onClick={() => {
                    setPreview(null);
                    setGig(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GigUploads;
