import { useEffect, useState, useRef } from "react";
import { RenderMessageText, RenderUserNameText } from "./components";
import Divider from "@mui/material/Divider";
import { IoIosArrowDown } from "react-icons/io";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LuSmilePlus } from "react-icons/lu";
import {
  MdOutlineReply,
  MdOutlineEdit,
  MdOutlineDelete,
  MdOutlineContentCopy,
} from "react-icons/md";

import EmojiPicker from "emoji-picker-react";
import { IoCloseOutline } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { socket } from "../../../..";
import { idGenerator } from "../../render_text_input/container";

export default function Messages(props) {
  const op = useRef(null);
  const [MessageItemsanchor, setMessageItemsAnchor] = useState(null);
  const MessageOptionsOpen = Boolean(MessageItemsanchor);
  const [editClicked, setEditClicked] = useState(false);
  // const [deleteClicked, setDeleteClicked] = useState(false);
  const [hoveredObj, setHoveredObj] = useState({});
  const moment = require("moment");
  const [hover, setHover] = useState(false);
  const [editedMessage, setEditedMessage] = useState();
  const [reactions, setReactions] = useState(["❤️", "😂", "😲", "👍", "🎉"]);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [hoveredReaction, setHoveredReaction] = useState(null);
  const [smallEmojiPicker, setSmallEmojiPicker] = useState(false);
  const [smallPickerOpenLeft, setSmallPickerOpenLeft] = useState(false);
  const emojiPickRef = useRef(null);
  const {
    item = null,
    myId = "",
    newUserMessage = true,
    replyClicked,
    setReplyClicked,
    // replyObj,
    setReplyObj,
    // deleted,
    setDeleted,
    setSendClicked,
    searchText,
    setSearchText,
    messageRefs,
  } = props;
  const _id = item?.sender && item?.sender?._id;

  const sentByMe = myId && _id && myId === _id;
  const NotMyOptions = [
    {
      id: 0,
      name: "Reply",
      icon: MdOutlineReply,
    },
    {
      id: 1,
      name: "Copy",
      icon: MdOutlineContentCopy,
    },
  ];
  const MyOptions = [
    {
      id: 0,
      name: "Reply",
      icon: MdOutlineReply,
    },
    {
      id: 1,
      name: "Edit",
      icon: MdOutlineEdit,
    },
    {
      id: 2,
      name: "Copy",
      icon: MdOutlineContentCopy,
    },
    {
      id: 3,
      name: "Delete",
      icon: MdOutlineDelete,
    },
  ];

  useEffect(() => {
    if (!(item?.type === "SYSTEM")) {
      const messageSubCon = document.getElementById("message_sub_container");
      if (messageSubCon) {
        messageSubCon.style.color = "#3363ff";
        messageSubCon.style.alignSelf = "flex-start";
        messageSubCon.style.marginTop = newUserMessage ? "16px" : "0px";
      }
    }
  }, [item?.type, newUserMessage, sentByMe]);

  const handleMessageEdit = () => {
    const data = {
      messageId: hoveredObj?._id,
      roomId: hoveredObj?.roomId,
      text: editedMessage,
    };
    // if (socket.readyState === 1) {
    //   if (item?.sessionId) {
    //     socket.send(
    //       JSON.stringify({
    //         event: "MESSAGE_EDIT",
    //         message: hoveredObj,
    //         roomId: hoveredObj?.roomId,
    //         text: editedMessage,
    //         _id: idGenerator(),
    //       })
    //     );
    //   } else {
    //     socket.send(
    //       JSON.stringify({
    //         event: "MESSAGE_EDIT",
    //         message: hoveredObj,
    //         roomId: hoveredObj?.roomId,
    //         text: editedMessage,
    //         _id: idGenerator(),
    //       })
    //     );
    //   }
    // }

    // socket.onmessage = (event) => {
    //   const parsedEvent = JSON.parse(event?.data);
    //   const { event: dataEvent, data: dataData } = parsedEvent || {};
    //   if (dataEvent === "PING") {
    //     socket.send(JSON.stringify({ event: "PONG" }));
    //   } else if (dataEvent === "MESSAGE_EDIT") {
    //   }
    // };
    // socket.onerror = (err) => {
    //   console.error(err, "sent_message");
    // };
    setEditClicked(false);
    setSendClicked(true);
  };
  const renderMsgProps = {
    newUserMessage,
    sentByMe,
    item,
    hoveredObj,
    editClicked,
    setEditClicked,
    handleMessageEdit,
    editedMessage,
    setEditedMessage,
    setReplyClicked,
    replyClicked,
    searchText,
    setSearchText,
    messageRefs,
  };

  const handleHoverOnMessage = (item) => {
    setHover(true);
  };

  const handleLeaveMessage = () => {
    setHover(false);
  };

  const handleMyOptionsSelect = (e, obj) => {
    setDeleted(false);
    if (e.target.textContent === "Edit") {
      setEditClicked(true);
      setHoveredObj(obj);
      setEditedMessage(obj?.text);
    } else if (e.target.textContent === "Copy") {
      copyTextToClipboard(obj?.text);
    } else if (e.target.textContent === "Delete") {
      // setDeleteClicked(true);

      // if (socket.readyState === 1) {
      //   socket.send(
      //     JSON.stringify({ event: "MESSAGE_DELETE", messageId: obj?._id })
      //   );

      //   socket.onmessage = (event) => {
      //     setDeleted(true);
      //     const parsedEvent = JSON.parse(event?.data);
      //     const { event: dataEvent, data: dataData } = parsedEvent || {};
      //     if (dataEvent === "PING") {
      //       socket.send(JSON.stringify({ event: "PONG" }));
      //     } else if (dataEvent === "MESSAGE_DELETE") {
      //       setDeleted(true);
      //     }
      //   };
      // }

      // socket.onerror = (err) => {
      //   setDeleted(false);
      // };
    } else {
      setReplyClicked(true);
      setHoveredObj(obj);
      setReplyObj(obj);
    }
    op.current.hide();
    setHover(false);
  };

  const handleAddReaction = () => {
    setSmallEmojiPicker(true);
    setSmallPickerOpenLeft(false);
  };

  const copyTextToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleOutsideClick = (event) => {
    if (emojiPickRef.current && !emojiPickRef.current.contains(event.target)) {
      setSmallEmojiPicker(false);
      setSmallPickerOpenLeft(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSaveReaction = (reaction) => {
    setSelectedEmoji(reaction);
    setSmallPickerOpenLeft(false);
  };

  const handleEmojiClick = (emojiObject) => {
    setSelectedEmoji(emojiObject.emoji);
    setSmallEmojiPicker(false);
    setSmallPickerOpenLeft(false);
  };

  const handleMyNotOptionsSelect = async (e, obj) => {
    if (e.target.textContent === "Copy") {
      copyTextToClipboard(obj?.text);
    } else {
      setReplyClicked(true);
      setHoveredObj(obj);
      setReplyObj(obj);
    }
    op?.current?.hide();
    setMessageItemsAnchor(null);
    setHover(false);
  };

  if (item?.type === "SYSTEM") {
    const moment = require("moment");

    const inputTimestamp = item?.updatedAt;
    const outputFormat = "hh:mm A";

    const convertedTimestamp = moment(inputTimestamp).format(outputFormat);
    return (
      <div className={"system_messages"}>
        <Divider>
          <div className={"system_msg_txt"}>
            <p>{item?.text}</p>
            <p>{convertedTimestamp}</p>
          </div>
        </Divider>
      </div>
    );
  } else {
    return (
      <div className={"message_container"}>
        <div
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: sentByMe ? "flex-end" : "flex-start",
          }}
        >
          <RenderUserNameText {...{ newUserMessage, sentByMe, item }} />
          <div style={{ width: "100%" }}>
            {!sentByMe && (
              <div className={"text_container_msg_username"}>
                <p
                  className={`message_text_name_styles ${sentByMe} ? sent_by_me_text_name : not_sent_by_me`}
                >
                  {item?.sender?.displayName}{" "}
                  {`${moment(item?.updatedAt)?.format("h:mm A") || "N/A"}`}
                </p>
              </div>
            )}

            <div
              className={
                sentByMe ? "test_container_left" : "test_container_right"
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width:
                    (item.type === "AGENDA" ||
                      item.type === "agenda" ||
                      item.type === "assignment" ||
                      item.type === "poll" ||
                      item.type === "quizz" ||
                      item.type === "task" ||
                      item.type === "ask" ||
                      item.type === "ASSIGNMENT" ||
                      item.type === "POLL" ||
                      item.type === "QUIZZ" ||
                      item.type === "TASK" ||
                      item.type === "ASK") &&
                    "100%",
                }}
              >
                {sentByMe && (
                  <div className={"text_container_msg_username"}>
                    <p
                      className={`message_text_name_styles ${sentByMe} ? sent_by_me_text_name : not_sent_by_me`}
                    >
                      {item?.sender?.displayName}{" "}
                      {`${moment(item?.updatedAt)?.format("h:mm A") || "N/A"}`}
                    </p>
                  </div>
                )}
                <div
                  onMouseEnter={() => handleHoverOnMessage(item)}
                  onMouseLeave={() => handleLeaveMessage()}
                  className={
                    props.text === "Feeds"
                      ? item?.type === "TEXT" || item.type === "REPLY"
                        ? "message_container_left"
                        : item?.type === "DOCUMENT" ||
                          item?.type === "AUDIO" ||
                          item?.type === "VIDEO"
                        ? "file_container_left"
                        : "file_container_middle"
                      : sentByMe
                      ? item?.type === "TEXT" || item.type === "REPLY"
                        ? "message_container_left"
                        : "file_container_left"
                      : item?.type === "TEXT" || item.type === "REPLY"
                      ? "message_container_right"
                      : "file_container_right"
                  }
                >
                  <div id={"sent_by_me_alignment"}>
                    <RenderMessageText {...renderMsgProps} />
                  </div>

                  <div
                    style={{
                      width: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {hover && sentByMe ? (
                      <>
                        <button
                          className="mes-hover-cont-but"
                          style={{
                            marginLeft:
                              item?.type === "REPLY"
                                ? "5px"
                                : !(item?.type === "TEXT")
                                ? "-30px"
                                : "5px",
                            bottom:
                              item?.type === "IMAGE"
                                ? "70px"
                                : item?.type === "AUDIO"
                                ? "25px"
                                : item?.type === "REPLY"
                                ? "5px"
                                : !(item?.type === "TEXT")
                                ? "55px"
                                : "10px",
                          }}
                          onClick={(e) => op.current.toggle(e)}
                        >
                          <IoIosArrowDown size={12} color="black" />
                        </button>
                        <OverlayPanel
                          ref={op}
                          style={{ padding: "0px !important" }}
                        >
                          {sentByMe
                            ? MyOptions.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  onClick={(e) =>
                                    handleMyOptionsSelect(e, item)
                                  }
                                >
                                  <option.icon style={{ marginRight: 10 }} />
                                  {option.name}
                                </MenuItem>
                              ))
                            : NotMyOptions.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  onClick={(e) =>
                                    handleMyNotOptionsSelect(e, item)
                                  }
                                >
                                  <option.icon style={{ marginRight: 10 }} />
                                  {option.name}
                                </MenuItem>
                              ))}
                        </OverlayPanel>
                      </>
                    ) : (
                      <>
                        <Button
                          className="mes-hover-cont-but"
                          id="arrowDownButton"
                          type="button"
                          style={{
                            outline: "none",
                            padding: "2px",
                            display: "none",
                          }}
                          icon={
                            <>
                              <IoIosArrowDown size={12} />
                            </>
                          }
                          onClick={(e) => op.current.toggle(e)}
                        />
                      </>
                    )}
                  </div>
                </div>
                {!sentByMe && (
                  <div
                    onClick={() => setSelectedEmoji()}
                    style={{
                      padding: selectedEmoji && "5px",
                      marginTop: -15,
                      marginBottom: "20px",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                  >
                    {selectedEmoji}
                  </div>
                )}
              </div>

              {!sentByMe && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-start",
                    marginTop: "5px",
                    cursor: "pointer",
                    marginLeft: "3px",
                    padding: "2px",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                  }}
                >
                  {smallPickerOpenLeft ? (
                    <IoCloseOutline
                      onClick={() => setSmallPickerOpenLeft(false)}
                    />
                  ) : (
                    <LuSmilePlus onClick={() => setSmallPickerOpenLeft(true)} />
                  )}
                </div>
              )}
              {smallPickerOpenLeft && !sentByMe && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    backgroundColor: "#ecf0ff",
                    width: "200px",
                    padding: "5px",
                    borderRadius: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {reactions.map((reaction) => (
                      <span
                        onMouseEnter={() => setHoveredReaction(reaction)}
                        onMouseLeave={() => setHoveredReaction(null)}
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          transition: "transform 0.2s",
                          transform:
                            hoveredReaction === reaction
                              ? "scale(1.5)"
                              : "scale(1)", // Apply zoom-in effect when hovered
                        }}
                        key={reaction}
                        onClick={() => handleSaveReaction(reaction)}
                      >
                        {reaction}
                      </span>
                    ))}
                  </div>

                  <div
                    style={{ marginBottom: -10, cursor: "pointer" }}
                    onClick={() => handleAddReaction()}
                  >
                    <CiCirclePlus size={27} />
                  </div>
                  <div>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      aria-label="more"
                      id="long-button"
                      aria-controls={
                        MessageOptionsOpen ? "long-menu" : undefined
                      }
                      aria-expanded={MessageOptionsOpen ? "true" : undefined}
                      aria-haspopup="true"
                      sx={{ marginLeft: "-6px" }}
                      onClick={(e) => setMessageItemsAnchor(e.currentTarget)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={MessageItemsanchor}
                      open={MessageOptionsOpen}
                      onClose={() => setMessageItemsAnchor(null)}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {sentByMe
                        ? MyOptions.map((option) => (
                            <MenuItem
                              key={option.id}
                              onClick={(e) => handleMyOptionsSelect(e, item)}
                            >
                              <option.icon style={{ marginRight: 10 }} />
                              {option.name}
                            </MenuItem>
                          ))
                        : NotMyOptions.map((option) => (
                            <MenuItem
                              key={option.id}
                              onClick={(e) => handleMyNotOptionsSelect(e, item)}
                            >
                              <option.icon style={{ marginRight: 10 }} />
                              {option.name}
                            </MenuItem>
                          ))}
                    </Menu>
                  </div>
                </div>
              )}
              {smallEmojiPicker && !sentByMe ? (
                <div id={"mySmallPicker"} ref={emojiPickRef}>
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              ) : (
                <>
                  <Button
                    className="mes-hover-cont-but"
                    id="arrowDownButton"
                    type="button"
                    style={{ outline: "none", padding: "2px", display: "none" }}
                    icon={
                      <>
                        <IoIosArrowDown size={12} />
                      </>
                    }
                    onClick={(e) => op.current.toggle(e)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
