import React from "react";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import JoinButton from "../globalComponents/JoinButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
import { BiLogoApple } from "react-icons/bi";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { BsGooglePlay } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { AautiStrings } from "../globalComponents/AautiStrings";

const Footer = (props) => {
  const {} = useSelector((state) => state.responsive);
  const currentPath = window?.location?.pathname;
  const path = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className="Footer-container"
      style={{
        // marginTop: "10px",
        display: currentPath?.includes("/AllGigs") && "none",
      }}
    >
      <div className="footerdiv3">
        <div className="footer-content">
          <div className="footer-con2">
            <img
              src="https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/logo-header.png"
              alt="Logo Header"
              style={{ width: 112.03, height: 35 }}
            />
            <AautiText
              textStyle={{ color: "grey", width: "90%" }}
              // className={"Loremtext"}
              size={"semi"}
              weight={"normal"}
              title={
                "Get Quality Education Faster and Easier From Industry Experts"
              }
            />
          </div>
          <div className="footer-con1">
            <AautiText
              title={AautiStrings?.quickLinksString}
              className={"subText"}
            />

            <AautiText
              onClick={() => {
                navigate("/overview");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.overviewString}
            />

            <AautiText
              onClick={() => {
                navigate("/features");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.featuresString}
            />
            <AautiText
              onClick={() => {
                navigate("/solutions");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.solutionsString}
            />
            <AautiText
              onClick={() => {
                navigate("/help-support");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.helpAndSupportString}
            />
          </div>
          <div className="footer-con1">
            <AautiText
              title={AautiStrings?.teachWithAautiString}
              className={"subText"}
            />
            <AautiText
              onClick={() => {
                navigate("/whyAauti");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.aboutUSString}
            />
            <AautiText
              onClick={() => {
                navigate("/careers");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.careersString}
            />
          </div>
          <div className="footer-con1">
            <AautiText
              onClick={() => {
                navigate("/institutions");
              }}
              title={AautiStrings?.aautiForInstitutionsString}
              className={"subText"}
            />
            <AautiText
              onClick={() => {
                navigate("/blog");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.blogString}
            />

            <AautiText
              onClick={() => {
                navigate("/newletter");
              }}
              className={"hover-text"}
              weight={"normal"}
              size={"medium"}
              title={AautiStrings?.newsletterString}
            />
          </div>
          <div className="footer-con1button">
            <AautiText
              weight={"bold"}
              title={AautiStrings?.getTheAppString}
              textStyle={{ marginLeft: 10 }}
            />
            <div className="footerApplediv">
              <JoinButton
                className="buttonwidth"
                height="60px"
                title={AautiStrings?.googlePlayString}
                bgColor="#000000"
                text="Get it on"
                icon={<BsGooglePlay style={{ color: "#ffffff" }} size={25} />}
              />
              <JoinButton
                className="buttonwidth"
                height="60px"
                outlined
                title={AautiStrings?.appStoreString}
                text="Download on"
                icon={<BiLogoApple size={30} />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="Footerdev1">
        <div className="Footerdev2">
          <div className="Footerdev3">
            <CopyrightIcon sx={{ fontSize: 13, color: "grey" }} />
            <AautiText
              textStyle={{ color: "grey", cursor: "pointer" }}
              weight={"medium"}
              size={"semi"}
              title={
                AautiStrings?.twentyTwentyThreeAautiAllRightsReservedString
              }
            />
          </div>
          <AautiText
            onClick={() => {
              navigate("/privacypolicy");
            }}
            textStyle={{ color: "grey", cursor: "pointer" }}
            weight={"medium"}
            size={"semi"}
            title={AautiStrings?.privacyPolicyString}
          />
          <br />
          <AautiText
            onClick={() => {
              navigate("/termsandconditions");
            }}
            textStyle={{ color: "grey", cursor: "pointer" }}
            weight={"medium"}
            size={"semi"}
            title={AautiStrings?.termsAndConditionsString}
          />
        </div>
        <div className="Footerdev4">
          <FaXTwitter
            className={"hover-icon-Twitter"}
            size={18}
            style={{ cursor: "pointer" }}
          />

          <InstagramIcon
            sx={{ fontSize: 20, cursor: "pointer" }}
            className={"hover-icon-Instagram"}
          />
          <FacebookIcon
            sx={{ fontSize: 20, cursor: "pointer" }}
            className={"hover-icon-Facebook"}
          />
          <YouTubeIcon
            sx={{ fontSize: 20, cursor: "pointer" }}
            className={"hover-icon-YouTube"}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
