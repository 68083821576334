import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

export default function BasicPopover(props) {
    const {

        Id,
        isopen,
        anchor,
        onClose,
        Text1,
        Text2,
        Text1Function,
        Text2Function
    } = props
    const [anchorEl, setAnchorEl] = React.useState(anchor);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = isopen ? 'simple-popover' : undefined;

    return (
        <div>

            <Popover
                id={id}
                open={isopen}
                anchorEl={anchor}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0.5

                }}>
                    <Button size='small' sx={{ p: 1 }} onClick={() => Text1Function()}>{Text1}</Button>
                    <Button size='small' sx={{ p: 1 }} onClick={() => Text2Function()}>{Text2}</Button>
                </Box>
            </Popover>
        </div>
    );
}