import React from "react";
import "./styles.scss";
import { Box } from "@mui/material";
import Lottie from "react-lottie-player";
import SpinnerLottie from "../../assets/lotties/Spinner.json";
export default function Spinner() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
    >
      <Lottie
        loop
        play
        animationData={SpinnerLottie}
        // className={"lottie_styles"}
        style={{
          height: "130px",
          width: "130px",
        }}
      />
      {/* <img
        src="https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/logo-header.png"
        width="130.22px"
        height="50px"
        alt="logo"
      />
      <span className="loader" /> */}
    </Box>
  );
}
