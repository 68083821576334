import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

const urlConstants = {
  saveComment: "comment/saveComments",
  updateComment: "comment/update",
  deleteComment: "comment/deleteCommentById?",
  getCommentsByGigId: "comment/getDetailedCommentByGigId?",
  addLikedUsers: "comment/addLikedUsers?",
  addDislikedUsers: "comment/addDislikedUsers?",
};

function saveComment(data, onSuccess, onFailure) {
  return ApiRequest({
    url: urlConstants.saveComment,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}
function updateComment(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.updateComment,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function deleteComment(id, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.deleteComment,
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function getCommentsByGigId(params, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.getCommentsByGigId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}
function addLikedUsers(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.addLikedUsers,
    method: ApiMethods.PUT,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}
function addDislikedUsers(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.addDislikedUsers,
    method: ApiMethods.PUT,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const CommentServices = {
  saveComment,
  updateComment,
  deleteComment,
  getCommentsByGigId,
  addLikedUsers,
  addDislikedUsers,
};
