import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

export function deleteCourseAdhoc(id, onSuccess, onFailure) {
  ApiRequest({
    method: ApiMethods.DELETE,
    url: "mySchedule/deleteMySchedule?",
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const CourseAdhocCont = () => {
  const [courseList, setCourseAdhoc] = useState([]);
  const [sheetData, setSheetData] = useState({ open: false, batches: [] });
  const { loggedinUserId } = useSelector((state) => state.app);

  const getAdhocs = useCallback(() => {
    ApiRequest({
      url: "/mySchedule/getMySchedulesByUserId?",
      params: { userId: loggedinUserId, type: "course adhoc" },
    })
      .then((response) => {
        if (response?.data?.result) {
          setCourseAdhoc(response.data.result);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getAdhocs();
  }, []);

  const onPressDelete = (id) => {
    deleteCourseAdhoc(id, onDeleteSucess, (e) => {});
  };

  const onDeleteSucess = () => {
    getAdhocs();
  };

  return { courseList, onPressDelete, setSheetData };
};
