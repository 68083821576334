import React, { useEffect, useState, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "react-quill/dist/quill.snow.css";
import { getAllContries } from "../signIn/Services";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import AautiText from "./AautiText";
import "./styles.scss";
import { CapitalizeFirstLetter } from "../CommonFunctions";

const AautiTextInput = ({
  style,
  text,
  rows = 1,
  onChange,
  helperText,
  length,
  multiline,
  important,
  type,
  leftContent,
  value,
  disable,
  size,
  maxLength,
  error,
  labelWeight = "normal",
  id,
  onBlur,
  labelStyle,
  readOnly,
  defaultCountry,
  setDefaultCountry,
  inputProps,
  capitalize = true,
  contenttype = "absolute",
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCountries, setShowCountries] = useState(false);
  const [countries, setCountries] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowCountries(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const text33 = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "fit-content",
        zIndex: 250,
      }}
    >
      <AautiText
        weight={labelWeight}
        size={"normal"}
        title={text}
        textStyle={{ color: "#000" }}
      />
      {important && <Typography sx={{ color: "red" }}>*</Typography>}
    </div>
  );

  useEffect(() => {
    if (leftContent) {
      getAllContries(
        (res) => {
          if (res.status === "Success") {
            setCountries(res.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [leftContent, showCountries]);

  const returnCountries = () => {
    return (
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowCountries(!showCountries);
        }}
      >
        <img
          src={selectedCountry?.flag}
          alt="Co"
          style={{ width: 25, height: 20, marginRight: 10 }}
        />
        <AautiText
          title={selectedCountry?.phoneCode}
          textStyle={{ color: "#000000" }}
        />
        {showCountries ? <ArrowDropUp /> : <ArrowDropDown />}
      </div>
    );
  };

  const filteredCountries = countries.filter((each) => {
    return each?.name?.toLowerCase().includes(searchCountry.toLowerCase());
  });

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        marginTop: 10,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* {text !== "Search" && <InputLabel>{text33}</InputLabel>} */}
      <FormControl sx={{ ...style }} size={size || "small"} variant="outlined">
        <InputLabel
          sx={{
            color: "#000",
            fontWeight: 400,
            zIndex: 201,
            ...labelStyle,
          }}
          htmlFor={text}
        >
          {text}
          {important && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
        <OutlinedInput
          // required={important}
          label={`${text}${important ? "*" : ""}`}
          id={id ? id : text}
          size={size || "small"}
          error={error && error}
          readOnly={readOnly}
          multiline={multiline && true}
          rows={rows}
          inputProps={{
            maxLength: maxLength && maxLength,
            inputMode: type === "number" ? "numeric" : "text",
            "aria-label": "weight",
            ...inputProps,
          }}
          disabled={disable}
          autoComplete="off"
          sx={{
            bgcolor: disable ? "#f2f2f2" : null,

            zIndex: 200,
          }}
          type={type === "password" && !showPassword ? "password" : "text"}
          value={capitalize ? CapitalizeFirstLetter(value) : value} // Set the value here
          onChange={(e) => {
            const value =
              type === "number"
                ? e.target.value
                    .trimStart()
                    .replace(/\s+/g, " ")
                    .replace(/[^0-9]/g, "")
                : e.target.value.trimStart().replace(/\s+/g, " ");
            e.target.value = value;
            onChange(e);
            e.target.value === "" && setShowCountries(false);
          }}
          fullWidth
          startAdornment={
            leftContent && (
              <InputAdornment position="start">
                {returnCountries()}
              </InputAdornment>
            )
          }
          endAdornment={
            type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }
          {...rest}
        />
        {helperText && (
          <FormHelperText
            id="component-helper-text"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              color: error ? "red" : "grey",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      {showCountries && (
        <div
          ref={popupRef}
          style={{
            width: 300,
            backgroundColor: "#ffffff",
            position: contenttype,
            zIndex: 2223,
            borderRadius: 10,
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <FormControl
            sx={{ width: "100%", mb: 1, mt: 1 }}
            variant="outlined"
            size="small"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Search Country
            </InputLabel>
            <OutlinedInput
              onBlur={(e) => {
                onBlur && onBlur(e);
              }}
              inputProps={{ maxLength: maxLength && maxLength }}
              value={searchCountry}
              id="outlined-adornment-password"
              type="text"
              onChange={(e) => {
                setSearchCountry(e.target.value);
              }}
              fullWidth
              label="Search Country"
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
              maxHeight: 250,
              height: "auto",
            }}
          >
            {filteredCountries?.map((each, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setSelectedCountry(each);
                  setDefaultCountry(each);
                  setShowCountries(false);
                  setSearchCountry("");
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  src={each?.flag}
                  style={{ height: 20, width: 20 }}
                  alt="flag"
                />
                <AautiText title={each?.name} />
                <AautiText title={each?.phoneCode} />
              </MenuItem>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AautiTextInput;
