import { chatUrl } from '../service/api-constants.js'
import { MaxChatResultsCount, SocketEvents } from '../components/chat/constants'
import {
  connectionEstablished,
  disconnectSocket,
  setActiveRoom,
  setActiveUser,
  updateUnreadCount,
} from '../redux/reducer/chatSlice.js'

const socketMiddleware = (store) => {
  
  let socket = null
  let timerId
  let isConnected = false;

  function closeSocket() {
    timerId && clearInterval(timerId)
    socket?.close && socket.close()
    socket = null
    isConnected = false;
  }

  function onOpenSocket(){
      getUnreadCount()
      timerId && clearInterval(timerId)
  }

  const connectSocket = (userId) => {
    if ((isConnected || socket?.readyState === WebSocket.CONNECTING) && !userId){
      if(timerId) clearInterval(timerId)
      return;
    }
    socket = new WebSocket(`${chatUrl}${userId}`)
    socket.onopen = onOpenSocket

    socket.onmessage = (msgEvent) => {
      if(!isConnected && socket.readyState !== WebSocket.CONNECTING){
        store.dispatch(connectionEstablished())
        isConnected = true;
      }
      const { event, ...rest } = JSON.parse(msgEvent.data)
      switch (event) {
        case SocketEvents.ping:
          send({ event: SocketEvents.pong })
          break

        case SocketEvents.connected:
          onOpenSocket();
          break

        case SocketEvents.users.getUnreadCount:
          store.dispatch(updateUnreadCount(rest.unreadCount))
          break

        case SocketEvents.users.status:
          store.dispatch(setActiveUser(rest.data))
          break

        case SocketEvents.users.getSupport:
        case SocketEvents.room.getRoom:
          store.dispatch(setActiveRoom({ data: rest.data }))
          break

        default: {
          const eventHandler = getChatPropFromStore('eventHandler')
          if (typeof eventHandler === 'function') {
            eventHandler({ event, ...rest })
          } else {
            handleEventsInMiddleWare({ event, ...rest })
          }
          break
        }
      }
    }
    socket.onerror = closeSocket
    socket.onclose = closeSocket
  }

  function reconnectSocket(e) {
    const userId = localStorage.getItem("USER_ID");
    if (!socket ||
      (socket &&
      socket?.readyState &&
      socket.readyState === WebSocket.CLOSED) && !!userId
    ) {
      clearInterval(timerId)
      isConnected = false;
      timerId = setInterval(()=>connectSocket(userId), 12000)
    }
  }

  function getChatPropFromStore(prop) {
    const chatState = store.getState().chat
    return chatState?.[prop]
  }

  function handleEventsInMiddleWare(props) {
    const unreadCount = getChatPropFromStore('unreadCount')
    if (props?.data?.hasOwnProperty('message')) {
      store.dispatch(updateUnreadCount(unreadCount + 1))
    }
  }

  function getUnreadCount() {
    send({
      event: SocketEvents.users.getUnreadCount,
    })
  }

  function send(data) {
    if (isConnected) {
      const stringifiedData = JSON.stringify(data)
      socket?.send(stringifiedData)
    } 
    else {
      reconnectSocket()
    }
  }

  return (next) => (action) => {
    switch (action?.type) {
      case SocketEvents.connect:
        if (!isConnected) {
          connectSocket(action.payload)
        }
        break

      case disconnectSocket.type:
        closeSocket()
        break

      case SocketEvents.room.getConversations:
        getUnreadCount()
      case SocketEvents.getContacts:
      case SocketEvents.getCourseSubscribers:
      case SocketEvents.room.getRoom:
        send({
          event: action.type,
          ...action.payload,
          limit: MaxChatResultsCount,
        })
        break

      case SocketEvents.users.getUnreadCount:
        getUnreadCount()
        break

      case SocketEvents.room.getMessagesByRoom:
        send({
          event: action.type,
          roomId: action.roomId,
          pageNo: action.pageNo,
          limit: MaxChatResultsCount,
        })
        break

      case SocketEvents.room.deleteConversation: {
        const activeRoom = getChatPropFromStore('activeRoom')
        store.dispatch(
          setActiveRoom({
            data: { ...activeRoom, deleted: true },
          })
        )
      }
      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
        {
          const isEventIsFreeze = action.type === SocketEvents.room.freeze
          const activeRoom = getChatPropFromStore('activeRoom')
          store.dispatch(
            setActiveRoom({
              data: {
                ...activeRoom,
                adminFreezed: isEventIsFreeze,
              },
            })
          )
        }
      case SocketEvents.room.updateLastSeen:
      case SocketEvents.room.clearConversation:
      case SocketEvents.room.getMembers:
        send({ event: action.type, roomId:action.payload })
        break

      case SocketEvents.users.status:
        send({ event: action.type, userId: action.payload })
        break
      
      case SocketEvents.room.makeAdmin:
      case SocketEvents.room.makeModerator:
      case SocketEvents.room.removeModerator:
      case SocketEvents.room.removeAdminAccess:
      case SocketEvents.room.removeUser:
      case SocketEvents.room.leave:
      case SocketEvents.room.blockUser:
      case SocketEvents.room.unblockUser:
        {
          send({
            event: action.type,
            ...action?.payload??{},
          })
        }
        break

      case SocketEvents.room.updateInfo: {
        let activeRoom = getChatPropFromStore('activeRoom')
        store.dispatch(
          setActiveRoom({ data: { ...activeRoom, ...action.payload } })
        )
      }

      case SocketEvents.room.updateTimeFreeze:
      case SocketEvents.room.createRoom:
      case SocketEvents.message.send:
      case SocketEvents.message.edit:
      case SocketEvents.message.delete:
      case SocketEvents.message.messageDeleteForMe:
      case SocketEvents.aautiFeed.getFeedsAccess:
      case SocketEvents.aautiFeed.updateFeedsAccess:
      case SocketEvents.aautiFeed.recentFeed:
      case SocketEvents.users.getSupport:
      case SocketEvents.room.addUsers:
        send({
          event: action.type,
          ...(action?.payload ?? {}),
        })
        break
    }
    next(action)
  }
}

export default socketMiddleware
