// import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { method } from "lodash";
import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

/** login authentication
 * @param username, password
 */
function getOrderDetails(params, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_RECENT_ORDER_DETAILS,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getRefundRequestDetails(params, onSuccess, onFailure) {
  ApiRequest({
    url: "orderDetails/requestRefund",
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCompleteOrderDetails(orderId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETCOMPLETEORDERDETAILS,
    method: ApiMethods.GET,
    params: {
      orderId,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getInvoiceDetailsByOrderId(orderId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_GENARATEINVOICE_BY_ORDER_ID,
    method: ApiMethods.GET,
    params: { orderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveRatingsReviews(ratingsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_RATINGS_REVIEWS,
    method: ApiMethods.POST,
    data: ratingsData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRelatedGigsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_RELATED_GIGS + "?id=" + id,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSessionsByCourseIdBatchId(
  courseId,
  batchId,
  userId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      ApiPaths.GET_SESSIONBY_COURSEID_BATCHID +
      "courseId=" +
      courseId +
      "&batchId=" +
      batchId +
      "&userId=" +
      userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getBlockBusterDeals(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_BLOCK_BUSTER_DEALS,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function refundRequest(refundData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.REFUND_REQUEST,
    method: ApiMethods.POST,
    data: refundData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllRefundRequests(params, onSuccess, onFailure) {
  ApiRequest({
    url: "paymentRefund/getAllRefundByEndUserId",
    method: ApiMethods.GET,
    params: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getHighRatedCourses(params, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getTopRatedCoursesForEndUser",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getCoursesCountByCategories(params, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesCountByCategoryAndSubcategory",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getcourseslistBycategory2(
  category,
  offset,
  count,
  endUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_COURSES_BY_CATEGORY,
    method: ApiMethods.POST,
    data: {
      category: [category],
      offset,
      count,
      endUserId,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getCoursesListByCategory(params, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesListByCategory",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getGigsListByCategory(params, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getGigsListByCategory",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getGigsCountByCategoryAndSubcategory(params, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getGigsCountByCategoryAndSubcategory",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAllContries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  getOrderDetails,
  getCompleteOrderDetails,
  getInvoiceDetailsByOrderId,
  saveRatingsReviews,
  getRelatedGigsById,
  getBlockBusterDeals,
  refundRequest,
  getRefundRequestDetails,
  getAllRefundRequests,
  getSessionsByCourseIdBatchId,
  getHighRatedCourses,
  getCoursesCountByCategories,
  getCoursesListByCategory,
  getGigsListByCategory,
  getGigsCountByCategoryAndSubcategory,
  getcourseslistBycategory2,
  getAllContries,
};
