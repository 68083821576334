import "./Style.scss";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import JoinButton from "../globalComponents/JoinButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  setReloadCart,
  setSignupScreenIndex,
  toggleSignInPop,
} from "../../redux/reducer/appReducer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Popover,
} from "@mui/material";
import AautiText from "../globalComponents/AautiText";
import AautiDialog from "../globalComponents/AautiDialog";
import { logoutFunction } from "../CommonFunctions";
import CountrySelect from "./CountrySelect";
import HeaderTabsLogin from "./HeaderTabsLogin";
import { isEmpty } from "lodash";
import { BsCartCheck } from "react-icons/bs";
import { HeaderContainer } from "./Container";
import { HeaderStrings } from "./constants";
import TodaysEvents from "../home/TodaysEvents";
import Notifications from "../home/notifications/index";
import BottomSheet from "../globalComponents/BottomSheet";
import { SearchRounded } from "@mui/icons-material";
import { SlGlobe } from "react-icons/sl";
import { GlobeIcon } from "../homeScreen/header/pageHeader/pageHeaderUtils";
const Header = () => {
  const {
    handleOpenConfirmation,
    closeCountryPop,
    handleOnChange,
    payment,
    saveUserRole,
    id,
    handleCloseNot,
    handleUserRole,
    handleClickNot,
    // handleMobileClick,
    TabsData,
    handleMenuClick,
    menuOpen,
    profilePercentage,
    filteredCountries,
    allCountries,
    unreadCount,
    loadingCountNot,
    navigation,
    mobileDevices,
    ipodDevices,
    pathname,
    isUserLoggedIn,
    relaodCart,
    FirstName,
    UserRole,
    userDetails,
    cartCount,
    countryObject,
    cartsDataBeforeLogin,
    dispatch,
    toggleCurrency,
    confirmation,
    setConfirmation,
    setAnchorElNot,
    setToggleCurrency,
    openNot,
    anchorElNot,
    userRole,
    handleMenuClose,
    setSelectedTab,
    selectedTab,
    handleDrawerOpen,
    eventsResponsiveHandle,
    openTodayEvent,
    setOpenTodatEvents,
  } = HeaderContainer();

  return (
    <div className="hd-container" style={{ display: payment && "none" }}>
      <header className="Header-div1">
        <div className="Header-div2">
          <div
            className="hd-con2"
            style={{
              paddingLeft: isUserLoggedIn
                ? mobileDevices
                  ? "1%"
                  : "3%"
                : !isUserLoggedIn && mobileDevices
                ? "1%"
                : "5%",
              paddingRight: isUserLoggedIn
                ? mobileDevices
                  ? "1%"
                  : "3%"
                : !isUserLoggedIn && mobileDevices
                ? "1%"
                : "5%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <img
                onClick={() => {
                  navigation("/");
                }}
                src={
                  userDetails?.role?.includes(HeaderStrings?.instituteAdmin)
                    ? userDetails?.profileImage
                    : HeaderStrings?.altImage
                }
                alt="Logo Header"
                className="Header-img1"
                style={{
                  width: userDetails?.role?.includes(
                    HeaderStrings?.instituteAdmin
                  )
                    ? 80
                    : "auto",
                }}
              />
              {userDetails?.role?.includes(HeaderStrings?.instituteAdmin) && (
                <AautiText
                  title={HeaderStrings?.poweredByAauti}
                  className={"Header-text1"}
                />
              )}
            </Box>

            {!mobileDevices && !ipodDevices && !isUserLoggedIn && (
              <Tabs className="Header-tab1" value={selectedTab}>
                {TabsData?.map((each) => {
                  return (
                    <Tab
                      onClick={() => {
                        navigation(each?.screenName);
                      }}
                      key={each?.id}
                      label={each?.label}
                      value={each.value}
                      onChange={() => {
                        setSelectedTab(each.value);
                      }}
                      className="Header-tab2"
                      style={{
                        color:
                          pathname === each.screenName ? "#078dda" : "black",
                        textTransform: "capitalize",
                        fontSize: 16,
                        fontWeight: "bold",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    />
                  );
                })}
              </Tabs>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
              }}
            >
              <IconButton
                size="medium"
                onClick={() => {
                  navigation("/search-result");
                }}
              >
                <SearchRounded />
              </IconButton>
              <IconButton
                onClick={() => setToggleCurrency(true)}
                sx={{
                  display: mobileDevices && "none",
                  position: "relative",
                  width: "auto",
                  height: "auto",
                }}
                style={{ marginRight: 3 }}
              >
                <GlobeIcon />
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    display: "flex",
                    alignSelf: "center",
                    background: "#fff",
                    borderRadius: 10,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 9 }}>
                    {countryObject?.countryCurrency || ""}
                  </p>
                </div>
              </IconButton>

              {isUserLoggedIn ? (
                <>
                  <HeaderTabsLogin
                    {...{
                      handleMenuClose,
                      profilePercentage,
                      menuOpen,
                      userDetails,
                      FirstName,
                      userRole,
                      handleUserRole,
                      UserRole,
                      navigation,
                      logoutFunction,
                      mobileDevices,
                      handleCloseNot,
                      anchorElNot,
                      id,
                      openNot,
                      handleClickNot,
                      unreadCount,
                      loadingCountNot,
                      handleMenuClick,
                      countryObject,
                      setToggleCurrency,
                      setAnchorElNot,
                      handleOpenConfirmation,
                      cartCount,
                      dispatch,
                      relaodCart,
                      pathname,
                      eventsResponsiveHandle,
                    }}
                  />
                </>
              ) : (
                <div className="head-buttons">
                  {cartsDataBeforeLogin?.length > 0 ? (
                    <Badge
                      color="warning"
                      overlap="circular"
                      showZero
                      badgeContent={
                        loadingCountNot ? (
                          <CircularProgress size={10} color="inherit" />
                        ) : (
                          cartsDataBeforeLogin?.length || 0
                        )
                      }
                      aria-describedby={id}
                      variant="contained"
                      onClick={() => {
                        navigation("/cart");
                      }}
                      sx={{ cursor: "pointer", mr: 2 }}
                      max={99}
                    >
                      <BsCartCheck
                        size={mobileDevices ? 18 : 22}
                        className="Header-icon2"
                      />
                    </Badge>
                  ) : (
                    <BsCartCheck
                      onClick={() => {
                        navigation("/cart");
                      }}
                      size={mobileDevices ? 18 : 22}
                      className="Header-icon1"
                    />
                  )}

                  {!mobileDevices && (
                    <Popover
                      sx={{
                        p: 1,
                        "& .MuiPopover-paper": {
                          p: 2,
                          width: "420px",
                          maxWidth: "600px",
                        },
                      }}
                      id={id}
                      open={openNot}
                      anchorEl={anchorElNot}
                      onClose={handleCloseNot}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Notifications
                        handleCloseNot={handleCloseNot}
                        setAnchorElNot={setAnchorElNot}
                        anchorElNot={anchorElNot}
                        unreadCount={unreadCount}
                      />
                    </Popover>
                  )}
                  {mobileDevices && (
                    <Drawer
                      anchor={"right"}
                      open={openNot}
                      onClose={handleCloseNot}
                      sx={{ width: "100%" }}
                      id="NotDrawer"
                    >
                      <Notifications
                        handleCloseNot={handleCloseNot}
                        setAnchorElNot={setAnchorElNot}
                        anchorElNot={anchorElNot}
                        unreadCount={unreadCount}
                      />
                    </Drawer>
                  )}
                  <JoinButton
                    outlined
                    onClick={() => {
                      dispatch(toggleSignInPop(true));
                    }}
                    title={"Sign In"}
                    height={mobileDevices ? "auto" : "35px"}
                    buttonStyle={{
                      borderWidth: "0px",
                      width: "fit-content !important",
                    }}
                    className="Header-button2"
                  />
                  <JoinButton
                    onClick={() => {
                      dispatch(setSignupScreenIndex(1));
                      dispatch(toggleSignInPop(true));
                    }}
                    height={mobileDevices ? "30px" : "40px"}
                    title={"Sign Up"}
                    className="Header-button3"
                    buttonStyle={{ width: mobileDevices && "fit-content" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <>
        {mobileDevices ? (
          <AautiDialog
            onClose={() => {
              closeCountryPop();
            }}
            open={toggleCurrency}
            dialogTitle={HeaderStrings?.SelectCurrency}
          >
            <CountrySelect
              {...{
                countryObject,
                allCountries,
                handleOnChange,
                filteredCountries,
                closeCountryPop,
              }}
            />
          </AautiDialog>
        ) : (
          <BottomSheet
            onClose={() => {
              closeCountryPop();
            }}
            open={toggleCurrency}
            dialogTitle={HeaderStrings?.SelectCurrency}
          >
            <CountrySelect
              {...{
                countryObject,
                allCountries,
                handleOnChange,
                filteredCountries,
                closeCountryPop,
              }}
            />
          </BottomSheet>
        )}
      </>
      {/* <AautiDialog
        sxStyles={{
          zIndex: 5000,
        }}
        open={!isEmpty(confirmation)}
        onClose={() => {
          setConfirmation(null);
        }}
        dialogTitle={
          confirmation !== HeaderStrings?.logout && HeaderStrings?.confirmation
        }
      >
        <AautiText
          title={`${HeaderStrings?.SureText} ${
            confirmation === HeaderStrings?.logout
              ? HeaderStrings?.logoutCap
              : confirmation === HeaderStrings?.delete
              ? HeaderStrings?.deleteAccount
              : HeaderStrings?.provideCourse
          }?`}
          textAlign={"center"}
        />

        <div className="Header-div3">
          <button
            onClick={() => {
              setConfirmation(null);
            }}
            className="Header-button4"
          >
            {HeaderStrings?.cancel}
          </button>
          <button
            onClick={() => {
              if (confirmation === HeaderStrings?.logout) {
                navigation("/");
                logoutFunction();
                setConfirmation(null);
              } else if (confirmation === HeaderStrings?.delete) {
                setConfirmation(null);
              } else {
                saveUserRole();
              }
            }}
            className="Header-button4"
          >
            {HeaderStrings?.okay}
          </button>
        </div>
      </AautiDialog> */}
      {/* {console.log("confirmmj", confirmation)} */}
      <Dialog
        open={!isEmpty(confirmation)}
        maxWidth={"sm"}
        onClose={() => {
          setConfirmation(false);
        }}
        sx={{ display: isEmpty(confirmation) && "none" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2 }}>
          {confirmation !== HeaderStrings?.logout &&
            HeaderStrings?.confirmation}
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={() => setConfirmation(null)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          })}
        >
          <HighlightOffIcon />
        </IconButton> */}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <AautiText
              title={`${HeaderStrings?.SureText} ${
                confirmation === HeaderStrings?.logout
                  ? HeaderStrings?.logoutCap
                  : confirmation === HeaderStrings?.delete
                  ? HeaderStrings?.deleteAccount
                  : confirmation == HeaderStrings?.provideCourse &&
                    HeaderStrings?.provideCourse
              }?`}
              textAlign={"center"}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="Header-div3">
            <button
              onClick={() => {
                setConfirmation(false);
              }}
              className="Header-button4"
            >
              {HeaderStrings?.cancel}
            </button>
            <button
              onClick={() => {
                if (confirmation === HeaderStrings?.logout) {
                  setConfirmation(false);
                  navigation("/");
                  logoutFunction();
                } else if (confirmation === HeaderStrings?.delete) {
                  setConfirmation(false);
                } else if (confirmation == HeaderStrings?.provideCourse) {
                  setConfirmation(false);
                  saveUserRole();
                }
              }}
              className="Header-button4"
            >
              {HeaderStrings?.okay}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <AautiDialog
        open={openTodayEvent}
        onClose={() => {
          setOpenTodatEvents(false);
        }}
      >
        <TodaysEvents />
      </AautiDialog>
    </div>
  );
};
export default Header;
