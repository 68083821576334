import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { appStorageServices } from "../../storage";
// import { defaultCountry } from "../../components/CommonFunctions";

const prevData = localStorage.getItem("CartDataBeforeLogin");

const defaultCountry = {
  _id: "624c1d2a28bc5658e358b969",
  name: "USA",
  timeZone: "UTC-10:00",
  countryCode: "US",
  countryCurrency: "USD",
  countryCurrencySymbol: "$",
  flag: "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/2807790_18165.jpg",
  phoneCode: "+1",
  gmtDifference: -180,
  description: "USA",
  deleted: false,
  region: "North America",
};

const initialState = {
  status: "idle",
  userLogin: {
    status: "idle",
    data: {},
  },
  aautiLoader: false,
  loginUserId: "",
  loginUser: null,
  userDetails: null,
  FirstName: localStorage.getItem("FirstName"),
  signInPop: false,
  signupType: "sign-in",
  UserRole: localStorage.getItem("ROLE")?.includes("instituteAdmin")
    ? "instituteAdmin"
    : localStorage.getItem("ROLE")?.includes("serviceprovider")
    ? "serviceprovider"
    : "endUser",
  registeredRole: "",
  categories: [],
  AllCourses: [],
  isUserLoggedIn: localStorage.getItem("Logged In") === "true",
  sideBarToggle: false,
  loggedinUserId: localStorage.getItem("USER_ID"),
  instituteId: localStorage.getItem("instituteId"),
  profileData: [],
  jitsiPipMode: false,
  jitsiFullScreenMode: false,
  paymentLoading: false,
  cartCount: 0,
  roomName: "",
  paymentComplete: false,
  buyNowData: null,
  userLocation: {},
  editLessonPlan: null,
  editCourse: null,
  editGig: null,
  draftCourse: null,
  cloneCourse: null,
  screenName: "",
  packageDetails: null,
  countries: [],
  professions: [],
  subCategories: [],
  cartsDataBeforeLogin: JSON.parse(prevData),
  signUpScreenIndex: 0,
  sessionDetails: {},
  eventSearchKey: "",
  jwtToken: "",
  countryObject: defaultCountry,
  pricingRates: {},
  membersListWithParent: [],
  isParent: false,
  membersList: [],
  selectedCategory: null,
  editPackage: null,
  countriesArray: [],
  orderData: {},
  adhocEditObject: {},
  todayEvents: null,
  hasEvents: false,
  createAdhocObject: null,
  // showUpcomingClass: true,
  askClicked: false,
  askObject: {},
  chatClick: false,
  relaodCart: false,
  showBackSignUp: false,
  activeGigObject: {},
  paymentStatus: "",
  allLanguages: [],
  showReceipt: false,
  chatScreen: false,
  spFollowId: null,
  spWishlistItem: null,
  editOffTimeObject: null,
  filterMetaData :{},
};

export const userLoginAction = createAsyncThunk("app/login", async (data) => {
  //   const response = await ""; import login service and use
  //   return response.data;
});

// export const getCountries = createAsyncThunk(
//   "countries/fetchData",
//   async (data) => {
//     alert("in????");
//     const res = await GetAllCountries();
//     return res?.data.result;
//   }
// );

const appReducerSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    editPackageObject: (state, action) => {
      state.editPackage = action.payload;
    },
    setEventsSearchKey: (state, action) => {
      state.eventSearchKey = action.payload;
    },

   

    updateMembersList(state, action) {
      state.membersList = action.payload;
      if (action.payload.length > 0) {
        state.membersListWithParent = [
          // {
          //   _id: state?.loggedinUserId,
          //   userName: "",
          //   displayName: "My Self",
          //   relation: "",
          //   email: "",
          //   isIndependent: true,
          //   profileImage: state?.userDetails?.profileImage,
          // },
          ...action.payload,
        ];
      } else {
        state.membersListWithParent = [];
      }
      state.isParent = action.payload?.length > 0;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setSessionDetails: (state, action) => {
      state.sessionDetails = action.payload;
    },
    setBuyNowdata: (state, action) => {
      state.buyNowData = action.payload;
    },
    editLessonPlanObject: (state, action) => {
      state.editLessonPlan = action.payload;
    },
    setDraftCourseObject: (state, action) => {
      state.draftCourse = action?.payload;
    },
    toggleStatus: (state, action) => {
      state.status = action.payload;
    },
    setAdhocEditObject: (state, action) => {
      state.adhocEditObject = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setAautiLoader: (state, action) => {
      state.aautiLoader = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setLoggedInUserId: (state, action) => {
      state.loginUserId = action.payload;
    },
    setUserdetails: (state, action) => {
      state.userDetails = action.payload;
    },
    // loginUser: (state, action) => {
    //   state.userLogin = action.payload;
    // },
    toggleSignInPop: (state, action) => {
      state.signInPop = action.payload;
    },
    setRegisteredType: (state, action) => {
      state.registeredRole = action.payload;
    },
    setSignUpType: (state, action) => {
      state.signupType = action.payload;
    },
    AllCategories: (state, action) => {
      state.categories = action.payload;
    },
    AllCourses: (state, action) => {
      state.AllCourses = action.payload;
    },
    loginUser: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    setAskClicked: (state, action) => {
      state.askClicked = action.payload;
    },
    setAskObject: (state, action) => {
      state.askObject = action.payload;
    },
    setChatClick: (state, action) => {
      state.chatClick = action.payload;
    },
    sideBarToggleName: (state, action) => {
      state.sideBarToggle = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    ChangeUserRole: (state, action) => {
      state.UserRole = action.payload;
    },
    setJitsiPipMode: (state, action) => {
      state.jitsiPipMode = action.payload;
    },
    setJitsiFullScreenMode: (state, action) => {
      state.jitsiFullScreenMode = action.payload;
    },
    setShowReceipt: (state, action) => {
      state.showReceipt = action.payload;
    },
    setPaymentLoading: (state, action) => {
      state.paymentLoading = action.payload;
    },
    setRoomName: (state, action) => {
      state.roomName = action.payload;
    },
    setPaymentComplete: (state, action) => {
      state.paymentComplete = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    editCourseObject: (state, action) => {
      state.editCourse = action.payload;
    },
    setSpFollowId: (state, action) => {
      state.spFollowId = action.payload;
    },
    setSpWishlistItem: (state, action) => {
      state.spWishlistItem = action.payload;
    },

    editGigObject: (state, action) => {
      state.editGig = action.payload;
    },
    CloneCourseObj: (state, action) => {
      state.cloneCourse = action.payload;
    },
    setScreenName: (state, action) => {
      state.screenName = action.payload;
    },
    setProfessions: (state, action) => {
      state.professions = action.payload;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    setCartsDataBeforeLogin: (state, action) => {
      state.cartsDataBeforeLogin = action.payload;
    },
    setSignupScreenIndex: (state, action) => {
      state.signUpScreenIndex = action.payload;
    },
    setReloadCart: (state, action) => {
      state.relaodCart = action.payload;
    },
    setShowBackSignUp: (state, action) => {
      state.showBackSignUp = action.payload;
    },
    setCountryObject: (state, action) => {
      if (action?.payload) {
        state.countryObject = action.payload;
      } else {
        state.countryObject = defaultCountry;
      }
    },
    setPricingRates: (state, action) => {
      state.pricingRates = action.payload;
    },
    setCountriesArray: (state, action) => {
      state.countriesArray = action.payload;
    },
    setTodayEvents: (state, action) => {
      state.todayEvents = action.payload;
    },
    setHasEvents: (state, action) => {
      state.hasEvents = action.payload;
    },
    // setShowUpcomingClass: (state, action) => {
    //   state.showUpcomingClass = action.payload;
    // },
    setCreateAdhocObject: (state, action) => {
      state.createAdhocObject = action.payload;
    },
    setActiveGigObject: (state, action) => {
      state.activeGigObject = action.payload;
    },
    setAllLanguages: (state, action) => {
      state.allLanguages = action.payload;
    },
    setChatScreen: (state, action) => {
      state.chatScreen = action.payload;
    },
    setEditOffTimeObject: (state, action) => {
      state.editOffTimeObject = action?.payload;
    },
    updateFilterMetaData :(state,action)=>{
      state.filterMetaData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAction.pending, (state, action) => {
        state.userLogin.status = "loading";
      })
      .addCase(userLoginAction.fulfilled, (state, action) => {
        state.userLogin.status = "success";
      })
      .addCase(userLoginAction.rejected, (state, action) => {
        state.userLogin.status = "failed";
      });
    // .addCase(getCountries.fulfilled, (state, action) => {
    //   state.countries = action.payload;
    // });
  },
});

export const {
  toggleStatus,
  setEventsSearchKey,
  updateMembersList,
  setJwtToken,
  setSessionDetails,
  setPricingRates,
  setCountryObject,
  setCartsDataBeforeLogin,
  setProfessions,
  setSubCategories,
  editLessonPlanObject,
  setAautiLoader,
  setLoggedInUserId,
  setUserdetails,
  loginUser,
  toggleSignInPop,
  setRegisteredType,
  setSignUpType,
  AllCategories,
  sideBarToggleName,
  AllCourses,
  setPaymentStatus,
  setProfileData,
  ChangeUserRole,
  setJitsiPipMode,
  setJitsiFullScreenMode,
  setPaymentLoading,
  setCartCount,
  setRoomName,
  setPaymentComplete,
  setCreateAdhocObject,
  setBuyNowdata,
  setUserLocation,
  editCourseObject,
  editGigObject,
  setScreenName,
  storePackageDetails,
  setSelectedCategory,
  setSignupScreenIndex,
  editPackageObject,
  setCountriesArray,
  setAdhocEditObject,
  setOrderData,
  setDraftCourseObject,
  CloneCourseObj,
  setTodayEvents,
  setHasEvents,
  // setShowUpcomingClass,
  setChatClick,
  setAskObject,
  setShowBackSignUp,
  setAskClicked,
  setReloadCart,
  setActiveGigObject,
  setShowReceipt,
  setAllLanguages,
  setChatScreen,
  setSpFollowId,
  setSpWishlistItem,
  setEditOffTimeObject,
  updateFilterMetaData
} = appReducerSlice.actions;
export default appReducerSlice.reducer;
