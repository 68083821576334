import "./styles.scss";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import moment from "moment";
import { IconButton, ImageList, ImageListItem } from "@mui/material";
import { getFileTypes } from "../../render_text_input/container";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { BsFiletypeXls } from "react-icons/bs";
import { BsFiletypeCsv } from "react-icons/bs";
import { BsFiletypePdf } from "react-icons/bs";
import Box from "@mui/material/Box";
import { BsFileEarmarkText } from "react-icons/bs";
import AautiText from "../../../../../globalComponents/AautiText";
import { MdChevronRight } from "react-icons/md";
import AautiDialog from "../../../../../globalComponents/AautiDialog";
import { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { RiFileVideoLine } from "react-icons/ri";
import { MdAudioFile } from "react-icons/md";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IoCloseSharp, IoCheckmarkSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { CalendarMonth } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { FaChevronRight } from "react-icons/fa";
import {
  setAskClicked,
  setAskObject,
  setChatClick,
} from "../../../../../../redux/reducer/appReducer";
import { chatConstants } from "../../../../constants";
export function RenderUserNameText(props) {
  const { newUserMessage, sentByMe, item } = props;
  const { loggedinUserId } = useSelector((state) => state.app);

  if (newUserMessage) {
    if (sentByMe) {
      return (
        <div className={"sent_by_me_avatar_container"}>
          {/* {item?.sender?.profileImage ? (
            <Avatar
              alt={item?.sender?.displayName}
              src={item?.sender?.profileImage}
              sx={{ width: 45, height: 45, backgroundColor: "#6abbe9" }}
            ></Avatar>
          ) : (
            <Avatar sx={{ bgcolor: deepPurple[500] }}>
              {item?.sender?.displayName?.[0]}
            </Avatar>
          )} */}
        </div>
      );
    } else if (item?.type === "SYSTEM") {
      return null;
    } else {
      return (
        <div className={"avatar_container"}>
          {item?.sender?.profileImage ? (
            <Avatar
              alt={item?.sender?.displayName}
              src={item?.sender?.profileImage}
              sx={{ width: 45, height: 45, backgroundColor: "#6abbe9" }}
            ></Avatar>
          ) : (
            <Avatar sx={{ bgcolor: deepPurple[500] }}>
              {item?.sender?.displayName[0]}
            </Avatar>
          )}
        </div>
      );
    }
  } else {
    if (item?.sender?._id !== loggedinUserId) {
      return (
        <div className={"avatar_container"}>
          {item?.sender?.profileImage ? (
            <Avatar
              alt={item?.sender?.displayName}
              src={item?.sender?.profileImage}
              sx={{ width: 45, height: 45, backgroundColor: "#6abbe9" }}
            ></Avatar>
          ) : (
            <Avatar sx={{ bgcolor: deepPurple[500] }}>
              {item?.sender?.displayName?.[0]}
            </Avatar>
          )}
        </div>
      );
    }
  }
}
export function RenderMessageText(props) {
  const {
    sentByMe,
    item,
    hoveredObj,
    editClicked,
    setEditClicked,
    handleMessageEdit,
    editedMessage,
    setEditedMessage,
    searchText,
    setSearchText,
    messageRefs,
  } = props;

  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pollDetailDialog, setPollDetailDialog] = useState(false);
  const [activeResults, setActiveResults] = useState(null);
  const [previewOpen, setPreview] = useState(null);
  const [imageDialog, setImageDialog] = useState(false);
  const [docDialog, setDocDialog] = useState(false);
  const [each, setEach] = useState({});
  function highlightText(text, query) {
    if (!query) return text;

    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span
          key={index}
          style={{
            color: "white",
            backgroundColor: "#3083EF",
            fontWeight: "bold",
            borderRadius: "3px",
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  const newText = highlightText(item.text, searchText);
  const handleConfirmDialogClose = () => {
    setDialogOpen(false);
  };
  const handlePollDetailDialogClose = () => {
    setPollDetailDialog(false);
  };

  const handleImgDialog = (each) => {
    setEach(each);
    setImageDialog(true);
  };

  const handleDocPreview = (each) => {
    setEach(each);
    setDocDialog(true);
  };

  const handleFileName = (file) => {
    const urlObject = new URL(file);
    const fileName = urlObject.pathname.split("/").pop();
    const capitalizedFileName =
      fileName.charAt(0).toUpperCase() + fileName.slice(1);
    return capitalizedFileName;
  };
  if (item?.type === "IMAGE") {
    if (item?.files?.every((_) => getFileTypes(_) === "IMAGE")) {
      return (
        <div
          className={`message_text_styles ${sentByMe} ? sent_by_me_text : not_sent_by_me_text`}
          style={{
            alignSelf: "flex-start",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "10px",
          }}
        >
          <ImageList
            sx={{
              width: 300,
              height: item?.files?.length > 1 ? 300 : "100%",
              margin: "5px",
            }}
            cols={item?.files?.length > 1 ? 2 : 1}
          >
            {item?.files?.map((item, key) => (
              <>
                <ImageListItem key={item}>
                  <img
                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                    alt={"alt"}
                    loading="lazy"
                    key={key}
                    style={{
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImgDialog(item)}
                  />
                </ImageListItem>
                <AautiDialog
                  open={imageDialog}
                  onClose={() => {
                    setImageDialog(false);
                  }}
                  dialogTitle={chatConstants?.previewImage}
                >
                  {each && (
                    <img
                      srcSet={`${each}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${each}?w=164&h=164&fit=crop&auto=format`}
                      alt={"alt"}
                      loading="lazy"
                      key={each._id}
                      style={{
                        borderRadius: "5px",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </AautiDialog>
              </>
            ))}
          </ImageList>

          {item?.text && (
            <p
              className={
                sentByMe ? "chat_file_text_container" : "chat_file_text_con_nsm"
              }
            >
              <span
                className={sentByMe ? "chat_file_text" : "chat_file_text_nsm"}
              >
                {newText}
              </span>
            </p>
          )}
        </div>
      );
    }
  }

  if (item.type === "AUDIO") {
    return (
      <div className={"files_map_container"}>
        {item?.files?.map((item, key) => {
          const fileExt = new URL(item).pathname
            .split("/")
            .pop()
            .split(".")
            .pop()
            .toUpperCase();
          const fileName = new URL(item).pathname.split("/").pop();
          return (
            <Box
              className={"document_icon_files"}
              sx={{
                border:
                  fileExt === "MP3" || fileExt === "AAC"
                    ? "0px"
                    : sentByMe
                    ? "solid 1px #5C5B57"
                    : "solid 1px rgb(104, 104, 104)",
              }}
              key={key}
            >
              <div
                style={{
                  padding: "10px",
                  minWidth: "300px",
                  backgroundColor: "#CCDCF5",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                <audio
                  className="audio-cont"
                  controls
                  src={item}
                  style={{
                    width: "90%",
                    marginLeft: "15px",
                    height: "40px",
                  }}
                />
              </div>
            </Box>
          );
        })}
        {item?.text && (
          <p
            className={
              sentByMe ? "chat_file_text_container" : "chat_file_text_con_nsm"
            }
          >
            <span
              className={sentByMe ? "chat_file_text" : "chat_file_text_nsm"}
            >
              {newText}
            </span>
          </p>
        )}
      </div>
    );
  }

  if (item.type === "VIDEO") {
    return (
      <div className={"files_map_container"}>
        {item?.files?.map((item, key) => {
          const fileExt = new URL(item).pathname
            .split("/")
            .pop()
            .split(".")
            .pop()
            .toUpperCase();
          const fileName = new URL(item).pathname.split("/").pop();
          return (
            <Box
              className={"document_icon_files"}
              sx={{
                border:
                  fileExt === "MP3" || fileExt === "AAC"
                    ? "0px"
                    : sentByMe
                    ? "solid 1px #5C5B57"
                    : "solid 1px rgb(104, 104, 104)",
              }}
              key={key}
            >
              <div
                className={"document_name_container"}
                style={{ width: "100%" }}
              >
                {(fileExt === "MP4" || fileExt === "WEBM") && (
                  <video
                    style={{
                      height: "180px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    src={item}
                    controls
                  ></video>
                )}

                {fileExt !== "MP4" && fileExt !== "WEBM" && (
                  <a href={item} className={"download_file_button_ic"}>
                    <IconButton>
                      <DownloadForOfflineOutlinedIcon
                        className={"download_icon_file"}
                        style={{
                          color: sentByMe ? "#5C5B57" : "rgb(104, 104, 104)",
                        }}
                      />
                    </IconButton>
                  </a>
                )}
              </div>
            </Box>
          );
        })}
        {item?.text && (
          <p
            className={
              sentByMe ? "chat_file_text_container" : "chat_file_text_con_nsm"
            }
          >
            <span
              className={sentByMe ? "chat_file_text" : "chat_file_text_nsm"}
            >
              {newText}
            </span>
          </p>
        )}
      </div>
    );
  }

  if (item.type === "DOCUMENT") {
    return (
      <div
        className={`message_text_styles ${sentByMe} ? sent_by_me_text : not_sent_by_me_text`}
        style={{
          height: "100%",
          alignSelf: "flex-start",
          marginTop: "-13px",
        }}
      >
        <ImageList
          sx={{ width: 300, height: item?.files?.length > 1 ? 300 : "100%" }}
          cols={item?.files?.length > 1 ? 2 : 1}
          rowHeight={80}
        >
          {item?.files?.map((item, key) => {
            const fileExt = new URL(item).pathname
              .split("/")
              .pop()
              .split(".")
              .pop()
              .toUpperCase();
            const fileName = new URL(item).pathname.split("/").pop();
            if (fileExt === "PDF") {
              return (
                <Box
                  className={"document_icon_files"}
                  sx={{
                    border: sentByMe ? null : "solid 1px rgb(104, 104, 104)",
                    padding: 0,
                    backgroundColor: "#CCDCF5",
                  }}
                  key={key}
                >
                  <div className={"document_name_container"}>
                    {fileExt === "XLSX" || fileExt === "XLS" ? (
                      <BsFiletypeXls
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    ) : fileExt === "CSV" ? (
                      <BsFiletypeCsv
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    ) : fileExt === "PDF" ? (
                      <BsFiletypePdf
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    ) : fileExt === "MP4" ? (
                      <RiFileVideoLine
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    ) : fileExt === "MP3" ||
                      fileExt === "AAC" ||
                      fileExt === "WEBM" ? (
                      <MdAudioFile
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    ) : (
                      <BsFileEarmarkText
                        className={
                          sentByMe ? "document_icon" : "document_icon_blue"
                        }
                      />
                    )}

                    <AautiText
                      size={"small"}
                      weight={"bold"}
                      textStyle={{
                        color: sentByMe ? "#5C5B57" : "rgb(104, 104, 104)",
                      }}
                      // className={sentByMe ? "document_icon" : "document_icon_blue"}
                      title={
                        fileName.length > 20 ? fileName.slice(0, 20) : fileName
                      }
                    />

                    <a href={item} className={"download_file_button_ic"}>
                      <IconButton>
                        <DownloadForOfflineOutlinedIcon
                          className={"download_icon_file"}
                          style={{
                            color: sentByMe ? "#5C5B57" : "rgb(104, 104, 104)",
                          }}
                        />
                      </IconButton>
                    </a>
                  </div>
                </Box>
              );
            } else {
              return (
                <>
                  <ImageListItem key={item}>
                    <img
                      srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item}?w=164&h=164&fit=crop&auto=format`}
                      alt={"alt"}
                      loading="lazy"
                      key={key}
                      style={{
                        borderRadius: "5px",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDocPreview(item)}
                    />
                  </ImageListItem>
                  <AautiDialog
                    open={docDialog}
                    onClose={() => {
                      setDocDialog(false);
                    }}
                    dialogTitle={chatConstants?.documentPreview}
                  >
                    {each && (
                      <img
                        srcSet={`${each}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${each}?w=164&h=164&fit=crop&auto=format`}
                        alt={"alt"}
                        loading="lazy"
                        key={each._id}
                        style={{
                          borderRadius: "5px",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    )}
                  </AautiDialog>
                </>
              );
            }
          })}
        </ImageList>

        {item?.text && (
          <p
            className={
              sentByMe ? "chat_file_text_container" : "chat_file_text_con_nsm"
            }
          >
            <span
              className={sentByMe ? "chat_file_text" : "chat_file_text_nsm"}
            >
              {newText}
            </span>
          </p>
        )}
      </div>
    );
  }

  if (item?.type === "AGENDA") {
    return (
      <div className="fd-ag-main">
        <AautiText
          textAlign={"left"}
          size={"small"}
          weight={"normal"}
          textStyle={{
            color: "#828282",
            marginBottom: 2,
          }}
          title={`Agenda Created by ${item?.sender?.displayName} `}
        />
        <div className="ag-fd-sub">
          <AautiText
            textAlign={"left"}
            size={"normal"}
            weight={"medium"}
            textStyle={{
              color: "#4B4D4D",
            }}
            title={item.title}
          />
          <div className="tp-ag-fd" onClick={() => setDialogOpen(true)}>
            <AautiText
              textAlign={"left"}
              size={"small"}
              weight={"medium"}
              textStyle={{
                color: "#4B4D4D",
              }}
              title={`${item?.topics.length} Topic's mentioned`}
            />
            <MdChevronRight />
          </div>
        </div>
        <AautiText
          weight={"auto"}
          textStyle={{
            color: !sentByMe ? "white" : "#828282",
            marginTop: "5px",
            display: "flex",
            alignSelf: "flex-end",
            fontSize: "8px",
          }}
          title={`${moment.utc(item?.createdAt).local().format("hh:mm A")}`}
        />
        <AautiDialog onClose={handleConfirmDialogClose} open={dialogOpen}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="agenda-card">
              <div className="agenda-card-sub">
                <AautiText
                  textAlign={"left"}
                  size={"medium"}
                  weight={"bold"}
                  textStyle={{
                    color: "#4B4D4D",
                    width: "100%",
                  }}
                  title={`${item?.title}`}
                />
              </div>
              <div className="agenda-sub-sub">
                {item?.topics.map((item, index) => (
                  <div key={index} className="det-age">
                    <GoDotFill
                      size={10}
                      style={{ marginTop: 5, marginRight: 10 }}
                    />
                    <div className="det-age-sub">
                      <div className="edit-cont">
                        <AautiText
                          textAlign={"left"}
                          size={"semi"}
                          weight={"medium"}
                          textStyle={{
                            color: "#4B4D4D",
                            width: "100%",
                          }}
                          title={`${item?.topicDescription}`}
                        />
                      </div>

                      <div className="files-cont">
                        {item?.uploadedFiles.map((sub, index) => (
                          <AautiText
                            key={index}
                            textAlign={"left"}
                            size={"semi"}
                            weight={"medium"}
                            textStyle={{
                              color: "#4B4D4D",
                              width: "100%",
                            }}
                            title={`${handleFileName(sub)}`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </AautiDialog>
      </div>
    );
  }

  if (item?.type === "task") {
    return (
      <>
        <div
          style={{
            background: "#f4f7fc",
            borderRadius: 6,
            marginBottom: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              gap: 10,

              padding: 10,
            }}
          >
            <AautiText
              title={item?.title}
              weight={"medium"}
              textStyle={{ color: "#000" }}
            />
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <div
                style={{
                  background: "#fff",
                  padding: 8,
                  borderRadius: 6,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "50%" }}>
                  <AautiText title={chatConstants?.startDate} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <CalendarMonth />
                    <AautiText
                      title={`${moment(item?.startDate).format(
                        "ddd, MMM D, YYYY"
                      )}`}
                      size={"small"}
                    />
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <AautiText title={chatConstants?.endDate} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <CalendarMonth />
                    <AautiText
                      title={`${moment(item?.endDate).format(
                        "ddd, MMM D, YYYY"
                      )}`}
                      size={"small"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setActiveResults(item?.assignedUsers);
              setPreview(true);
            }}
            style={{
              background: "#066cb5",
              padding: 15,
              width: "100%",
              border: "none",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={chatConstants?.results}
              textStyle={{ color: "#fff" }}
              weight={"medium"}
            />
          </button>
        </div>
        <AautiDialog
          open={!isEmpty(previewOpen) || !isEmpty(activeResults)}
          onClose={() => {
            setActiveResults(null);
            setPreview(null);
          }}
        >
          {!isEmpty(activeResults) ? (
            <div>
              <AautiText
                title={chatConstants?.students}
                size={"medium"}
                textStyle={{ color: "#000" }}
                weight={"medium"}
              />
              <div style={{ marginTop: 15 }}>
                {activeResults?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 6,
                      border: "1px solid lightgrey",
                      padding: 8,
                      borderRadius: 6,
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      <Avatar
                        src={each?.profileImage}
                        alt={each?.displayName}
                        sx={{ height: 40, width: 40 }}
                      />
                      <div>
                        <AautiText
                          title={each?.displayName}
                          weight={"medium"}
                          textStyle={{ color: "#000" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <FiberManualRecordIcon
                            sx={{ color: "red", fontSize: 12 }}
                          />
                          <AautiText
                            size={"small"}
                            title={each?.status}
                            weight={"medium"}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            previewOpen && (
              <div>
                <img
                  src={previewOpen}
                  alt="img-preview"
                  style={{
                    width: "100%",
                    height: "525px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            )
          )}
        </AautiDialog>
      </>
    );
  }

  if (item?.type === "ASK") {
    return (
      <div className="fd-ag-main">
        <AautiText
          textAlign={"left"}
          size={"small"}
          weight={"normal"}
          textStyle={{
            color: "#828282",
          }}
          title={`Ask Created by
              ${item?.sender?.displayName}`}
        />
        <div
          onClick={() => {
            dispatch(setAskClicked(true));
            dispatch(setChatClick(true));
            dispatch(setAskObject(item));
          }}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: 12,
            borderRadius: 4,
            border: "0px #E0E0E0 solid",
            backgroundColor: "#f4f7fc",
            justifyContent: "space-between",
            alignSelf: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: "97%",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "space-between",
              borderRadius: 4,
              border: "1px #E0E0E0 solid",
              backgroundColor: "#ffffff",
            }}
          >
            <AautiText
              title={item?.title}
              size={"normal"}
              textStyle={{ color: "#5C5B57" }}
            />

            <FaChevronRight size={12} />
          </div>
        </div>
      </div>
    );
  }

  if (item?.type === "POLL") {
    return (
      <div className="fd-ag-main">
        <AautiText
          textAlign={"left"}
          size={"small"}
          weight={"normal"}
          textStyle={{
            color: "#828282",
          }}
          title={`Poll Created by ${item?.sender?.displayName} `}
        />
        <div className="ag-fd-sub">
          <AautiText
            textAlign={"left"}
            size={"normal"}
            weight={"medium"}
            textStyle={{
              color: "#4B4D4D",
            }}
            title={item?.question}
          />
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"auto"}
            textStyle={{
              color: "#4B4D4D",
              marginTop: 2,
            }}
            title={`Total Votes : ${item?.totalVotes}`}
          />
          {item?.options.map((each) => (
            <div className="tp-ag-fd">
              <AautiText
                textAlign={"left"}
                size={"small"}
                weight={"medium"}
                textStyle={{
                  color: "#4B4D4D",
                }}
                title={`${each?.answer}`}
              />
            </div>
          ))}
        </div>
        <button
          className="all-votes-cont1"
          onClick={() => setPollDetailDialog(true)}
        >
          {chatConstants?.viewResults}
        </button>
        <AautiText
          weight={"auto"}
          textStyle={{
            color: !sentByMe ? "white" : "#828282",
            marginTop: "5px",
            display: "flex",
            alignSelf: "flex-end",
            fontSize: "8px",
          }}
          title={`${moment.utc(item?.createdAt).local().format("hh:mm A")}`}
        />
        <AautiDialog
          onClose={handlePollDetailDialogClose}
          open={pollDetailDialog}
          dialogTitle={chatConstants?.pollDetails}
        >
          <div className="dialog-main" style={{ flexDirection: "column" }}>
            <div className="pollD-sub">
              <AautiText
                size={"semi"}
                weight={"medium"}
                textStyle={{
                  color: "#4B4D4D",
                }}
                title={
                  chatConstants?.yourQuestionWillStandHereBelowFollowsTheAnswers
                }
              />
            </div>
            <div className="quest-dialog-cont">
              <div className="poll-card-inner">
                <AautiText
                  textAlign={"left"}
                  size={"normal"}
                  weight={"bold"}
                  textStyle={{
                    color: "#4B4D4D",
                  }}
                  title={`${item.question}`}
                />

                <div className="ans-cont" style={{ marginTop: 8 }}>
                  {item?.options?.map((each, index) => (
                    <>
                      <div
                        className="each-ans1"
                        key={index}
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                          marginBottom: 5,
                          padding: 10,
                          borderRadius: 5,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <AautiText
                            textAlign={"left"}
                            size={"normal"}
                            weight={"medium"}
                            textStyle={{
                              color: "#4B4D4D",
                            }}
                            title={`${each.answer}`}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </AautiDialog>
      </div>
    );
  }

  if (item?.type === "REPLY") {
    return (
      <>
        {editClicked && hoveredObj._id === item._id ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <input
              onChange={(e) => setEditedMessage(e.target.value)}
              type="text"
              value={editedMessage}
              style={{
                borderRadius: "5px",
                border: "none",
                outline: "none",
                padding: "5px",
                width: "110%",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "110%",
                marginBottom: "12px",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() => setEditClicked(false)}
                style={{
                  padding: "3px 8px 3px 8px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  backgroundColor: "white",
                  marginRight: "2px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
              >
                <IoCloseSharp />
              </button>
              <button
                onClick={handleMessageEdit}
                style={{
                  padding: "3px 8px 3px 8px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "green",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "3px",
                }}
              >
                <IoCheckmarkSharp color="white" />
              </button>
            </div>
          </div>
        ) : (
          <div className="reply-cont">
            <div
              className="reply-mes-cont"
              style={{ backgroundColor: sentByMe ? "white" : "#CCDCF5" }}
            >
              <p className="inc-mes-text-dn">
                {item?.reply?.sender?.displayName}
              </p>
              <p className="inc-mes-text-me">{item?.reply?.text}</p>
            </div>
            <p
              className={`message_text_styles ${sentByMe} ? sent_by_me_text : not_sent_by_me_text`}
            >
              {newText}
            </p>
          </div>
        )}
      </>
    );
  }

  if (item?.type === "assignment") {
    return (
      <>
        <div
          style={{
            background: "#dce7ef",
            borderRadius: 6,
            marginBottom: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              gap: 10,

              padding: 10,
            }}
          >
            <AautiText
              title={item?.title}
              weight={"medium"}
              textStyle={{ color: "#000" }}
            />
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <AautiText
                title={chatConstants?.assignedTo}
                weight={"medium"}
                textStyle={{
                  color: "#000",
                  background: "#fff",
                  borderRadius: 6,
                  padding: 8,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                  marginTop: 6,
                  background: "#fff",
                  borderRadius: 6,
                  padding: 8,
                }}
              >
                {item?.assignedUsers?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 6,
                      alignItems: "center",
                      border: "1px solid lightgrey",
                      borderRadius: 6,
                      padding: 6,
                    }}
                  >
                    <Avatar
                      src={each?.profileImage}
                      alt={each?.displayName}
                      sx={{ height: "35px", width: "35px" }}
                    />
                    <AautiText
                      title={each?.displayName}
                      textStyle={{ color: "#000" }}
                    />
                  </div>
                ))}
              </div>

              <div
                style={{
                  background: "#fff",
                  padding: 8,
                  borderRadius: 6,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "50%" }}>
                  <AautiText title={chatConstants?.startDate} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <CalendarMonth />
                    <AautiText
                      title={`${moment(item?.startDate).format(
                        "ddd, MMM D, YYYY"
                      )}`}
                      size={"small"}
                    />
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <AautiText title={chatConstants?.endDate} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <CalendarMonth />
                    <AautiText
                      title={`${moment(item?.endDate).format(
                        "ddd, MMM D, YYYY"
                      )}`}
                      size={"small"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setActiveResults(item?.assignedUsers);
              setPreview(true);
            }}
            style={{
              background: "#066cb5",
              padding: 15,
              width: "100%",
              border: "none",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={chatConstants?.results}
              textStyle={{ color: "#fff" }}
              weight={"medium"}
            />
          </button>
        </div>
        <AautiDialog
          open={!isEmpty(previewOpen) || !isEmpty(activeResults)}
          onClose={() => {
            setActiveResults(null);
            setPreview(null);
          }}
        >
          {!isEmpty(activeResults) ? (
            <div>
              <AautiText
                title={chatConstants?.students}
                size={"medium"}
                textStyle={{ color: "#000" }}
                weight={"medium"}
              />
              <div style={{ marginTop: 15 }}>
                {activeResults?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 6,
                      border: "1px solid lightgrey",
                      padding: 8,
                      borderRadius: 6,
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      <Avatar
                        src={each?.profileImage}
                        alt={each?.displayName}
                        sx={{ height: 40, width: 40 }}
                      />
                      <div>
                        <AautiText
                          title={each?.displayName}
                          weight={"medium"}
                          textStyle={{ color: "#000" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <FiberManualRecordIcon
                            sx={{ color: "red", fontSize: 12 }}
                          />
                          <AautiText
                            size={"small"}
                            title={each?.status}
                            weight={"medium"}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            previewOpen && (
              <div>
                <img
                  src={previewOpen}
                  alt="img-preview"
                  style={{
                    width: "100%",
                    height: "525px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            )
          )}
        </AautiDialog>
      </>
    );
  }

  if (item?.type === "SYSTEM") {
    return null;
  } else {
    if (item?.text) {
      return (
        <div style={{ padding: "5px" }}>
          {editClicked && hoveredObj._id === item._id ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <input
                onChange={(e) => setEditedMessage(e.target.value)}
                type="text"
                value={editedMessage}
                style={{
                  borderRadius: "5px",
                  border: "none",
                  outline: "none",
                  padding: "5px",
                  width: "110%",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "110%",
                  marginBottom: "12px",
                  marginTop: "5px",
                }}
              >
                <button
                  onClick={() => setEditClicked(false)}
                  style={{
                    padding: "3px 8px 3px 8px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    backgroundColor: "white",
                    marginRight: "2px",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                >
                  <IoCloseSharp />
                </button>
                <button
                  onClick={handleMessageEdit}
                  style={{
                    padding: "3px 8px 3px 8px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "green",
                    cursor: "pointer",
                    border: "none",
                    borderRadius: "3px",
                  }}
                >
                  <IoCheckmarkSharp color="white" />
                </button>
              </div>
            </div>
          ) : (
            <AautiText
              className={`message_text_styles ${sentByMe} ? sent_by_me_text : not_sent_by_me_text`}
              title={newText}
              textStyle={{
                margin: "5px",
                wordWrap: "break-word",
                backgroundColor: item?.matchesSearch && "#db7b83",
                maxWidth: sentByMe ? "30vw" : "30vw",
                color: "#1E1E1E",
              }}
              weight={"medium"}
              size={"semi"}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
