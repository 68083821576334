import { Box } from "@mui/material";
import React from "react";
import "../styles.scss";

const CatCards = ({ navigate, exploreData }) => {
  return (
    <section id="FINDING_SLIDER">
      <Box className="slider_container_box flex_container">
        {exploreData?.data?.map((slide, index) => (
          <Box
            className="slide_content_box"
            key={index}
            onClick={() => {
              navigate(`courses/${slide?.category}`);
            }}
          >
            <img
              src={
                slide?.categoryImage ||
                "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
              }
              alt={slide?.category}
              className="slider_image"
            />
            <Box className="slider_content_box_wrapper">
              <Box className="slider_content_box_learners_box">
                <Box className="slider_content_box_learners_text">
                  {slide?.subscribersCount == 1
                    ? `${slide?.subscribersCount || 0} Learner`
                    : `${slide?.subscribersCount || 0} Learners`}
                </Box>
                <Box className="slider_content_box_learners_text">
                  {slide?.coursesCount == 1
                    ? `${slide?.coursesCount || 0} Course`
                    : `${slide?.coursesCount || 0} Courses`}
                </Box>
              </Box>
              <Box className="slider_content_box_title_text">
                {slide?.category || ""}
              </Box>
              <Box className="slider_content_box_certified_box">
                <Box className="slider_content_box_certified_text">
                  {`${slide?.aautiCertifiedUsers || ""} Aauti Certified`}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </section>
  );
};

export default CatCards;
