// import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

/** login authentication
 * @param username, password
 */

function getServiceProviderDetails(id, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id, endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRatingsReviewsByServiceProviderId(
  serviceProviderId,
  sortBy,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByServiceProviderId",
    method: ApiMethods.GET,
    params: {
      serviceProviderId: serviceProviderId,
      sortBy: sortBy,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getCourseReviewsById(courseId, sortBy, onSuccess, OnFailure) {
  ApiRequest({
    url: ApiPaths.GET_REVIEWS_BY_COURSE_ID + courseId + "&sortBy=" + sortBy,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      OnFailure(error);
    }
  );
}
function getGigReviewsById(gigId, sortBy, onSuccess, OnFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_GIG_REVIEWS_BY_GIGID +
      `gigId=${gigId}` +
      "&sortBy=" +
      sortBy,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      OnFailure(error);
    }
  );
}

function getAdhocReviewsById(category, subCategory, serviceProviderId, sortBy, onSuccess, OnFailure) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByCategoryAndSubCategory",
    method: ApiMethods.GET,
    params: { category, subCategory, serviceProviderId, sortBy }
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      OnFailure(error);
    }
  );
}
function saveRatingsReviews(ratingsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_RATINGS_REVIEWS,
    method: ApiMethods.POST,
    data: ratingsData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteRatingsReviews(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_RATING_REVIEWS_BY_REVIEWSID,
    params: { id },
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateRatingsReviews(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_RATING_REVIEWS,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const getCourseRatings = (
  url,
  serviceProviderId,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: url,
    method: ApiMethods.GET,
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
};
export function getCourseDetails(
  loggedIn,
  id,
  endUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "myCourse/getCompleteCourseDetails",
    method: ApiMethods.GET,
    params: loggedIn ? { courseId: id, endUserId } : { courseId: id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getGigById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "/gig/getCompleteGigDetails",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const AuthService = {
  getServiceProviderDetails,
  getRatingsReviewsByServiceProviderId,
  saveRatingsReviews,
  deleteRatingsReviews,
  updateRatingsReviews,
  getCourseReviewsById,
  getGigReviewsById,
  getAdhocReviewsById,
  getCourseDetails,
  getGigById
};
