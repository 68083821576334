import { Box, Rating } from "@mui/material";
import React from "react";
import { truncateDescription } from "../../CommonFunctions";
import "../styles.scss";

const ReviewCards = ({ testimonialsData }) => {
  return (
    <section id="WHAT_OTHERS_TALK">
      <Box className="slider_container_box flex_container">
        {testimonialsData?.map((slide, index) => (
          <Box className="slide_content_box" key={index}>
            <Box className="slider_content_box_profile">
              <img
                src={slide?.image}
                alt={slide.title}
                className="slider_image"
              />
              <Box className="slider_content_box_profile_wrapper">
                <p className="slider_content_box_profile_name">
                  {slide?.name || "N/A"}
                </p>
                <p className="slider_content_box_profile_title">
                  {slide?.title || "N/A"}
                </p>
              </Box>
            </Box>
            <p className="slider_content_box_text">
              {truncateDescription(slide?.description)}
            </p>
            <Rating name="read-only" value={slide?.rating} readOnly />
          </Box>
        ))}
      </Box>
    </section>
  );
};

export default ReviewCards;
