import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import DeletionAlert from "../../assets/images/DeletionAlert.png";

const ConfirmationDialogRaw = (props) => {
  const { onClose, open, onClick } = props;

  const handleCancel = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          maxWidth: "320px",
          maxHeight: 435,
        },
      }}
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
    >
      <DialogContent
        sx={{
          padding: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="delete"
          style={{
            height: "200px",
            width: "200px",
            // marginLeft: "20%",
            alignSelf: "center",
          }}
          src={DeletionAlert}
        />
      </DialogContent>
      <DialogTitle>Are you sure want to delete?</DialogTitle>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          onClick={handleCancel}
          id="confirm-cancel"
        >
          Cancel
        </Button>
        <Button onClick={onClick} variant="contained" id="confirm-delete">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogRaw;
