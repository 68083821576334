import React, { useEffect, useState } from "react";
import "./index.scss";
import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AautiUpload from "../../globalComponents/AautiUpload";
import { LuUpload } from "react-icons/lu";
import CourseOverview from "./CourseOverview";
import CreateCourse3 from "../createCourse/CreateCourse3";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate, useBeforeUnload } from "react-router-dom";
import {
  ChatGptFunc,
  getAautiClassPricing,
  getAllLanguages,
  saveDraftCourse,
  saveDraftGig,
  updateDraftCourse,
  // getSpConflicts,
} from "../createCourse/Services";
import {
  getAllCategories,
  getRefundConditions,
} from "../courseDetails/Services";
import { Backdrop, CircularProgress, Drawer, setRef } from "@mui/material";
import CourseInPerson from "../createCourse/CourseInperson";
import CreateCourse2 from "../createCourse/CreateCourse2";
import CoursePricing from "./CoursePricing";
import { dailyMTObj } from "../createCourse/Constants";
import CourseImages from "./CourseImages";
import NavButton from "./NavButton";
import Tab from "./Tab";
import ActiveOverviewTab from "./ActiveOverviewTab";
import JoinButton from "../../globalComponents/JoinButton";
import GigImageFields from "../createCourse/gigCreation/GigImageFields";
import _, { isEmpty } from "lodash";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import GigPricing from "../createCourse/gigCreation/GigPricing";
import { showToast } from "../../globalComponents/Toast";
import {
  editCourseObject,
  editGigObject,
  setDraftCourseObject,
} from "../../../redux/reducer/appReducer";
import PreviewCourse from "./PreviewCourse";
import HelperText from "./HelperText";
import {
  formatDatesAndTimes,
  PriceConversions,
  timeZone,
  validateUrl,
} from "../../CommonFunctions";
import AautiDialog from "../../globalComponents/AautiDialog";
import RefundConditions from "./RefundConditions";
import MembersConditions from "./MembersConditions";
import { GoDotFill } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import ConflictCard from "../ConflictCard";
import { getSpConflicts } from "../../../service/auth-service";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { courseConstant } from "./courseConstant";
import RefundSectionView from "../courseDetails/RefundSectionView";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const tabsList = [
  {
    id: 1,
    name: "Title",
    text: "It also includes subjects the instructor might select like following below Levels, Category, Type, Location, Age, Description, Tags, etc... Instructor can also divide the Sessions which will reflect on the Lesson Planner",
  },
  {
    id: 2,
    name: "Lesson Plan",
    text: "You can create a Lesson Plan or pick from existing & find more ways to select it. ",
  },

  {
    id: 4,
    name: "Couse Type & Pricing",
    text: "Add Individual or Group slots for the students",
  },
];
let sessionObject = {
  sessionDuration: 60,
  topics: [
    {
      topicName: "",
      topicDescription: "",
      contentData: "",
      duration: 60,
      uploadContent: [],
      uploadDocument: [],
      videoURL: "",
      subTopics: [],
    },
  ],
};
let subTopicObject = {
  subTopicName: "",
  subTopicDescription: "",
  contentData: "",
  uploadContent: [],
  uploadDocument: [],
};
let topicObject = {
  topicName: "",
  topicDescription: "",
  contentData: "",
  duration: 60,
  uploadContent: [],
  uploadDocument: [],
  videoURL: "",
  subTopics: [],
};

const CourseCreation = () => {
  const [activeField, setActiveField] = useState("");
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [openAddress, setOpenAddress] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeSession, setactiveSession] = useState(0);
  const [activeTopic, setactiveTopic] = useState(0);
  const [activeSubTopic, setactiveSubTopic] = useState(0);
  const [conflictsList, setConflictsList] = useState(null);
  const [dialoTitle, setDialogTitle] = useState("");
  const date = new Date();
  const today = moment(date).format("YYYY-MM-DDT00:00:00");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    loggedinUserId,
    editCourse,
    userDetails,
    editGig,
    draftCourse,
    cloneCourse,
    countriesArray,
    countryObject,
  } = useSelector((state) => state.app);
  const { onlyPriceConvert, convertIndianAmount } = PriceConversions();
  const { mobileDevices } = useSelector((state) => state.app);
  const [showPreview, setShowPreview] = useState(false);
  const [refundConditions, setRefundConditions] = useState([]);
  const [ASPrincing, setASPring] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [saveApiLoad, setSaveApiLoad] = useState(false);
  const [openRefundConditions, setOpenRefundConditions] = useState(false);

  const userCountry = _.filter(countriesArray, {
    name: userDetails?.country,
  });

  const gb = {
    gigImage: editGig ? editGig?.gigImage : "",
    shortVideoUrl: editGig ? editGig?.shortVideoUrl : "",
    serviceProviderDisplayName: "",
    serviceProviderId: "",
    serviceProviderName: "",
    profileImage: "",
    gigPrice: editGig ? editGig?.gigPrice : 0,
    localGigPrice: editGig
      ? editGig?.localGigPrice
        ? editGig?.localGigPrice
        : onlyPriceConvert(editGig?.gigPrice)
      : 0,
    relatedDocuments: [],
    gigVideo: "",
    gigTopics: [],
    gigVideos: editGig ? editGig?.gigVideos : [],
    discountStartDate: today,
    discountEndDate: today,
    discountType: "percentage",
    gender: ["Male"],
    localGigDiscountValue: editGig ? editGig?.localGigDiscountValue : 0,
    gigDiscountValue: editGig ? editGig?.gigDiscountValue : 0,
    localDiscountPrice: editGig ? editGig?.localDiscountPrice : 0,
    discountPrice: editGig ? editGig?.discountPrice : 0,

    countryFlag:
      "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Flag_of_India.svg.png",
  };

  const [gigObject, setGigObject] = useState(gb);
  const itemsWithNewComponent = [...tabsList];
  itemsWithNewComponent.splice(2, 0, { isComponent: true });

  const [planObj, setPlanObj] = useState({
    name: "",
    description: "",
    category: "",
    subCategory: "",
    level: [],
    duration: 0,
    noOfSessions: 1,
    noOfTopics: 1,
    noOfSubTopics: 0,
    serviceProviderId: loggedinUserId,
    lessons: [sessionObject],
  });
  const dispatch = useDispatch();
  const [courseOverviewErrors, setCourseOverviewErrors] = useState({});
  const location = useLocation();
  const [open2, setOpen2] = useState(false);
  const prevData = localStorage.getItem("CreateCourseObj");

  const defaultLan = editGig
    ? editGig?.languages
    : isEmpty(userDetails?.preferredLanguages)
    ? ["English"]
    : userDetails?.preferredLanguages;
  const obj = {
    serviceProviderId: loggedinUserId,
    courseLevel: editGig ? editGig?.courseLevel : [],
    category: editGig ? editGig?.category : "",
    subCategory: editGig ? editGig?.subCategory : "",
    languages: defaultLan,
    groupLanguage: userDetails?.preferredLanguages?.[0] ?? "English",
    courseType: editGig || location.state == "Gig" ? ["Gig"] : [],
    searchTags: editGig ? editGig?.searchTags : [],
    inPerson: false,
    virtual: false,
    sponsoredCourse: false,
    blockbusterDeal: false,
    isClientLocation: false,
    radius: 0,
    radiusType: "Km",
    targetAgeGroups: editGig ? editGig?.targetAgeGroups : [],
    courseName: editGig ? editGig?.gigName : "",
    courseDescription: editGig ? editGig?.gigDescription : "",
    summary: editGig ? editGig?.summary : "",
    courseImage: "",
    relatedImages: editGig ? editGig?.relatedImages : [],
    gender: editGig?.gender
      ? editGig?.gender
      : userDetails?.gender
      ? [userDetails?.gender]
      : ["Male"],
    relatedDocuments: [],
    lessonPlan: {},
    sessionsCount: 1,
    recurrenceType: [],
    individualWeekly: [],
    individualDaily: [],
    groupWeekly: [],
    groupDaily: [],
    averageRating: 0,
    ratingCount: 0,
    address: "",
    timeZone: moment().utcOffset(),
    deleted: false,
    bookCoverPage: [],
    refundConditions: [],
    isRefundAvailable: false,
    // currencyCode:,
  };

  const [CreateCourseObj, setCreateCourseObj] = useState(
    editCourse
      ? editCourse
      : draftCourse
      ? {
          ...draftCourse,
        }
      : cloneCourse
      ? {
          ...cloneCourse,
          gender: cloneCourse?.gender || ["Male"],
          lessonPlan: cloneCourse?.lessonPlan
            ? cloneCourse?.lessonPlan
            : {
                name: cloneCourse?.courseName,
                description: cloneCourse?.courseDescription,
              },
          individualDaily: [],
          individualWeekly: [],
          groupDaily: [],
          groupWeekly: [],
          isRefundAvailable: false,
          refundConditions: [],
          endUserList: [],
        }
      : obj
  );
  const [open, setOpen] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const isGig = CreateCourseObj?.courseType?.includes("Gig");

  const defaultAddress = userDetails?.addressList?.filter(
    (each) => each?.isDefault == true
  );

  useEffect(() => {
    apiCalls();
    if (pathname == "/update-gig") {
      setCreateCourseObj((prev) => ({
        ...prev,
        courseType: ["Gig"],
      }));
    }
  }, []);

  useEffect(() => {
    if (CreateCourseObj?.category && CreateCourseObj?.subCategory) {
      getAautiClassPricing(
        loggedinUserId,
        CreateCourseObj?.category,
        CreateCourseObj?.subCategory,
        userDetails?.country,
        (res) => {
          if (res?.status == "Success") {
            setASPring(res?.result);
          }
        },
        (err) => {}
      );
    }
  }, [CreateCourseObj?.category, CreateCourseObj?.subCategory]);

  const draftCourse2 = (obj) => {
    const draftObj = { ...obj };
    const {
      searchTags,
      subCategory,
      category,
      relatedImages,
      courseName,
      courseDescription,
      languages,
      targetAgeGroups,
      courseLevel,
      summary,
    } = CreateCourseObj;
    const {
      gigImage,
      shortVideoUrl,
      gigVideos,
      gigPrice,
      discountType,
      discountStartDate,
      discountEndDate,
      localGigPrice,
      localGigDiscountValue,
    } = gigObject;
    const gigDiscountPrice = returnDiscPric();
    const gigObj = {
      gigName: courseName,
      gigDescription: courseDescription,
      createdBy: loggedinUserId,
      searchTags: searchTags,
      category: category,
      subCategory: subCategory,
      gigImage: gigImage,
      shortVideoUrl: shortVideoUrl,
      serviceProviderDisplayName: userDetails?.displayName,
      serviceProviderId: loggedinUserId,
      serviceProviderName: userDetails?.firstName,
      relatedImages: relatedImages,
      profileImage: userDetails?.profileImage,
      gigPrice: gigPrice,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      localGigPrice,
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      summary: summary,
      discountEndDate,
      discountStartDate,
      discountType,
      gigVideos,
      languages,
      targetAgeGroups,
      courseLevel,
      currencyCode: userCountry?.countryCode,
    };
    if (isGig) {
      saveDraftGig(
        gigObj,
        (response) => {
          showToast("info", response?.message);
          navigate("/view-gigs", { replace: true });
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      saveDraftCourse(
        draftObj,
        (response) => {
          showToast("info", response?.message);
          navigate("/my-courses", { replace: true });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  useEffect(() => {
    const handlePopstate = (event) => {
      const sameObjects = _.isEqual(CreateCourseObj, editCourse);
      if (
        !CreateCourseObj.courseName ||
        !CreateCourseObj.courseLevel ||
        !CreateCourseObj?.category ||
        !CreateCourseObj?.subCategory ||
        !CreateCourseObj?.courseLevel ||
        sameObjects
      ) {
        return;
      }

      const confirmationMessage = "Do you want to save changes as Drafts?";
      if (window.confirm(confirmationMessage)) {
        draftCourse2(CreateCourseObj);
      } else {
        event.preventDefault();
      }
    };
    const handleNavigation = (event) => {
      const sameObjects = _.isEqual(CreateCourseObj, editCourse);
      if (
        !CreateCourseObj.courseName ||
        !CreateCourseObj.courseLevel ||
        !CreateCourseObj?.category ||
        !CreateCourseObj?.subCategory ||
        !CreateCourseObj?.courseLevel ||
        sameObjects
      ) {
        return;
      }
      const confirmationMessage = "Do you want to save changes as Drafts?";
      if (event.target.closest("header") || event.target.closest("sidebar")) {
        if (window.confirm(confirmationMessage)) {
          event.preventDefault();
          draftCourse2(CreateCourseObj);
        }
      }
    };

    window.addEventListener("popstate", handlePopstate);
    window.history.pushState(null, document.title, window.location.href);
    document.addEventListener("click", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
      document.removeEventListener("click", handleNavigation);
    };
  }, [CreateCourseObj, location.pathname]);

  useBeforeUnload(
    React.useCallback(() => {
      if (
        CreateCourseObj?.courseName &&
        CreateCourseObj?.courseDescription &&
        CreateCourseObj?.category &&
        CreateCourseObj?.subCategory &&
        CreateCourseObj?.courseLevel
      ) {
        localStorage.setItem(
          "CreateCourseObj",
          JSON.stringify(CreateCourseObj)
        );
      }
    }, [CreateCourseObj])
  );
  React.useEffect(() => {
    if (prevData != null) {
      setCreateCourseObj(JSON.parse(prevData));
    }
  }, []);

  const apiCalls = () => {
    getAllCategories(
      (response) => {
        if (response?.status === "Success") {
          setAllCategories(response?.result);
          setDataCate(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    getAllLanguages(
      (response) => {
        if (response?.status === "Success") {
          languageSet(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const Virtual = () => {
    if (
      CreateCourseObj?.virtual === true ||
      CreateCourseObj?.inPerson === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isButtonEnabled =
    CreateCourseObj?.courseName?.length < 49 ||
    CreateCourseObj?.category === "" ||
    CreateCourseObj?.subCategory === "" ||
    CreateCourseObj?.courseLevel?.length === 0 ||
    (CreateCourseObj?.courseType?.includes("Individual") &&
      CreateCourseObj?.languages?.length < 1) ||
    (CreateCourseObj?.courseType?.includes("Group") &&
      CreateCourseObj?.groupLanguage?.length < 1) ||
    CreateCourseObj?.courseType?.length === 0 ||
    CreateCourseObj?.courseDescription?.length < 50 ||
    CreateCourseObj?.summary?.length < 50 ||
    CreateCourseObj?.targetAgeGroups?.length < 1 ||
    (!isGig && Virtual() === false) ||
    CreateCourseObj?.gender?.length < 1;

  const languageSet = (res) => {
    const newList = [];
    res.map((each) => newList.push(each.name));
    setLanguages(newList);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    setCreateCourseObj(obj);
    setOpen2(false);
  };
  const handleDisAgree = () => {
    setOpen2(false);
  };

  const changeCreateObj = (val, feild) => {
    setCreateCourseObj((prev) => ({
      ...prev,
      [feild]: val,
    }));

    if (feild == "virtual" || feild == "inPerson") {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        inPersonOrVirtual: "",
      }));
    } else {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        [feild]: "",
      }));
    }
  };

  const onChageDrodown = (e) => {
    setCreateCourseObj((prev) => ({
      ...prev,
      category: e,
    }));
    const newList = [];
    const b = allCategories.filter((each, index) => {
      return e === each?.categoryName;
    });
    b[0]?.subCategories?.map((each, index) =>
      newList.push(each.subCategoryName)
    );
    setSubCategories(newList);
  };

  const changePlanObject = (val, feild) => {
    setPlanObj((prev) => ({
      ...prev,
      [feild]: val,
    }));
  };

  const generateAIResp = (field) => {
    const { subCategory, courseLevel, courseName } = CreateCourseObj;
    let value = field == "courseDescription" ? "short description" : "summary";
    let levels = courseLevel?.join(",");
    let prompt = `generate ${value} for ${subCategory} course for ${levels} level with ${
      courseName || ""
    } in  exact ${
      value == "short description" ? 100 : 200
    } characters with out any special characters and quotes`;
    if (subCategory && courseLevel) {
      handleOpen();
      ChatGptFunc(
        prompt,
        (response) => {
          if (response) {
            // changeCreateObj(response, field);
            // setRefresh(!refresh);
            let resp2;
            if (value === "short description") {
              resp2 = response?.slice(1, 101);
              const obj = {
                ...CreateCourseObj?.lessonPlan,
                description: resp2,
              };
              changeCreateObj(obj, "lessonPlan");
            } else {
              resp2 = response?.slice(0, 200);
            }
            setCreateCourseObj((pre) => ({
              ...pre,
              [field]: resp2,
            }));
            setCourseOverviewErrors((prev) => ({
              ...prev,
              [field]: "",
            }));
          } else {
            showToast("info", response?.message);
          }
          handleClose();
        },
        (error) => {
          handleClose();
        }
      );
    } else {
      if (isEmpty(courseLevel)) {
        showToast("info", "Please choose course levels");
      } else if (!subCategory) {
        showToast("info", "Please choose category & subcategory");
      }
    }
  };

  const returnVideoName = (video) => {
    var filename = video.substring(video.lastIndexOf("/") + 1);
    var videoName = filename.split(".").slice(0, -1).join(".");
    return videoName;
  };

  const multipleVideoUpload = async (file, i) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    const json = await response.json();
    if (json.result) {
      const videoName = await returnVideoName(json?.result);
      let obj = {
        gigHours: 0,
        gigMinutes: 1,
        gigSeconds: 2,
        videoUrl: json?.result,
        videoTitle: videoName,
        topicsCount: 1,
        topics: [
          {
            topicName: videoName + " - 1",
            hours: 0,
            minutes: 1,
            seconds: 2,
          },
        ],
      };
      setGigObject((prev) => ({
        ...prev,
        gigVideos: [...prev?.gigVideos, obj],
      }));
    }
    handleClose();
  };

  const handleGigImage = async (e, field) => {
    if (field === "gigVideo") {
      handleOpen();
      const { files } = e.target;
      for (let i = 0; i < files.length; i++) {
        multipleVideoUpload(files[i], i);
      }
    } else {
      let data = e.target.files[0];
      uploadGigImage(data, field);
    }
  };

  const uploadGigImage = async (data, field) => {
    handleOpen();
    const formData = new FormData();
    formData.append("file", data);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await res.json();
    if (field === "gigImage" || field === "shortVideoUrl") {
      setGigObject((prev) => ({
        ...prev,
        [field]: responseJson?.result,
      }));
    } else if (field === "mediaFiles") {
      const relatedImages = [
        ...CreateCourseObj.relatedImages,
        responseJson?.result,
      ];
      setCreateCourseObj((prev) => ({
        ...prev,
        relatedImages: relatedImages,
      }));
    }
    handleClose();
  };
  const handleImageFields = (field, itemIndex) => {
    if (field === "mediaFiles") {
      const relatedImgs = [...CreateCourseObj?.relatedImages]?.filter(
        (each, index) => itemIndex !== index
      );
      setCreateCourseObj((prev) => ({
        ...prev,
        relatedImages: relatedImgs,
      }));
    } else {
      setGigObject((prev) => {
        const updatedState = { ...prev, [field]: "" };

        if (field === "gigVideo") {
          const filteredVideos = [...gigObject?.gigVideos]?.filter(
            (each, index) => itemIndex !== index
          );
          updatedState.gigVideos = filteredVideos;
        }
        return updatedState;
      });
    }
  };

  const handleAddTopic = (videoIndex) => {
    const { gigVideos } = gigObject;
    if (!isEmpty(gigVideos)) {
      let updatedGigObj = { ...gigObject };
      let updatedGigVideos = [...gigVideos];
      const newCount = updatedGigVideos[videoIndex]?.topicsCount + 1;
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topicsCount: newCount,
      };
      updatedGigVideos[videoIndex]?.topics?.push({
        topicName:
          updatedGigVideos[videoIndex]?.videoTitle +
          " - " +
          parseInt(updatedGigVideos[videoIndex]?.topics?.length + 1),
        hours: 0,
        minutes: 1,
        seconds: 2,
      });
      updatedGigObj.gigVideos = updatedGigVideos;
      setGigObject(updatedGigObj);
    }
  };

  const handleRemoveTopic = (videoIndex) => {
    const { gigVideos } = gigObject;
    if (!isEmpty(gigVideos)) {
      let updatedGigObj = { ...gigObject };
      let updatedGigVideos = [...gigVideos];
      const newCount = updatedGigVideos[videoIndex]?.topicsCount - 1;
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topicsCount: newCount,
      };
      updatedGigVideos[videoIndex]?.topics?.pop();
      updatedGigObj.gigVideos = updatedGigVideos;
      setGigObject(updatedGigObj);
    }
  };

  const handleOnChangeTopic = (val, videoIndex, field, topicIndex) => {
    if (field === "videoTitle") {
      const updatedGb = { ...gigObject };
      const updatedGigVideos = [...updatedGb.gigVideos];
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        videoTitle: val,
      };
      updatedGb.gigVideos = updatedGigVideos;
      setGigObject(updatedGb);
    } else if (field === "topicName") {
      const updatedGb = { ...gigObject };
      const updatedGigVideos = [...updatedGb.gigVideos];
      const updatedTopics = [...updatedGigVideos[videoIndex].topics];
      updatedTopics[topicIndex] = {
        ...updatedTopics[topicIndex],
        topicName: val,
      };
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topics: updatedTopics,
      };
      updatedGb.gigVideos = updatedGigVideos;
      setGigObject(updatedGb);
    }
  };

  function convertINRtoUSD(amountInINR) {
    const returnAmount = convertIndianAmount(amountInINR);

    return returnAmount || 0;
  }

  const handleDate = (date, field) => {
    const dateFormat = moment(date?.$d).format("YYYY-MM-DDT00:00:00");
    setGigObject((prev) => ({
      ...prev,
      [field]: dateFormat,
    }));
  };

  const handleChangePricing = (value, field) => {
    setGigObject((prev) => {
      const state = { ...prev };
      if (field === "discountType") {
        state.discountType = value;
      } else {
        state[field] = parseInt(value);
      }
      return state;
    });
  };

  const serviceProviderConflicts = (type, data) => {
    const zones = timeZone();
    let conflict = {
      serviceProviderId: loggedinUserId,
      startDate: moment(moment(data.startDate).format("YYYY-MM-DD"))
        .utc(true)
        .format(),
      endDate: moment(moment(data.endDate).format("YYYY-MM-DD"))
        .local()
        .endOf("day")
        .utc()
        .format(),

      excludedDates: [],
      timeZone: zones.localZone,
      offset: moment().utcOffset(),
      zone: zones.opposite,
    };

    const offset = moment().utcOffset();

    if (type == "Daily") {
      conflict.timeSlots = [...data.dailyTimeSlots];
      conflict.recurrenceType = "daily";
      // return conflict;
    } else {
      let weekData = {
        ["Sunday"]: [],
        ["Monday"]: [],
        ["Tuesday"]: [],
        ["Wednesday"]: [],
        ["Thursday"]: [],
        ["Friday"]: [],
        ["Saturday"]: [],
      };
      data.weekSlots.forEach((day, index) =>
        day.slots.forEach((each) => {
          // start time in minutes
          // const start =
          //   new Date(each.startTime).getHours() * 60 +
          //   new Date(each.startTime).getMinutes();

          // let day = getDay(index);

          // //positive offset
          // if (offset > 0 && start <= offset) {
          //   day = getDay(index - 1);
          // }

          //negative offset
          // if (offset < 0) {
          //   start = start - 1440;
          //   if (start >= offset) {
          //     day = getDay(index + 1);
          //   }
          // }
          weekData[day.day].push({
            startTime: moment(each.startTime).local().format(),
            endTime: moment(each.endTime).local().format(),
          });
        })
      );
      conflict = { ...conflict, ...weekData, recurrenceType: "weekly" };
    }

    getSpConflicts(
      conflict,
      (res) => {
        if (res?.status == "Success" && res?.result) {
          if (res?.result?.length > 0) {
            setConflictsList(res?.result);
          } else {
            showToast("info", "No Conflicts found");
          }
        }
      },
      (err) => {
        showToast("info", "No Conflicts found");
        console.log(err, "---error");
      }
    );
  };

  const checkYoutubeVideo = (url) => {
    const urlValidate =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
    return urlValidate.test(url);
  };

  const handleUploadVideoUrl = async (url, field) => {
    const validUrl = validateUrl(url);
    const youTubeVideo = checkYoutubeVideo(url);
    const getVideoID = (url) => {
      const urlObj = new URL(url);
      return urlObj.hostname;
    };

    const generateVideoName = (url) => {
      const videoID = getVideoID(url);

      return `Video - ${videoID || ""}`;
    };
    if (validUrl || youTubeVideo) {
      const videoName = await generateVideoName(url);
      let obj = {
        gigHours: 0,
        gigMinutes: 1,
        gigSeconds: 2,
        videoUrl: url,
        videoTitle: videoName,
        topicsCount: 1,
        topics: [
          {
            topicName: videoName,
            hours: 0,
            minutes: 1,
            seconds: 2,
          },
        ],
      };
      setGigObject((prev) => ({
        ...prev,
        gigVideos: [...prev?.gigVideos, obj],
      }));
    } else {
      showToast("info", "Please upload a valid url");
    }
  };

  const validateObj = (obj) => {
    const errors = {};
    // Validate array fields
    const arrayKeys = [
      "courseLevel",
      "languages",
      "courseType",
      "targetAgeGroups",
      "gender",
    ];
    arrayKeys.forEach((key) => {
      if (obj[key]?.length === 0) {
        errors[key] = `${key} should not be empty`;
      }
    });

    // Validate boolean fields
    if (!isGig && !obj.inPerson && !obj.virtual) {
      errors.inPersonOrVirtual = "Either 'inPerson' or 'virtual' must be true";
    }

    // Validate string fields
    const stringKeys = ["category", "subCategory"];
    stringKeys.forEach((key) => {
      if (!obj[key]) {
        errors[key] = `${key} should not be empty`;
      }
    });

    // Validate string length for courseName, courseDescription, and summary
    const minLengthKeys = ["courseName", "courseDescription", "summary"];
    minLengthKeys.forEach((key) => {
      if (!obj[key]) {
        errors[key] = `${key} should not be empty`;
      } else if (obj[key].length < 50) {
        errors[key] = `${key} should be at least 50 characters long`;
      }
    });

    setCourseOverviewErrors(errors);
    return errors;
  };

  const returnActiveContent = (active) => {
    switch (activeField) {
      case tabsList[0].name:
        return (
          <CourseOverview
            {...{
              categories,
              subCategories,
              CreateCourseObj,
              onChageDrodown,
              changeCreateObj,
              languages,
              setOpenAddress,
              openAddress,
              planObj,
              sessionObject,
              changePlanObject,
              generateAIResp,
              pathname,
              handleAgree,
              setOpen2,
              open2,
              handleDisAgree,
              setActiveField,
              isGig,
              isButtonEnabled,
              location,
              editCourse,
              draftCourse,
              cloneCourse,
              setCreateCourseObj,
              setSubCategories,
              allCategories,
              defaultAddress,
              courseOverviewErrors,
              validateObj,
              setCourseOverviewErrors,
            }}
          />
        );
      case tabsList[1].name:
        return (
          <div className="tab">
            <AautiText
              size={"medium"}
              weight={"bold"}
              title={AautiStrings?.LessonPlanString}
              textStyle={{ marginBottom: "15px" }}
            />
            <CreateCourse2
              {...{
                CreateCourseObj,
                changeCreateObj,
                planObj,
                setPlanObj,
                changePlanObject,
                sessionObject,
                activeSession,
                activeSubTopic,
                activeTopic,
                setactiveSession,
                setactiveTopic,
                setactiveSubTopic,
                topicObject,
                subTopicObject,
                dialoTitle,
                setDialogTitle,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ alignSelf: "flex-end" }}>
                <HelperText title={AautiStrings.LpNotMandatory} />
              </div>
              <NavButton
                text={AautiStrings?.NextString}
                handleClick={() => {
                  setActiveField("Course Image");
                }}
              />
            </div>
          </div>
        );
      case tabsList[2].name:
        return (
          <>
            {isGig ? (
              <div className="tab">
                <AautiText
                  size={"medium"}
                  weight={"bold"}
                  title={AautiStrings?.GigPricingString}
                  textStyle={{ marginBottom: "15px" }}
                />
                <GigPricing
                  {...{
                    returnRelatedPricing,
                    handleDate,
                    gigObject,
                    handleChangePricing,
                    convertINRtoUSD,
                    editGig,
                  }}
                />
              </div>
            ) : (
              <CoursePricing
                {...{
                  CreateCourseObj,
                  changeCreateObj,
                  convertINRtoUSD,
                  handleOpen,
                  handleClose,
                  returnRelatedPricing,
                  editCourse,
                  serviceProviderConflicts,
                  countryObject,
                }}
              />
            )}
            {!isGig && (
              <div
                className="tags-container"
                style={{ flexDirection: "column", padding: 15 }}
                onClick={() => setOpenRefundConditions(true)}
              >
                <div
                  style={{
                    gap: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AautiText title={"Refunds"} weight={"bold"} />
                  <ChevronRightIcon />
                </div>
                <div
                  style={{
                    width: mobileDevices ? "100%" : "50%",
                    marginTop: 10,
                    display:
                      CreateCourseObj?.isRefundAvailable &&
                      !isEmpty(CreateCourseObj?.refundConditions)
                        ? "block"
                        : "none",
                  }}
                >
                  <RefundSectionView
                    completeDetails={CreateCourseObj}
                    onClickEdit={() => {
                      setOpenRefundConditions(true);
                    }}
                    creation={true}
                  />
                </div>
              </div>
            )}
          </>
        );
      case "Course Image":
        const buttonDisabled =
          CreateCourseObj?.courseImage === "" ||
          CreateCourseObj?.relatedImages?.length < 2;

        const returnBool =
          gigObject?.gigImage === "" ||
          gigObject?.shortVideoUrl === "" ||
          gigObject?.gigVideos?.length === 0;

        return (
          <div className="tab">
            <AautiText
              size={"medium"}
              weight={"bold"}
              title={courseConstant.AautiAddImagesAndVideosString}
              textStyle={{ marginBottom: "15px" }}
            />

            {isGig ? (
              <GigImageFields
                {...{
                  handleGigImage,
                  gigObject,
                  handleImageFields,
                  CreateCourseObj,
                  handleAddTopic,
                  handleRemoveTopic,
                  handleOnChangeTopic,
                  uploadGigImage,
                  handleUploadVideoUrl,
                }}
              />
            ) : (
              <CreateCourse3
                {...{
                  CreateCourseObj,
                  changeCreateObj,
                  handleClose,
                  handleOpen,
                }}
              />
            )}

            <NavButton
              disabled={isGig ? returnBool : buttonDisabled}
              text={AautiStrings.NextString}
              handleClick={() => {
                setActiveField(tabsList[2].name);
              }}
            />
          </div>
        );
      default:
        break;
    }
  };
  const setDataCate = (res) => {
    const newList = [];
    res.map((each, index) => newList.push(each.categoryName));
    setCategories(newList);
  };

  const handleSetSelectAddress = (type) => {
    const { isClientLocation } = CreateCourseObj;
    if (isClientLocation) {
      setCreateCourseObj((prev) => ({
        ...prev,
        radiusType: type,
        address: selectedAddress,
      }));
      setOpenAddress(false);
      setSelectedAddress(undefined);
    } else {
      changeCreateObj(selectedAddress, "address");
      setOpenAddress(false);
      setSelectedAddress(undefined);
    }
  };

  const checkPrice = (keyName, ...arrays) => {
    for (const array of arrays) {
      if (array.length === 0) continue;
      for (const item of array) {
        if (item[keyName] === 0) {
          return false;
        }
      }
    }
    return true;
  };

  const returnDiscPric = () => {
    const {
      discountType,
      discountStartDate,
      discountEndDate,
      localGigPrice,
      localGigDiscountValue,
    } = gigObject;
    const now = moment();
    const start = moment(discountStartDate);
    const end = moment(discountEndDate);

    if (now.isBetween(start, end, undefined, "[]")) {
      if (discountType == "percentage") {
        const discount = (localGigPrice * localGigDiscountValue) / 100;
        return localGigPrice - discount;
      } else {
        return localGigPrice - localGigDiscountValue;
      }
    } else {
      return localGigPrice;
    }
  };

  const returnRelatedPricing = (keyProp) => {
    const filteredObject = CreateCourseObj?.courseLevel.reduce((acc, key) => {
      if (ASPrincing?.[key]) {
        acc[key] = ASPrincing[key];
      }
      return acc;
    }, {});
    const minGroupPrice = Object.values(filteredObject).reduce(
      (minPrice, level) => {
        return level[keyProp] < minPrice ? level[keyProp] : minPrice;
      },
      Infinity
    );
    const minGroupPriceObjects = Object.keys(filteredObject).reduce(
      (acc, key) => {
        if (filteredObject[key]?.[keyProp] === minGroupPrice) {
          return filteredObject[key];
        }
        return acc;
      },
      {}
    );
    const returnValue = minGroupPriceObjects
      ? minGroupPriceObjects[keyProp]
      : keyProp == "groupPrice"
      ? 20
      : 30;
    return returnValue || 30;

    // return filteredObject[CreateCourseObj?.courseLevel[0]][keyProp];
  };

  const saveGigApi = () => {
    const {
      searchTags,
      subCategory,
      category,
      relatedImages,
      courseName,
      courseDescription,
      languages,
      targetAgeGroups,
      courseLevel,
      courseType,
      summary,
    } = CreateCourseObj;
    const {
      gigImage,
      shortVideoUrl,
      gigVideos,
      gigPrice,
      discountType,
      discountStartDate,
      discountEndDate,
      localGigPrice,
      localGigDiscountValue,
    } = gigObject;

    const gigDiscountPrice = returnDiscPric();

    const gigObj = {
      gigName: courseName,
      gigDescription: courseDescription,
      createdBy: loggedinUserId,
      searchTags: searchTags,
      category: category,
      subCategory: subCategory,
      gigImage: gigImage,
      shortVideoUrl: shortVideoUrl,
      serviceProviderDisplayName: userDetails?.displayName,
      serviceProviderId: loggedinUserId,
      serviceProviderName: userDetails?.firstName,
      relatedImages: relatedImages,
      profileImage: userDetails?.profileImage,
      gigPrice: gigPrice,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      localGigPrice,
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      summary: summary,
      discountEndDate,
      discountStartDate,
      discountType,
      gigVideos,
      languages,
      targetAgeGroups,
      courseLevel,
      currencyCode: userCountry?.countryCode,
      approvalStatus: "pending",
      isRefundAvailable: CreateCourseObj?.isRefundAvailable
        ? CreateCourseObj?.isRefundAvailable
        : false,
      refundConditions: CreateCourseObj?.refundConditions
        ? CreateCourseObj?.refundConditions
        : [],
    };

    const gigObjectSend = {
      serviceProviderId: loggedinUserId,
      createdBy: loggedinUserId,
      courseLevel: courseLevel,
      category: category,
      subCategory: subCategory,
      languages: languages,
      courseType: courseType,
      targetAgeGroups: targetAgeGroups,
      gigName: courseName,
      gigDescription: courseDescription,
      summary: summary,
      gigImage: gigImage,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      searchTags: searchTags,
      shortVideoUrl: shortVideoUrl,
      thumbnail: "",
      gigVideos: gigVideos,
      gigPrice: gigPrice,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      discountStartDate: discountStartDate,
      discountEndDate: discountEndDate,
      discountType: discountType,
      approvalStatus: "pending",
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      allowOutside: false,
      localGigPrice,
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      currencyCode: editGig?.currencyCode || userCountry?.countryCode,
      isRefundAvailable: CreateCourseObj?.isRefundAvailable
        ? CreateCourseObj?.isRefundAvailable
        : false,
      refundConditions: CreateCourseObj?.refundConditions
        ? CreateCourseObj?.refundConditions
        : [],
    };

    if (editGig) {
      saveDraftGig(
        { _id: editGig?._id, ...gigObjectSend },
        (res) => {
          if (res.result !== null) {
            showToast("success", res?.message);
            setGigObject(gb);
            setCreateCourseObj(obj);
            dispatch(editGigObject(null));
            localStorage.removeItem("CreateCourseObj");
            navigate("/view-gigs", { replace: true });
            setSaveApiLoad(false);
          } else {
            showToast("info", res?.message);
            setSaveApiLoad(false);
          }
        },
        (error) => {
          console.log(error, "error-save-gig");
          setSaveApiLoad(false);
        }
      );
    } else {
      saveDraftGig(
        { ...gigObj },
        (res) => {
          if (res.result !== null) {
            showToast("info", "Waiting for admin approval");
            setGigObject(gb);
            setCreateCourseObj(obj);
            localStorage.removeItem("CreateCourseObj");
            navigate("/view-gigs", { replace: true });
            setSaveApiLoad(false);
          } else {
            showToast("info", res?.message);
            setSaveApiLoad(false);
          }
        },
        (error) => {
          console.log(error, "error-save-gig");
          setSaveApiLoad(false);
        }
      );
    }
  };

  const saveCourseApi = () => {
    setSaveApiLoad(true);
    if (cloneCourse) {
      delete CreateCourseObj?._id;
      if (CreateCourseObj?.deleted) {
        delete CreateCourseObj?.deleted;
      }
    }
    let saveObject = { ...CreateCourseObj, approvalStatus: "pending" };
    saveObject.gender = saveObject?.gender?.map((each) => each?.toLowerCase());
    saveObject.individualDaily = formatDatesAndTimes(
      saveObject?.individualDaily
    );
    saveObject.groupDaily = formatDatesAndTimes(saveObject?.groupDaily);
    saveObject.groupWeekly = formatDatesAndTimes(saveObject?.groupWeekly);
    saveObject.individualWeekly = formatDatesAndTimes(
      saveObject?.individualWeekly
    );

    // console.log(saveObject, "12345?.....");

    if (!saveObject?.isRefundAvailable) {
      saveObject = {
        ...saveObject,
        isRefundAvailable: false,
        refundConditions: [],
      };
    }
    if (isGig) {
      saveGigApi();
    } else {
      const pricesNotZero =
        checkPrice("groupPrice", CreateCourseObj?.groupWeekly) &&
        checkPrice("groupPrice", CreateCourseObj?.groupDaily) &&
        checkPrice("individualDaily", CreateCourseObj?.individualDaily) &&
        checkPrice("individualWeekly", CreateCourseObj?.individualWeekly);
      if (draftCourse) {
        updateDraftCourse(
          saveObject,
          (response) => {
            showToast("info", response?.message);
            navigate("/my-courses", { replace: true });
            dispatch(setDraftCourseObject(null));
            setSaveApiLoad(false);
          },
          (err) => {
            console.log(err);
            setSaveApiLoad(false);
          }
        );
      } else if (pricesNotZero) {
        saveDraftCourse(
          saveObject,
          (response) => {
            showToast("info", "Waiting for admin approval");
            localStorage.removeItem("CreateCourseObj");
            navigate("/my-courses", { replace: true });
            dispatch(setDraftCourseObject(null));
            dispatch(editCourseObject(null));
            setSaveApiLoad(false);
          },
          (err) => {
            console.log(err);
            setSaveApiLoad(false);
          }
        );
      } else {
        showToast("error", "Batch Price Cannot be zero");
      }
    }
  };

  function checkSlots(categories) {
    const keysToCheck = {
      Individual: ["individualWeekly", "individualDaily"],
      Group: ["groupDaily", "groupWeekly"],
    };

    if (isGig) {
      if (onlyPriceConvert(5).toFixed() > gigObject?.localGigPrice) {
        return false;
      }
    } else {
      if (isEmpty(CreateCourseObj?.courseType)) {
        return false;
      }
      for (const category of CreateCourseObj?.courseType) {
        const keys = keysToCheck[category] || [];
        let hasData = false;
        for (const key of keys) {
          if (CreateCourseObj[key] && CreateCourseObj[key]?.length > 0) {
            hasData = true;
            break;
          }
        }
        if (!hasData) {
          return false;
        }
      }
    }

    return true;
  }

  const checkSlot = checkSlots();

  const returnActiveTabContent = (each) => {
    const showContent =
      each?.name == "Lesson Plan" && CreateCourseObj?.lessonPlan?.name;
    if (each?.name == "Title" && !isButtonEnabled && activeField != "Title") {
      return (
        <ActiveOverviewTab object={CreateCourseObj} {...{ setActiveField }} />
      );
    }
    return (
      <Tab
        each={each}
        {...{ CreateCourseObj, showContent, editCourse, checkSlot }}
        activeField={activeField}
        setActiveField={(tab) => {
          if (
            each?.name == "Title" ||
            editCourse ||
            cloneCourse ||
            draftCourse ||
            showContent ||
            prevData !== null ||
            editGig
          ) {
            setActiveField(tab);
          } else if (each?.name == "Lesson Plan" && !isButtonEnabled) {
            setActiveField(tab);
          } else if (
            each?.name == "Couse Type & Pricing" &&
            CreateCourseObj?.courseImage !== "" &&
            CreateCourseObj?.relatedImages?.length >= 2
          ) {
            setActiveField(tab);
          }
        }}
      />
    );
  };

  const handleSaveRefundDetails = (conditions) => {
    let refundAvailability = conditions.some((each) => each?.isSelected);
    changeCreateObj(refundAvailability, "isRefundAvailable");
    changeCreateObj(conditions, "refundConditions");
    setOpenRefundConditions(false);
  };

  return (
    <AppContainer className={"app-container"}>
      <div
        className="add-container"
        style={{ display: mobileDevices && "none" }}
      ></div>
      <div className="main-container">
        <AautiText
          size="bigSize"
          weight={"extraBold"}
          title={
            isGig ? AautiStrings.GigCreation : AautiStrings.CourseCreationString
          }
          textStyle={{ marginBottom: 5 }}
        />
        <AautiText
          title={
            courseConstant?.followandCreateFromTheBottomSectionswhereItEnablesTheCoursePreviewForTheStudents
          }
        />

        <div className="tabs-container">
          {itemsWithNewComponent?.map((each, index) => {
            return (
              <div
                key={index}
                style={{
                  display: each?.name === "Lesson Plan" && isGig && "none",
                }}
              >
                {each?.isComponent ? (
                  <>
                    {(CreateCourseObj?.courseImage !== "" ||
                      gigObject?.gigImage !== "") &&
                    CreateCourseObj?.relatedImages?.length >= 2 &&
                    activeField !== "Course Image" ? (
                      <CourseImages
                        {...{ setActiveField }}
                        imagesList={[
                          ...CreateCourseObj?.relatedImages,
                          CreateCourseObj?.courseImage,
                        ]}
                      />
                    ) : (
                      <div
                        className="tab image-upload-cont"
                        style={{
                          display: activeField === "Course Image" && "none",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => {
                          if (isEmpty(courseOverviewErrors)) {
                            if (editGig) {
                              setActiveField("Course Image");
                            } else if (CreateCourseObj?.lessonPlan?.name) {
                              setActiveField("Course Image");
                            }
                          }
                        }}
                      >
                        <div className="text-icons">
                          <AautiText
                            size="big"
                            weight={"bold"}
                            title={
                              isGig
                                ? AautiStrings.GigImage
                                : AautiStrings.CourseImagesString
                            }
                            textStyle={{ marginBottom: 5, width: "80%" }}
                          />
                          <div className="icons-flex">
                            <GoDotFill
                              color={
                                (CreateCourseObj?.courseImage !== "" &&
                                  CreateCourseObj?.relatedImages?.length >=
                                    2) ||
                                gigObject.gigImage !== ""
                                  ? "green"
                                  : "red"
                              }
                            />
                            <FaArrowRight />
                          </div>
                        </div>
                        <AautiUpload
                          id="upload media"
                          disabled={true}
                          accept="image/*,"
                          //   onChange={(e) => {
                          //     // checkImageRatios(e, "courseImage", true);
                          //   }}s
                          iconName={AautiStrings.CoverImageString}
                          important
                          customLabel={
                            <div className="upload-label">
                              <LuUpload size={35} color="#3083EF" />
                              <AautiText
                                size={"semi"}
                                weight={"normal"}
                                textStyle={{ color: "#000" }}
                                title={AautiStrings.UploadImagesOrFilesString}
                              />
                            </div>
                          }
                        />
                      </div>
                    )}
                    {activeField === "Course Image" && returnActiveContent()}
                  </>
                ) : (
                  <>
                    {returnActiveTabContent(each)}
                    {activeField === each.name && returnActiveContent()}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="add-container save-cont">
        <div className="submit-btn-container">
          <JoinButton
            onClick={() => {
              setShowPreview(true);
            }}
            disabled={activeField != "Couse Type & Pricing" || !checkSlot}
            title={courseConstant?.previewCourse}
            outlined
            buttonStyle={{
              marginBottom: 8,
              display: (editGig || isGig) && "none",
            }}
            width="170px"
          />
          <JoinButton
            buttonStyle={{ marginBottom: 8 }}
            loading={saveApiLoad}
            disabled={
              activeField != "Couse Type & Pricing" ||
              !checkSlot ||
              isButtonEnabled
            }
            title={AautiStrings.SubmitForReviewString}
            onClick={() => {
              setShowDisclaimer(true);
            }}
            width="170px"
          />
        </div>
        <HelperText
          info={true}
          title={courseConstant?.onceSubmittedItWillBeUnderReview}
        />
      </div>
      <Drawer
        anchor={mobileDevices ? "bottom" : "right"}
        sx={{
          zIndex: 201,
          padding: 10,
          "& .MuiDrawer-paper": {
            borderRadius: mobileDevices
              ? "10px 10px 0px 0px"
              : "10px 0px 0px 10px",
            minHeight: mobileDevices ? "80vh" : "100vh",
            maxHeight: mobileDevices ? "80vh" : "100vh",
            minWidth: !mobileDevices && "40vw",
            maxWidth: "40vw",
          },
        }}
        open={openAddress}
        onClose={() => {
          setOpenAddress(false);
        }}
      >
        <CourseInPerson
          handleSelectAddress={(item) => {
            changeCreateObj(defaultAddress?.[0] || {}, "address");
            setSelectedAddress(item);
          }}
          selectedAddress={selectedAddress}
          CreateCourseObj={CreateCourseObj}
          changeCreateObj={changeCreateObj}
          isClientLocation="isClientLocation"
          {...{ handleSetSelectAddress, setCreateCourseObj }}
        />
      </Drawer>
      <Drawer
        anchor={"bottom"}
        sx={{
          zIndex: 201,
          padding: 10,
          "& .MuiDrawer-paper": {
            borderRadius: mobileDevices
              ? "10px 10px 0px 0px"
              : "10px 0px 0px 10px",
            minHeight: "90vh",
            maxHeight: "90vh",

            maxWidth: "100vw",
          },
        }}
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <PreviewCourse object={CreateCourseObj} {...{ setShowPreview }} />
      </Drawer>
      <AautiDialog
        open={openRefundConditions}
        onClose={() => {
          setOpenRefundConditions(false);
        }}
        dialogTitle={AautiStrings.RefundsString}
        titlePosition="left"
        sxStyles={{ padding: "20px 20px 20px 20px" }}
      >
        {openRefundConditions && (
          <RefundConditions
            refundConditions={CreateCourseObj?.refundConditions}
            handleSaveRefundDetails={handleSaveRefundDetails}
          />
        )}
      </AautiDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AautiDialog
        // minWidth={300}
        dialogTitle={courseConstant?.conflicts}
        open={conflictsList}
        onClose={() => {
          setConflictsList(null);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 5,
          }}
        >
          {conflictsList?.map((each, index) => {
            return <ConflictCard {...{ each }} />;
          })}
        </div>
      </AautiDialog>
      <AautiDialog
        open={showDisclaimer}
        onClose={() => {
          setShowDisclaimer(false);
        }}
      >
        <AautiText
          size={"normal"}
          title={
            AautiStrings.YouAreResponsibleForTheContentYouHaveUploadedString
          }
          textStyle={{ color: "#000" }}
        />
        <NavButton
          text={AautiStrings.OkString}
          handleClick={() => {
            setShowDisclaimer(false);
            saveCourseApi();
          }}
        />
      </AautiDialog>
    </AppContainer>
  );
};

export default CourseCreation;
