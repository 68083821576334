import React, { useEffect, useRef, useState } from "react";
import { HighlightOff } from "@mui/icons-material";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import {
  CircularProgress,
  FormControl,
  IconButton,
  NativeSelect,
} from "@mui/material";
import { BootstrapInput } from "../../globalComponents/AautiHead";
import CommentCard from "./CommentCard";
import { CommentServices } from "./Services";
import CommentInput from "./CommentInput";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import NoData from "../../globalComponents/NoDataFound";
import { showToast } from "../../globalComponents/Toast";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const GigComments = ({ gigObject, handleClose }) => {
  const [commentsData, setCommentsData] = useState({});
  const videoCommentsRef = useRef();
  const [replyId, setReplyId] = useState("");
  const { loggedinUserId } = useSelector((state) => state.app);
  const [showReplyId, setShowReplyId] = useState("");
  const [editCommentObj, setEditCommentObj] = useState({});
  const [editReplyObj, setEditReplyObj] = useState({});
  const [commentsLoading, setCommentsLoading] = useState(false);

  const getCommentsById = (id) => {
    setCommentsLoading(true);
    CommentServices.getCommentsByGigId(
      { gigId: id },
      (response) => {
        setCommentsLoading(false);
        if (response?.result) {
          setCommentsData(response?.result);
        }
      },
      (error) => {
        setCommentsLoading(false);
        console.log(error, "error-getting-comments");
      }
    );
  };
  useEffect(() => {
    getCommentsById(gigObject?._id);
  }, [gigObject?._id]);

  const handleInputCancel = () => {
    setReplyId("");
  };

  const onSuccessComment = (response) => {
    if (response?.result) {
      setReplyId("");
      getCommentsById(gigObject?._id);
    }
  };

  const onFailure = (error) => {
    console.log(error, "error-response");
  };

  const handleInputSave = (value, isReply, item) => {
    if (isReply) {
      const data = {
        endUserId: loggedinUserId,
        serviceProviderId: gigObject?.serviceProviderId,
        gigId: gigObject?._id,
        comment: value,
        parentId: item?.parentId ? item?.parentId : item?._id,
      };
      CommentServices.saveComment(data, onSuccessComment, onFailure);
    } else {
      const data = {
        endUserId: loggedinUserId,
        serviceProviderId: gigObject?.serviceProviderId,
        gigId: gigObject?._id,
        comment: value,
      };
      CommentServices.saveComment(data, onSuccessComment, onFailure);
    }
  };

  const handleThumbAction = (action, comment) => {
    const data = {
      _id: comment?._id,
      endUserId: loggedinUserId,
      select: true,
    };
    if (action == "like") {
      CommentServices.addLikedUsers(
        data,
        (response) => {
          getCommentsById(gigObject?._id);
        },
        (error) => {
          console.log(error, "error-like");
        }
      );
    } else if (action == "disLike") {
      CommentServices.addDislikedUsers(
        data,
        (response) => {
          getCommentsById(gigObject?._id);
        },
        (error) => {
          console.log(error, "error-like");
        }
      );
    }
  };

  const handleDeleteComment = (id) => {
    CommentServices.deleteComment(
      id,
      (response) => {
        if (response?.status === "Success") {
          showToast("success", response?.message);
          
          setCommentsData((prevData) => ({
            ...prevData,
            comments: prevData.comments.filter((comment) => comment._id !== id),
            totalComment: prevData.totalComment - 1,
          }));
          
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error-deleting comment");
      }
    );
  };
  

  const handleEditComment = (obj) => {
    setEditCommentObj(obj);
  };

  const handleEditReply = (obj) => {
    setEditReplyObj(obj);
  };

  const updateApiCall = (data) => {
    CommentServices.updateComment(
      data,
      (response) => {
        if (response?.result) {
          showToast("success", response?.message);
          setEditCommentObj({});
          setEditReplyObj({});

          handleInputCancel();
          getCommentsById(gigObject?._id);
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error-updating comment");
      }
    );
  };

  const handleUpdateComment = (value) => {
    const data = {
      ...editCommentObj,
      comment: value,
    };
    updateApiCall(data);
  };

  const handleUpdateReply = (value) => {
    const data = {
      ...editReplyObj,
      comment: value,
    };

    updateApiCall(data);
  };

  return (
    <div
      className="comments-main"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="comment-video-cont">
        <video
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: 0,
          }}
          autoPlay
          ref={videoCommentsRef}
          //   onTimeUpdate={handleTimeUpdate}
          //   onPlay={() => {
          //     setVideoPlaying(true);
          //     setIsLoading(false);
          //   }}
          muted={true}
          controls={true}
          controlsList="nodownload"
          preload="metadata"
          loop
        >
          <source src={gigObject?.shortVideoUrl} type="video/mp4"></source>
        </video>
      </div>
      <div className="comment-content">
        <div>
          <div className="comment-header-name">
            <AautiText
              title={gigObject?.gigName}
              size={"semi"}
              weight={"bold"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 1,
                textOverflow: "ellipsis",
                maxWidth: "80%",
                textShadow: "0px 1px 2px 4px",
                color: "#000",
              }}
            />
            <IconButton
              size="medium"
              onClick={handleClose}
              sx={{ p: 0, cursor: "pointer" }}
            >
              <HighlightOff />
            </IconButton>
          </div>
          <AautiText
            textStyle={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              maxWidth: "100%",
              textShadow: "0px 1px 2px 4px",
            }}
            title={gigObject?.gigDescription}
            size={"semi"}
          />
        </div>
        <div
          className="comment-header-name"
          style={{ marginTop: 5, marginBottom: 5 }}
        >
          <AautiText
            size={"normal"}
            weight={"bold"}
            textAlign={"left"}
            title={`Comments (${commentsData?.totalComment || 0})`}
            textStyle={
              {
                //   color: "#000",
              }
            }
          />
          <div style={{ display: "none", alignItems: "center" }}>
            <AautiText
              size={"semi"}
              textStyle={{ color: "grey", width: "auto", minWidth: "53px" }}
              title={AautiStrings?.sortByString}
            />
            <FormControl
              style={{ width: "auto", minWidth: "75%" }}
              variant="standard"
            >
              <NativeSelect
                id="demo-customized-select-native"
                // value={age}
                // onChange={handleChange}
                input={<BootstrapInput />}
              >
                <option value={10}>Recommended</option>
                <option value={20}>Best Selling </option>
                <option value={20}>New Arrivals </option>
              </NativeSelect>
            </FormControl>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 15, width: "100%" }}>
            <CommentInput
              {...{ handleInputCancel, handleInputSave }}
              isReply={false}
            />
          </div>
          {commentsLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                height: "50vh",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : isEmpty(commentsData) ? (
            <NoData NoDataFound={AautiStrings?.noCommentsString} />
          ) : (
            <div style={{ height: "60vh", overflow: "scroll" }}>
              {commentsData?.comments?.map((comment, index) => {
                return (
                  <CommentCard
                    comment={comment}
                    key={index}
                    {...{
                      replyId,
                      setReplyId,
                      handleInputSave,
                      handleThumbAction,
                      showReplyId,
                      setShowReplyId,
                      handleDeleteComment,
                      handleEditComment,
                      editCommentObj,
                      handleUpdateComment,
                      handleEditReply,
                      editReplyObj,
                      handleUpdateReply,
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GigComments;
