import React from "react";
import { useSelector } from "react-redux";

function Chips({ data = [], onClick = () => {} }) {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobileDevices ? "column" : "row",
        columnGap: 10,
        rowGap: 10,
        width: mobileDevices ? "80%" : "100%",
        marginRight: mobileDevices ? "5%" : 0,
      }}
    >
      {data?.map((each, idx) => {
        return (
          <div
            style={{
              padding: mobileDevices ? "5%" : "1%",
              border: each?.active
                ? "1px solid #ffffff55"
                : "1px solid #575B5CCC",
              borderRadius: 5,
              backgroundColor: each?.active ? "#1e98d7" : "white",
              cursor: "pointer",
            }}
            onClick={() => onClick(idx)}
          >
            <p
              style={{
                color: each?.active ? "white" : "black",
                textAlign: "center",
              }}
            >
              {each?.title}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default Chips;
