import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { BiSolidDiscount } from "react-icons/bi";
import moment from "moment";
import { GoPerson } from "react-icons/go";
import { FiMonitor } from "react-icons/fi";
import { MdOutlineAccessTime, MdOutlineCalendarMonth } from "react-icons/md";
import { PriceConversions, returnIcon } from "../../CommonFunctions";
import bookIcon from "../../../assets/images/normal_u432.svg";
import VirtualIcon from "../../globalComponents/icons/VirtualIcon";
import InPerson from "../../globalComponents/icons/InPerson";
import { useNavigate, useParams } from "react-router-dom";
import { RescheduleConstants } from "./RescheduleConstants";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const RescheduleBatchCard = ({
  each,
  title,
  selectedSlotDate,
  setSelectedSlotData,
  listing = false,
}) => {
  const { convertPriceWithCurrency } = PriceConversions();
  const currentDate = moment();
  const isDaily = title == "individualDaily" || title == "groupDaily";
  const isGroup = title == "groupDaily" || title == "groupWeekly";
  const format = (date) => {
    const formatDate = moment.utc(date).local().format("DD MMM YYYY");
    return formatDate;
  };
  const { courseId } = useParams();
  const navigate = useNavigate();
  const returnTime = (date) => {
    const time = moment.utc(date).local().format("hh:mmA");
    return time;
  };
  const isFutureDate = moment(each?.discountStartDate).isAfter(currentDate);
  const checkDiscount =
    moment(each?.discountStartDate).isBefore(moment(new Date())) &&
    moment(each?.discountEndDate).isAfter(moment(new Date())) &&
    (each?.individualDiscountValue || each?.groupDiscountValue);

  const returnDiscountType = (item) => {
    const value = !isGroup
      ? item?.individualDiscountValue
      : item?.groupDiscountValue;

    if (item?.discountType !== "Amount") {
      return `(${value}%)`;
    }
    return `(-${convertPriceWithCurrency(value || 0)})`;
  };

  const groupDaysByTimings = (schedule) => {
    const grouped = new Map();
    for (const [day, timeSlots] of Object.entries(schedule)) {
      for (const slot of timeSlots) {
        const { startTime, endTime } = slot;
        const key = `${startTime}-${endTime}`;

        if (!grouped.has(key)) {
          grouped.set(key, { startTime, endTime, days: [] });
        }
        grouped.get(key).days.push(day);
      }
    }

    return Array.from(grouped.values());
  };

  const returnWeek = (weeks) => {
    const combineWeeks = groupDaysByTimings(weeks);
    return (
      <div>
        {combineWeeks?.map((week, index) => {
          return (
            <div
              key={index}
              className="some-box"
              style={{ border: "none", padding: 0 }}
            >
              <div className="weekName-item">
                <MdOutlineAccessTime size={20} />
                <AautiText
                  title={returnTime(week.startTime)}
                  weight={"bold"}
                  size={"semi"}
                  textStyle={{ marginLeft: 10 }}
                />
                <AautiText
                  title={" - "}
                  weight={"bold"}
                  size={"semi"}
                  textStyle={{ marginRight: 10, marginLeft: 10 }}
                />
                <AautiText
                  title={returnTime(week?.endTime)}
                  weight={"bold"}
                  size={"semi"}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                {week?.days?.map((each, index) => (
                  <div className="weekNaem">
                    <AautiText
                      size={"small"}
                      weight={"bold"}
                      title={each.slice(0, 3)}
                      textStyle={{ color: "#ffffff" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const returnTimings = (each) => {
    const slotType = each?.type;
    if (slotType == "groupWeekly" || slotType == "individualWeekly") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 10,
            width: "fit-content",
          }}
        >
          {returnWeek(each?.recurringDays)}
        </div>
      );
    } else if (
      slotType == "groupDaily" ||
      slotType == "individualDaily" ||
      slotType == "individualDaily"
    ) {
      return (
        <>
          <div
            style={{
              display: "flex",
              marginBottom: 6,
              marginTop: 6,
            }}
          >
            <MdOutlineCalendarMonth size={20} />
            <AautiText
              title={format(each?.startDate) + " - " + format(each?.endDate)}
              textStyle={{ marginLeft: 10 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: 6,
              marginTop: 6,
              alignItems: "center",
            }}
          >
            <MdOutlineAccessTime size={20} />
            <AautiText
              title={
                returnTime(each?.startTime) + " - " + returnTime(each?.endTime)
              }
              textStyle={{ marginLeft: 10 }}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        if (!each?.isRescheduled && !listing) {
          setSelectedSlotData({ ...each, type: title });
        }
      }}
      style={{
        borderColor:
          each?._id === selectedSlotDate?._id ? "#078dda" : "lightgrey",

        borderRadius: listing ? 0 : 4,
        background: "#fff",
        // "red",
        borderStyle: listing ? "none" : "solid",
        width: "100%",
        cursor: "pointer",
        marginBottom: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: 0,
        borderRightStyle: listing ? "none" : "solid",
        height: listing && "100%",
      }}
      key={each?._id}
    >
      <div className="Waiting-con-detai">
        <AautiText
          title={each?.batchName || "NA"}
          weight={"bold"}
          textAlign={"left"}
          textStyle={{
            color: "#333333",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            width: "80%",
            height: 28,
            lineHeight: "19px",
            zIndex: 200,
          }}
          size={"medium"}
        />
        {returnIcon(each)}
      </div>
      <div style={{ padding: 10, width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={bookIcon}
            alt="Weekly"
            style={{ height: "15px", width: "15px" }}
          />
          <AautiText
            title={
              isDaily ? AautiStrings?.DailyString : AautiStrings?.weeklyString
            }
            textStyle={{ marginLeft: 10, color: "#3166ba" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 6,
            marginTop: 6,
          }}
        >
          <MdOutlineCalendarMonth size={20} />
          <AautiText
            title={
              format(listing ? each?.reScheduleStartDate : each?.startDate) +
              " - " +
              format(listing ? each?.reScheduleEndDate : each?.endDate)
            }
            textStyle={{ marginLeft: 10 }}
          />
        </div>
        {isDaily ? (
          <div
            style={{
              display: "flex",
              marginBottom: 6,
              marginTop: 6,
              alignItems: "center",
            }}
          >
            <MdOutlineAccessTime size={20} />
            <AautiText
              title={
                returnTime(
                  listing ? each?.reScheduleStartTime : each?.startTime
                ) +
                " - " +
                returnTime(listing ? each?.reScheduleEndTime : each?.endTime)
              }
              textStyle={{ marginLeft: 10 }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              width: "fit-content",
            }}
          >
            {returnWeek(
              listing ? each?.reScheduleRecurringDays : each?.recurringDays
            )}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginTop: 8,
          }}
        >
          {each?.virtual ? (
            <FiMonitor fontSize="medium" />
          ) : (
            <InPerson fontSize="medium" color="red" />
          )}
          {/* {(each?.individualDiscountValue || each?.groupDiscountValue) && ( */}
          <AautiText
            title={`${convertPriceWithCurrency(
              isGroup ? each?.groupPrice : each?.individualPrice
            )}`}
            size={"semi"}
            textStyle={{
              color: "#3166ba",
              textDecoration: checkDiscount ? "line-through" : "",
            }}
          />
          {/* )} */}
          {
            // each?.individualDiscountValue || each?.groupDiscountValue
            true ? (
              <AautiText
                title={returnDiscountType(each)}
                size={"semi"}
                textStyle={{
                  color: "#3166ba",
                  display: !checkDiscount && "none",
                }}
              />
            ) : (
              <AautiText
                title={`${convertPriceWithCurrency(
                  isGroup ? each?.groupPrice : each?.individualPrice
                )}`}
                size={"semi"}
                textStyle={{
                  color: "#3166ba",
                  // display: !checkDiscount && "none",
                }}
              />
            )
          }
          {/* {each?.individualDiscountPrice || each?.groupDiscountPrice ? ( */}
          <AautiText
            title={`${convertPriceWithCurrency(
              (!isGroup
                ? each?.individualDiscountPrice
                : each?.groupDiscountPrice) || 0
            )}`}
            weight={"bold"}
            textStyle={{
              color: "#3166ba",
              display: !checkDiscount && "none",
            }}
          />
        </div>
        <div
          style={{
            display: isFutureDate ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginTop: 8,
          }}
        >
          <BiSolidDiscount />
          <AautiText
            title={`${returnDiscountType(each)} off from (${moment(
              each?.discountStartDate
            ).format("DD MMM YYYY")} - ${moment(each?.discountEndDate).format(
              "DD MMM YYYY"
            )})`}
          />
        </div>
        <div
          style={{
            display: each?.isRescheduled && !listing ? "flex" : "none",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <AautiText
            title={RescheduleConstants?.rescheduleBatch}
            weight={"bold"}
            textAlign={"left"}
            size={"semi"}
            textStyle={{ color: "#078dda", marginBottom: 8 }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 5,
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              <AautiText
                title={"Accepted: " + each?.acceptedUsers?.length || 0}
                size={"semi"}
              />
              <AautiText
                title={"Rejected: " + each?.rejectedUsers?.length || 0}
                size={"semi"}
              />
            </div>
            <AautiText
              onClick={() => {
                // setPageNavigate("Rescheduled");
                // setBatchesData(each);
                navigate(`/reschedule-list`, {
                  state: { courseId, batchId: each?.batchId },
                });
              }}
              title={AautiStrings?.seeMoreString}
              size={"small"}
              textStyle={{ color: "#078dda", cursor: "pointer" }}
            />
          </div>
        </div>
        {
          <div
            style={{
              display: listing ? "flex" : "none",
              flexDirection: "column",
              marginBottom: 5,
              marginTop: 10,
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <AautiText
              title={
                AautiStrings?.theBatchHasBeenRescheduledToBelowTimingsString
              }
              size={"small"}
              textAlign={"left"}
            />
            {returnTimings(each)}
          </div>
        }
      </div>
    </button>
  );
};

export default RescheduleBatchCard;
