import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContainer from "../../AppContainer";
import EmptyData from "../../globalComponents/EmptyData";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "lodash";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import CourseFilters from "../../home/upcomming/CourseFilters";
import moment from "moment";
import { AuthService } from "../../../service";
import { FetchServices } from "./FetchServices";
import Coursecard from "../../appCards/Coursecard";

const MoreResults = () => {
  const { type } = useParams();
  const { mobileDevices, ipodDevices, lapy, miniLapys } = useSelector(
    (state) => state.responsive
  );
  const { loggedinUserId } = useSelector((state) => state.app);
  const [searchValue, setSearchValue] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [moreResults, setMoreResults] = useState([]);

  const handleFetchApi = (data) => {
    const onSuccess = (resp) => {
      setApiLoading(false);
      if (resp?.result) {
        setMoreResults(resp?.result);
      }
    };
    const onFailure = (error) => {
      setApiLoading(false);
      console.error(error, "error-getting more results");
    };

    switch ("Courses") {
      case "Courses":
        AuthService.getTimeslotsCourseSchedules(data, onSuccess, onFailure);
        break;
      case "Gigs":
        FetchServices.gigSMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Adhoc Sessions":
        FetchServices.adhocSessionsMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Service Providers":
        FetchServices.getSpMatchMySchedule(data, onSuccess, onFailure);
        break;
      default:
        break;
    }
  };

  const makeSearchApi = (value, filtersData) => {
    setApiLoading(true);
    let cat = [];

    let data = {
      categories: cat,
      countries: [],
      dateSlots: {},
      endDate: moment(new Date()).add(3, "month").format("YYYY-MM-DDTHH:mm:00"),
      languages: [],
      searchKey: searchValue,
      startDate: moment(new Date()).format('"YYYY-MM-DDTHH:mm:00"'),
      endUserId: loggedinUserId ? loggedinUserId : null,
      timeSlots: [],
      weekSlots: {},
      subCategories: [],
      timeZone: moment().utcOffset(),
      ageGroup: [],
      levels: [],
      count: 100,
      type: "sponsored",
    };

    if (value) {
      data.searchKey = value;
    }

    if (filtersData) {
      data = { ...data, ...filtersData };
    }
    handleFetchApi(data);
  };

  useEffect(() => {
    makeSearchApi();
  }, [type]);
  return (
    <AppContainer>
      <div
        style={{
          width: "100%",
          paddingLeft: "1.5%",
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          marginTop: 15,
          gap: 15,
        }}
      >
        <div
          style={{
            width: mobileDevices ? "99%" : "20%",
            maxWidth: mobileDevices ? "99%" : "20%",
            minWidth: mobileDevices ? "99%" : "20%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            borderRadius: 4,
            padding: 10,
            height: "fit-content",
          }}
        >
          <CourseFilters
            handleClickApply={(data) => {
              makeSearchApi(null, data);
            }}
            searchValue={searchValue}
            onChangeSearchValue={(e) => {
              const value = e.target.value.trimStart().replace(/\s+/g, " ");
              setSearchValue(value);
              makeSearchApi(value);
            }}
          />
        </div>
        <div style={{ width: mobileDevices ? "100%" : "78%" }}>
          <div style={{ width: "100%" }}>
            <AautiText title={"Showing Results"} />

            {apiLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: "50vh",
                  width: "100% !important",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : isEmpty(moreResults) ? (
              <div>
                <EmptyData showExplore={true} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  flexWrap: "wrap",
                  marginBottom: 15,
                }}
              >
                {moreResults?.map((each, index) => (
                  <div key={index}>
                    <Coursecard
                      title={"searchResults"}
                      {...{ each }}
                      cardWidth={mobileDevices ? "98%" : 269}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default MoreResults;
