import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { GoDotFill } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import "./index.scss";

const Tab = ({
  each,
  activeField,
  setActiveField,
  CreateCourseObj,
  showContent,
  checkSlot,
  hasData = false,
}) => {
  return (
    <div
      className="tab"
      style={{
        display: activeField === each.name ? "none" : "block",
      }}
      onClick={() => {
        setActiveField(each.name);
      }}
    >
      <div className="text-icons">
        <AautiText
          size="big"
          weight={"bold"}
          title={each.name}
          textStyle={{ marginBottom: 5, width: "80%" }}
        />
        <div className="icons-flex">
          <GoDotFill
            color={showContent || checkSlot || hasData ? "green" : "red"}
          />
          <FaArrowRight />
        </div>
      </div>
      <AautiText
        title={showContent ? CreateCourseObj?.courseName : each?.text}
      />
    </div>
  );
};

export default Tab;
