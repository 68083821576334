import React from "react";
import AautiText from "../globalComponents/AautiText";
import { Divider, TextField } from "@mui/material";
import { Padding } from "@mui/icons-material";
import moment from "moment";

const ConflictCard = ({ each }) => {
  return (
    <div
      style={{ width: "100%", border: "1px solid lightGrey", borderRadius: 5 }}
    >
      <AautiText
        title={each?.courseName}
        textStyle={{ color: "#3083EF", padding: 5 }}
      />
      <Divider
        color={"lightGrey"}
        sx={{
          width: "100%",
          //   margin: "5px 5px",
        }}
      />
      <AautiText title={each?.batchName} textStyle={{ padding: 5 }} />
      <div
        style={{
          display: "flex",
          // justifyContent: "space-around",
          alignItems: "center",
          padding: "0px 5px 5px 5px",
        }}
      >
        <AautiText
          size={"semi"}
          title={moment(each?.startTime).format("LLL")}
          textStyle={{ marginRight: 10 }}
        />
        <AautiText title={" - "} textStyle={{}} />
        <AautiText
          size={"semi"}
          title={moment(each?.endTime).format("LLL")}
          textStyle={{ marginLeft: 10 }}
        />
      </div>
    </div>
  );
};

export default ConflictCard;
