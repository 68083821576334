import React, { useEffect, useState } from "react";
import CourseFilters from "../home/upcomming/CourseFilters";
import AautiHead from "../globalComponents/AautiHead";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Coursecard from "../appCards/Coursecard";
import EmptyData from "../globalComponents/EmptyData";
import "../course/courseDetails/index.scss";
import { getChildOngoinCourses, getChildsubscriptions } from "./Service";
import { MembersStrings } from "./Constants";

const EnrolledCourses = () => {
  const location = useLocation();
  const [courses, setCourses] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const subCategory = location.state;
  const { mobileDevices } = useSelector((state) => state.responsive);
  const callApi = () => {
    if (subCategory?.type === MembersStrings?.enrolled) {
      getChildsubscriptions(
        subCategory?.id,
        (res) => {
          if (res?.status == MembersStrings?.success) {
            setCourses(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      getChildOngoinCourses(
        subCategory?.id,
        (res) => {
          if (res?.status == MembersStrings?.success) {
            setCourses(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };
  useEffect(() => {
    callApi();
  }, [subCategory?.id]);

  const searchedList = courses?.filter((each, index) => {
    return each?.courseName
      .toLocaleLowerCase()
      .includes(searchValue.toLocaleLowerCase());
  });

  return (
    <div className="subCourses-div1">
      <div className="subCourses-div2">
        <CourseFilters />
      </div>
      <div style={{ width: !mobileDevices ? "calc(100% - 280px)" : "100%" }}>
        <AautiHead title={`${subCategory.type} ${MembersStrings?.courses}`} />
        {!isEmpty(courses) ? (
          <div
            className="subCourses-div3"
            style={{
              gap: mobileDevices ? 18 : 18,
            }}
          >
            {searchedList?.map((each, index) => {
              return (
                <Coursecard {...{ each: each, isEnrolled: true }} key={index} />
              );
            })}
          </div>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default EnrolledCourses;
