import React from "react";

const CourseImage = ({ imgUrl, height = "200px", className = "" }) => {
  const encodedUrl = imgUrl;
  return (
    <div
      style={{
        height: height,
        width: "100%",
        // backgroundImage: `url(${encodedUrl})`,
        backgroundSize: "cover",
        // position: "relative",
        borderRadius: 6,
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(10px)",
          borderRadius: 6,
        }}
      ></div> */}
      <img
        className={className}
        alt="related-imgs"
        width="100%"
        src={
          imgUrl ||
          "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
        }
        style={{
          width: "100%",
          height: "100%",

          // objectFit: "contain",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          borderRadius: 6,
          backgroundSize: "contain",
        }}
      />
    </div>
  );
};

export default CourseImage;
