import { Grid, IconButton, Tooltip } from "@mui/material";
import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AccountTabs from "../../globalComponents/AccountTabs";
import { useSelector } from "react-redux";
import { CapitalizeFirstLetter, returnIcon } from "../../CommonFunctions";
import moment from "moment";
import ShareIcon from "../../globalComponents/icons/ShareIcon";
import { CourseAdhocCont } from "./container";
import { Icon } from "../../appCards/AdhocEventsCard";
import ScheduleIcon from "../../../assets/images/schedule.png";
import ClockIcon from "../../../assets/images/Clock-Icon.png";
import Virtualicon from "../../../assets/images/monitor.png";
import InPersonIcon from "../../../assets/images/drop-inperson.png";

const CourseAdhocEvent = () => {
  const { courseList, onPressDelete } = CourseAdhocCont();
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
    marginBottom: 8,
  };
  console.log(courseList[0]);
  return (
    <AppContainer>
      {/* <AccountTabs /> */}
      <AautiText
        title={"Course Adhoc Events"}
        weight={"bold"}
        textStyle={{ marginBottom: "15px" }}
        size={"medium"}
      />
      <div
        style={{
          height: "75vh",
          border: "1px solid #E0E0E0",
          borderRadius: 4,
          padding: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",

            display: "flex",
            flexDirection: "row",
            maxHeight: "80vh",
            gap: 12,
            flexWrap: "wrap",
          }}
        >
          {courseList.map((each) => (
            <div className="events-card">
              <Tooltip title={CapitalizeFirstLetter(each?.title)}>
                <div className="card-head-dots">
                  <AautiText
                    title={CapitalizeFirstLetter(each?.title) || "Title"}
                    weight={"bold"}
                    textStyle={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                    }}
                  />
                </div>
              </Tooltip>
              <div className="events-card-data">
                <AautiText
                  title={each?.courseName}
                  weight={"normal"}
                  textStyle={{ marginBottom: 8 }}
                />
                <div style={rowStyle}>
                  <Icon icon={ScheduleIcon} />
                  <AautiText
                    textStyle={{ color: "#5C5B57" }}
                    title={
                      moment(each?.startDate).format("DD MMM YYYY") +
                      " - " +
                      moment(each?.endDate).format("DD MMM YYYY")
                    }
                  />
                </div>
                <div
                  style={{
                    gap: 12,
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Icon icon={ClockIcon} />
                  <AautiText
                    textStyle={{
                      color: "#5C5B57",
                      maxWidth: "350px",
                      textWrap: "nowrap",
                      overflow: "scroll",
                      maxWidth: "400px",
                    }}
                    title={` ${
                      moment(each?.startTime).format("LT") +
                      " - " +
                      moment(each?.endTime).format("LT")
                    }`}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                  }}
                >
                  {each?.virtual && (
                    <Tooltip title="Virtual">
                      <IconButton
                        sx={{
                          p: 0,
                          "&:hover": { background: "#fff" },
                        }}
                      >
                        <img
                          src={Virtualicon}
                          alt="virtual"
                          style={{
                            height: "18px",
                            width: "18px",
                            zIndex: 100,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {each?.inPerson && (
                    <Tooltip title={"In Person"}>
                      <IconButton sx={{}}>
                        <img
                          src={InPersonIcon}
                          alt="inPerson"
                          style={{
                            height: "18px",
                            width: "18px",
                            zIndex: 100,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  <AautiText title={`$${each?.price}`} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "auto",
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onPressDelete(each?._id);
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      padding: "8px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <AautiText
                      title={"Delete"}
                      weight={"bold"}
                      textStyle={{ color: "#3083EF" }}
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      // e.stopPropagation();
                      // if (each?.isPurchased) {
                      //   showToast("info", "Users purchased can't be edited");
                      // } else {
                      //   dispatch(setAdhocEditObject(each));
                      //   navigate("/AdhocEvent?edit=true", {
                      //     state: { screenName: pathname },
                      //   });
                      // }
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      padding: "8px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <AautiText
                      title={"Edit"}
                      weight={"bold"}
                      textStyle={{ color: false ? "lightgrey" : "#3083EF" }}
                    />
                  </button>
                </div>
              </div>
              {/* <ConfirmationDialogRaw
              id="ringtone-menu"
              keepMounted
              open={open}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onClose={() => {
              }}
            /> */}
            </div>
          ))}
        </div>
      </div>
    </AppContainer>
  );
};

export default CourseAdhocEvent;
