import { ApiMethods } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

export function AddAdhocEvent(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/saveMySchedule",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function UpdateAdhocEvent(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/updateMySchedule",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function GetAllCountries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
