import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBatchDetailsByBatchId } from "./Services";
import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AautiHead from "../../globalComponents/AautiHead";
import { isEmpty } from "lodash";
import BatchCard from "../myCourses/BatchCard";
import RescheduleBatchCard from "./RescheduleBatchCard";
import NoData from "../../globalComponents/NoDataFound";

import SearchIcon from "@mui/icons-material/Search";
import BatchUserCard from "./BatchUserCard";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import AautiFileHeader from "../../globalComponents/AautiFileHeader";
import { AautiStrings } from "../../globalComponents/AautiStrings";

const RescheduleList = () => {
  const [rescheduleList, setRescheduleList] = useState({});
  const [activeState, setActiveState] = useState("Accepted");
  const { state } = useLocation();

  useEffect(() => {
    const data = {
      courseId: state.courseId,
      batchId: state.batchId,
    };

    getBatchDetailsByBatchId(
      data,
      (response) => {
        console.log(response, "response?..?");
        if (response.result) {
          console.log(response?.result, "resulttt?...");
          setRescheduleList(response?.result[0]);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  }, [state]);

  const returnActiveContent = () => {
    const activeList =
      activeState == "Accepted"
        ? rescheduleList?.acceptedUsers
        : activeState == "Rejected"
        ? rescheduleList?.rejectedUsers
        : [];

    return (
      <div>
        {isEmpty(activeList) ? (
          <NoData NoDataFound={AautiStrings?.noUsersString} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginTop: 10,
            }}
          >
            {activeList?.map((each, index) => (
              <div key={index} style={{ width: "48%" }}>
                <BatchUserCard obj={each} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <AppContainer>
      <AautiFileHeader activeTitle={"Reschedule"} />
      {!isEmpty(rescheduleList) && (
        <div
          style={{
            width: "100%",
            display: rescheduleList ? "flex" : "none",
            flexDirection: "row",
            border: "1px solid #E0E0E0",
            borderRadius: 8,
            padding: 10,
            height: "90vh",
            gap: 15,
          }}
        >
          <div
            style={{ width: "30%", display: isEmpty(rescheduleList) && "none" }}
          >
            <RescheduleBatchCard
              each={rescheduleList}
              title={rescheduleList?.type}
              listing={true}
            />
          </div>
          <div style={{ width: "70%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {["Accepted", "Rejected"]?.map((each, index) => (
                    <AautiText
                      onClick={() => {
                        setActiveState(each);
                      }}
                      key={index}
                      title={each}
                      textStyle={{
                        color: each === activeState ? "#078dda" : "#1e1e1e",
                        borderBottom:
                          each === activeState
                            ? "3px solid #078dda"
                            : "1px solid #dedede",
                        paddingBottom: 10,
                        width: "50%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </div>
                <FormControl
                  sx={{ m: 1, width: "25ch", margin: 0 }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"text"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Search"
                  />
                </FormControl>
              </div>
            </div>
            <div>{returnActiveContent()}</div>
          </div>
        </div>
      )}
    </AppContainer>
  );
};

export default RescheduleList;
