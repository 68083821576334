import React from "react";
import AautiTextInput from "../globalComponents/AautiTextInput";
import AautiText from "../globalComponents/AautiText";
import { IoMdInformationCircleOutline } from "react-icons/io";
// import AautiDropDown from "../globalComponents/AautiDropDown";
import AautiDropDown from "../globalComponents/AautiDropDown";
import "./style.scss";

const CreateRequest = ({
  payoutList,
  convertPriceWithCurrency,
  banksList,
  selectedBank,
  setSelectedBank,
}) => {
  function maskBankNumber(bankNumber) {
    const maskedSection = bankNumber.slice(0, -4).replace(/\d/g, "X");
    const visibleSection = bankNumber.slice(-4);
    return maskedSection + visibleSection;
  }
  return (
    <div>
      <AautiTextInput
        value={null}
        text={"Withdraw amount"}
        important
        style={{ width: "100%" }}
        size="small"
        onChange={(e) => null}
        helperText={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AautiText
              textAlign={"left"}
              size={"semi"}
              weight={"normal"}
              textStyle={{
                color: "#7A797E",
              }}
              title={`(Minimum withdraw amount - 100)`}
            />
            <AautiText
              textAlign={"left"}
              size={"semi"}
              weight={"normal"}
              title={`(Available Funds - ${convertPriceWithCurrency(
                payoutList?.availableFunds
              )})`}
            />
          </div>
        }
      />
      <AautiDropDown
        className={"payment-dropdown"}
        style={{ marginTop: "5px" }}
        important
        title={"Select Bank"}
        onChange={(e) => setSelectedBank(e.target.value)}
        data={banksList.map((bank) => (
          <>
            <AautiText
              title={`${bank.bankName} - ${maskBankNumber(bank.accountNumber)}`}
              weight={"medium"}
              size={"normal"}
            />
          </>
        ))}
        // onChange={(e) =>
        //   onChangeFun(e.target.value, "CountryBank")
        // }
        helperText={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <IoMdInformationCircleOutline
              size={25}
              style={{ marginRight: "8px" }}
            />
            <AautiText
              textAlign={"left"}
              size={"semi"}
              weight={"normal"}
              title={`Withdraw the available amount with 10% TDS deduction. Get pain instantly for a small fee 5%. Minimum withdral amount is $100`}
            />
          </div>
        }
      />
    </div>
  );
};

export default CreateRequest;
