import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import AautiText from "../../globalComponents/AautiText";
import constantSizes from "./CommonConstants";

const DialogTopBar = (props) => {
  const { TitleText, onClose, size, textAlign, textStyle, weight, disabled } =
    props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 0,
      }}
    >
      <AautiText
        size={constantSizes.large}
        weight={constantSizes.bold}
        title={TitleText}
        textAlign={textAlign}
        textStyle={textStyle}
      />
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => onClose()}
        aria-label="close"
        disabled={disabled}
      >
        <CloseIcon sx={{ height: 20, color: disabled ? "grey" : "red" }} />
      </IconButton>
    </Box>
  );
};

export default DialogTopBar;
