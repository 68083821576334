import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import { FormHelperText, Typography } from "@mui/material";
import Select from "@mui/material/Select";

const AautiDropDown = (props) => {
  const {
    title,
    data,
    width,
    value,
    onChange,
    style,
    multiselect,
    important,
    helperText,
    item,
    disabled = false,
  } = props;

  return (
    <Box sx={[{ width: width, zIndex: 222 }, style]}>
      <FormControl fullWidth size="small">
        <InputLabel
          sx={{
            color: "#000",
            fontWeight: 400,
          }}
          htmlFor={title}
        >
          {title}
          {important && <span style={{ color: "red" }}>*</span>}
        </InputLabel>

        <Select
          disabled={disabled}
          labelId={title}
          id={title}
          value={value || ""}
          label={`${title}${important ? " *" : ""}`}
          onChange={onChange}
          multiple={multiselect && true}
        >
          {data?.map((each, index) => (
            <MenuItem key={index} value={each} id={title}>
              {item ? item(each) : each}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText
            id="component-helper-text"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default AautiDropDown;
