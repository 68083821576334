import React from "react";
import AautiText from "./AautiText";
import { useLocation, useNavigate } from "react-router-dom";
//props --> {tabObj :{ label: "My Account", navigation: "/my-account", active: active }},
const AccountTabs = (props) => {
  const { pathname } = useLocation();
  const active = [
    "/my-account",
    // "/select-profession",
    // "/interests",
    "/attendance-details",
    "/students",
    "/AdhocEvent",
    "/adhoc-price",
    "/adhoc-events",
    "/myLesson-plans",
    "/view-gigs",
    "/my-schedule",
    "/my-revenue",
    "/my-courses",
    "/my-learnings",
    "/my-instructors",
    "/wishlist",
    "/orders",
    "/add-package",
  ]?.includes(pathname);
  const tabs = [
    { label: "My Account", navigation: "/my-account", active: active },
  ];

  const defaultTabs = [
    ...tabs,
    {
      label: "Settings",
      navigation: "/account-settings",
      active: [
        "/account-settings",
        "/refunds",
        "/reviews-ratings",
        "/bank-details",
        "/transfer-requests",
        "/payout-requests",
        "/reschedule-merge",
        "/coupons",
        // "/interests",
        "/profile/interests",
        "/select-profession",
        "/help-support",
        "/delete-deactivate",
      ]?.includes(pathname),
    },

    {
      label: "Profile",
      navigation: "/profile",
      active: ["/profile"]?.includes(pathname),
    },
  ];

  const renderTabs = props?.tabObj ? [...tabs, props?.tabObj] : defaultTabs;

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      {renderTabs?.map((each, index) => {
        return (
          <button
            onClick={() => {
              navigate(each?.navigation);
            }}
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 6,
              padding: 2,
              alignItems: "center",
              border: "none",
              background: "none",
              cursor: "pointer",
              textTransform: "capitalize",
            }}
          >
            <AautiText
              title={each.label}
              size={"semi"}
              textStyle={{ color: each?.active ? "#3083EF" : "#575B5CCC" }}
            />
            {index !== renderTabs.length - 1 && <span> | </span>}
          </button>
        );
      })}
    </div>
  );
};

export default AccountTabs;
