import React, { useEffect, useState } from "react";
import "./styles.scss";
import CourseFilters from "../home/upcomming/CourseFilters";
import { useSelector } from "react-redux";
import noDataImages from "../../assets/images/no-image.svg";
import AautiText from "../globalComponents/AautiText";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCoursesCountByCategoryAndSubcategory,
  getGigsCountByCategoryAndSubcategory,
} from "./Service";
import gigStrings from "../gig/gigStrings";
import { AautiStrings } from "../globalComponents/AautiStrings";

const CourseCategories = () => {
  const { selectedCategory, categories } = useSelector((state) => state.app);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const location = useLocation();
  const type = location?.state;
  const [hoveredInd, setHoveredInd] = useState();
  const [similarSubCat, setSimilarSubCat] = useState([]);
  const navigate = useNavigate();
  const getSubCategories = () => {
    if (type == "course") {
      getCoursesCountByCategoryAndSubcategory(
        selectedCategory?.categoryName,
        selectedCategory?.subCategoryName,
        (res) => {
          if (res?.status === gigStrings?.success) {
            setSimilarSubCat(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      getGigsCountByCategoryAndSubcategory(
        selectedCategory?.categoryName,
        selectedCategory?.subCategoryName,
        (res) => {
          if (res?.status === gigStrings?.success) {
            setSimilarSubCat(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };
  useEffect(() => {
    getSubCategories();
  }, []);
  console.log(selectedCategory, "categories");
  const filteredItems = categories?.filter(
    (each) => each.categoryName === selectedCategory
  );
  return (
    <div className="ccMainCont" style={{ marginTop: mobileDevices && "10px" }}>
      <div
        className="ccMainSub"
        style={{
          flexDirection: mobileDevices ? "column" : "row",
          // justifyContent: "center",
        }}
      >
        <div
          className="filtersCCCOnt"
          style={{
            width: mobileDevices && "99%",
            border: "1px solid lightgrey",
            borderRadius: "4px",
          }}
        >
          <CourseFilters />
        </div>
        <div className="CCSubCateCont">
          <AautiText
            title={AautiStrings?.similarSubcategoriesYouMayLikeString}
            size={mobileDevices ? "extraMedium" : "large"}
            weight={"extraBold"}
            textStyle={{ color: "#002058", marginBottom: 15 }}
          />
          <div className="categories-map-gig">
            {!isEmpty(similarSubCat) ? (
              similarSubCat[0]?.subCategory.map((each, index) => (
                <>
                  {each?.count > 0 && (
                    <div
                      key={index + "./"}
                      className="category-box-gig"
                      onMouseEnter={() => setHoveredInd(index)}
                      onMouseLeave={() => setHoveredInd(-1)}
                      onClick={() => {
                        const newArray = {
                          subCategory: each?.subCategoryName,
                          category: selectedCategory.categoryName,
                        };
                        type == "gig"
                          ? navigate(`/${each?.subCategoryName}/gigs`, {
                              state: [each?.subCategoryName],
                            })
                          : navigate(`/${each?.subCategoryName}/courses`, {
                              state: newArray,
                            });
                      }}
                    >
                      <img
                        src={each?.subCategoryImage || noDataImages}
                        alt=""
                      />

                      <div className="subcategory-count-gig">
                        <AautiText
                          title={each?.subCategoryName}
                          size={"semi"}
                          textAlign={"center"}
                          weight={"bold"}
                          className={"courseRating-text1"}
                        />
                      </div>
                      {hoveredInd === index && (
                        <AautiText
                          title={
                            type == "gig"
                              ? `(Gigs - ${each?.count})`
                              : `(Courses - ${each?.count})`
                          }
                          textAlign={"center"}
                          size={"semi"}
                          weight={"normal"}
                          className={"detailsComp-text1"}
                        />
                      )}
                    </div>
                  )}
                </>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "60vh",
                }}
              >
                <AautiText
                  title={AautiStrings?.NoDataFoundString}
                  size={"normal"}
                  weight={"extraBold"}
                  textAlign={"center"}
                  textStyle={{ color: "#1E1E1E" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCategories;
