import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

export function getMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function sendSms(toPhoneNumber, onSuccess, onFailure) {
    ApiRequest({
      url: "sms/sendSms",
      method: ApiMethods.GET,
      params: { toPhoneNumber },
    }).then(
      (response) => {
        onSuccess(response?.data);
      },
      (error) => {
        onFailure();
      }
    );
  }

  export function VerifySms(toPhoneNumber, otp, onSuccess, onFailure) {
    ApiRequest({
      url: "sms/verifySms",
      method: ApiMethods.POST,
      params: { toPhoneNumber, otp },
    }).then(
      (response) => {
        onSuccess(response?.data);
      },
      (error) => {
        onFailure();
      }
    );
  }

  export function getDeactivateReasons(onSuccess, onFailure) {
    ApiRequest({
      url: "deactivateReasons/getAllDeactivateReasons",
      method: ApiMethods.GET,
    }).then(
      (response) => {
        onSuccess(response?.data);
      },
      (error) => {
        onFailure();
      }
    );
  }

  export function reactivateUser(userId, onSuccess, onFailure) {
    ApiRequest({
      url: "user/reactivateUser",
      method: ApiMethods.GET,
      params: { userId },
    }).then(
      (response) => {
        onSuccess && onSuccess(response.data.result);
      },
      (error) => {
        onFailure && onFailure(error);
      }
    );
  }

  export function deleteUser(payload, onSuccess, onFailure) {
    ApiRequest({
      url: "user/deleteUser",
      method: ApiMethods.POST,
      data: payload,
    }).then(
      (response) => {
        onSuccess(response?.data);
      },
      (error) => {
        onFailure();
      }
    );
  }

  export function loginAuth(userData, onSuccess, onFailure) {
    ApiRequest({
      url: ApiPaths.LOGIN,
      method: ApiMethods.POST,
      data: userData,
    }).then(
      (response) => {
        onSuccess(response?.data);
      },
      (error) => {
        onFailure(error);
      }
    );
  }

  
  
