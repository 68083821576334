import { configureStore } from "@reduxjs/toolkit";
import appReducerSlice from "./reducer/appReducer";
import responsiveReducer from "./reducer/responsiveReducer";
import PaymentSlice from "./reducer/PaymentSlice";
import homePageSlice from '../../src/components/homeScreen/reducer'
import chatSlice from "./reducer/chatSlice";
import socketMiddleware from "./socketMiddleware";

const store = configureStore({
  reducer: {
    app: appReducerSlice,
    responsive: responsiveReducer,
    paymentReducer: PaymentSlice,
    homePageReducer: homePageSlice,
    chat:chatSlice
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['chat/setSocketHandler'],
        ignoredActionPaths: ['payload.callBackFunc'],
        ignoredPaths: ['chat.eventHandler'],
      },
    }).concat([socketMiddleware])
  }
});
export default store;
