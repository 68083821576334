import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

export function loginAuth(userData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.LOGIN,
    method: ApiMethods.POST,
    data: userData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function addOrUpdateFCMToken(deviceDetails, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_FCM_TOKEN_DETAILS,
    method: ApiMethods.POST,
    data: deviceDetails,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getUserByMail(email, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_USERBY_MAIL + email,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function sendMail(email, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SEND_MAIL + email,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function sendSms(toPhoneNumber, onSuccess, onFailure) {
  ApiRequest({
    url: `sms/sendSms?toPhoneNumber=${toPhoneNumber}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function checkEmailIfUserHasAnyData(email, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERBYEMAIL + email,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function checkMobileIfUserHasAnyData(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserByMobile",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveMultiCarts(data, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/saveMultipleCarts",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function wishSp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "wishlist/wishlistServiceProvider",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function wishlistWhilSign(data, onSuccess, onFailure) {
  ApiRequest({
    url: "wishlist/saveWishlist",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveUserData(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVEUSER,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function VerifyOtpAuth(email, otp, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.VERIFY_OTP,
    method: ApiMethods.POST,
    data: { email, otp },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function VerifySms(toPhoneNumber, otp, onSuccess, onFailure) {
  ApiRequest({
    url: `sms/verifySms?toPhoneNumber=${toPhoneNumber}&otp=${otp}`,
    method: ApiMethods.POST,
    // params: { toPhoneNumber, otp },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function updatePasswordAuth(email, password, onSuccess, onFailure) {
  ApiRequest({
    url: "app/updatePassword",
    method: ApiMethods.POST,
    params: { username: email, password },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function changePasswordAuth(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/changePassword",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAllContries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function onInstituteRegister(data, onSuccess, onFailure) {
  ApiRequest({
    url: "institution/saveInstitution",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getInstituteDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: "institution/getInstitutionDetailsById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getInstituteDashboardDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: "instituteHomePage/getByInstituteId",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveInstituteDashboard(data, onSuccess, onFailure) {
  ApiRequest({
    url: "instituteHomePage/save",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
