import React from "react";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import { Typography } from "@mui/material";

const AautiUpload = (props) => {
  const {
    accept,
    id,
    onChange,
    iconName = "upload",
    iconSize = 20,
    iconColor = "#078dda",
    style = {},
    important,
    customLabel,
    disabled,
    multiple,
    title,
  } = props;
  return (
    <div>
      <label
        htmlFor={id}
        style={{
          cursor: "pointer",
          marginTop: 10,
          alignItems: "center",
          border: "2px dashed #078dda",
          padding: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: 5,
          ...style,
        }}
      >
        {customLabel ? (
          customLabel
        ) : (
          <>
            <CastForEducationIcon
              style={{ color: iconColor }}
              fontSize="medium"
            />
            <h4 style={{ display: "flex" }}>
              {iconName}
              {important && (
                <Typography
                  sx={{ color: "red", marginTop: "-2px", marginLeft: "1px" }}
                >
                  *
                </Typography>
              )}
            </h4>
          </>
        )}
      </label>

      <input
        multiple={multiple}
        type="file"
        name={id}
        id={id}
        accept={accept && accept}
        style={{ display: "none" }}
        onChange={onChange && onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default AautiUpload;
