import { Box, Button, IconButton, InputBase } from "@mui/material";
import { RiSearchLine } from "react-icons/ri";
import constants from "./constants.json";
import {
  BannerFoldIconLeft,
  BannerFoldIconRight,
  ChatbotIcon,
  CoachingIcon,
  CoachingIconFive,
  CoachingIconFour,
  CoachingIconThree,
  CoachingIconTwo,
  CourseSlider,
  LearnProfessionsRightImage,
  SeeHowItWorksIcon,
  ServiceProviderSlider,
  SimpleSlider,
  TextUnderLineIcon,
  VectorUnderLineIcon,
  WhatOthersSlides,
} from "./container";
import "./styles.scss";
import Footer from "./footer";
import { EasyToStart } from "./easyToStart";
import ReadyToStart from "./readyToStart";
import { useEffect, useState } from "react";
import { getAautiDetails, getAllCategories } from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import main_image_mobile from "./images/main_image_mobile.png";
import { useNavigate } from "react-router-dom";
import { Header } from "./header";
import { isEnterPressed } from "../../storage/constants";
import CountUp from "react-countup";
import ExploreMore from "../home/exploreMore";
import LoadingCards from "../globalComponents/LoadingCards";
import { isEmpty } from "lodash";
import { HomeCatServices } from "./services";
import ServiceproviderSection from "./serviceprovidersSection/ServiceproviderSection";
import { cardsCount } from "../CommonFunctions";

export default function HomePageNew() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());

    dispatch(getAautiDetails());
  }, []);
  const {
    categories,
    details,
    topServiceProviders,
    testimonialsData,
    coursesByCategory,
  } = useSelector((state) => state.homePageReducer);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?._id);
  const [selectedCategoryName, setSelectedCategoryName] = useState(
    categories[0]?.categoryName
  );
  const [selectedDoseSection, setSelectedDoseSection] = useState(1);
  const [categoriesData, setCategoriesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategoriesBasedSelection();
  }, [selectedDoseSection]);

  const onSuccess = (response) => {
    setLoading(false);
    if (response?.result) {
      setCategoriesData(response?.result);
    }
  };

  const onFailure = (error) => {
    setLoading(false);
    console.error(error, "");
  };

  const getCategoriesBasedSelection = () => {
    setLoading(true);
    const sectionsData = {
      1: () =>
        HomeCatServices.getTrendingCategories(
          { noOfDays: 10 },
          onSuccess,
          onFailure
        ),
      2: () => HomeCatServices.getCategoriesSpecialDeals(onSuccess, onFailure),
      3: () => HomeCatServices.getBlockBusterCategories(onSuccess, onFailure),
      4: () =>
        HomeCatServices.getCategoriesByOfferPercentage(
          { percentage: "60" },
          onSuccess,
          onFailure
        ),
      5: () =>
        HomeCatServices.getCategoriesByOfferPercentage(
          { percentage: "50" },
          onSuccess,
          onFailure
        ),
      6: () =>
        HomeCatServices.getCategoriesByOfferPercentage(
          { percentage: "20" },
          onSuccess,
          onFailure
        ),
      7: () =>
        HomeCatServices.getCategoriesByOfferPercentage(
          { percentage: "10" },
          onSuccess,
          onFailure
        ),
      8: () =>
        HomeCatServices.getTrendingCategories(
          { noOfDays: 10 },
          onSuccess,
          onFailure
        ),
      9: () => HomeCatServices.getNewCategories(onSuccess, onFailure),
    };

    const action =
      sectionsData[selectedDoseSection] ||
      (() => HomeCatServices.getTrendingCategories({}, onSuccess, onFailure));

    action();
  };
  useEffect(() => {
    setSelectedCategory(categories[0]?._id);
    setSelectedCategoryName(categories[0]?.categoryName);
  }, [categories]);

  const handleCategoryClick = (category, categoryName) => {
    setSelectedCategory(category);
    setSelectedCategoryName(categoryName);
  };
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [inspirationCount, setInspirationCount] = useState(0);

  const [activeSpCategory, setActiveSpCategory] = useState(
    constants?.teacherCategories[0].id
  );

  // Handle category selection
  const handleSelectCategory = (id) => {
    setActiveSpCategory(id);
  };

  return (
    <>
      <Header />
      <section id="LEARN_NEW_PROFESSION">
        <Box className="learn_professions_right_image_top">
          <img
            src={main_image_mobile}
            alt="mob-image"
            style={{
              width: "95%",
            }}
          />
        </Box>
        <Box className="learn_new_profession_frame">
          <Box className="learn_new_profession_text">
            <p className="learn_new_profession_label">
              Learn new professions any time and place convenient for&nbsp;
              <span className="learn_new_profession_span">
                {constants.highlightText}
              </span>
              .
            </p>
            <p className="learn_new_profession_label2">
              {constants.learnNewProfessionLabel2}
            </p>
            <Box className="text_underline_container">
              <TextUnderLineIcon />
            </Box>
          </Box>
          <Box className="search_all_courses_container">
            <InputBase
              className="search_all_courses_input"
              placeholder={constants.searchPlaceholder}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value.trimStart().replace(/\s+/g, " "));
              }}
              onKeyDown={(event) => {
                if (isEnterPressed(event.key)) {
                  navigate("/search-result", {
                    state: { searchKey: searchInput },
                  });
                  setSearchInput("");
                }
              }}
            />
            <IconButton
              type="button"
              className="aauti_search_button_icon"
              aria-label="search"
              onClick={() => {
                navigate("/search-result", {
                  state: { searchKey: searchInput },
                });
                setSearchInput("");
              }}
            >
              <RiSearchLine />
            </IconButton>
          </Box>
          <Box className="learn_new_profession_button_container">
            <Button
              variant="contained"
              className="learn_new_profession_button"
              onClick={() => {
                // dispatch(toggleSignInPop(true));
                // dispatch(setSignupScreenIndex(1));
                navigate("/register/user-type");
              }}
            >
              {constants.getStartedButton}
            </Button>
            <Box className="see_how_it_works_container">
              <SeeHowItWorksIcon />
              <p className="see_how_it_works_label">
                {constants.seeHowItWorksLabel}
              </p>
              <Box className="text_underline">
                <TextUnderLineIcon />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="learn_professions_right_image">
          <LearnProfessionsRightImage />
        </Box>
      </section>
      <section id="DOSE_OF_INSPIRATION">
        <Box className="dose_of_inspiration_frame">
          <p className="dose_of_inspiration_label">
            A dose of{" "}
            <span className="dose_of_inspiration_span">inspiration</span>,
            whenever you need it
          </p>
          <Box className="block_background"></Box>
          <Box className="text_underline">
            <VectorUnderLineIcon />
          </Box>
        </Box>
        <Box className="filter_courses_container">
          {constants.sections.map((section, index) => (
            <Box
              key={index}
              className="filter_course_section"
              sx={{
                backgroundColor:
                  section?.id === selectedDoseSection ? "#3083EF" : "#ffffff",
                color:
                  section?.id === selectedDoseSection ? "#ffffff" : "#000000",
              }}
              onClick={() => setSelectedDoseSection(section?.id)}
            >
              <p className="filter_course_section_label">{section.text}</p>
            </Box>
          ))}
        </Box>
        <Box className="courses_container">
          <p className="courses_container_label">
            Total {categoriesData?.length || 0} Findings
          </p>
          {/* <p className="courses_container_label">Sort By</p> */}
        </Box>
        <Box className="simple_slider_container">
          {loading ? (
            <LoadingCards />
          ) : isEmpty(categoriesData) ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={1}
              height={"100%"}
            >
              <h3>No Content</h3>
            </Box>
          ) : (
            <SimpleSlider {...{ categoriesData, loading }} />
          )}
        </Box>
        <Box
          className="view_more_container"
          style={{ display: categoriesData?.length <= cardsCount && "none" }}
        >
          <Button
            variant="contained"
            className="view_more_button"
            onClick={() => {
              navigate("/explore-more-categories", {
                state: { activeKey: selectedDoseSection },
              });
            }}
          >
            View More
          </Button>
        </Box>
      </section>
      <section id="POOL_OF_COURSES">
        <Box className="pool_of_courses_top_frame"></Box>
        <Box className="pool_of_courses_info_frame">
          <Box className="pool_of_courses_info_fold_left">
            <BannerFoldIconLeft />
          </Box>
          <Box className="pool_of_courses_info_fold_right">
            <BannerFoldIconRight />
          </Box>
          <Box className="pool_of_courses_info_content_container">
            <p className="pool_of_courses_info_label">
              <CountUp end={details?.categoryCount} />
            </p>
            <p className="pool_of_courses_info">Categories</p>
          </Box>
          <Box className="pool_of_courses_info_content_container">
            <p className="pool_of_courses_info_label">
              <CountUp end={details?.serviceProviderCount} />
            </p>
            <p className="pool_of_courses_info">Expert Instructors</p>
          </Box>
          <Box className="pool_of_courses_info_content_container">
            <p className="pool_of_courses_info_label">
              <CountUp end={details?.courseCount} />
            </p>
            <p className="pool_of_courses_info">Courses</p>
          </Box>
          <Box className="pool_of_courses_info_content_container pool_of_courses_info_content_container_last">
            <p className="pool_of_courses_info_label">
              <CountUp end={details?.studentsCount} />
            </p>
            <p className="pool_of_courses_info">Active students</p>
          </Box>
        </Box>
        <Box id="DOSE_OF_INSPIRATION">
          <Box className="dose_of_inspiration_frame">
            <p className="dose_of_inspiration_label">
              Explore Pool of{" "}
              <span className="dose_of_inspiration_span">courses</span> <br />{" "}
              to boost yourself
            </p>
            <Box className="block_background block_background_adjustment_courses"></Box>
            <Box className="text_underline">
              <VectorUnderLineIcon />
            </Box>
          </Box>
          <Box className="filter_courses_container">
            {categories?.map((section, index) => (
              <Box
                key={index}
                className="filter_course_section"
                onClick={() =>
                  handleCategoryClick(section?._id, section?.categoryName)
                }
                sx={{
                  backgroundColor:
                    section?._id === selectedCategory ? "#3083EF" : "#ffffff",
                  color:
                    section?._id === selectedCategory ? "#ffffff" : "#000000",
                  cursor: "pointer",
                }}
              >
                <p className="filter_course_section_label">
                  {section?.categoryName}
                </p>
              </Box>
            ))}
          </Box>
          <Box className="courses_container">
            <p className="courses_container_label">
              Total {coursesByCategory?.length} Findings
            </p>
          </Box>
          <Box className="simple_slider_container">
            <CourseSlider
              categoryId={selectedCategory}
              selectedCategoryName={selectedCategoryName}
            />
          </Box>
          <Box
            className="view_more_container"
            style={{
              display: coursesByCategory?.length <= cardsCount && "none",
            }}
          >
            <Button
              variant="contained"
              className="view_more_button view_more_courses_button"
              onClick={() => {
                navigate(`courses/${selectedCategoryName}`);
              }}
            >
              View More
            </Button>
          </Box>
        </Box>
      </section>
      <section id="WHAT_TO_EXPECT">
        <Box className="what_to_expect_frame_text_container">
          <Box className="what_to_expect_frame_header">
            <p className="what_to_expect_frame_header_label">
              What To <span className="dose_of_inspiration_span">Expect</span>{" "}
              From Aauti?
            </p>
            <Box className="block_background block_background_adjustment_expect"></Box>
            <Box className="text_underline">
              <VectorUnderLineIcon />
            </Box>
          </Box>
          <p className="what_to_expect_frame_content">
            Enrich your courses with activity-based learning, collaborative
            projects, and community facilitation, all made possible by the Our
            platform.
          </p>
        </Box>
        <Box className="what_to_expect_frame_card_container">
          {constants.cards.map((card, index) => (
            <Box className="what_to_expect_frame_card" key={index}>
              {card.icon === "play-button" && <CoachingIcon />}
              {card.icon === "teacher" && <CoachingIconTwo />}
              {card.icon === "ai" && <CoachingIconThree />}
              {card.icon === "video-camera" && <CoachingIconFour />}
              {card.icon === "chat" && <CoachingIconFive />}
              <p className="what_to_expect_frame_card_label">{card.title}</p>
              <p className="what_to_expect_frame_card_description">
                {card.description}
              </p>
            </Box>
          ))}
        </Box>
      </section>
      <section id="AAUTI_BOT">
        <ChatbotIcon />
        <Box className="aauti_bot_text_container">
          <Box className="aauti_bot_text_frame">
            <p className="aauti_bot_text">
              Find your perfect course with
              <span className="aauti_bot_text">
                &nbsp;AautiBot
                <Box className="text_underline">
                  <VectorUnderLineIcon />
                </Box>
              </span>
              ...
            </p>
            <p className="aauti_bot_text_description">
              Explore your career options with
              <strong className="aauti_bot_text_strong">
                &nbsp;AautiBot, the AI-powered Chatbot
              </strong>
              <br /> that brings you tailor-made course recommendations. Get
              Started and
              <br /> make your next career move.
            </p>
          </Box>
          <Box className="aauti_bot_button_container">
            <Button variant="contained" className="aauti_bot_button">
              Chat Now
            </Button>
          </Box>
        </Box>
      </section>
      <EasyToStart />
      <ReadyToStart />
      <ServiceproviderSection />
      <section
        id="DOSE_OF_INSPIRATION"
        className="what_others_talk_about_us"
        style={{ display: isEmpty(testimonialsData) && "none" }}
      >
        <Box className="dose_of_inspiration_frame">
          <p className="dose_of_inspiration_label">
            What others Talk{" "}
            <span className="dose_of_inspiration_span">about us</span>{" "}
          </p>
          <Box className="text_underline text_underline2">
            <VectorUnderLineIcon />
          </Box>
        </Box>
        <Box className="simple_slider_container">
          <WhatOthersSlides />
        </Box>
        <Box
          className="view_more_container"
          style={{ display: testimonialsData?.length <= cardsCount && "none" }}
        >
          <Button
            variant="contained"
            className="view_more_button"
            onClick={() => {
              navigate("/what-others-talk-about");
            }}
          >
            View More
          </Button>
        </Box>
      </section>
      <ExploreMore />
      <Footer />
    </>
  );
}
