import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

export function getCoursesCountByCategoryAndSubcategory(
  category,
  subcategory,
  onSuccess,
  onFailure
) {
  const data = {
    categoryName: category,
    subCategoryName: subcategory,
  };
  ApiRequest({
    url: `myCourse/getCoursesCountByCategoryAndSubcategory`,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getGigsCountByCategoryAndSubcategory(
  category,
  subcategory,
  onSuccess,
  onFailure
) {
  const data = {
    categoryName: category,
    subCategoryName: subcategory,
  };
  ApiRequest({
    url: `gig/getGigsCountByCategoryAndSubcategory?`,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
