import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import Virtualicon from "../../../assets/images/Inperson_Virtual.svg";

const VirtualInPerson = () => {
  return (
    <Tooltip title="Virtual, InPerson">
      <IconButton
        sx={{
          //   background: "#fff",
          mb: -0.5,
          p: 1,
          "&:hover": { background: "#fff" },
        }}
      >
        <img
          src={Virtualicon}
          alt="virtual"
          style={{
            height: "20px",
            width: "20px",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default VirtualInPerson;
