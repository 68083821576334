import React, { useEffect, useState } from "react";
import "./index.scss";
import AautiText from "../../globalComponents/AautiText";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShareIcon from "../../globalComponents/icons/ShareIcon";
import { AiFillStar } from "react-icons/ai";
import CourseDetailsStrings from "./CourseDeatilsStrings";
import { toggleSignInPop } from "../../../redux/reducer/appReducer";

import { showToast } from "../../globalComponents/Toast";
import { isEmpty } from "lodash";
import { AuthService } from "../../serviceProviderDetails/service";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

const UserCard = ({
  completeDetails,
  each,
  type,
  isPackage = false,
  isDashboard,
}) => {
  const { ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const { loggedinUserId, isUserLoggedIn } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const path = window.origin;
  const dispatch = useDispatch();
  const sharePath = `${path}/sp-profile/${completeDetails?.serviceProviderId}`;
  const [cardItem, setCardItem] = useState(completeDetails);

  const onSuccessDeleteList = (response) => {
    if (response.status === "Success") {
      // showToast("success", "Unfollowed");
      setCardItem({ ...cardItem, isSpWishlisted: !cardItem?.isSpWishlisted });
    } else {
      // showToast("error", response?.message);
    }
  };
  const deleteListAPI = (each) => {
    const wishListData = {
      serviceProviderId: completeDetails?.serviceProviderId,
      name: completeDetails?.displayName,
      description: "Wishlist deleted",
      endUserId: loggedinUserId,
    };
    AuthService.deleteWishlistedServiceProvider(
      wishListData,
      onSuccessDeleteList,
      onFailure
    );
  };
  const onSuccessWishList = (response) => {
    if (response.status === "Success") {
      showToast("success", "Following");
      setCardItem({ ...cardItem, isSpWishlisted: !cardItem?.isSpWishlisted });
    } else {
      showToast("error", response?.message);
    }
  };
  const wishListAPI = (each) => {
    const wishListData = {
      serviceProviderId: completeDetails?.serviceProviderId,
      name: completeDetails?.displayName,
      description: "wishList",
      endUserId: loggedinUserId,
    };
    AuthService.wishlistServiceProvider(
      wishListData,
      onSuccessWishList,
      onFailure
    );
  };
  const onFailure = (error) => {
    showToast("error", "somthing went wrong");
  };
  return (
    <div
      id="serviceprovider-card"
      className="userCard-div1"
      style={{
        marginTop: mobileDevices || ipodDevices ? 10 : !isPackage && 15,
      }}
    >
      {!isDashboard && (
        <AautiText
          title={`A ${isPackage ? "Package" : type} by`}
          weight={"extraBold"}
          className={"userCard-text1"}
        />
      )}
      <div
        onClick={() =>
          navigate(`/sp-profile/${completeDetails?.serviceProviderId}`)
        }
        className="profile3"
        style={{
          flexDirection: mobileDevices && "column",
          justifyContent: mobileDevices && "flex-start",
          alignItems: mobileDevices && "center",
        }}
      >
        <div className="userCard-div2">
          <Avatar
            alt={completeDetails?.displayName}
            sx={{ height: 80, width: 80 }}
            src={completeDetails?.profileImage}
            className="michale-im"
          />
          <div className={"userCard-div3"}>
            {completeDetails?.spAverageRating ||
            completeDetails?.averageRating ? (
              <div className="userCard-div4">
                <AiFillStar
                  size={mobileDevices ? 12 : 15}
                  className="userCard-icon1"
                />
                <AautiText
                  size={"small"}
                  weight={"normal"}
                  className={"userCard-text2"}
                  title={(
                    completeDetails?.spAverageRating ||
                    completeDetails?.averageRating
                  )?.toFixed(1)}
                />
              </div>
            ) : (
              <AautiText
                title={CourseDetailsStrings?.new}
                weight={"normal"}
                size={"small"}
                className={"userCard-text2"}
              />
            )}
          </div>
        </div>
        <div className="userCard-div5">
          <div className="userCard-div6">
            <AautiText
              size={"medium"}
              weight={"bold"}
              className={"userCard-text3"}
              title={CapitalizeFirstLetter(completeDetails?.displayName)}
            />
            {completeDetails?.skillsetName && (
              <AautiText
                size={"small"}
                title={`(${completeDetails?.skillsetName})`}
              />
            )}
          </div>
          <div className="sp-detaisl">
            <div className="details-item">
              <AautiText
                title={
                  type === "Gig"
                    ? (completeDetails?.spGigCount
                        ? completeDetails?.spGigCount
                        : completeDetails?.spCourseCount) == 1
                      ? "Gig"
                      : CourseDetailsStrings?.gigs
                    : completeDetails?.spCourseCount == 1
                    ? "Course"
                    : CourseDetailsStrings?.courses
                }
                size={"semi"}
                className={"rightCont-div6"}
              />
              <AautiText
                title={
                  type === "Gig"
                    ? completeDetails?.spCourseCount || 0
                    : // completeDetails?.spCourseCount ||
                      // completeDetails?.coursesCount
                      completeDetails?.spCourseCount ||
                      completeDetails?.coursesCount
                }
                weight={"bold"}
                size={"semi"}
                className={"courseReviewsABuy-text2"}
              />
            </div>
            <div className="details-item5">
              <AautiText
                title={`${
                  (completeDetails?.spStudentsCount ||
                    completeDetails?.studentsCount) == 1
                    ? "Student"
                    : "Students"
                }`}
                size={"semi"}
                className={"rightCont-div6"}
              />
              <AautiText
                title={
                  completeDetails?.spStudentsCount ||
                  completeDetails?.studentsCount ||
                  0
                }
                weight={"bold"}
                size={"semi"}
                className={"courseReviewsABuy-text2"}
              />
            </div>
          </div>
          <div
            className="userCard-div7"
            style={{
              justifyContent:
                completeDetails?.tags?.length > 0
                  ? "space-between"
                  : "flex-end",
            }}
          >
            {!isDashboard && (
              <div
                className="TPhighTextdiv"
                style={{
                  display: completeDetails?.tags?.length < 1 && "none",
                }}
              >
                <AautiText size={"semi"} title={completeDetails?.tags?.[0]} />
              </div>
            )}
            {!isDashboard && (
              <div className="userCard-div8">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ShareIcon
                    shareUrl={sharePath}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
                {cardItem?.isSpWishlisted ? (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isUserLoggedIn) {
                        deleteListAPI(cardItem);
                      } else {
                        // dispatch(toggleSignInPop(true));
                        // showToast("Info", "Please login to continue....");
                        navigate("/login");
                      }
                    }}
                    className="TPFollowTextDiv"
                  >
                    <AautiText
                      size={mobileDevices ? "small" : "semi"}
                      title={AautiStrings?.followingString}
                      textStyle={{ color: "#fff" }}
                    />
                  </button>
                ) : (
                  <button
                    disabled={
                      loggedinUserId === completeDetails?.serviceProviderId
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        isUserLoggedIn &&
                        loggedinUserId !== completeDetails?.serviceProviderId
                      ) {
                        wishListAPI(cardItem);
                      } else if (!isUserLoggedIn) {
                        navigate("/login");
                        // dispatch(toggleSignInPop(true));
                        // showToast("Info", "Please login to continue....");
                      }
                    }}
                    className="TPFollowTextDiv"
                  >
                    <AautiText
                      size={mobileDevices ? "small" : "semi"}
                      title={AautiStrings?.followString}
                      textStyle={{ color: "#fff" }}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
