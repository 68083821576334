import React, { useState, useEffect } from "react";
import AautiText from "../globalComponents/AautiText";
import AautiDialog from "../globalComponents/AautiDialog";
import { updateMembersList } from "../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { logoutFunction } from "../CommonFunctions";
import { useNavigate } from "react-router-dom";
import {
  getMembers,
  sendSms,
  getDeactivateReasons,
  deleteUser,
  loginAuth,
  VerifySms,
  reactivateUser,
} from "./service";
import { isEmpty } from "lodash";
import {
  Box,
  FormControl,
  Skeleton,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Button,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import AppContainer from "../AppContainer";
import { showToast } from "../globalComponents/Toast";
import deletemember from "../../assets/images/deletemember.png";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import AccountTabs from "../globalComponents/AccountTabs";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { AautiStrings } from "../globalComponents/AautiStrings";
import { courseConstant } from "../course/courseCreation/courseConstant";

const DeleteOrDeactivate = () => {
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [yourMembers, setYourMembers] = useState(null);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [openPwdVerification, setOpenPwdVerification] = useState(false);
  const [openOtpVerification, setOpenOtpVerification] = useState(false);
  const [openDeactivationReasons, setOpenDeactivationReasons] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState("deactivate");
  const [password, setPassword] = useState("");
  const [deactivationReasons, setDeactivationReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [customReason, setCustomReason] = useState("");
  const [deactivationDays, setDeactivationDays] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    setLoading(true);
    getMembers(
      loggedinUserId,
      (resp) => {
        console.log("MEMBERS", resp);
        setYourMembers(resp.result);
        dispatch(updateMembersList(resp?.result || []));
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
    getDeactivateReasons(
      (resp) => {
        if (resp.status === "Success") {
          setDeactivationReasons(resp.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [loggedinUserId, dispatch, deleteClicked]);

  useEffect(() => {
    if (openOtpVerification) {
      setResendEnabled(false);
      setTimer(60);
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(countdown);
            setResendEnabled(true);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [openOtpVerification]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpenPwdVerification = () => {
    setOpenPwdVerification(true);
  };

  const handleClosePwdVerification = () => {
    setOpenPwdVerification(false);
    setErrorMessage("");
  };

  const handleOpenOtpVerification = () => {
    setOpenOtpVerification(true);
  };

  const handleCloseOtpVerification = () => {
    setOpenOtpVerification(false);
  };

  const handleOpenDeactivationReasons = () => {
    setOpenDeactivationReasons(true);
  };

  const handleCloseDeactivationReasons = () => {
    setOpenDeactivationReasons(false);
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setPassword("");
    setOtp("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOtpChange = (e) => {
    setErrorMessage("");
    setOtp(e.target.value);
  };

  const handleCustomReasonChange = (e) => {
    setCustomReason(e.target.value);
  };

  const handleDeactivationDaysChange = (e) => {
    setDeactivationDays(e.target.value);
  };

  const toggleReason = (reason) => {
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const onPressContinue = () => {
    if (!selectedUser) {
      showToast("error", "Please select a user or member to continue.");
      return;
    }
    handleOpenConfirmDialog();
  };

  const passwordVerificationScreen = () => (
    <div>
      <AautiDialog
        open={openPwdVerification}
        onClose={handleClosePwdVerification}
        dialogTitle={AautiStrings?.passwordVerificationString}
        titlePosition="left"
        dialogWidth="500px"
      >
        <AautiText
          title={
            AautiStrings?.forYourSecurityPleaseReEnterYourPasswordOrOTPToContinueString
          }
        />
        <AautiTextInput
          important
          text={AautiStrings?.passwordString}
          value={password}
          onChange={handlePasswordChange}
          type="password"
          style={{ width: "100%" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setErrorMessage("");
            if (!password) {
              showToast("error", "Please enter password.");
              return;
            }
            loginAuth(
              { username: selectedUser.email, password },
              (response) => {
                if (response.result) {
                  handleClosePwdVerification();
                  handleOpenDeactivationReasons();
                } else {
                  showToast("error", "Invalid password. Please try again.");
                }
              },
              (err) => {
                console.log(err);
                showToast("error", "An error occurred. Please try again.");
              }
            );
          }}
          style={{ marginTop: "20px" }}
        >
          Verify
        </Button>
      </AautiDialog>
    </div>
  );

  const otpVerificationScreen = () => (
    <AautiDialog
      open={openOtpVerification}
      onClose={handleCloseOtpVerification}
      dialogTitle={AautiStrings?.OTPVerificationString}
      titlePosition="left"
      dialogWidth="500px"
    >
      <div>
        <AautiText
          title={AautiStrings?.pleaseEnterTheOTPSentToYourMobileNumberString}
        />
        <AautiTextInput
          important
          text={AautiStrings?.OTPString}
          value={otp}
          onChange={handleOtpChange}
          style={{ width: "100%", marginTop: "10px" }}
        />
        {errorMessage && (
          <Typography color="error" style={{ marginTop: "10px" }}>
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setErrorMessage("");
            VerifySms(
              selectedUser.mobile,
              otp,
              (response) => {
                if (response.status === "Success") {
                  handleCloseOtpVerification();
                  handleOpenDeactivationReasons();
                } else {
                  showToast("error", "Invalid OTP. Please try again.");
                }
              },
              (err) => {
                console.log(err);
                showToast("error", "An error occurred. Please try again.");
              }
            );
          }}
          style={{ marginTop: "20px" }}
        >
          Verify
        </Button>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <AautiText
            title={`Resend OTP ${resendEnabled ? "" : `in ${timer} seconds`}`}
            weight={"bold"}
            textStyle={{
              color: resendEnabled ? "#3166BA" : "grey",
              cursor: "pointer",
            }}
            onClick={() => {
              if (resendEnabled) {
                setOtp("");
                sendSms(
                  selectedUser?.mobile,
                  (response) => {
                    if (response.status === "Success") {
                      setResendEnabled(false);
                      setTimer(60);
                    }
                  },
                  (err) => {
                    console.log(err, "errr");
                  }
                );
              }
            }}
          />
        </div>
      </div>
    </AautiDialog>
  );

  const deactivationReasonsScreen = () => (
    <Dialog
      open={openDeactivationReasons}
      onClose={handleCloseDeactivationReasons}
      aria-labelledby="deactivation-reasons-title"
    >
      <DialogTitle id="deactivation-reasons-title">
        {selectedValue === "deactivate"
          ? "Deactivating your Aauti account"
          : "Deleting your Aauti account"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          {deactivationReasons.map((question) => (
            <Box
              key={question._id}
              onClick={() => toggleReason(question.name)}
              sx={{
                padding: "10px",
                marginBottom: "5px",
                cursor: "pointer",
                backgroundColor: selectedReasons.includes(question.name)
                  ? "#E3F2FD"
                  : "transparent",
                borderRadius: "4px",
              }}
            >
              <Typography variant="body1">{question.name}</Typography>
            </Box>
          ))}
          <Box
            onClick={() => toggleReason("Other")}
            sx={{
              padding: "10px",
              marginBottom: "5px",
              cursor: "pointer",
              backgroundColor: selectedReasons.includes("Other")
                ? "#E3F2FD"
                : "transparent",
              borderRadius: "4px",
            }}
          >
            <Typography variant="body1">Other</Typography>
          </Box>
          {selectedReasons.includes("Other") && (
            <TextField
              label="Reason"
              value={customReason}
              onChange={handleCustomReasonChange}
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        </FormControl>
        {selectedValue === "deactivate" && (
          <Box mt={2}>
            <Typography>Automatically reactivate my account in:</Typography>
            <FormControl fullWidth margin="normal">
              <Select
                value={deactivationDays}
                onChange={handleDeactivationDaysChange}
                fullWidth
              >
                {[10, 30, 60].map((days) => (
                  <MenuItem key={days} value={days}>
                    {days} days
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeactivationReasons} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleCloseDeactivationReasons();
            handleOpenConfirmationDialog();
          }}
          variant="contained"
          color="primary"
        >
          {selectedValue === "deactivate"
            ? "Deactivate Account"
            : "Delete Account"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmationDialog = () => (
    <Dialog
      open={openConfirmationDialog}
      onClose={handleCloseConfirmationDialog}
      aria-labelledby="confirm-dialog-title"
      maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          maxWidth: "320px",
          maxHeight: 435,
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={DeletionAlert}
          alt="Confirm"
          style={{
            height: "200px",
            width: "200px",
            alignSelf: "center",
          }}
        />
      </DialogContent>
      <DialogTitle id="confirm-dialog-title">
        {selectedValue === "deactivate"
          ? "Confirm Deactivation"
          : "Are you sure you want to delete?"}
      </DialogTitle>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseConfirmationDialog}
          sx={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDeleteClicked(true);
            if (selectedUser?._id === loggedinUserId) {
              if (selectedValue === "deactivate") {
                const payload = {
                  parentId: loggedinUserId,
                  reactivateDate: new Date(
                    new Date().getTime() +
                      deactivationDays * 24 * 60 * 60 * 1000
                  ).toISOString(),
                  reasonText: customReason,
                  reasons: selectedReasons,
                  status: true,
                  type: "deActivate",
                  userId: loggedinUserId,
                };

                deleteUser(
                  payload,
                  (response) => {
                    handleCloseConfirmationDialog();
                    console.log("Account action successful", response);
                  },
                  (error) => {
                    console.log("Account action failed", error);
                  }
                );

                navigation("/");
                logoutFunction();
              } else {
                const deleteAllMembers = async () => {
                  const promises = yourMembers.map((each) => {
                    const payload = {
                      parentId: loggedinUserId,
                      reactivateDate: null,
                      reasonText: customReason,
                      reasons: selectedReasons,
                      status: true,
                      type: "delete",
                      userId: each._id,
                    };
                    return new Promise((resolve, reject) => {
                      try {
                        deleteUser(payload, (response) => {
                          console.log("Member deletion successful", response);
                          resolve(response);
                        });
                      } catch (error) {
                        console.log("Member deletion failed", error);
                        showToast(
                          "error",
                          "An error occurred while deleting members. Please try again."
                        );
                        reject(error);
                      }
                    });
                  });

                  try {
                    await Promise.all(promises);

                    const payload = {
                      parentId: loggedinUserId,
                      reactivateDate: null,
                      reasonText: customReason,
                      reasons: selectedReasons,
                      status: true,
                      type: "delete",
                      userId: loggedinUserId,
                    };

                    deleteUser(
                      payload,
                      (response) => {
                        handleCloseConfirmationDialog();
                        console.log("Account action successful", response);
                        navigation("/");
                        logoutFunction();
                      },
                      (error) => {
                        console.log("Account action failed", error);
                      }
                    );
                  } catch (error) {
                    console.error("Error during member deletion", error);
                  }
                };

                deleteAllMembers();
              }
            } else {
              const payload = {
                parentId: loggedinUserId,
                reactivateDate:
                  selectedValue === "deactivate"
                    ? new Date(
                        new Date().getTime() +
                          deactivationDays * 24 * 60 * 60 * 1000
                      ).toISOString()
                    : null,
                reasonText: customReason,
                reasons: selectedReasons,
                status: true,
                type: selectedValue === "deactivate" ? "deActivate" : "delete",
                userId: selectedUser?._id,
              };

              deleteUser(
                payload,
                (response) => {
                  handleCloseConfirmationDialog();
                  setDeleteClicked(false);
                  console.log("Account action successful", response);
                },
                (error) => {
                  setDeleteClicked(false);
                  console.log("Account action failed", error);
                }
              );
            }
          }}
        >
          {selectedValue === "deactivate" ? "Deactivate" : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmDialog = () => (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          maxWidth: "320px",
          maxHeight: 435,
        },
      }}
      maxWidth="xs"
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={deletemember}
          alt="Confirm"
          style={{ width: "100px", height: "100px" }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AautiText
          title={
            courseConstant?.allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseConfirmDialog();
            if (selectedUser?.email) {
              handleOpenPwdVerification();
            } else if (selectedUser?.mobile !== null) {
              sendSms(
                selectedUser?.mobile,
                (response) => {
                  if (response.status === "success") {
                    handleOpenOtpVerification();
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              showToast(
                "error",
                "The selected user/member does not have valid contact information."
              );
            }
          }}
          color="primary"
          autoFocus
          variant="outlined"
        >
          Yes
        </Button>
        <Button
          onClick={handleCloseConfirmDialog}
          variant="contained"
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );

  const returnHeaderTabs = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignSelf: "center",
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <AautiText
          weight={"bold"}
          title={courseConstant?.deactivationOrDeletion}
          textStyle={{ color: "#000000" }}
        />
      </div>
    </div>
  );

  const UserCard = () => (
    <div
      onClick={() => setSelectedUser(userDetails)}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        backgroundColor:
          selectedUser === userDetails ? "#E3F2FD" : "transparent",
        padding: "10px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <Avatar
        src={
          !isEmpty(userDetails?.profileImage)
            ? userDetails?.profileImage
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        }
        alt="profile"
        style={{
          width: "50px",
          height: "50px",
          marginRight: "10px",
        }}
      />
      <div style={{ flex: 1 }}>
        <AautiText
          className={"textStyle1"}
          title={userDetails?.firstName + " " + userDetails?.lastName}
          weight={"bold"}
        />
        <AautiText
          className={"textStyle2"}
          title={userDetails?.displayName}
          weight={"bold"}
          size={"semi"}
        />
        <div
          style={{
            borderTop: "1px solid #dedede",
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );

  const MemberCard = (each, index) => (
    <div
      key={each._id}
      onClick={() => setSelectedUser(each)}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        backgroundColor: selectedUser === each ? "#E3F2FD" : "transparent",
        padding: "10px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <Avatar
        src={
          !isEmpty(each?.profileImage)
            ? each?.profileImage
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        }
        alt="profile"
        style={{
          width: "50px",
          height: "50px",
          marginRight: "10px",
        }}
      />
      <div style={{ flex: 1 }}>
        <AautiText
          className={"textStyle1"}
          title={each?.displayName}
          weight={"bold"}
        />
        <AautiText
          className={"textStyle2"}
          title={each?.relation || "Unknown"}
          weight={"bold"}
          size={"semi"}
        />
        <div
          style={{
            borderTop: "1px solid #dedede",
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );

  const RadioWithSubtitle = ({ title, subtitle, value }) => (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: "4px", maxWidth: "600px" }}
          >
            {subtitle}
          </Typography>
        </Box>
      }
      sx={{ alignItems: "flex-start", margin: "0" }}
    />
  );

  return (
    <AppContainer>
      <AccountTabs />
      <div className="head">
        {returnHeaderTabs()}
        {loading ? (
          <Box className="TRloadingdiv1">
            <Box className="TRloadingdiv2">
              <Skeleton variant="rounded" width={"100%"} height={40} />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={40}
                sx={{ m: "8px 0px" }}
              />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={mobileDevices ? 200 : 400}
              />
            </Box>
            <Box className="TRloadingdiv3">
              <Skeleton variant="rounded" width={"100%"} height={30} />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={40}
                sx={{ m: "8px 0px" }}
              />
              <Skeleton variant="rounded" width={"100%"} height={60} />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderRadius: 1.5,
              boxShadow:
                "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  width: "30%",
                  borderRight: "1px #dedede solid",
                }}
              >
                <Box
                  sx={{
                    height: "65vh",
                    overflow: "scroll",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  <div>{UserCard()}</div>
                  {yourMembers && yourMembers.length > 0 && (
                    <>
                      <AautiText
                        weight={"bold"}
                        title={AautiStrings?.membersString}
                      />
                      <div>
                        {yourMembers?.map((each, index) =>
                          MemberCard(each, index)
                        )}
                      </div>
                    </>
                  )}
                </Box>
              </Box>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "30px",
                }}
              >
                <div>
                  <AautiText
                    weight={"bold"}
                    title={
                      AautiStrings?.deactivationOrDeletionYourAautiAccountString
                    }
                  />
                  <AautiText
                    weight={"normal"}
                    size={"semi"}
                    title={
                      AautiStrings?.ifYouWantToTemporarilyCloseYourAccountYouCanDeactivateItIfYouWantToPermanentlyRemoveYourDataFromAautiYouCanDeleteYourAccountString
                    }
                    textStyle={{ marginTop: "8px", maxWidth: "900px" }}
                  />
                  <FormControl component="fieldset">
                    <RadioGroup value={selectedValue} onChange={handleChange}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          borderRadius: 1.5,
                          padding: "10px",
                          boxShadow:
                            "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          marginTop: "10px",
                          minHeight: "100px",
                        }}
                      >
                        <RadioWithSubtitle
                          title="Deactivate Account"
                          subtitle="Your Profile won't be shown on Aauti (There should not be any Active/Upcoming Sessions)"
                          value="deactivate"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          borderRadius: 1.5,
                          padding: "10px",
                          boxShadow:
                            "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          marginTop: "10px",
                          minHeight: "100px",
                        }}
                      >
                        <RadioWithSubtitle
                          title={AautiStrings?.deleteAccountString}
                          subtitle={
                            AautiStrings?.deletingYourAccountIsPermanentAndIrreversibleOnceDeletedYouWontBeAbleToRetrieveYourAccountString
                          }
                          value="delete"
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>

                  <AautiText
                    weight={"normal"}
                    size={"semi"}
                    title={
                      <span>
                        <strong>Note:</strong>&nbsp;Before Deleting/Deactivating
                        make sure that there are no Ongoing/Upcoming Sessions.
                        Please Cancel all the Sessions (if any) before
                        proceeding further.
                      </span>
                    }
                    textStyle={{ maxWidth: "900px", marginTop: "20px" }}
                  />
                </div>
              </div>
            </>
          </Box>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          margin: "40px 0px",
        }}
      >
        <Button onClick={onPressContinue} variant="contained" color="primary">
          Continue
        </Button>
      </div>
      {passwordVerificationScreen()}
      {otpVerificationScreen()}
      {deactivationReasonsScreen()}
      {confirmationDialog()}
      {confirmDialog()}
    </AppContainer>
  );
};

export default DeleteOrDeactivate;
