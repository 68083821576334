import React, { useEffect, useState } from "react";
import { EnUserFeedback, ServiceProviderFeedback } from "./Services";
import { useSelector } from "react-redux";
import AautiText from "../globalComponents/AautiText";
import "./styles.scss";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { isEmpty } from "lodash";
import EmptyTopic from "../globalComponents/EmptyTopic";
import EmptyFeedback from "../../assets/images/EmptyFeedback.png";
import { useNavigate } from "react-router-dom";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import { AautiStrings } from "../globalComponents/AautiStrings";

const Feedback = (props) => {
  const { UserRole, loggedinUserId } = useSelector((state) => state.app);
  const [feedbackList, setFeedbackList] = useState([]);
  const navigate = useNavigate();
  const onSuccess = (res) => {
    if (res?.status == "Success") {
      setFeedbackList(res?.result);
    }
  };
  const onFailure = (err) => {
    console.log(err);
  };
  useEffect(() => {
    if (UserRole == "serviceprovider") {
      ServiceProviderFeedback(loggedinUserId, onSuccess, onFailure);
    } else {
      EnUserFeedback(loggedinUserId, onSuccess, onFailure);
    }
  }, [UserRole]);
  const returntime = (time) => {
    const formattedTime = moment.utc(time).local().startOf("seconds").fromNow();
    return formattedTime || "NA";
  };
  const { mobileDevices } = useSelector((state) => state.responsive);

  return (
    <AppContainer>
      <AccountTabs />
      <AautiText
        title={AautiStrings?.feedbackString}
        textStyle={{ color: "#000000" }}
        weight={"bold"}
        size={"large"}
      />

      <div className="mainContainer-feedback">
        <div className="feedbacks-list-container">
          {isEmpty(feedbackList) ? (
            <EmptyTopic
              topicName={`${
                UserRole === "serviceprovider"
                  ? "You haven't added any Feedback yet."
                  : "You haven't received  any Feedback yet."
              }`}
              Image={EmptyFeedback}
              showButton={false}
              buttonName={AautiStrings?.addFeedbackString}
              onClick={() => navigate("/feedback")}
            />
          ) : (
            <>
              {feedbackList?.map((each, index) => (
                <div key={index} className="feedback-item">
                  <div className="feedback-main-div2 ">
                    <Avatar
                      sx={{
                        width: mobileDevices ? 45 : 60,
                        height: mobileDevices ? 45 : 60,
                      }}
                      alt={each?.serviceProviderName}
                      src={each?.endUserImage}
                    />
                    <div className="feedback-child-div">
                      <div className="feedback-child-div2 ">
                        <AautiText
                          size={"medium"}
                          weight={"bold"}
                          title={each?.endUserName}
                        />
                        <AautiText
                          size={"semi"}
                          title={returntime(each?.createdAt)}
                        />
                      </div>
                      <AautiText
                        weight={"medium"}
                        title={each?.name}
                        className={"feedback-name-text"}
                      />
                    </div>
                  </div>
                  <AautiText
                    title={each?.comment}
                    className={"feedback-comment-text"}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default Feedback;
