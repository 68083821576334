import React, { useCallback, useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import HelperText from "./HelperText";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import {
  commonBatchObject,
  dailyMTObj,
  getNextTimeSlot,
  weekMTObj,
} from "../createCourse/Constants";
import NavButton from "./NavButton";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import WeeklySlotSelection from "./WeeklySlotSelection";
import CustomSlotSelection from "./CustomSlotSelection";
import { RxCross2 } from "react-icons/rx";
import { showToast } from "../../globalComponents/Toast";
import { getMembersDiscounts } from "../createCourse/Services";
import MembersConditions from "./MembersConditions";
import { PriceConversions, weekKeys } from "../../CommonFunctions";
import rightConflicts from "../../../assets/images/right-conflict.svg";
import { courseConstant } from "./courseConstant";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { AccountCircle } from "@mui/icons-material";
import PercentIcon from "@mui/icons-material/Percent";

const CoursePricing = ({
  CreateCourseObj,
  changeCreateObj,
  convertINRtoUSD,
  handleOpen,
  handleClose,
  returnRelatedPricing,
  editCourse,
  serviceProviderConflicts,
  countryObject,
}) => {
  const { convertPriceWithCurrency, onlyPriceConvert, onlyPriceWithCurrency } =
    PriceConversions();
  const nextTimeSlot = getNextTimeSlot();

  // const [courseBatch, setWeeklyObj] = useState({});
  let today = new Date();
  const moment = require("moment-timezone");
  const [editItem, setEditItem] = useState(null);
  const [activeBatch, setActiveBatch] = useState(10);
  const [editBatch, setEditBatch] = useState(false);
  const [activeBatchId, setActiveBatchId] = useState();
  const [activeBatchType, setActivBatchType] = useState(
    CreateCourseObj?.courseType[0]
  );
  const [previousBatchObj, setPreviousBatchObj] = useState({});

  const activeSlot =
    activeBatch == 10 || activeBatch == 30
      ? {
          ...commonBatchObject,
          startTime: nextTimeSlot,
          endTime: moment(nextTimeSlot)
            ?.add(1, "hour")
            ?.format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {
          ...commonBatchObject,
          recurringDays: {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
          },
        };
  const [courseBatch, setCourseBatch] = useState({ ...activeSlot });
  const [dialogOpen, setDialogOpen] = useState("");

  const onChangetimeSlotObj = (val, slot) => {
    let dateFormat = moment(val?.$d)?.format("YYYY-MM-DDT00:00:00");
    let timeFromat = moment(val?.$d)?.format("YYYY-MM-DDTHH:mm:00");
    const newOnHour = moment(timeFromat)
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:00");

    const isBefore = moment(timeFromat)?.isBefore(courseBatch?.endTime);
    if (slot === "batchName") {
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: val,
      }));
      setCourseBatch((prevS) => ({
        ...prevS,
        [slot]: val,
      }));
    } else if (slot === "startDate") {
      const val2 =
        slot === "startDate" ? "discountStartDate" : "discountEndDate";
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: dateFormat,
        [val2]: dateFormat,
        endDate: dateFormat,
        discountEndDate: dateFormat,
      }));
      setCourseBatch((preve) => ({
        ...preve,
        [slot]: dateFormat,
        [val2]: dateFormat,
        endDate: dateFormat,
        discountEndDate: dateFormat,
      }));
    } else if (slot === "endDate") {
      setCourseBatch((prev) => ({
        ...prev,
        endDate: dateFormat,
        discountEndDate: dateFormat,
      }));
      setCourseBatch((preve) => ({
        ...preve,
        endDate: dateFormat,
        discountEndDate: dateFormat,
      }));
    } else if (slot === "startTime") {
      // !isBefore
      //   ? setCourseBatch((prev) => ({
      //       ...prev,
      //       startTime: val,
      //       endTime: newOnHour,
      //     }))
      //   :
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
        endTime: newOnHour,
      }));
    } else if (slot === "endTime") {
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
      }));
    } else {
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: val,
      }));
    }
  };

  const membersDiscount = courseBatch?.membersDiscount;

  const changeEditItem = (val, feild) => {
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    const timeFromat = moment(val?.$d).format("YYYY-MM-DDTHH:mm:00");
    const val2 = moment(val?.$d)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss");

    let newItem = null;
    if (feild == "startDate") {
      newItem = {
        ...editItem.each,
        startDate: dateFormat,
        endDate: dateFormat,
      };
    } else if (feild == "startTime") {
      newItem = {
        ...editItem.each,
        startTime: timeFromat,
        endTime: val2,
      };
    } else if (feild == "endTime") {
      newItem = {
        ...editItem.each,
        endTime: timeFromat,
      };
    } else if (feild == "endDate") {
      newItem = {
        ...editItem.each,
        endDate: timeFromat,
      };
    } else {
      newItem = { ...editItem.each, [feild]: val };
    }
    setEditItem((prev) => ({
      ...prev,
      each: newItem,
    }));
  };

  const returnMinTime = (startTime) => {
    const minTime = dayjs()
      .set("hour", moment(startTime).hour())
      .startOf("minute");
    // .add(30, "minute");
    return minTime;
  };
  const onChangeAddPrice = (val, feild) => {
    if (feild === "discountType") {
      setCourseBatch((prev) => ({
        ...prev,
        [feild]: val || "",
      }));
    } else if (feild === "allowWaitingList") {
      setCourseBatch((prev) => ({
        ...prev,
        [feild]: val,
      }));
    } else {
      if (activeBatch == 10 || activeBatch == 30) {
        setCourseBatch((prev) => ({
          ...prev,
          [feild]: val || "",
        }));
      } else {
        setCourseBatch((prev) => ({
          ...prev,
          [feild]: val || "",
        }));
      }
    }
  };

  const amount =
    courseBatch.localIndividualDiscountPrice ||
    courseBatch.localIndividualPrice;

  const handlePercentageChange = (value, key) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      const updatedDiscounts = membersDiscount?.map((discount) => {
        if (discount.noOfMembers == key) {
          const updatedValue = Math.min(numericValue, discount.maximum);
          const amount = returnCalAmount(updatedValue);
          return {
            ...discount,
            percentage: updatedValue.toString(),
            finalPrice: convertINRtoUSD(amount),
            localFinalPrice: amount,
          };
        }
        return discount;
      });
      onChangeAddPrice(updatedDiscounts, "membersDiscount");
    }
  };

  const returnCalAmount = (percentage) => {
    const discountAmount = (amount * percentage) / 100;
    const finalAmount = amount - discountAmount;
    return finalAmount;
  };

  useEffect(() => {
    if (courseBatch?.allowMembers) {
      functionHandleFinalPrice(membersDiscount);
    }
  }, [amount]);

  const functionHandleFinalPrice = (conditonsArray) => {
    let newArray = conditonsArray?.map((each) => {
      const localAmount = returnCalAmount(each?.percentage);
      return {
        ...each,
        finalPrice: convertINRtoUSD(localAmount),
        localFinalPrice: localAmount,
      };
    });
    onChangeAddPrice(newArray, "membersDiscount");
  };

  const handleMembersDiscountApi = () => {
    handleOpen();
    getMembersDiscounts(
      (response) => {
        if (response?.result !== null) {
          functionHandleFinalPrice(response?.result);
          setDialogOpen("membersDiscount");
        }
        handleClose();
      },
      (error) => {
        handleClose();
        console.log(error, "error-members discounts");
      }
    );
  };

  const onChangeDate = (val, slot) => {
    const val2 = slot === "startDate" ? "discountStartDate" : "discountEndDate";
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    setCourseBatch((prev) => ({
      ...prev,
      [slot]: dateFormat,
      [val2]: dateFormat,
    }));
  };

  const returnBatchType = () => {
    const endTime = moment(courseBatch?.startTime)
      ?.add(1, "hour")
      ?.format("YYYY-MM-DDTHH:mm:ss");
    switch (activeBatch) {
      case 10:
        return (
          <div className="start-end-time">
            <div className="eachDateStart" id="start-time-pricing">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["Time Picker"]}>
                  <TimePicker
                    label={AautiStrings.StartTimeString}
                    timeSteps={{ minutes: 30 }}
                    onError={false}
                    sx={{ borderColor: "#000000", width: "100%" }}
                    minTime={
                      moment(courseBatch?.startDate).format("YYYY-MM-DD") ===
                      moment(new Date()).format("YYYY-MM-DD")
                        ? dayjs(nextTimeSlot)
                        : null
                    }
                    // disablePast={
                    //   moment(courseBatch?.startDate).format("YYYY-MM-DD") ===
                    //   moment(new Date()).format("YYYY-MM-DD")
                    // }
                    ampm={false}
                    value={
                      // editItem
                      //   ? editItem?.each?.startTime
                      //     ? dayjs(editItem?.each?.startTime)
                      //     : null
                      //   : dayjs(courseBatch?.startTime)
                      //   ? dayjs(courseBatch?.startTime)
                      //   : dayjs(nextTimeSlot)
                      dayjs(courseBatch?.startTime)
                    }
                    onChange={(newVal) => {
                      // const val2 = moment(newVal)
                      //   ?.add(1, "hour")
                      //   ?.format("YYYY-MM-DDTHH:mm:ss");
                      // if (editItem) {
                      //   changeEditItem(newVal, "startTime");
                      // } else {
                      //   onChangetimeSlotObj(newVal, "startTime");
                      //   // onChangetimeSlotObj(val2, "endTime");
                      // }
                      onChangetimeSlotObj(newVal, "startTime");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="eachDateStart" id="end-time-pricing">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={{ width: "100%" }}
                >
                  <TimePicker
                    sx={{ width: "100%" }}
                    label={AautiStrings.EndTimeString}
                    timeSteps={{ minutes: 30 }}
                    minTime={
                      editItem
                        ? returnMinTime(editItem?.each?.startTime)
                        : dayjs(endTime)
                    }
                    ampm={false}
                    value={
                      // editItem
                      //   ? editItem?.each?.endTime
                      //     ? dayjs(editItem?.each?.endTime)
                      //     : null
                      //   : courseBatch?.endTime
                      //   ? dayjs(courseBatch?.endTime)
                      //   : null
                      dayjs(courseBatch?.endTime)
                    }
                    onChange={(newVal) => {
                      editItem
                        ? changeEditItem(newVal, "endTime")
                        : onChangetimeSlotObj(newVal, "endTime");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        );
      case 20:
        const recurringDays = courseBatch?.recurringDays || {};
        const WeekArray = Object.keys(recurringDays)?.filter(
          (day) => recurringDays[day]?.length > 0
        );
        return (
          <div style={{ width: "80%" }}>
            {isEmpty(WeekArray) ? (
              <button
                className="day-button"
                onClick={() => {
                  setDialogOpen("weekly");
                }}
              >
                Select Days
              </button>
            ) : (
              <>
                <AautiText
                  title={courseConstant?.selectedDays}
                  size={"small"}
                />
                <div
                  className="tags-container"
                  style={{
                    gap: 6,
                    overflow: "scroll",
                    flexWrap: "nowrap",
                    marginTop: 1,
                  }}
                  onClick={() => {
                    setDialogOpen("weekly");
                  }}
                >
                  {WeekArray.map((each, index) => (
                    <button key={index} className="day-button">
                      <AautiText size={"small"} key={index} title={each} />
                      <RxCross2 />
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        );
      case 30:
        const selectedRange = `${moment(courseBatch?.startDate).format(
          "DD MMM YYYY"
        )}-${moment(courseBatch?.endDate).format("DD MMM YYYY")}`;
        return (
          <div style={{ width: "100%", marginTop: -8 }}>
            {courseBatch?.customDates ? (
              <>
                <AautiText
                  title={courseConstant?.selectedDates}
                  size={"small"}
                />
                <div
                  onClick={() => {
                    setDialogOpen("custom");
                  }}
                  className="tags-container"
                  style={{ width: "100%", padding: "8px 10px", marginTop: 1 }}
                >
                  <AautiText title={selectedRange} />
                </div>
              </>
            ) : (
              <button>
                <AautiText
                  title={AautiStrings.SelectDatesString}
                  onClick={() => {
                    setDialogOpen("custom");
                  }}
                />
              </button>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const showBatchType = CreateCourseObj?.inPerson && CreateCourseObj?.virtual;

  const handleSaveBatch = () => {
    setPreviousBatchObj({});
    const localDiscountPrice = checkDiscount();
    let batchObje;
    const handleDeleteItemByKey = (obj, keys) => {
      const newObj = { ...obj };
      keys.forEach((key) => {
        if (key in newObj) {
          delete newObj[key];
        }
      });
      return newObj;
    };
    let batchName;
    if (
      activeBatchType === "Group" &&
      (activeBatch == 10 || activeBatch == 30)
    ) {
      batchName = "groupDaily";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "individualDiscountValue",
        "individualPrice",
      ]);
    } else if (activeBatchType === "Group" && activeBatch == 20) {
      batchName = "groupWeekly";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "individualDiscountValue",
        "individualPrice",
      ]);
    } else if (
      activeBatchType == "Individual" &&
      (activeBatch == 10 || activeBatch == 30)
    ) {
      batchName = "individualDaily";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "groupDiscountValue",
        "groupPrice",
      ]);
    } else if (activeBatchType == "Individual" && activeBatch == 20) {
      batchName = "individualWeekly";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "groupDiscountValue",
        "groupPrice",
      ]);
    }
    if (activeBatchType == "Group") {
      batchObje.groupDiscountPrice = convertINRtoUSD(localDiscountPrice);
      batchObje.localGroupDiscountPrice = localDiscountPrice;
    } else if (activeBatchType == "Individual") {
      batchObje.individualDiscountPrice = convertINRtoUSD(localDiscountPrice);
      batchObje.localIndividualDiscountPrice = localDiscountPrice;
    }
    batchObje.timeZone = moment().utcOffset();
    if (!showBatchType) {
      batchObje.inPerson = CreateCourseObj.inPerson;
      batchObje.virtual = CreateCourseObj.virtual;
    }

    if (activeBatch == 10) {
      delete batchObje.recurringDays;
    } else if (activeBatch == 20) {
      delete batchObje.startTime;
      delete batchObje.endTime;
    }

    if (editBatch) {
      // console.log(
      //   CreateCourseObj[batchName],
      //   "CreateCourseObj[batchName]",
      //   batchName,
      //   { batchObje },
      //   { CreateCourseObj }
      // );
      if (batchName != courseBatch?.batchLabel) {
        let newBatches = [...CreateCourseObj[batchName], batchObje];
        let newList = CreateCourseObj[batchName]?.filter(
          (each, index) => index !== activeBatchId
        );

        changeCreateObj(newBatches, batchName);
        changeCreateObj(newList, courseBatch?.batchLabel);
      } else {
        let intitialList = CreateCourseObj[courseBatch?.batchLabel]?.map(
          (each, index) => {
            if (index == activeBatchId) {
              let object = courseBatch;
              if (activeBatch == 10) {
                delete object.recurringDays;
              } else if (activeBatch == 20) {
                delete object.startTime;
                delete object.endTime;
              }
              return { ...object };
            } else {
              return { ...each };
            }
          }
        );
        changeCreateObj(
          intitialList,
          editBatch ? courseBatch?.batchLabel : batchName
        );
      }
    } else {
      let allBatches = [...CreateCourseObj[batchName], batchObje];
      changeCreateObj(
        allBatches,
        editBatch ? courseBatch?.batchLabel : batchName
      );
    }

    setCourseBatch({
      ...commonBatchObject,
      startTime: nextTimeSlot,
      endTime: moment(nextTimeSlot)
        ?.add(1, "hour")
        ?.format("YYYY-MM-DDTHH:mm:ss"),
    });
    setActiveBatch(10);
    setActivBatchType(CreateCourseObj?.courseType[0]);
    setEditBatch(false);
  };

  const returnBatchesArray = (type) => {
    const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
      CreateCourseObj;

    const individual = [
      {
        title: "individualDaily",
        arrayOfBatches: individualDaily,
      },
      {
        title: "individualWeekly",
        arrayOfBatches: individualWeekly,
      },
    ];
    const group = [
      {
        title: "groupWeekly",
        arrayOfBatches: groupWeekly,
      },
      {
        title: "groupDaily",
        arrayOfBatches: groupDaily,
      },
    ];
    return type == "Individual" ? individual : group;
  };

  const handleRemoveBatch = (type, batch, object, ind) => {
    if (isEmpty(object?.participants)) {
      const newlist = [...batch?.arrayOfBatches]?.filter(
        (each, index) => index != ind
      );
      changeCreateObj(newlist, batch?.title);
    } else {
      showToast("info", "Batch Purchased by users cannot be deleted");
    }
  };

  const returnBatches = (type) => {
    return (
      <div style={{ width: "100%" }}>
        {returnBatchesArray(type).map((each, index) => {
          return (
            <div style={{ width: "100%" }}>
              {each.arrayOfBatches?.map((batch, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (each?.title == "individualDaily") {
                      setActivBatchType("Individual");
                      if (batch?.excludedDates) {
                        setActiveBatch(30);
                      } else {
                        setActiveBatch(10);
                      }
                    } else if (each?.title == "individualWeekly") {
                      setActivBatchType("Individual");
                      if (batch?.recurringDays) {
                        setActiveBatch(20);
                      } else {
                        if (batch?.excludedDates) {
                          setActiveBatch(30);
                        } else {
                          setActiveBatch(10);
                        }
                      }
                    } else if (each?.title == "groupDaily") {
                      setActivBatchType("Group");
                      if (batch?.excludedDates) {
                        setActiveBatch(30);
                      } else {
                        setActiveBatch(10);
                      }
                    } else if (each?.title == "groupWeekly") {
                      setActivBatchType("Group");
                      if (batch?.recurringDays) {
                        setActiveBatch(20);
                      } else {
                        if (batch?.excludedDates) {
                          setActiveBatch(30);
                        } else {
                          setActiveBatch(10);
                        }
                      }
                    }
                    setCourseBatch({ ...batch, batchLabel: each.title });
                    setEditBatch(true);
                    setActiveBatchId(index);
                  }}
                  className="flex-container"
                  style={{
                    justifyContent: "space-between",
                    marginBottom: 6,
                    background: "#F2F2F6",
                    padding: 4,
                    borderRadius: 4,
                  }}
                >
                  <AautiText
                    textStyle={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 1,
                      textOverflow: "ellipsis",
                      maxWidth: "80%",
                    }}
                    title={batch?.batchName}
                  />
                  <Tooltip title={AautiStrings.RemoveString}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveBatch(type, each, batch, index);
                      }}
                    >
                      <RxCross2 size={15} />
                    </IconButton>
                  </Tooltip>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const convrtedPrice = onlyPriceConvert(5).toFixed();
  const minimumCurrency = onlyPriceWithCurrency(convrtedPrice);

  const addSlotValidations = () => {
    const presentTime = new Date();
    const ipDate = new Date(courseBatch?.startDate);
    const price = courseBatch?.individualPrice || courseBatch?.groupPrice;
    const localPrice =
      courseBatch?.localGroupPrice || courseBatch?.localIndividualPrice;
    const newErrors = {};
    if (!Number(price)) newErrors.price = "Price is required";
    if (courseBatch?.batchName?.length < 3)
      newErrors.username = "title is required";
    if (localPrice < convrtedPrice) {
      newErrors.lowPrice = "check minimum price";
    }
    if (showBatchType && !courseBatch.virtual && !courseBatch.inPerson) {
      newErrors.classType = "choose class type";
    }
    // if (presentTime > ipDate) {
    //   newErrors.startTime = "start time cannot be present time";
    // }
    const maxNoOfStudents = courseBatch?.maxNoOfStudents;

    if (activeBatchType == "Group" && (!maxNoOfStudents || maxNoOfStudents < 2))
      newErrors.maxNoOfStudents = "No of students should be more than 2";

    if (Object.keys(newErrors)?.length == 0) {
      // else if (courseBatch?.level?.length == 0)
      //   newErrors.level = "please select level";
      // else if (courseBatch?.courseType?.length == 0)
      //   newErrors.courseType = "Please select course type";
      // else if (!courseBatch?.inPerson && !courseBatch?.virtual)
      //   newErrors.password = "Please select any Inperson Or Virtual";
      // else if (courseBatch?.categories?.length == 0)
      //   newErrors.courseType = "Please select categories";
      // else if (courseBatch?.countries?.length == 0)
      //   newErrors.password = "Please select countries";
      // else if (
      //   courseBatch?.noOfStudents < 2 &&
      //   courseBatch?.courseType?.includes("Group")
      // )
      //   newErrors.password = "No of students should be more than 2.";
      // else if (!courseBatch?.adhocImage) newErrors.image = "Please upload image";

      return false;
    } else {
      return true;
    }
  };

  const checkDiscount = () => {
    const {
      discountType,
      localIndividualPrice,
      localIndividualDiscountValue,
      localGroupDiscountValue,
      localGroupPrice,
    } = courseBatch;
    const localMainPrice =
      activeBatchType == "Group" ? localGroupPrice : localIndividualPrice;
    const localDiscount =
      activeBatchType == "Group"
        ? localGroupDiscountValue
        : localIndividualDiscountValue;

    if (localDiscount) {
      if (discountType == "Percentage") {
        const discount = (localMainPrice * localDiscount) / 100;
        return localMainPrice - discount || "";
      } else {
        return localMainPrice - localDiscount || "";
      }
    } else {
      return "";
    }
  };

  const onChangeRadio = (bool, field) => {
    if (activeBatch == 20) {
      setCourseBatch((prev) => ({
        ...prev,
        [field]: bool,
      }));
    } else {
      setCourseBatch((prev) => ({
        ...prev,
        [field]: bool,
      }));
    }
  };

  const maxDate = moment(courseBatch?.startDate).add(6, "months").toDate();

  const hasWeekKey = Object.values(courseBatch?.recurringDays || {}).some(
    (arr) => arr?.length > 0
  );

  return (
    <div className="tab">
      <AautiText
        size={"medium"}
        weight={"bold"}
        title={courseConstant?.coursePricing}
        textStyle={{ marginBottom: "15px" }}
      />
      <div
        className="flex-container"
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <div style={{ width: "17%" }}>
          {CreateCourseObj?.courseType?.map((each, index) => {
            const active = activeBatchType === each;
            return (
              <div key={index} style={{ width: "100%" }}>
                <button
                  onClick={() => {
                    if (!active) {
                      if (CreateCourseObj?.courseType?.length == 2) {
                        if (
                          !previousBatchObj?.hasOwnProperty(activeBatchType)
                        ) {
                          setPreviousBatchObj((prev) => ({
                            ...prev,
                            [activeBatchType]: courseBatch,
                          }));
                        }
                        setActivBatchType(each);
                        if (previousBatchObj?.[each]) {
                          setCourseBatch(previousBatchObj?.[each]);
                        } else {
                          setCourseBatch({
                            ...commonBatchObject,
                            startTime: nextTimeSlot,
                            endTime: moment(nextTimeSlot)
                              ?.add(1, "hour")
                              ?.format("YYYY-MM-DDTHH:mm:ss"),
                          });
                        }
                        setEditBatch(false);
                      } else {
                        setActivBatchType(each);
                        setCourseBatch({
                          ...commonBatchObject,
                          startTime: nextTimeSlot,
                          endTime: moment(nextTimeSlot)
                            ?.add(1, "hour")
                            ?.format("YYYY-MM-DDTHH:mm:ss"),
                        });
                        setEditBatch(false);
                      }
                    }
                  }}
                  key={index}
                  style={{ width: "100%", marginBottom: 8 }}
                  className={`level-item course-types center-content ${
                    active ? "active-class" : ""
                  }`}
                >
                  <AautiText title={each} size="normal" textStyle={{}} />
                </button>
                <div
                  style={{
                    padding: 4,
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {returnBatches(each)}
                  <button
                    className="Add-btn"
                    onClick={() => {
                      setActivBatchType(each);
                      setCourseBatch({
                        ...commonBatchObject,
                        startTime: nextTimeSlot,
                        endTime: moment(nextTimeSlot)
                          ?.add(1, "hour")
                          ?.format("YYYY-MM-DDTHH:mm:ss"),
                      });
                      setEditBatch(false);
                      setActiveBatch(10);
                    }}
                  >
                    <AautiText
                      size={"semi"}
                      title={AautiStrings.AddBatchString}
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <hr className="inline-hr" />
        <div style={{ width: "80%" }}>
          <div>
            <AautiText
              title={AautiStrings?.step1BatchString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <button
              className={`level-item course-types center-content active-class`}
            >
              <AautiText title={activeBatchType} size="normal" textStyle={{}} />
            </button>
          </div>
          <div className="level-main">
            <AautiText
              title={AautiStrings?.step2DetailsString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  gap: 10,
                  marginBottom: 15,
                }}
              >
                <div style={{ width: "100%" }} id="start-date-pricing">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings.StartDateString}>
                        <DatePicker
                          disabled={
                            courseBatch?._id &&
                            !isEmpty(CreateCourseObj?.endUserList)
                          }
                          value={
                            // editItem
                            //   ? editItem?.each?.startDate
                            //     ? dayjs(editItem?.each?.startDate)
                            //     : null
                            //   : courseBatch?.startDate
                            //   ? dayjs(courseBatch?.startDate)
                            //   : null
                            dayjs(courseBatch?.startDate)
                          }
                          onChange={(e) => {
                            if (editItem) {
                              changeEditItem(e, "startDate");
                            } else {
                              onChangetimeSlotObj(e, "startDate");
                            }
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs(today)}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }} id="end-date-pricing">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings.EndDateString}>
                        <DatePicker
                          disabled={
                            courseBatch?._id &&
                            !isEmpty(CreateCourseObj?.endUserList)
                          }
                          sx={{ minWidth: "100% !important" }}
                          value={
                            editItem
                              ? editItem?.each?.endDate
                                ? dayjs(editItem?.each?.endDate)
                                : null
                              : courseBatch?.endDate
                              ? dayjs(courseBatch?.endDate)
                              : null
                          }
                          onChange={(newDate) => {
                            editItem
                              ? changeEditItem(newDate, "endDate")
                              : onChangetimeSlotObj(newDate, "endDate");
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs(courseBatch?.startDate)}
                          maxDate={dayjs(maxDate)}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <AautiTextInput
                  text={AautiStrings.BatchTitleString}
                  onChange={(e) => {
                    editItem
                      ? changeEditItem(e.target.value, "batchName")
                      : onChangetimeSlotObj(e.target.value, "batchName");
                  }}
                  important
                  maxLength={50}
                  value={
                    editItem
                      ? editItem?.each?.batchName
                      : courseBatch?.batchName
                  }
                  style={{ width: "100%" }}
                />
                <HelperText
                  title={`${
                    editItem
                      ? editItem?.each?.batchName?.length
                      : courseBatch?.batchName?.length || 0
                  }/50 (minimum of 5 Characters)`}
                />
              </div>
            </div>
          </div>

          <div className="level-main">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <AautiText
                title={courseConstant?.selectBatchType}
                size={"normal"}
                textStyle={{ marginBottom: 4 }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let type =
                    activeBatch == 10
                      ? "Daily"
                      : activeBatch == 20
                      ? "Weekly"
                      : "Custom";
                  if (activeBatch == 10) {
                    serviceProviderConflicts(type, {
                      ...courseBatch,
                      dailyTimeSlots: [
                        {
                          startTime: moment(courseBatch?.startTime)
                            .local()
                            .format(),
                          endTime: moment(courseBatch?.endTime)
                            .local()
                            .format(),
                        },
                      ],
                    });
                  } else {
                    let weekSlots = [];
                    Object.keys(courseBatch?.recurringDays)?.map((each) => {
                      weekSlots?.push({
                        day: each,
                        slots: courseBatch?.recurringDays[each],
                      });
                    });

                    serviceProviderConflicts(type, {
                      ...courseBatch,
                      weekSlots: weekSlots,
                    });
                  }
                }}
              >
                <AautiText
                  title={courseConstant?.conflicts}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{ marginBottom: 4, color: "#3083EF" }}
                />
                <img
                  src={rightConflicts}
                  alt="right-conflict"
                  style={{ width: 20, height: 12 }}
                />
              </div>
            </div>
            <div className="flex-container">
              <FormControl sx={{ minWidth: "20%" }} size="medium">
                <InputLabel id="demo-simple-select-label">
                  Batch Type
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  autoWidth
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  value={activeBatch}
                  label={AautiStrings.BatchTypeString}
                  onChange={(event) => {
                    setActiveBatch(event.target.value);
                    if (event.target.value == 20) {
                      const oneWeek = moment(courseBatch?.startDate)
                        .add(1, "week")
                        .format("YYYY-MM-DDT00:00:00");
                      setCourseBatch({
                        ...courseBatch,
                        endDate: oneWeek,
                        recurringDays: {
                          Sunday: [],
                          Monday: [],
                          Tuesday: [],
                          Wednesday: [],
                          Thursday: [],
                          Friday: [],
                          Saturday: [],
                        },
                        discountEndDate: oneWeek,
                      });
                    }
                  }}
                >
                  <MenuItem
                    value={10}
                    // disabled={activeBatch != 10 && editBatch}
                  >
                    Daily
                  </MenuItem>
                  <MenuItem
                    value={20}
                    // disabled={activeBatch != 20 && editBatch}
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem
                    value={30}
                    // disabled={activeBatch != 30 && editBatch}
                  >
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
              {returnBatchType()}
            </div>
          </div>

          <AautiText
            title={AautiStrings.ClassTypeString}
            required
            textStyle={{ display: !showBatchType && "none" }}
          />
          <div
            className="level-main"
            style={{
              display: !showBatchType ? "none" : "flex",
              flexDirection: "row",
              gap: 6,
              alignItems: "center",
              marginTop: 1,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              }}
            >
              <input
                checked={courseBatch?.virtual}
                onChange={(e) => {
                  const check = e.target.checked;
                  onChangeRadio(check, "virtual");
                  onChangeRadio(!check, "inPerson");
                }}
                type="radio"
                id="virtal-radio"
                style={{ height: 20, width: 20, cursor: "pointer" }}
              />
              <label htmlFor="virtal-radio">
                <AautiText title={AautiStrings.VirtualString} />
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="inperson-radio"
                checked={courseBatch?.inPerson}
                onChange={(e) => {
                  const check = e.target.checked;
                  onChangeRadio(check, "inPerson");
                  onChangeRadio(!check, "virtual");
                }}
                style={{ height: 18, width: 18, cursor: "pointer", margin: 0 }}
              />
              <label htmlFor="inperson-radio">
                <AautiText title={AautiStrings.InPersonString} />
              </label>
            </div>
          </div>

          <div
            className="level-main"
            style={{ display: activeBatchType != "Group" && "none" }}
          >
            <AautiTextInput
              maxLength={3}
              style={{ width: "100%" }}
              text={AautiStrings.NoOfStudentsString}
              size="small"
              important
              value={
                (courseBatch?.maxNoOfStudents !== undefined &&
                  courseBatch?.maxNoOfStudents) ||
                0
              }
              onChange={(e) => {
                const number = Number(e.target.value) || 0;
                onChangeAddPrice(number, "maxNoOfStudents");
              }}
            />
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              <input
                checked={courseBatch?.allowWaitingList || false}
                type="checkbox"
                onChange={(e) => {
                  onChangeAddPrice(e.target.checked, "allowWaitingList");
                }}
                id="waiting-list-check"
                style={{ height: "20px", width: "20px" }}
              />
              <label htmlFor="waiting-list-check">Allow Waiting List</label>
            </div>
          </div>
          <div>
            <AautiText
              title={courseConstant?.step3Pricing}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div>
              <AautiTextInput
                disable={
                  courseBatch?._id && !isEmpty(CreateCourseObj?.endUserList)
                }
                important
                key={"number"}
                size="small"
                style={{ width: "100%", marginTop: 0.5 }}
                maxLength={6}
                value={
                  activeBatchType === "Group"
                    ? courseBatch?.localGroupPrice || 0
                    : courseBatch?.localIndividualPrice || 0
                }
                text={
                  activeBatchType === "Group"
                    ? "Group Price"
                    : "Individual Price"
                }
                onChange={(e) => {
                  const number = Number(e.target.value);
                  if (!isNaN(number)) {
                    onChangeAddPrice(
                      number,
                      activeBatchType === "Group"
                        ? "localGroupPrice"
                        : "localIndividualPrice"
                    );
                    onChangeAddPrice(
                      convertINRtoUSD(number),
                      activeBatchType === "Group"
                        ? "groupPrice"
                        : "individualPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "groupDiscountPrice"
                        : "individualDiscountPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "groupDiscountValue"
                        : "individualDiscountValue"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "localGroupDiscountPrice"
                        : "localIndividualDiscountPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "localGroupDiscountValue"
                        : "localIndividualDiscountValue"
                    );
                  }
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <AautiText
                  title={`minimum price should be ${minimumCurrency}`}
                  size={"small"}
                />
                <AautiText
                  title={`ASP - ${onlyPriceWithCurrency(
                    onlyPriceConvert(
                      returnRelatedPricing(
                        activeBatchType === "Group"
                          ? "groupPrice"
                          : "individualPrice"
                      )
                      // activeBatchType === "Group"
                      //   ? returnRelatedPricing(
                      //       activeBatchType === "Group"
                      //         ? "groupPrice"
                      //         : "individualPrice"
                      //     ).groupPrice
                      //   : returnRelatedPricing(
                      //       activeBatchType === "Group"
                      //         ? "groupPrice"
                      //         : "individualPrice"
                      //     ).individualPrice
                    ).toFixed()
                  )}`}
                  size={"small"}
                />
              </div>

              <div
                className="level-main"
                style={{
                  display: activeBatchType === "Group" ? "none" : "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                    marginBottom: 10,
                  }}
                >
                  <input
                    checked={courseBatch?.allowMembers || false}
                    disabled={!courseBatch?.localIndividualPrice}
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        onChangeAddPrice(e.target.checked, "allowMembers");
                        handleMembersDiscountApi();
                      } else {
                        onChangeAddPrice(e.target.checked, "allowMembers");
                        onChangeAddPrice([], "membersDiscount");
                      }
                    }}
                    id="allow-members"
                    style={{ height: "25px", width: "25px" }}
                  />
                  <label htmlFor="allow-members" style={{ cursor: "pointer" }}>
                    <AautiText
                      title={courseConstant?.allowAdditionalFamilyMembersToJoin}
                    />
                    <AautiText
                      title={
                        courseConstant?.discountForEachAdditionalMemeberBeyondTheFirst
                      }
                      size={"small"}
                    />
                  </label>
                </div>
                <button
                  onClick={() => {
                    setDialogOpen("membersDiscount");
                  }}
                  style={{
                    background: "none",
                    display: !courseBatch?.allowMembers && "none",
                    border: "none",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#3083EF",
                  }}
                >
                  View
                </button>
              </div>
              <div className="level-main">
                <AautiText
                  title={courseConstant?.discountType}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{ marginBottom: 4 }}
                />
                <div className="flex-container">
                  <FormControl
                    sx={{ minWidth: "20%", marginTop: 1 }}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Discount Type
                    </InputLabel>
                    <Select
                      sx={{ width: "100%" }}
                      autoWidth
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-label"
                      value={
                        courseBatch?.discountType === "Percentage" ? 10 : 20
                      }
                      label={"Discount Type"}
                      onChange={(event) => {
                        if (event.target.value == 10) {
                          setCourseBatch((prev) => ({
                            ...prev,
                            discountType: "Percentage",
                          }));
                          onChangeAddPrice(
                            "",
                            activeBatchType === "Group"
                              ? "groupDiscountValue"
                              : "individualDiscountValue"
                          );
                          onChangeAddPrice(
                            "",
                            activeBatchType === "Group"
                              ? "localGroupDiscountValue"
                              : "localIndividualDiscountValue"
                          );
                        } else if (event.target.value == 20) {
                          setCourseBatch((prev) => ({
                            ...prev,
                            discountType: "Amount",
                          }));
                          onChangeAddPrice(
                            "",
                            activeBatchType === "Group"
                              ? "groupDiscountValue"
                              : "individualDiscountValue"
                          );
                          onChangeAddPrice(
                            "",
                            activeBatchType === "Group"
                              ? "localGroupDiscountValue"
                              : "localIndividualDiscountValue"
                          );
                        }
                      }}
                    >
                      <MenuItem value={10}>Percentage</MenuItem>
                      <MenuItem value={20}>Amount</MenuItem>
                    </Select>
                  </FormControl>
                  <div style={{ width: "80%" }}>
                    <AautiTextInput
                      labelStyle={{ zIndex: 201 }}
                      disable={
                        activeBatchType === "Group"
                          ? !courseBatch?.groupPrice
                          : !courseBatch?.individualPrice
                      }
                      size="small"
                      maxLength={
                        courseBatch?.discountType == "Percentage"
                          ? 2
                          : activeBatchType === "Group"
                          ? courseBatch?.groupPrice?.length
                          : courseBatch?.individualPrice?.length
                      }
                      style={{ width: "100%" }}
                      text={
                        activeBatchType == "Group"
                          ? "Group Discount Value"
                          : "Individual Discount Value"
                      }
                      value={
                        activeBatchType === "Group"
                          ? courseBatch?.localGroupDiscountValue || ""
                          : courseBatch?.localIndividualDiscountValue || ""
                      }
                      onChange={(e) => {
                        const number = Number(e.target.value) || 0;
                        if (
                          courseBatch?.discountType === "Amount" &&
                          (activeBatchType === "Group"
                            ? number < courseBatch?.groupPrice
                            : number < courseBatch?.individualPrice)
                        ) {
                          onChangeAddPrice(
                            number,
                            activeBatchType === "Group"
                              ? "localGroupDiscountValue"
                              : "localIndividualDiscountValue"
                          );
                          onChangeAddPrice(
                            parseFloat(convertINRtoUSD(number)) || "",
                            activeBatchType === "Group"
                              ? "groupDiscountValue"
                              : "individualDiscountValue"
                          );
                        } else if (courseBatch?.discountType === "Percentage") {
                          const localMainPrice =
                            activeBatchType == "Group"
                              ? courseBatch?.localGroupPrice
                              : courseBatch?.localIndividualPrice;
                          const discountedPrice =
                            (localMainPrice * number) / 100;
                          const actPriceWithDic =
                            localMainPrice - discountedPrice;
                          if (actPriceWithDic >= convrtedPrice) {
                            onChangeAddPrice(
                              number,
                              activeBatchType === "Group"
                                ? "groupDiscountValue"
                                : "individualDiscountValue"
                            );
                            onChangeAddPrice(
                              number,
                              activeBatchType === "Group"
                                ? "localGroupDiscountValue"
                                : "localIndividualDiscountValue"
                            );
                          }
                        }
                      }}
                      endAdornment={
                        courseBatch?.discountType === "Percentage" ? (
                          <InputAdornment position="start">
                            {courseBatch?.discountType === "Percentage" ? (
                              <PercentIcon fontSize="18px" />
                            ) : (
                              countryObject?.countryCurrencySymbol || ""
                            )}
                          </InputAdornment>
                        ) : null
                      }
                      startAdornment={
                        courseBatch?.discountType === "Amount" ? (
                          <InputAdornment position="start">
                            {courseBatch?.discountType === "Percentage" ? (
                              <PercentIcon fontSize="18px" />
                            ) : (
                              countryObject?.countryCurrencySymbol || ""
                            )}
                          </InputAdornment>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="level-main">
                <AautiText
                  title={courseConstant?.selectDiscountValidDates}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{ marginBottom: 4 }}
                />

                <div
                  className="flex-container"
                  style={{
                    gap: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem label="Start Date">
                          <DatePicker
                            disabled={
                              activeBatchType === "Group"
                                ? !courseBatch?.groupDiscountValue
                                : !courseBatch?.individualDiscountValue
                            }
                            value={
                              courseBatch !== null &&
                              dayjs(courseBatch?.discountStartDate)
                            }
                            onChange={(e) => {
                              onChangeDate(e, "discountStartDate");
                            }}
                            defaultValue={
                              courseBatch !== null
                                ? dayjs(courseBatch?.discountStartDate)
                                : dayjs(today)
                            }
                            format="DD/MM/YYYY"
                            minDate={
                              courseBatch !== null
                                ? dayjs(courseBatch?.startDate)
                                : dayjs(today)
                            }
                            maxDate={
                              courseBatch !== null
                                ? dayjs(courseBatch?.endDate)
                                : dayjs(today)
                            }
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div style={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem label={AautiStrings.EndDateString}>
                          <DatePicker
                            disabled={
                              activeBatchType === "Group"
                                ? !courseBatch?.groupDiscountValue
                                : !courseBatch?.individualDiscountValue
                            }
                            value={
                              courseBatch !== null &&
                              dayjs(courseBatch?.discountEndDate)
                            }
                            onChange={(e) => {
                              onChangeDate(e, "discountEndDate");
                            }}
                            format="DD/MM/YYYY"
                            defaultValue={
                              courseBatch !== null
                                ? dayjs(courseBatch?.startDate)
                                : dayjs(today)
                            }
                            minDate={
                              courseBatch !== null
                                ? dayjs(courseBatch?.startDate)
                                : dayjs(today)
                            }
                            maxDate={
                              courseBatch !== null
                                ? dayjs(courseBatch?.endDate)
                                : dayjs(today)
                            }
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-container buttons-cont">
            <NavButton
              disabled={addSlotValidations()}
              text={editBatch ? "Update batch" : "Save Batch"}
              handleClick={() => {
                const inTime = moment(courseBatch?.startTime);
                const now = moment();
                const hasPassed =
                  inTime.isBefore(now) &&
                  inTime.isSame(courseBatch?.startDate, "day");
                const startDate = moment(courseBatch?.startDate, "YYYY-MM-DD");
                const endDate = moment(courseBatch?.endDate, "YYYY-MM-DD");
                const isValidStartDate = startDate.isSameOrAfter(now, "day");
                const isValidEndDate = endDate.isSameOrAfter(now, "day");
                const validDates = isValidStartDate && isValidEndDate;
                if (hasPassed && activeBatch == 10) {
                  showToast("info", "Please check start time");
                } else if (!editBatch && !editCourse && !validDates) {
                  showToast("info", "Please check batch start & end dates");
                } else if (activeBatch == 20 && !hasWeekKey) {
                  showToast("info", "Please select days");
                } else {
                  handleSaveBatch();
                }
              }}
            />
          </div>
        </div>
      </div>

      <AautiDialog
        titlePosition="left"
        dialogTitle={
          dialogOpen == "membersDiscount"
            ? "Members Discount"
            : "Select Day & Time"
        }
        open={!isEmpty(dialogOpen)}
        onClose={() => {
          setDialogOpen("");
        }}
        sxStyles={{ padding: "20px 20px 20px 20px" }}
      >
        {dialogOpen == "weekly" && (
          <WeeklySlotSelection
            {...{
              courseBatch,
              editItem,
              nextTimeSlot,
              setCourseBatch,
              setEditItem,
            }}
          />
        )}
        {dialogOpen == "custom" && (
          <CustomSlotSelection
            closePop={() => {
              setDialogOpen("");
            }}
            {...{
              courseBatch,
              setCourseBatch,
              nextTimeSlot,
              onChangetimeSlotObj,
            }}
          />
        )}
        {dialogOpen == "membersDiscount" && (
          <MembersConditions
            {...{
              membersDiscount,
              handlePercentageChange,
              setDialogOpen,
            }}
          />
        )}
      </AautiDialog>
    </div>
  );
};

export default CoursePricing;
