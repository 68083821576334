import { ApiMethods, ApiPaths } from "./api-constants";
import { ApiRequest } from "./api-request";

function loginAuth(userData, onSuccess, onFailure) {
  const { username, password } = userData;
  ApiRequest({
    url: ApiPaths.LOGIN,
    method: ApiMethods.POST,
    params: { username, password },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function saveuserCountry(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/updateUserData",
    method: "PATCH",
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllCartsByUser(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_CARTS_BY_USER + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getConversionRates(onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getConversionRate",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCoursesBySubCategoryList(category, data, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_COURSES_BY_SUBCATEGORY_LIST + data + "&category=" + category,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveRatingsReviews(ratingsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_RATINGS_REVIEWS,
    method: ApiMethods.POST,
    data: ratingsData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllCategories(onSuccess, onFailure) {
  ApiRequest({ url: ApiPaths.GET_ALL_CATEGORIES, method: ApiMethods.GET }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getSpConflicts(data, onSuccess, onFailure) {
  ApiRequest({
    url: "/session/getConflictSPTimeSlots",
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function complaintSave(complaintData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_COMPLAINT_SAVE,
    method: ApiMethods.POST,
    data: complaintData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllNotifications(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_NOTIFICATIONS,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllNotificationsByUserId(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_NOTIFICATIONS_USER + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function saveCart(cartData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_UPDATE_CART,
    method: ApiMethods.POST,
    data: cartData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function socialLogin(userData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SOCIAL_LOGIN,
    method: ApiMethods.POST,
    data: userData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getWishListDataByUserId(props, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_WISHLIST_DATA_BY_USERID,
    method: ApiMethods.GET,
    params: props,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveWishList(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_SAVE_WISHLIST,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteWishList(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_WISHLIST,
    method: ApiMethods.DELETE,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getLessonPlan(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_LESSON_PLAN,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateUserDetails(userDetailsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPADTE_USER_DETAILS,
    method: ApiMethods.PUT,
    data: userDetailsData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function addOrUpdateFCMToken(deviceDetails, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_FCM_TOKEN_DETAILS,
    method: ApiMethods.POST,
    data: deviceDetails,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllSubscriptionsByUserID(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_SUBSCRIPTIONS_BY_USER_ID,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getTimeslotsCourseSchedules(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.COURSE_MATCH_MY_SCHEDULE,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveUserData(requestData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVEUSER,
    method: ApiMethods.POST,
    requestData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllCoursesApi(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.GETALLCONTENTS,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getCourseByCategory(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesListByCategory?",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
async function getCoursesByCourseId(id) {
  const apiEndpoint = ApiPaths.GET_COURSE_BY_COURSE_ID;
  const apiMethod = ApiMethods.GET;
  const params = { id };
  try {
    const response = await ApiRequest({
      url: apiEndpoint,
      method: apiMethod,
      params: params,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
function sendMail(email, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SEND_MAIL + email,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function GetuserDataById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERSBYIDNEW,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function savePackageDetails(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.SAVE_PACKAGE,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getGigCompleteDetails(gigID, endUserID, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GIG_DETAILS + `id=${gigID}&endUserId=${endUserID}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getGigCompleteDetailsBeforeLogin(gigID, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GIG_DETAILS + `id=${gigID}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function deleteWishlistItem(id, onDeleteSuccess, onDeleteFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_WISHLIST_ITEM,
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onDeleteSuccess(response?.data);
    },
    (error) => {
      onDeleteFailure();
    }
  );
}

function getMyGigsForEndUser(loggedInUserId, onSuccess, onFailure) {
  // let param = `?endUserId=${loggedInUserId}`
  ApiRequest({
    url: ApiPaths.GET_MYGIGSBYUSERID_ENDUSER + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyGigsForServiceProvider(data, onSuccess, onFailure) {
  // alert("Hello");
  ApiRequest({
    url: "gig/getGigListByServiceProviderId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getDraftedGigsBySpID(userId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths?.BASE_URL +
      ApiPaths?.GET_DRAFT_GIGS_BY_SERVICEPROVIDER_ID +
      userId,
    method: ApiMethods?.GET,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderDashboardDetails(
  loggedInUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDER_DASHBOARD_DETAILS + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRatingsReviewsByServiceProviderId(
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_RATING_REVIEWS_BY_SERVICEPROVIDER_ID + serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getSubCategory(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETSUBCATEGORY_BYCATEGORY + id,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteCart(cartData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETECART,
    method: ApiMethods.DELETE,
    data: cartData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function deleteDraftedGig(id, onSuccess, onFailure) {
  ApiRequest({
    url: "draftGig/deleteDraftGig",
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getOrderDetails(noOfDays, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETORDERDETAILS,
    method: ApiMethods.GET,
    params: {
      endUserId,
      noOfDays,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
const getSessionsBetweenDatesUrl = ({ role, startDate, endDate, userId }) => {
  const {
    GET_SERVICE_PROVIDER_START_END_DATES_SESSIONS,
    GET_END_USER_START_END_DATES_SESSIONS,
  } = ApiPaths;
  if (role === "serviceprovider") {
    return `${GET_SERVICE_PROVIDER_START_END_DATES_SESSIONS}endDate=${endDate}&serviceProviderId=${userId}&startDate=${startDate}`;
  }
  return `${GET_END_USER_START_END_DATES_SESSIONS}endDate=${endDate}&endUserId=${userId}&startDate=${startDate}`;
};
function getSessionsBetweenDates(
  { role, startDate, endDate, userId },
  onSuccess,
  onFailure
) {
  const sessionsUrl = getSessionsBetweenDatesUrl({
    role,
    startDate,
    endDate,
    userId,
  });
  ApiRequest({
    url: sessionsUrl,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response === undefined ? [] : response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveAdhocSchedule(data, handleSaveAdhocSchedule, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_SAVE_ADHOC_SCHEDULE,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      handleSaveAdhocSchedule(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAttendance(noOfDays, serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ATTENDANCE +
      "?noOfDays=" +
      noOfDays +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getEndUserAttendance(noOfDays, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ENDUSER_ATTENDANCE +
      "?endUserId=" +
      endUserId +
      "&serviceProviderCount=" +
      noOfDays,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getMyCoursesByUserid(userId, onSuccess, onFailure, noOfDays) {
  ApiRequest({
    url:
      ApiPaths.GET_MY_COURSES_BY_USERID +
      "?noOfDays=" +
      noOfDays +
      "&offset=" +
      0 +
      "&count=100&userId=" +
      userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
//anudeep
function getWishListServiceProvidersDataByEndUserId(
  props,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_WISHLIST_SERVICEPROVIDERS_DATA_BY_ENDUSERID,
    method: ApiMethods.GET,
    params: props,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateRatingsReviews(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_RATING_REVIEWS,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getCompleteOrderDetails(orderId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETCOMPLETEORDERDETAILS,
    method: ApiMethods.GET,
    params: {
      orderId,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTotalRevenueByServiceProviderId(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOTAL_REVENUE_BY_SERVICEPROVIDER_ID + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllInstituteTypes(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_INSTITUTE_TYPES,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAllAcademicSystems(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_ACADEMIC_SYSTEMS,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAllGrades(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_All_GRADES,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getGigReviewsById(gigId, sortBy, onSuccess, OnFailure) {
  ApiRequest({
    url: ApiPaths.GET_GIG_REVIEWS_BY_GIGID + `gigId=${gigId}`,
    method: ApiMethods.GET,
    params: { sortBy },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      OnFailure(error);
    }
  );
}
function getUpcomingCoursesByUserId(userId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_UPCOMING_COURSES_BY_USER_ID +
      "?userId=" +
      userId +
      "&noOfDays=150&count=25&offset=0",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getEndUserDashboardDetails(loggedInUserId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_END_DASHBOARD_DETAILS + "endUserId=" + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getPackageDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGE_DETAILS_BY_ID + id,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getPackagesByCourseIds(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGES_BY_COURSE_IDS + data,
    method: ApiMethods.GET,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderDetails(id, endUserid, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id, endUserid },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderDetailsBeforeLogin(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getUserLocationByLatLang(lattitude, longitude, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_USER_LOCATION +
      "longitude=" +
      longitude +
      "&latitude=" +
      lattitude,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function deleteCourse(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_COURSE + id,
    method: ApiMethods.DELETE,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getCourseReviewsById(courseId, onSuccess, OnFailure) {
  ApiRequest({
    url: ApiPaths.GET_REVIEWS_BY_COURSE_ID + courseId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      OnFailure(error);
    }
  );
}

function deleteUserGigById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_GIG_BY_ID,
    method: "DELETE",
    params: { id },
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getServiceProviderRevenueByServiceProviderId(
  SpId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDER_REVENUE_BY_SERVICEPROVIDER_ID + SpId,
    method: ApiMethods.GET,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCoursesBySubCategory(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesListByCategoryAndSubcategory",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function saveAttendance(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_ATTENDANCE,
    method: ApiMethods.POST,
    data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getReports(type, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_REPORTS + `?type=${type}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSessionDetailsById(sessionId, onSuccess, onFailure) {
  ApiRequest({
    url: "session/getSessionDetailsById?sessionId=" + sessionId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getProvidersByUserId(props, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_MY_PROVIDERS,
    method: ApiMethods.GET,
    params: props,
  }).then(
    (resp) => {
      onSuccess(resp?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function generateJWT(data, onSuccess, onFailure) {
  ApiRequest({
    url: "session/generateJwtToken",
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAllMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList?",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function updatePackageDetails(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.UPDATE_PACKAGE,
    method: ApiMethods.PUT,
    data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deletePackage(packageId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.DELETE_PACKAGE + packageId,
    method: ApiMethods.DELETE,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function GetAllCountries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateRoomFreeze(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_ROOM_FREEZE,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSessionsByCourseIdBatchId(data, onSuccess, onFailure) {
  ApiRequest({
    url: "session/getRecentSession",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllFiltersData(onSuccess, onFailure) {
  ApiRequest({
    url: "filters/getAllFilters",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure();
    }
  );
}

function getPackagesListBySP(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGES_BY_SERVICEPROVIDER_ID,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess&& onSuccess(response?.data);
    },
    (error) => {
      onFailure&&   onFailure();
    }
  );
}


export const AuthService = {
  getUpcomingCoursesByUserId,
  getDraftedGigsBySpID,
  updatePackageDetails,
  deletePackage,
  getAllMembers,
  generateJWT,
  getProvidersByUserId,
  getReports,
  saveAttendance,
  getCourseReviewsById,
  deleteCourse,
  getCompleteOrderDetails,
  getCoursesBySubCategoryList,
  updateRatingsReviews,
  loginAuth,
  saveuserCountry,
  getServiceProviderDetailsBeforeLogin,
  getAllCategories,
  getTimeslotsCourseSchedules,
  getAllNotifications,
  saveAdhocSchedule,
  getSessionsByCourseIdBatchId,
  getAllCoursesApi,
  saveCart,
  socialLogin,
  getAllNotificationsByUserId,
  saveWishList,
  getLessonPlan,
  updateUserDetails,
  deleteWishList,
  getWishListDataByUserId,
  saveRatingsReviews,
  addOrUpdateFCMToken,
  getAllSubscriptionsByUserID,
  saveUserData,
  sendMail,
  GetuserDataById,
  getMyCoursesByUserid,
  deleteWishlistItem,
  getMyGigsForEndUser,
  getMyGigsForServiceProvider,
  getServiceProviderDashboardDetails,
  getRatingsReviewsByServiceProviderId,
  getSubCategory,
  deleteCart,
  deleteDraftedGig,
  getOrderDetails,
  getSessionsBetweenDates,
  getAttendance,
  getEndUserAttendance,
  savePackageDetails,
  getCoursesByCourseId,
  getWishListServiceProvidersDataByEndUserId,
  getTotalRevenueByServiceProviderId,
  getGigCompleteDetails,
  getAllInstituteTypes,
  getAllAcademicSystems,
  getAllGrades,
  getGigReviewsById,
  getGigCompleteDetailsBeforeLogin,
  getEndUserDashboardDetails,
  getPackagesByCourseIds,
  getPackageDetailsById,
  getServiceProviderDetails,
  getUserLocationByLatLang,
  deleteUserGigById,
  getServiceProviderRevenueByServiceProviderId,
  getCoursesBySubCategory,
  getConversionRates,
  GetAllCountries,
  getAllCartsByUser,
  updateRoomFreeze,
  complaintSave,
  getSessionDetailsById,
  getAllFiltersData,
  getCourseByCategory,
  getPackagesListBySP
};
