import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AautiText from "../globalComponents/AautiText";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthService } from "../../service/auth-service";
// import { showToast } from "../globalComponents/Toast";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import IndividualIcon from "../../assets/images/individual-svg.svg";
import { useDispatch } from "react-redux";
import GroupIcon from "../../assets/images/group-svg.svg";
import {
  setSpWishlistItem,
  toggleSignInPop,
} from "../../redux/reducer/appReducer";
import _, { isEmpty } from "lodash";
import VirtualIcon from "../globalComponents/icons/VirtualIcon";
import InPerson from "../globalComponents/icons/InPerson";
import ShareIcon from "../globalComponents/icons/ShareIcon";
import WishIcon from "../globalComponents/icons/WishIcon";
import CourseImage from "../globalComponents/CourseImage";
import {
  CapitalizeFirstLetter,
  PriceConversions,
  returnIcon,
} from "../CommonFunctions";
import TrashIcon from "../globalComponents/icons/TrashIcon";
import NewShare from "../../assets/images/share-wish.png";
import Cart from "../../assets/images/cart-icon.png";
import Icon from "../../assets/images/Share.svg";
import { IoCartSharp } from "react-icons/io5";
// import VirtualInPerson from "../globalComponents/icons/VirtualInPersonIcon";
import "./Style.scss";
import { checkNull } from "../institution/assigning/Container";
import AautiDialog from "../globalComponents/AautiDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Coursecard = ({
  each,
  title,
  cardWidth = "269px",
  isWishlist = false,
  deleteListAPI,
  isEnrolled = false,
  handleSaveWish,
  handleGetBatchesList,
  spName,
  adhoc = false,
}) => {
  const dispatch = useDispatch();
  const path = window.location.origin;
  const [id, setId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const isBefore = moment(new Date()).isBefore(each?.batchEndTime);
  const { pathname } = useLocation();
  const isMylearnings = pathname === "/my-learnings";
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { convertPriceWithCurrency } = PriceConversions();
  const [eachCourse, setEachCourse] = useState(each);
  const { userDetails, loggedinUserId, isUserLoggedIn } = useSelector(
    (state) => state.app
  );
  const isInstituteRole = userDetails?.role?.includes("instituteAdmin");
  const navigate = useNavigate();
  const { innerWidth: windowInnerWidth } = window;
  const options = ["Move to Cart", "Add to Cart"];
  const onWishlistCourse = () => {
    const data = {
      type: "course",
      courseId: eachCourse?._id,
      gigId: null,
      serviceProviderId: eachCourse?.serviceProviderId,
      name: eachCourse?.courseName,
      description: eachCourse?.courseDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };
    AuthService.saveWishList(data, onSuccess, onFailure);
  };
  const deleteCourseFromWish = () => {
    const deleteData = {
      type: "course",
      courseId: eachCourse._id,
      gigId: null,
      serviceProviderId: eachCourse?.serviceProviderId,
      name: eachCourse?.courseName,
      description: eachCourse?.courseDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      isDeleted: false,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };
    AuthService.deleteWishList(deleteData, onSuccessRemove, onFailureRemove);
  };

  const handleConfirmDialogClose = (e) => {
    e.stopPropagation();
    setOpenDialog(false);
  };

  const onSuccess = (res) => {
    // showToast("success", "Added to Wishlist");
    setEachCourse((prev) => ({
      ...prev,
      isWishlisted: true,
    }));
  };
  const onFailure = (err) => {
    console.log(err);
  };
  const onSuccessRemove = (res) => {
    // showToast("success", "Removed from wishlist");
    setEachCourse((prev) => ({
      ...prev,
      isWishlisted: false,
    }));
  };
  const onFailureRemove = (err) => {
    console.log(err);
  };

  const isUpdatedRecently = checkNull(each?.updatedAt)
    ? false
    : moment(each?.updatedAt).diff(moment(new Date()), "days") > -1;

  const returnTag = () => {
    if (
      title?.includes("Blockbuster deals")
      // title?.includes("Similar courses by other Service Providers")
      // title?.includes("Discover higly rated courses")
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F9EF99",
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AautiText
            size={"semi"}
            textStyle={{ color: "#000" }}
            title={`Upto ${each?.adminDiscount || 0}% Off`}
          />
        </div>
      );
    } else if (title?.includes("Starting next 8 hours")) {
      const sortedArray = each?.startTime?.sort(
        (a, b) => new Date(a) - new Date(b)
      );
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            overflow: "hidden",
            width: "78%",
            overflowX: "scroll",
          }}
        >
          {sortedArray?.map((time, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F9EF99",
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 4,
                paddingBottom: 4,
                minWidth: "76px",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                minWidth: "80px",
              }}
            >
              <AautiText
                size={"semi"}
                textStyle={{ color: "#000" }}
                title={moment(time).local().format("hh:mm A")}
              />
            </div>
          ))}
        </div>
      );
    } else if (
      title?.includes("Trending") ||
      title?.includes("Upcoming") ||
      title?.includes("searchResults")
    ) {
      if (
        _.some(each?.endUserList, { userId: loggedinUserId }) ||
        each?.isPurchased
      ) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F9EF99",
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 4,
              paddingBottom: 4,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              size={"small"}
              textStyle={{ color: "#000" }}
              title={"Previously purchased"}
            />
          </div>
        );
      } else if (isUpdatedRecently) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F9EF99",
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 4,
              paddingBottom: 4,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              size={"small"}
              textStyle={{ color: "#000" }}
              title={"Recently updated"}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              background: "#fff",
              display: "flex",
              flexDirection: "row",
              padding: "13px 6px",
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        );
      }
    } else if (isMylearnings) {
      if (each?.cancelled) {
        return (
          <button
            style={{
              background: "red",
              padding: "2px 8px",
              border: "none",
              borderRadius: 4,
              color: "#fff",
            }}
          >
            <AautiText title={"Cancelled"} />
          </button>
        );
      } else {
        if (
          moment(new Date()).isBefore(each?.batchEndTime) &&
          moment(new Date()).isBefore(each?.batchStartTime)
        ) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F9EF99",
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AautiText
                size={"small"}
                textStyle={{ color: "#000" }}
                title={"Up Coming"}
              />
            </div>
          );
        } else if (
          moment(new Date()).isBefore(each?.batchEndTime) &&
          moment(new Date()).isAfter(each?.batchStartTime)
        ) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F9EF99",
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AautiText
                size={"small"}
                textStyle={{ color: "#000" }}
                title={"Ongoing"}
              />
            </div>
          );
        } else if (
          moment(new Date()).isAfter(each?.batchEndTime) &&
          moment(new Date()).isAfter(each?.batchStartTime)
        ) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F9EF99",
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AautiText
                size={"small"}
                textStyle={{ color: "#000" }}
                title={"Completed"}
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                background: "#fff",
                display: "flex",
                flexDirection: "row",
                padding: "13px 6px",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          );
        }
      }
    } else if (title?.includes("home_screen_card_no_login")) {
      if (each?.groupDiscountPrice || each?.individualDiscountPrice) {
        return (
          <div
            style={{
              display: isEmpty(
                each?.groupDiscountPrice || each?.individualDiscountPrice
              )
                ? "none"
                : "flex",
              flexDirection: "row",
              backgroundColor: "#F9EF99",
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 4,
              paddingBottom: 4,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              size={"small"}
              textStyle={{ color: "#000" }}
              title={`  Upto ${
                each.groupDiscountPrice
                  ? Math.round(
                      (1 - each?.groupDiscountPrice / each.groupPrice) * 100
                    )
                  : each.individualDiscountPrice
                  ? Math.round(
                      (1 -
                        each?.individualDiscountPrice / each.individualPrice) *
                        100
                    )
                  : 0
              }% off`}
            />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "row",
            padding: "13px 6px",
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      );
    }
  };

  const onSuccessDetails = (response) => {
    if (adhoc) {
      if (response?.status === "Success") {
        const obj = response?.result?.[0];
        navigate(`/join_class`, { state: obj });
      }
    } else {
      if (response?.data?.status === "Success") {
        const obj = response?.data?.result;

        navigate(`/join_class`, { state: obj });
      }
    }
  };

  return (
    <div
      onMouseEnter={() => {
        if (!adhoc) {
          setId(each?._id);
        }
      }}
      onMouseLeave={() => {
        if (!adhoc) {
          setId("");
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (!isMylearnings) {
          navigate(`/course-details/${each?.courseId || each?._id}`);
        } else {
          if (adhoc) {
            AuthService.getSessionDetailsById(
              each.sessionId,
              onSuccessDetails,
              onFailure
            );
          } else {
            const data = { courseId: each.courseId, batchId: each.batchId };
            AuthService.getSessionsByCourseIdBatchId(
              data,
              onSuccessDetails,
              onFailure
            );
          }
          // navigate("/join_class", { state: each });
        }
      }}
      style={{
        width: mobileDevices ? windowInnerWidth - 40 : cardWidth,
        border: "1px solid lightgrey",
        borderRadius: 8,
        minWidth: cardWidth,
        maxWidth: cardWidth,
        background: title?.includes("home_screen_card_no_login") && "#fff",
        height: "fit-content",
        // boxShadow:
        //   "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
        position: "relative",
        cursor: "pointer",
        marginBottom: 4,
      }}
    >
      <div style={{ position: "relative", height: "200px" }}>
        {id == each?._id || ipodDevices || mobileDevices ? (
          each?.relatedImages?.length > 0 ? (
            <Carousel
              infiniteLoop={true}
              interval={2000}
              autoPlay
              showArrows={false}
              showThumbs={false}
              showStatus={false}
            >
              {each?.relatedImages?.map((eachItem, index) => {
                return (
                  <div key={index}>
                    <>
                      <div
                        key={"carousal item " + index}
                        style={{ position: "relative" }}
                      >
                        <CourseImage
                          imgUrl={
                            eachItem ||
                            "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
                          }
                          // height="160px"
                        />
                      </div>
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(-180deg, rgba(201, 201, 201, 0) 3.070175438596502%, rgba(69, 69, 69, 0.75) 40.1885964912281%, #000000 98.24561403508774%)",
                          position: "absolute",
                          bottom: 0,
                          height: "70px",
                          width: "100%",
                        }}
                      />
                    </>
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <img
                  src="https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
                  alt="course"
                  style={{
                    height: "160px",
                    width: "100%",
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                  }}
                />
              </div>
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(-180deg, rgba(201, 201, 201, 0) 3.070175438596502%, rgba(69, 69, 69, 0.75) 40.1885964912281%, #000000 98.24561403508774%)",
                  position: "absolute",
                  bottom: 0,
                  height: "70px",
                  width: "100%",
                }}
              />
            </>
          )
        ) : (
          <div>
            <CourseImage
              imgUrl={
                each?.courseImage ||
                "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
              }
              // height="160px"
            />
          </div>
        )}
      </div>
      <div style={{ padding: 10 }} id="course-card-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={each?.courseName}>
            <div style={{ width: "90%" }}>
              <AautiText
                id={"course-card-name"}
                title={
                  CapitalizeFirstLetter(each?.courseName?.trim()) ||
                  "Name unavailable"
                }
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  width: "100%",
                  height: 39,
                  lineHeight: "19px",
                  zIndex: 200,
                }}
              />
            </div>
          </Tooltip>
          {returnIcon(each)}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 5,
            // background:
            //   "linear-gradient(0deg, rgba(69,69,67,0.7428221288515406) 21%, rgba(243,247,247,0) 35%)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: 5,
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Tooltip
              title={
                CapitalizeFirstLetter(
                  each?.displayName ||
                    each?.serviceProvider?.displayName ||
                    spName
                ) || "N/A"
              }
            >
              <div style={{ maxWidth: "50%" }}>
                <AautiText
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/sp-profile/${each?.serviceProviderId}`);
                  }}
                  title={
                    each?.displayName ||
                    each?.serviceProvider?.displayName ||
                    spName ||
                    "N/A"
                  }
                  size={"small"}
                  weight={"bold"}
                  textStyle={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    maxWidth: "100%",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                  }}
                  className={"sp-name-styles"}
                />
              </div>
            </Tooltip>
            <AautiText
              title={
                each?.yearsOfExperience
                  ? `| ${each?.yearsOfExperience} Years Exp`
                  : "| Exp not listed"
              }
              size={"small"}
              weight={"bold"}
              textStyle={{ color: "#5C5B57" }}
            />
          </div>
          {each?.courseLevel?.length > 3 ? (
            <AautiText
              title={"All Levels"}
              size={"small"}
              weight={"medium"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                width: "100%",
                color: "#5C5B57",
              }}
            />
          ) : (
            <AautiText
              title={each?.courseLevel?.join(", ") || "N/A"}
              size={"small"}
              weight={"medium"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                width: "100%",
                color: "#5C5B57",
              }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
            marginTop: 5,
          }}
        >
          <AiFillStar size={18} color="#1e98d7" />
          <AautiText
            title={
              each?.averageRating ? each?.averageRating?.toFixed(1) : "New"
            }
            size={"semi"}
            weight={"medium"}
            textStyle={{ color: "#1e98d7" }}
          />
          {each?.ratingCount ? (
            <AautiText title={`(${each?.ratingCount || 0})`} size={"small"} />
          ) : null}
          {each?.subscribersCount ? (
            <AautiText
              title={`| ${
                each?.subscribersCount == 1
                  ? "1 Student"
                  : (each?.subscribersCount || 0) + " Students"
              }`}
              size={"small"}
            />
          ) : (
            <AautiText title={`| None registered yet!`} size={"small"} />
          )}
        </div>
        {isInstituteRole && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <BorderLinearProgress
                variant="determinate"
                value={60}
                valueBuffer={60}
              />
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 4,
                marginTop: 5,
              }}
            >
              <AautiText title={"10 AM"} size={"small"} />
              <AautiText title={"30 min left"} size={"small"} />
              <AautiText title={"11 AM"} size={"small"} />
            </div>
          </>
        )}
        {/* {!isInstituteRole && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginTop: 4,
            }}
          >
            <AautiText
              title={"From"}
              size={"semi"}
              textStyle={{ display: isMylearnings && "none" }}
            />
            <div
              style={{
                display: isMylearnings ? "none" : "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
                width: "100%",
                gap: 6,
              }}
            >
              {(each?.individualDiscountPrice || each?.individualPrice) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={IndividualIcon}
                    alt="individual"
                    style={{ height: "18px", width: "18px", zIndex: 100 }}
                  />
                  <AautiText
                    size={"semi"}
                    weight={"bold"}
                    title={`${convertPriceWithCurrency(
                      each?.individualPrice || 0
                    )}`}
                  />
                </div>
              )}
              {(each?.groupDiscountPrice || each?.groupPrice) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={GroupIcon}
                    alt="individual"
                    style={{ height: "19px", width: "19px", zIndex: 100 }}
                  />
                  <AautiText
                    title={`${convertPriceWithCurrency(each?.groupPrice || 0)}`}
                    size={"semi"}
                    weight={"bold"}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: !isMylearnings && "none",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {each?.batchType == "individual" ? (
                    <img
                      src={IndividualIcon}
                      alt="individual"
                      style={{ height: "18px", width: "18px", zIndex: 100 }}
                    />
                  ) : (
                    <img
                      src={GroupIcon}
                      alt="individual"
                      style={{ height: "18px", width: "18px", zIndex: 100 }}
                    />
                  )}
                  <AautiText
                    size={"semi"}
                    weight={"bold"}
                    title={`${convertPriceWithCurrency(each?.batchPrice)}`}
                  />
                </div>
                <AautiText
                  title={each?.batchName || "NA"}
                  weight={"bold"}
                  size={"semi"}
                  textStyle={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    maxWidth: "60%",
                  }}
                />
              </div>
            </div>
          </div>
        )} */}
        {!isInstituteRole && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginTop: 4,
            }}
          >
            <AautiText
              title={"From"}
              size={"semi"}
              textStyle={{ display: (isMylearnings || isEnrolled) && "none" }}
            />
            <div
              style={{
                display: isMylearnings || isEnrolled ? "none" : "flex",
                flexDirection: "row",
                width: "100%",
                gap: 20,
              }}
            >
              {(each?.individualDiscountPrice || each?.individualPrice) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={IndividualIcon}
                    alt="individual"
                    style={{ height: "18px", width: "18px", zIndex: 100 }}
                  />
                  <AautiText
                    size={"semi"}
                    weight={"bold"}
                    title={`${convertPriceWithCurrency(
                      each?.individualDiscountPrice ||
                        each?.individualPrice ||
                        0
                    )}`}
                  />
                </div>
              )}
              {(each?.groupDiscountPrice || each?.groupPrice) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={GroupIcon}
                    alt="individual"
                    style={{ height: "19px", width: "19px", zIndex: 100 }}
                  />
                  <AautiText
                    title={`${convertPriceWithCurrency(
                      each?.groupDiscountPrice || each?.groupPrice || 0
                    )}`}
                    size={"semi"}
                    weight={"bold"}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: isEnrolled || isMylearnings ? "" : "none",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {each?.batchType == "individual" ||
                  each?.courseType == "Individual" ? (
                    <img
                      src={IndividualIcon}
                      alt="individual"
                      style={{ height: "18px", width: "18px", zIndex: 100 }}
                    />
                  ) : (
                    <img
                      src={GroupIcon}
                      alt="individual"
                      style={{ height: "18px", width: "18px", zIndex: 100 }}
                    />
                  )}
                  <AautiText
                    size={"semi"}
                    weight={"bold"}
                    title={`${convertPriceWithCurrency(
                      each?.batchPrice ||
                        each?.price ||
                        each?.localPurchasedPrice
                    )}`}
                  />
                </div>
                <AautiText
                  title={each?.batchName || "NA"}
                  weight={"bold"}
                  size={"semi"}
                  textStyle={{
                    display: isEnrolled ? "none" : "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    maxWidth: "60%",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: title?.includes("home_screen_card_no_login")
              ? "flex-end"
              : returnTag
              ? "space-between"
              : "flex-end",
            alignItems: "center",
            marginTop: 4,
            width: "100%",
          }}
        >
          {returnTag()}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "end",
              gap: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ display: !isWishlist && "none" }}>
              {each?.addedToCart ? (
                <Tooltip title={"Already in cart"}>
                  <div
                    // className="removeCarthoverable" style
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(e);
                    }}
                  >
                    <IoCartSharp
                      size={mobileDevices ? 16 : 20}
                      style={{
                        color: "#3166ba",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title={"Cart"}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={openMenu ? "long-menu" : undefined}
                      aria-expanded={openMenu ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                      }}
                      sx={{
                        height: "16px",
                        width: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        //   backgroundColor: "#fff",
                        p: 0,
                        //   "&:hover": { background: "#fff" },
                      }}
                    >
                      <img
                        src={Cart}
                        alt="cart-icon"
                        style={{
                          height: "100%",
                          width: "100%",
                          zIndex: 100,
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={(e) => {
                      e.stopPropagation();
                      handleClose(e);
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: "18ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose(e);
                          handleSaveWish(each, option);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </div>
            <ShareIcon
              height={isWishlist ? "22px" : "20px"}
              width={isWishlist ? "22px" : "20px"}
              shareUrl={`${path}/course-details/${each?.courseId || each?._id}`}
              icon={isWishlist ? NewShare : Icon}
            />

            <div style={{ display: !isWishlist && "none" }}>
              <TrashIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDialog(true);
                }}
              />
            </div>
            <div style={{ display: (isWishlist || isMylearnings) && "none" }}>
              {eachCourse?.isWishlisted ? (
                <WishIcon
                  id={"wishlisted"}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCourseFromWish();
                  }}
                  active={eachCourse?.isWishlisted}
                />
              ) : (
                <WishIcon
                  id={"wishlit"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isUserLoggedIn) {
                      onWishlistCourse();
                    } else {
                      dispatch(
                        setSpWishlistItem({
                          type: "course",
                          courseId: each?._id,
                          gigId: null,
                          serviceProviderId: each?.serviceProviderId,
                          name: each?.courseName,
                          description: each?.courseDescription,
                          endUserId: null,
                          schedulerId: null,
                          parentId: null,
                        })
                      );

                      navigate("/login");
                    }
                  }}
                />
              )}
            </div>
            {isMylearnings &&
              !each?.cancelled &&
              each?.batchType?.toLowerCase() == "group" &&
              isBefore && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGetBatchesList(each);
                  }}
                  style={{
                    // display: each?.batchType === "group" && isBefore && "none",
                    background: "#3083EF",
                    color: "#fff",
                    padding: "4px 10px",
                    border: "none",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                >
                  Change Batch
                </button>
              )}
          </div>
          <AautiDialog
            onClose={(e) => handleConfirmDialogClose(e)}
            open={openDialog}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <AautiText
                textAlign={"left"}
                size={"normal"}
                weight={"bold"}
                textStyle={{
                  color: "#4B4D4D",
                }}
                title={`Are you sure want to proceed?`}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <button
                  className="yes-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteListAPI(each);
                  }}
                >
                  Yes
                </button>
                <button
                  className="no-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDialog(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </AautiDialog>
        </div>
      </div>
    </div>
  );
};

export default Coursecard;
