import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { isEmpty } from "lodash";
import { IconButton, Tooltip } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import { MdOutlineEdit } from "react-icons/md";

const RefundSectionView = ({
  completeDetails,
  creation = false,
  onClickEdit,
}) => {
  return (
    <div
      style={{
        display:
          completeDetails?.isRefundAvailable &&
          !isEmpty(completeDetails?.refundConditions)
            ? "block"
            : "none",
        border: "1px solid #f2f2f2",
        // height: "200px",
        width: "100%",
        marginBottom: 10,
        padding: 6,
        borderRadius: 4,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AautiText
          title={"Refund Details"}
          textStyle={{ marginBottom: 5 }}
          weight={"bold"}
        />
        <Tooltip title="Edit" sx={{ display: !creation && "none" }}>
          <IconButton onClick={onClickEdit}>
            <MdOutlineEdit size={18} />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        {completeDetails?.refundConditions?.map((conditions) => {
          return (
            <div
              style={{
                display: conditions?.isSelected ? "flex" : "none",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <AautiText
                title={CapitalizeFirstLetter(conditions?.name)}
                size={"semi"}
              />
              <AautiText
                textStyle={{ display: conditions?.key == -2 && "none" }}
                title={`${conditions?.percentage || 0}%`}
                size={"semi"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RefundSectionView;
